import { Divider, Typography } from '@material-ui/core';
import {
  CommunicationSendingRule,
  CommunicationSettings,
  CommunicationType,
  getCommunicationSettings,
  getIncludedVatAmountFromExcludedVatAmount,
  getTenantsName,
  getTransactionUnlinkedAmountForLease,
  getVatToApply,
  LeaseExtended,
  LinkType,
  PostingType,
  roundAtSecondDecimal,
} from '@rentguru/commons-utils';
import { ActionButton, CustomSimpleDialog } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isEmpty, isNil, sumBy, toUpper } from 'lodash';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PostingWithAmountSelected } from 'src/components/Accounting/Transactions/Reconciliation/LeaseTransactionsReconcilation';
import {
  linkInvoicesWithCreditNotes,
  linkTransactionWithInvoices,
} from 'src/components/Accounting/Transactions/Reconciliation/reconciliationsUtils';
import {
  AddTransactionFormValues,
  useTransactionUtils,
} from 'src/components/Accounting/Transactions/useTransactionUtils';
import { useAddEditLeaseUtils } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { getCommunicationSettingsProfile } from 'src/hooks/CommunicationSettingsProfilesContext';
import { InvoiceWithPostings, useInvoices } from 'src/hooks/InvoicesContext';
import { extendTransactions, fetchPostings, useTransactions } from 'src/hooks/TransactionsContext';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { v4 as uuidv4 } from 'uuid';
import { AddCustomInvoiceDialogProps } from './AddCustomInvoiceDialog';
import EditableCustomPosting from './EditableCustomPosting';
import NewBalancePart from './NewBalancePart';
import ReconciliationPart from './ReconciliationPart';
import {
  AddCustomInvoiceValues,
  CustomPosting,
  ReconciliationMethod,
  useAddCustomInvoiceStyles,
} from './addCustomInvoiceDialogUtils';

const getPostingsAmount = (lease: LeaseExtended, history: CustomPosting[], isPaid: boolean) => {
  const postingsAmount = history.reduce((acc, customPosting) => {
    const customPostingAmount = customPosting.amount ?? 0;
    const vatToApply = getVatToApply(lease, customPosting.accountLabel?.class ?? 0);
    const amountWithVAT = getIncludedVatAmountFromExcludedVatAmount(customPostingAmount, vatToApply);
    acc += amountWithVAT;
    return acc;
  }, 0);
  return isPaid ? postingsAmount : -postingsAmount;
};

const AddCustomInvoiceForm: React.FC<AddCustomInvoiceDialogProps> = ({ open, onClose, lease }) => {
  const { createNewTransaction } = useTransactionUtils();
  const { formatMessage } = useIntl();
  const { handleHistoricInvoiceIfNeeded } = useAddEditLeaseUtils();
  const {
    createTransaction,
    updateTransaction,
    createPosting,
    getAccountLabelForLease,
    getAccountLabelForBankAccountOrInsert,
    transactionRefetch,
  } = useTransactions();
  const { updateInvoice } = useInvoices();
  const classes = useAddCustomInvoiceStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [communicationSettings, setCommunicationSettings] = useState<CommunicationSettings | null>(null);
  const { values, setValues, setSubmitting, isSubmitting, validateForm } = useFormikContext<AddCustomInvoiceValues>();

  useEffect(() => {
    const fetchAndSet = async () => {
      const communicationSettingsProfile = await getCommunicationSettingsProfile(lease.communicationSettingsProfileId);
      const newCommunicationSettings = getCommunicationSettings(
        CommunicationType.LEASE_PAYMENT_REQUEST,
        communicationSettingsProfile
      );
      setCommunicationSettings(newCommunicationSettings);
    };
    fetchAndSet();
  }, [lease.communicationSettingsProfileId]);

  const unitId = lease.units?.find((unitLease) => unitLease.mainUnit)?.unitId ?? null;
  const oldLeaseBalance = lease.balance ?? 0;
  const postingsAmount = roundAtSecondDecimal(
    getPostingsAmount(lease, values.history, values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION)
  );
  const transactionToReconcileAmount = values.transactionToReconcile
    ? getTransactionUnlinkedAmountForLease(values.transactionToReconcile, lease.id)
    : 0;
  const creditNoteToReconcileAmount = values.creditNoteToReconcile?.amount ?? 0;
  const newLeaseBalance =
    values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION
      ? oldLeaseBalance
      : oldLeaseBalance + postingsAmount + transactionToReconcileAmount + creditNoteToReconcileAmount;

  const willInvoiceBePaid = () => {
    if (values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION) {
      return true;
    }
    if (
      values.reconciliationMethod === ReconciliationMethod.EXISTING_TRANSACTION &&
      values.transactionToReconcile &&
      getTransactionUnlinkedAmountForLease(values.transactionToReconcile, lease.id) === postingsAmount
    ) {
      return true;
    }
    return (
      values.reconciliationMethod === ReconciliationMethod.CREDIT_NOTE &&
      values.creditNoteToReconcile?.amount === postingsAmount
    );
  };

  const manageNewTransactionReconciliationMethod = async (
    createdInvoiceWithPostings: InvoiceWithPostings,
    operationDate: Date
  ) => {
    const addTransactionValues: AddTransactionFormValues = {
      linkId: lease.id,
      linkType: LinkType.Lease,
      sender: getTenantsName(lease.tenants),
      amount: postingsAmount,
      operationDate,
      bankAccountId: lease.bankAccountId!,
      remittanceInformation: values.remittanceInformation,
      remittanceInformationType: values.remittanceInformationType,
      rememberRule: false,
      invoiceMatch: createdInvoiceWithPostings,
    };
    await createNewTransaction(addTransactionValues, false);
  };

  const manageExistingTransactionReconciliationMethod = async (createdInvoiceWithPostings: InvoiceWithPostings) => {
    if (isNil(values.transactionToReconcile)) return;
    const operationsBundle = {
      createPosting,
      createTransaction,
      getAccountLabelForBankAccountOrInsert,
      getAccountLabelForLease,
      updateInvoice,
      updateTransaction,
    };
    const originalTransactionsOfLease = await extendTransactions([values.transactionToReconcile]);
    let amountLeft = getTransactionUnlinkedAmountForLease(values.transactionToReconcile, lease.id);
    const selectedPostings = createdInvoiceWithPostings.postings.reduce((acc, currentPosting) => {
      if (currentPosting.type === PostingType.CREDIT) {
        const newAmountLeft = amountLeft - currentPosting.totalAmount;
        const amountSelected = newAmountLeft > 0 ? currentPosting.totalAmount : amountLeft;
        amountLeft = amountLeft - amountSelected;
        acc.push({ ...currentPosting, amountSelected });
      }
      return acc;
    }, [] as PostingWithAmountSelected[]);

    await linkTransactionWithInvoices(
      {
        ...values.transactionToReconcile,
        amountLinked: getTransactionUnlinkedAmountForLease(values.transactionToReconcile, lease.id),
        amountToBeReconciled: 0,
      },
      selectedPostings,
      originalTransactionsOfLease,
      [createdInvoiceWithPostings],
      lease.id,
      operationsBundle
    );
  };

  const manageCreditNoteReconciliationMethod = async (createdInvoiceWithPostings: InvoiceWithPostings) => {
    if (isNil(values.creditNoteToReconcile)) return;
    const operationsBundle = {
      createPosting,
      createTransaction,
      getAccountLabelForBankAccountOrInsert,
      getAccountLabelForLease,
      updateInvoice,
      updateTransaction,
    };
    const selectedPostings = createdInvoiceWithPostings.postings.reduce((acc, currentPosting) => {
      if (currentPosting.type === PostingType.CREDIT) {
        acc.push({ ...currentPosting, amountSelected: currentPosting.totalAmount });
      }
      return acc;
    }, [] as PostingWithAmountSelected[]);

    const newPostingWithAmountSelected = values.creditNoteToReconcile.postings.reduce((acc, posting) => {
      if (posting.type === PostingType.DEBIT) {
        acc.push({ ...posting, amountSelected: posting.totalAmount });
      }
      return acc;
    }, [] as PostingWithAmountSelected[]);
    await linkInvoicesWithCreditNotes(
      lease.bankAccountId!,
      [...selectedPostings, ...newPostingWithAmountSelected],
      lease.id,
      [createdInvoiceWithPostings],
      operationsBundle
    );
    // Update invoice paid status if needed
    if (sumBy(selectedPostings, 'totalAmount') === sumBy(newPostingWithAmountSelected, 'totalAmount')) {
      await updateInvoice({ id: values.creditNoteToReconcile.id, paid: true, leaseId: lease.id });
    }
  };

  const handleCreateCustomInvoice = async () => {
    const errors = await validateForm();
    if (!isEmpty(Object.keys(errors))) return;
    setSubmitting(true);
    const createdInvoice = await handleHistoricInvoiceIfNeeded(lease, false, willInvoiceBePaid());
    if (!createdInvoice) {
      setSubmitting(false);
      return;
    }
    const invoicePostings = await fetchPostings('byInvoice', createdInvoice.id);
    const createdInvoiceWithPostings: InvoiceWithPostings = { ...createdInvoice, postings: invoicePostings };

    if (values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION) {
      await manageNewTransactionReconciliationMethod(createdInvoiceWithPostings, values.newTransactionOperationDate);
    } else if (values.reconciliationMethod === ReconciliationMethod.EXISTING_TRANSACTION) {
      await manageExistingTransactionReconciliationMethod(createdInvoiceWithPostings);
    } else if (values.reconciliationMethod === ReconciliationMethod.CREDIT_NOTE) {
      await manageCreditNoteReconciliationMethod(createdInvoiceWithPostings);
    }
    await transactionRefetch();
    enqueueSnackbar(formatMessage({ id: 'transactions.lettering.customInvoiceCreated' }), {
      variant: 'success',
    });
    setSubmitting(false);
    onClose();
  };

  const willSendCommunication =
    communicationSettings?.sendingRule === CommunicationSendingRule.AUTOMATIC && newLeaseBalance !== oldLeaseBalance;

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      onActionButtonClick={async () => await handleCreateCustomInvoice()}
      actionButtonLoading={isSubmitting}
      actionButtonLabel={formatMessage({ id: 'add' })}
      title={formatMessage({ id: 'lease.detail.addCustomInvoice.title' })}
      dividerBelowTitle
      contentGridStyle={{ marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}
      formatMessage={formatMessage}
    >
      <>
        {values.history.map((posting, index) => (
          <Fragment key={posting.id}>
            <EditableCustomPosting posting={posting} lease={lease} index={index} />
            {index !== values.history.length - 1 && <Divider style={{ marginTop: 20 }} />}
          </Fragment>
        ))}
        <ActionButton
          onClick={() =>
            setValues((currentValues) => ({
              ...currentValues,
              history: [
                ...currentValues.history,
                {
                  id: uuidv4(),
                  label: null,
                  amount: null,
                  accountLabel: null,
                  unitId,
                  dueDate: new Date(),
                  transactionType: null,
                },
              ],
            }))
          }
          className={classes.addButton}
        >
          <AddIcon />
          {toUpper(formatMessage({ id: 'add' }))}
        </ActionButton>
        <Divider className={classes.divider} />
        <ReconciliationPart lease={lease} />
        <Divider className={classes.divider} />
        <NewBalancePart newBalance={newLeaseBalance} oldBalance={oldLeaseBalance} />
        {willSendCommunication && (
          <>
            <Divider className={classes.divider} />
            <IconnedRemark
              Icon={InfoSvg}
              noFixWidth
              style={{
                marginRight: 0,
                marginTop: 14,
                marginBottom: 8,
                marginLeft: 0,
              }}
              message={<Typography>{formatMessage({ id: 'transactions.lettering.invoiceEmailWarning' })}</Typography>}
            />
          </>
        )}
      </>
    </CustomSimpleDialog>
  );
};

export default AddCustomInvoiceForm;
