import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';

import {
  CustomLabel,
  FileCategory,
  FolderEntity,
  Language,
  DocumentCategoryWithoutReadIdAndClientId,
  DocumentSortOptions,
  FolderModel,
  getTranslatedCategory,
} from '@rentguru/commons-utils';
import { groupBy, isEmpty, orderBy } from 'lodash';
import { translateText } from 'src/utils/translationUtils';
import Fuse from 'fuse.js';

export const openSnackbar = (
  enqueueSnackbar: (message: SnackbarMessage, options: OptionsObject) => SnackbarKey,
  message: string,
  variant: VariantType
) => {
  enqueueSnackbar(message, {
    variant,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  });
};

export const sortDocumentCategories = (
  categories: DocumentCategoryWithoutReadIdAndClientId[],
  language: string | null
) => {
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  const alphanumeric = categories.sort((a, b) =>
    collator.compare(getTranslatedCategory(a, language), getTranslatedCategory(b, language))
  );

  const groupedList = groupBy(alphanumeric, (category) => category.fileCategory === FileCategory.CUSTOM);

  return [...(groupedList['false'] ?? []), ...(groupedList['true'] ?? [])];
};

const makeSortedList = (folders: FolderModel[]) => {
  const groupedFolders = groupBy(folders, (folder) => folder.folderEntity);

  const groupedFolderEntityFolders = groupBy(
    groupedFolders[FolderEntity.FOLDER],
    (folder) => folder.documentCategory.fileCategory === FileCategory.CUSTOM
  );

  const sortedList = [
    ...(groupedFolders[FolderEntity.CONTACT] ?? []),
    ...(groupedFolders[FolderEntity.BUILDING] ?? []),
    ...(groupedFolders[FolderEntity.UNIT] ?? []),
    ...(groupedFolders[FolderEntity.LEASE] ?? []),
    ...(groupedFolderEntityFolders['false'] ?? []),
    ...(groupedFolderEntityFolders['true'] ?? []),
    ...(groupedFolders[FolderEntity.FILE] ?? []),
  ];

  return sortedList;
};

export const sortFolders = (folders: FolderModel[], sortOption: DocumentSortOptions): FolderModel[] => {
  if (sortOption === DocumentSortOptions.ALPAHBETICALLY_ASC) {
    // We need to use this for alphanumeric sorting
    // Without this the sorting will be like 1, 10, 2, 3, 4, 5, 6, 7, 8, 9 (lexicographical order)
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const alphanumeric = folders.sort((a, b) =>
      collator.compare(a.labelToSortOn ?? a.translatedLabel, b.labelToSortOn ?? b.translatedLabel)
    );

    const sortedList = makeSortedList(alphanumeric);

    return sortedList;
  }
  if (sortOption === DocumentSortOptions.ALPAHBETICALLY_DES) {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    const alphanumeric = folders
      .sort((a, b) => collator.compare(a.labelToSortOn ?? a.translatedLabel, b.labelToSortOn ?? b.translatedLabel))
      .reverse();

    const newSortedFolders = makeSortedList(alphanumeric);
    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.SIZE_ASC) {
    const orderedByFile = orderBy(folders, [(folder) => folder.size], ['asc']);

    const newSortedFolders = makeSortedList(orderedByFile);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.SIZE_DES) {
    const orderedByFile = orderBy(folders, [(folder) => folder.size], ['desc']);

    const newSortedFolders = makeSortedList(orderedByFile);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.DATE_ASC) {
    const orderByDate = orderBy(folders, [(folder) => new Date(folder.size).getTime()], ['asc']);

    const newSortedFolders = makeSortedList(orderByDate);

    return newSortedFolders;
  }
  if (sortOption === DocumentSortOptions.DATE_DES) {
    const orderByDate = orderBy(folders, [(folder) => new Date(folder.size).getTime()], ['desc']);

    const newSortedFolders = makeSortedList(orderByDate);

    return newSortedFolders;
  }
  return folders;
};

export const handleSaveWithTranslation = async (labels: CustomLabel[]) => {
  let translate = false;
  const filledLanguages: Language[] = labels.reduce((acc, label) => {
    if (!isEmpty(label.label)) {
      acc.push(label.language as Language);
      return acc;
    }
    translate = true;
    return acc;
  }, [] as Language[]);

  if (isEmpty(filledLanguages)) {
    return;
  }
  if (translate && filledLanguages.length > 0) {
    const allLanguages = Object.values(Language);
    const textToTranslate = labels.find((label) => label.language === filledLanguages[0])?.label;
    const destinationLanguages = allLanguages.filter((lang) => !filledLanguages.includes(lang));
    for (const destinationLanguage of destinationLanguages) {
      const newText = await translateText(textToTranslate!, filledLanguages[0], destinationLanguage);
      const labelToUpdate = labels.find((label) => label.language.toUpperCase() === destinationLanguage);
      if (labelToUpdate) {
        labelToUpdate.label = newText;
      }
    }
  }

  return labels;
};

export const filterFoldersOnName = (folders: FolderModel[], filter: string) => {
  const options = {
    keys: ['translatedLabel'],
    findAllMatches: true,
    threshold: 0,
    ignoreLocation: true,
  };
  const fuse = new Fuse(folders, options);
  return fuse.search(filter).map((result) => result.item);
};
