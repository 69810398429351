import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CSSProperties } from '@material-ui/styles';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as Warning } from 'src/icons/warning.svg';

interface FormAlertBoxProps {
  message1: string;
  message2?: string;
  subContent?: React.ReactNode;
  smallVersion?: boolean;
  removeLeftMargin?: boolean;
  width?: number;
  gridStyle?: CSSProperties;
  messagesGridStyle?: CSSProperties;
  dataTest?: string;
  icon?: React.ReactNode;
}

const FormAlertBox: React.FC<FormAlertBoxProps> = ({
  message1,
  message2,
  removeLeftMargin,
  width,
  smallVersion = false,
  gridStyle,
  messagesGridStyle,
  dataTest = 'FormAlertBox',
  subContent,
  icon,
}) => {
  return (
    <Grid
      style={{
        width: width ?? (!smallVersion ? 580 : 270),
        marginLeft: removeLeftMargin ? 0 : 30,
        backgroundColor: Colors.FADED_RED,
        padding: '10px 0',
        alignItems: 'center',
        borderRadius: 5,
        ...gridStyle,
      }}
      data-test={dataTest}
      container
    >
      <Grid item xs={!smallVersion ? 1 : 3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {icon ?? <Warning style={{ width: 20, height: 20, fill: Colors.BURNING_ORANGE }} />}
      </Grid>
      <Grid item xs={!smallVersion ? 11 : 8}>
        <Typography style={{ color: Colors.BURNING_ORANGE, ...messagesGridStyle, paddingRight: 3 }}>
          {message1} {message2 ?? ''}
        </Typography>
        {subContent}
      </Grid>
    </Grid>
  );
};

export default FormAlertBox;
