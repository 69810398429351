/* eslint-disable @typescript-eslint/no-shadow */
import React, { lazy } from 'react';
import { isEmpty, isNil } from 'lodash';
import { PermissionsContext } from 'src/hooks/utils/PermissionsContext';
import { Building, Unit, FEATURE_INVENTORY, FEATURE_ADVERTISEMENT, isFeatureEnabled } from '@rentguru/commons-utils';

// eslint-disable-next-line
import { generatePath, match } from 'react-router-dom'; //Typescript doesn't detect it is used
import { RouteDestination } from '../Routes/Routes';
import TicketsFilters from '../Tickets/TicketsFilters';
import { retryLazy } from '../Routes/LazyErrorBoundary';

/* Property Menu Imports */
const GeneralDetails = lazy(() => retryLazy(() => import('../Buildings/Details/GeneralDetails')));
const BuildingTickets = lazy(() => retryLazy(() => import('../Buildings/Details/TicketsPanel/BuildingTickets')));
const FinancialDashboard = lazy(() => retryLazy(() => import('../FinancialDashboard/FinancialDashboard')));
const Technics = lazy(() => retryLazy(() => import('../Technics/Technics')));
const UtilityProviders = lazy(() => retryLazy(() => import('../UtilityProviders/UtilityProviders')));
const BuildingRepartitionKey = lazy(() => retryLazy(() => import('../FinancialDashboard/BuildingRepartitionKey')));

const Documents = lazy(() => retryLazy(() => import('../Folders/FoldersLander')));

/* Unit Menu Imports  */
const UnitGeneralDetails = lazy(() => retryLazy(() => import('../RentalUnits/Details/GeneralDetails')));
const Advertisement = lazy(() => retryLazy(() => import('../RentalUnits/Details/Publication/Publication')));
const UnitTickets = lazy(() => retryLazy(() => import('../RentalUnits/Details/TicketsPanel/UnitTickets')));
const Inventory = lazy(() => retryLazy(() => import('../RentalUnits/Details/Inventory/Inventory')));
const UnitLeases = lazy(() => retryLazy(() => import('../RentalUnits/Details/UnitLeases/UnitLeases')));
const UnitCommunications = lazy(() => import('../Leases/Details/LeaseUnitCommunicationsDetail'));

export type PropertyUnitsDetailNavItem = {
  to: string;
  labelId: string;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  editable?: boolean;
  permission: boolean;
  value?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterComponent?: React.FC<any>;
};

export const PROPERTY_TO_PREFIX = '';
const PROPERTY_TO_GENERAL = `${PROPERTY_TO_PREFIX}`;
const PROPERTY_TO_FINANCIAL = `${PROPERTY_TO_PREFIX}financial`;
export const PROPERTY_TO_EDIT_POSSIBLE = [PROPERTY_TO_GENERAL, PROPERTY_TO_FINANCIAL];

export type PropertyNavItemsElementsToShow = {
  shouldShowUtility: boolean;
  shouldShowTechnics: boolean;
  shouldShowTickets: boolean;
  shouldShowDocuments: boolean;
};

export type UnitNavItemsElementsToShow = {
  shouldShowUtility: boolean;
  shouldShowTechnics: boolean;
  shouldShowTickets: boolean;
  shouldShowInventory: boolean;
  shouldShowCommunications: boolean;
  shouldShowDocuments: boolean;
};

export const getPropertyNavItems = (
  building: Building,
  permissions: PermissionsContext,
  elementsToShow: Partial<PropertyNavItemsElementsToShow>,
  isTenant = false
): PropertyUnitsDetailNavItem[] => {
  const {
    financialTransactionsRead,
    financialMortgagesRead,
    financialValuationsAndCostsRead,
    ticketsDetailsRead,
    buildingsUnitsUtilityProvidersRead,
    buildingsUnitsTechnicsRead,
  } = permissions;

  const { shouldShowUtility = false, shouldShowTechnics = false, shouldShowTickets = false } = elementsToShow;

  const propertyNavItems = [
    {
      to: PROPERTY_TO_GENERAL,
      labelId: 'building.detail.menu.general',
      exact: true,
      component: GeneralDetails,
      editable: true,
      permission: true,
    },
    {
      to: PROPERTY_TO_FINANCIAL,
      labelId: 'rentalUnit.detail.menu.financial',
      component: FinancialDashboard,
      permission: financialValuationsAndCostsRead || financialMortgagesRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}meters`,
      labelId: 'utilityProviders.name',
      component: UtilityProviders,
      permission: shouldShowUtility && buildingsUnitsUtilityProvidersRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}technics`,
      labelId: 'technic.name',
      value: 2,
      exact: true,
      component: Technics,
      editable: true,
      permission: shouldShowTechnics && buildingsUnitsTechnicsRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}tickets`,
      labelId: 'rentalUnit.detail.menu.tickets',
      component: BuildingTickets,
      filterComponent: TicketsFilters,
      permission: shouldShowTickets && ticketsDetailsRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}repartitionKey`,
      labelId: 'building.detail.repartitionKey.label',
      component: BuildingRepartitionKey,
      permission: financialTransactionsRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}documents`,
      labelId: 'menu.documents',
      component: Documents,
      permission: !isTenant,
    },
  ];
  // doesn't display financial dashboard if no owners
  if (building && building.owners && isEmpty(building.owners)) {
    return propertyNavItems.filter((nv) => nv.to !== `${PROPERTY_TO_PREFIX}financial`);
  }

  return propertyNavItems;
};

const UNIT_TO_PREFIX = '';
export const UNIT_TO_GENERAL = `${UNIT_TO_PREFIX}general`;
const UNIT_TO_FINANCIAL = `${UNIT_TO_PREFIX}financial`;
export const UNIT_TO_LEASES = `${UNIT_TO_PREFIX}leases`;
export const UNIT_TO_EDIT_POSSIBLE = [UNIT_TO_GENERAL, UNIT_TO_FINANCIAL];

export const getUnitsNavItems = (
  isOwnerOnBuildingLevel: boolean,
  permissions: PermissionsContext,
  features: string[],
  elementsToShow: Partial<UnitNavItemsElementsToShow>
): PropertyUnitsDetailNavItem[] => {
  const {
    leasesDetailsRead,
    leasesCreationRead,
    financialValuationsAndCostsRead,
    financialMortgagesRead,
    advertisementsDetailsRead,
    buildingsUnitsUtilityProvidersRead,
    buildingsUnitsTechnicsRead,
    inventoryOfFixturesCreationRead,
    furnituresInventoryCreationRead,
    ticketsDetailsRead,
    communicationsDetailsRead,
  } = permissions;

  const {
    shouldShowUtility = false,
    shouldShowTechnics = false,
    shouldShowInventory = false,
    shouldShowTickets = false,
    shouldShowCommunications = false,
    shouldShowDocuments = false,
  } = elementsToShow;

  let unitNavItems = [
    {
      to: UNIT_TO_LEASES,
      labelId: 'contact.detail.menu.leaseTitle',
      exact: true,
      component: UnitLeases,
      value: 2,
      editable: true,
      permission: leasesDetailsRead || leasesCreationRead,
    },
    {
      to: UNIT_TO_GENERAL,
      labelId: 'rentalUnit.detail.menu.general',
      exact: true,
      component: UnitGeneralDetails,
      editable: true,
      permission: true,
    },
    {
      to: UNIT_TO_FINANCIAL,
      labelId: 'rentalUnit.detail.menu.financial',
      component: FinancialDashboard,
      permission: financialValuationsAndCostsRead || financialMortgagesRead,
    },
    {
      to: `${UNIT_TO_PREFIX}advertisement`,
      labelId: 'rentalUnit.detail.menu.advertisement',
      component: Advertisement,
      permission: advertisementsDetailsRead,
    },
    {
      to: `${UNIT_TO_PREFIX}meters`,
      labelId: 'utilityProviders.name',
      component: UtilityProviders,
      permission: shouldShowUtility && buildingsUnitsUtilityProvidersRead,
    },
    {
      to: `${UNIT_TO_PREFIX}technics`,
      labelId: 'technic.name',
      value: 2,
      exact: true,
      component: Technics,
      editable: true,
      permission: shouldShowTechnics && buildingsUnitsTechnicsRead,
    },
    {
      to: `${UNIT_TO_PREFIX}inventory`,
      labelId: 'menu.inventory',
      exact: true,
      component: Inventory,
      editable: true,
      permission: shouldShowInventory && (inventoryOfFixturesCreationRead || furnituresInventoryCreationRead),
    },
    {
      to: `${UNIT_TO_PREFIX}tickets`,
      labelId: 'rentalUnit.detail.menu.tickets',
      component: UnitTickets,
      filterComponent: TicketsFilters,
      permission: shouldShowTickets && ticketsDetailsRead,
    },
    {
      to: `${UNIT_TO_PREFIX}communications`,
      labelId: 'menu.communications',
      component: UnitCommunications,
      permission: shouldShowCommunications && communicationsDetailsRead,
    },
    {
      to: `${PROPERTY_TO_PREFIX}documents`,
      labelId: 'menu.documents',
      component: Documents,
      permission: shouldShowDocuments,
    },
  ];
  // Feature filters
  if (!isFeatureEnabled(features, FEATURE_INVENTORY)) {
    unitNavItems = unitNavItems.filter((navItems) => !navItems.to.includes('inventory'));
  }
  if (!isFeatureEnabled(features, FEATURE_ADVERTISEMENT)) {
    unitNavItems = unitNavItems.filter((navItems) => !navItems.to.includes('advertisement'));
  }

  // doesn't display financial dashboard if no owners
  if (isOwnerOnBuildingLevel) {
    return unitNavItems.filter((nv) => nv.to !== `${UNIT_TO_PREFIX}financial`);
  }
  return unitNavItems;
};

export const getDetailUrlForUnit = (
  unitPrefixUrl: string,
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>
) => {
  return generatePath(`${RouteDestination.PROPERTIES}/:id/:detailName`, {
    id: match.params.id,
    detailName: unitPrefixUrl,
  });
};

export const UNIT_PREFIX = 'unit-';

export const removeUnitPrefix = (unitId: string | undefined) => {
  if (!unitId) {
    return undefined;
  }
  return unitId.replace(UNIT_PREFIX, '');
};

export const getUnitPrefixUrl = (unit: Unit) => {
  return `${UNIT_PREFIX}${unit.id}`;
};

export const getUnitIdFromMatchParams = (
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>
) => {
  if (isNil(match.params.detailName)) {
    return null;
  }
  if (!match.params.detailName.startsWith(UNIT_PREFIX)) {
    return null;
  }
  return match.params.detailName.substring(UNIT_PREFIX.length);
};

export const getInventoryIdFromMatchParams = (
  match: match<{
    id: string;
    detailName: string;
    nestedDetailName?: string | undefined;
  }>
) => {
  if (isNil(match.params.nestedDetailName)) {
    return null;
  }
  const fixture = match.params.nestedDetailName.startsWith('inventory-');
  const prefix = fixture ? 'inventory-' : 'furniture-';
  return match.params.nestedDetailName.substring(prefix.length);
};
