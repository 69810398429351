import React from 'react';
import TicketDetailNewMessage from './TicketDetailNewMessage';
import TicketDetailConversationsBox from './TicketDetailConversationsBox';
import { compareDesc } from 'date-fns';
import {
  getContactNameFromObject,
  stringToColor,
  EntityType,
  Ticket,
  File as FileModel,
  Contact,
  FileCategory,
} from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { TicketContext } from 'src/hooks/TicketsContext';

export interface AttachmentMetaData {
  id: string;
  bucket: string;
  region: string;
  key: string;
  loading: boolean;
}

interface TicketsDetailConversationsProps {
  ticket?: Ticket;
  fileArray: FileModel[];
  currentUser: Contact;
  createTicket: TicketContext['createTicket'];
  createFile: (
    file: File,
    entityType: EntityType,
    entityId: string,
    category?: FileCategory
  ) => Promise<FileModel | null>;
  loading: boolean;
  isTenant?: boolean;
  leaseId?: string;
  onCloseDialog?: () => void;
}

const TicketDetailConversations: React.FC<TicketsDetailConversationsProps> = ({
  ticket,
  fileArray,
  currentUser,
  loading,
  createFile,
  createTicket,
  isTenant = false,
  leaseId,
  onCloseDialog,
}) => {
  const { ticketsDetailsWrite } = usePermissions();
  const userAvatarName = getContactNameFromObject(currentUser);
  const userAvatarColor = stringToColor(userAvatarName);
  // for conversations
  const conversationList = !isNil(ticket) ? [...ticket.conversations!, ticket] : [];
  // sort conversations by Descending creation time order. (Oldest entry is last)
  conversationList.sort((a: Ticket, b: Ticket) => {
    return compareDesc(new Date(a.createdAt!), new Date(b.createdAt!));
  });
  const newMessageSubject = !isEmpty(conversationList) ? conversationList![0].subject : '';
  // for new message
  // last message is actually the first because of the descending order
  return (
    <>
      {ticketsDetailsWrite && (
        <TicketDetailNewMessage
          name={userAvatarName}
          color={userAvatarColor}
          newMessageSubject={newMessageSubject}
          ticket={ticket}
          createFile={createFile}
          createTicket={createTicket}
          currentUser={currentUser}
          isTenant={isTenant}
          leaseId={leaseId}
          onCloseDialog={onCloseDialog}
          style={{ padding: '10px 10px 20px' }}
        />
      )}
      {conversationList.map((conversation: Ticket) => {
        const filteredFileArray = fileArray.filter((file) => file.foreignKey === conversation.id);

        return (
          <TicketDetailConversationsBox
            conversation={conversation}
            key={conversation.id}
            loading={loading}
            filteredFileArray={filteredFileArray}
          />
        );
      })}
    </>
  );
};

export default TicketDetailConversations;
