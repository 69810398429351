import React from 'react';
import { Colors } from '@rentguru/commons-utils';
import { ReactComponent as Chevron } from 'src/icons/chevron-right.svg';
import { CustomIconButton } from '@up2rent/ui';

export const rotatingIconStyle = (isOpen: boolean, isPointingLeft: boolean, height: number, width: number): object => ({
  transform: `rotate(${isOpen ? 90 : isPointingLeft ? 180 : 0}deg)`,
  fill: isOpen ? Colors.DARK_SLATE_GREY : Colors.BLUEY,
  height,
  width,
});

interface ChevronIconProps {
  setOpen: (value: React.SetStateAction<boolean>) => void;
  isOpen: boolean;
  isPointingLeft?: boolean;
  height?: number;
  width?: number;
}

const CollapsingChevron: React.FC<ChevronIconProps> = ({
  setOpen,
  isOpen,
  height = 20,
  width = 20,
  isPointingLeft = false,
}) => {
  return (
    <CustomIconButton
      style={{ color: Colors.BLUE_GREY, height: 'fit-content', padding: 0 }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen((open) => !open);
      }}
      Icon={Chevron}
      iconStyle={rotatingIconStyle(isOpen, isPointingLeft, height, width)}
    />
  );
};

export default CollapsingChevron;
