import React, { useState } from 'react';
import {
  InventoryCustomItem,
  InventoryItemType,
  LeaseInventory,
  UnitInventoryStructureType,
  S3Object,
} from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import InventoryStructureCollapse from './InventoryStructureCollapse';
import AddStructureDialog from './LeaseInventoryDialogs/AddStructureFormDialog';
import isEmpty from 'lodash/isEmpty';
import PreviewFileDialogs from '../../ui/PreviewFileDialog';
import { ExtendedLeaseInventoryEncoding } from '../../../hooks/UnitInventoryContext';
import { FormikProps, useFormikContext } from 'formik';
import { EmptyFileWithUrl } from './InventoryStructureEncodingExtraInfos';
import AddDefectsDialog from './LeaseInventoryDialogs/AddDefectsDialog';
import AddColorsDialog from './LeaseInventoryDialogs/AddColorsDialog';
import AddMaterialsDialog from './LeaseInventoryDialogs/AddMaterialsDialog';
import { InventoryOfFixturesFormik } from '../EditInventory/EditInventory';
import { ReactComponent as Logo } from '../../../icons/unitPlaceholder.svg';
import { TablePlaceHolder } from '@up2rent/ui';
import { useIntl } from 'react-intl';

interface InventoryEncodingsProps {
  inside: boolean;
  leaseInventory: LeaseInventory;
  tab: number;
  readOnly?: boolean;
}

export interface AddCheckedItemDialogBundle {
  encoding: ExtendedLeaseInventoryEncoding | undefined;
  updateEncoding: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  type: InventoryItemType;
}

export interface FilePreviewDialogBundle {
  file: S3Object | EmptyFileWithUrl | null;
  onUpdatedFile?: (oldFile: S3Object | EmptyFileWithUrl, newFile: File) => void;
}

const InventoryEncodings: React.FC<InventoryEncodingsProps> = ({ inside, leaseInventory, tab, readOnly = false }) => {
  const { formatMessage } = useIntl();
  const [fileToPreview, setFileToPreview] = useState<FilePreviewDialogBundle>({ file: null, onUpdatedFile: undefined });
  const { values, setFieldValue }: FormikProps<InventoryOfFixturesFormik> = useFormikContext();
  const [proposedUnitInventory, setProposedUnitInventory] = useState<string>('');
  const [structureType, setStructureType] = useState<UnitInventoryStructureType>(UnitInventoryStructureType.OTHER);
  const [encodeCheckedItemDialog, setEncodeCheckedItemDialog] = useState<AddCheckedItemDialogBundle | null>(null);

  const filteredLocalUnitInventories = values.localUnitInventories.filter((ui) => ui.inside === inside);
  const updateCustomItems = (updateFunction: (item: InventoryCustomItem[]) => InventoryCustomItem[]) => {
    setFieldValue('localCustomItems', updateFunction(values.localCustomItems));
  };

  return (
    <>
      {filteredLocalUnitInventories.map((ui, index) => {
        return (
          <InventoryStructureCollapse
            key={ui.id}
            leaseInventory={leaseInventory}
            completeUnitInventory={ui}
            unitInventoryIndex={index}
            divider={index !== filteredLocalUnitInventories.length - 1}
            proposeUnitInventory={setProposedUnitInventory}
            previewFileFunction={setFileToPreview}
            setStructure={setStructureType}
            encodeDefects={(defectBundle: AddCheckedItemDialogBundle) => setEncodeCheckedItemDialog(defectBundle)}
            tab={tab}
            readOnly={readOnly}
          />
        );
      })}
      {isEmpty(filteredLocalUnitInventories) && (
        <TablePlaceHolder
          Icon={<Logo width={72} />}
          mainText={formatMessage({ id: 'leaseInventory.roomPlaceholder.mainText' })}
          subText={formatMessage({ id: 'leaseInventory.roomPlaceholder.subText' }, { readOnly })}
        />
      )}
      <AddStructureDialog
        open={!isEmpty(proposedUnitInventory)}
        onClose={() => setProposedUnitInventory('')}
        proposedUnitInventories={values.localUnitInventories}
        unitInventoryId={proposedUnitInventory}
        structureType={structureType}
      />
      <PreviewFileDialogs
        open={!isNil(fileToPreview.file)}
        file={fileToPreview.file}
        onClose={() => setFileToPreview({ file: null, onUpdatedFile: undefined })}
        editFunction={readOnly ? undefined : fileToPreview.onUpdatedFile}
      />
      <AddDefectsDialog
        open={!isNil(encodeCheckedItemDialog) && encodeCheckedItemDialog.type === InventoryItemType.DEFECT}
        encoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.encoding : undefined}
        updateEncoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.updateEncoding : undefined}
        onClose={() => setEncodeCheckedItemDialog(null)}
        customItems={values.localCustomItems}
        updateCustomItems={updateCustomItems}
      />
      <AddMaterialsDialog
        open={!isNil(encodeCheckedItemDialog) && encodeCheckedItemDialog.type === InventoryItemType.MATERIAL}
        encoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.encoding : undefined}
        updateEncoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.updateEncoding : undefined}
        onClose={() => setEncodeCheckedItemDialog(null)}
        customItems={values.localCustomItems}
        updateCustomItems={updateCustomItems}
      />
      <AddColorsDialog
        open={!isNil(encodeCheckedItemDialog) && encodeCheckedItemDialog.type === InventoryItemType.COLOR}
        encoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.encoding : undefined}
        updateEncoding={!isNil(encodeCheckedItemDialog) ? encodeCheckedItemDialog.updateEncoding : undefined}
        onClose={() => setEncodeCheckedItemDialog(null)}
        customItems={values.localCustomItems}
        updateCustomItems={updateCustomItems}
      />
    </>
  );
};

export default InventoryEncodings;
