import { Grid } from '@material-ui/core';
import {
  LeaseAction,
  LeaseAmountUpdateStatus,
  LeaseMonthlyChargesType,
  LeasePriceHistory,
  LeasePriceHistoryStatus,
  LeasePriceHistoryType,
  LeaseType,
  LeaseVariousOperation,
  ModelWithVersion,
  endOfUTCDay,
  getUTCDate,
} from '@rentguru/commons-utils';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ExtendLeaseForm, {
  ExtendLeaseFormValues,
  getExtendLeaseInitialValues,
  getExtendLeaseSchema,
  isNumberOfExtendsReached,
} from 'src/components/Leases/Details/Extend/ExtendLeaseForm';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useUser } from 'src/hooks/UserContext';
import { CustomSimpleDialog } from '@up2rent/ui';

interface ExtendLeaseDialogProps {
  open: boolean;
  lease: LeaseExtended;
  onClose: () => void;
}

const ExtendLeaseDialog: React.FC<ExtendLeaseDialogProps> = ({ open, lease, onClose }) => {
  const { formatMessage } = useIntl();
  const { updateLease, updateUnitLease, leasesLoading } = useLeases();
  const {
    getActiveLeaseVariousOperationsFromLease,
    updateLeaseVariousOperation,
    loading: leaseVariousOperationLoading,
  } = useLeaseVariousOperations();
  const { createLeasePriceHistory, loading: leasePriceHistoriesLoading } = useLeasePriceHistories();
  const { createLeaseActionHistory, loading: leaseActionHistoriesLoading } = useLeaseActionHistories();
  const { memberId } = useUser();
  const { push: historyPush } = useHistory();

  const initialValues: ExtendLeaseFormValues = getExtendLeaseInitialValues(lease);

  const loading =
    leasesLoading || leaseVariousOperationLoading || leasePriceHistoriesLoading || leaseActionHistoriesLoading;
  if (loading) {
    return null;
  }

  const onSubmit = async (
    values: ExtendLeaseFormValues,
    { setSubmitting, setStatus }: FormikHelpers<ExtendLeaseFormValues>
  ) => {
    const previousEndDate = lease.endDate;
    const newEndDate = endOfUTCDay(new Date(values.endDate));

    // Unit Lease update
    const updateUnitLeasePromises = values.unitsToExtend.map((unit) => {
      const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);
      return updateUnitLease(unitLease!, { endDate: newEndDate.toISOString() });
    });
    await Promise.all(updateUnitLeasePromises);

    // Lease action
    const historyDetails = values.unitsToExtend.map((unit) => {
      const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);

      return {
        unitId: unit.id,
        previousStartDate: unitLease!.startDate,
        newStartDate: unitLease!.startDate,
        previousEndDate: unitLease!.endDate,
        newEndDate: newEndDate.toISOString(),
      };
    });
    await createLeaseActionHistory({
      action: LeaseAction.EXTEND_LEASE,
      contactId: memberId!,
      leaseId: lease.id,
      historyDetails,
    });

    // Lease Update
    const shouldChangeLeaseType = isNumberOfExtendsReached(
      getUTCDate(new Date(lease.startDate)),
      newEndDate,
      lease.type as LeaseType
    );
    await updateLease(lease, {
      canBeExtended: true,
      endDate: newEndDate.toISOString(),
      ...(shouldChangeLeaseType ? { type: LeaseType.MAIN_RESIDENCE_LONG } : {}),
    });

    // Extends Operations if present
    const leaseVariousOperations = getActiveLeaseVariousOperationsFromLease(lease.id);
    if (values.includeOperations && !isEmpty(leaseVariousOperations)) {
      const leaseVariousOperationsUpdatePromises = leaseVariousOperations?.map((leaseVariousOperation) => {
        const newLeaseVariousOperationHistories = (leaseVariousOperation.leaseVariousOperationHistories ?? []).map(
          (history) => {
            if (history.periodTo === leaseVariousOperation.endDate) {
              return {
                ...history,
                periodTo: newEndDate.toISOString(),
              };
            }
            return history;
          }
        );

        const initialWithVersion = leaseVariousOperation as ModelWithVersion<LeaseVariousOperation>;
        const updatedOperation = {
          endDate: newEndDate.toISOString(),
          leaseVariousOperationHistories: newLeaseVariousOperationHistories,
          id: initialWithVersion!.id,
          _version: initialWithVersion._version,
        };

        return updateLeaseVariousOperation(updatedOperation);
      });

      await Promise.all(leaseVariousOperationsUpdatePromises ?? []);
    }

    // Create Lease Price History if needed
    let leasePriceHistory: LeasePriceHistory | null = null;
    if (values.adjustCommercialPrice) {
      const amountDetails = values.unitsToExtend.map((unit) => {
        const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);

        return {
          unitId: unit.id,
          indexationBaseIndex: -1,
          indexationNewIndex: -1,
          previousRentalPrice: unitLease!.rentalPrice,
          newRentalPrice: unitLease!.rentalPrice,
          rentalStatus: LeasePriceHistoryStatus.INDEXED,
          previousMonthlyChargesPrice: unitLease!.monthlyCharges,
          newMonthlyChargesPrice: unitLease!.monthlyCharges,
          monthlyChargesStatus: LeasePriceHistoryStatus.INDEXED,
          monthlyChargesType: unitLease!.monthlyChargesType as LeaseMonthlyChargesType,
        };
      });

      leasePriceHistory = await createLeasePriceHistory({
        previousTotalRentalPrice: lease.totalRentalPrice,
        previousTotalMonthlyCharge: lease.totalMonthlyCharges,
        totalRentalPrice: lease.totalRentalPrice,
        totalMonthlyCharges: lease.totalMonthlyCharges,
        applicationDate: previousEndDate,
        status: LeaseAmountUpdateStatus.DRAFT,
        type: LeasePriceHistoryType.TERMS_CHANGE,
        leaseId: lease.id,
        amountDetails,
      });
    }

    setStatus(true);
    setSubmitting(false);
    onClose();
    if (values.adjustCommercialPrice && leasePriceHistory) {
      // Redirect user to the price adjustment
      historyPush(RouteDestination.INDEXATION, { id: leasePriceHistory.id });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getExtendLeaseSchema(lease.endDate)}>
      {({ isSubmitting, values, handleSubmit }) => {
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              onActionButtonClick={handleSubmit}
              actionButtonDisabled={!isEmpty(values.overlappingEvents)}
              actionButtonLoading={isSubmitting}
              actionButtonLabel={formatMessage({ id: 'lease.detail.action.confirm' })}
              dividerBelowTitle
              title={formatMessage({ id: 'lease.endExtendLease.titleExtend' }, { name: lease.name })}
              formatMessage={formatMessage}
            >
              <Grid style={{ marginTop: 20 }}>
                <ExtendLeaseForm lease={lease} />
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExtendLeaseDialog;
