import React, { Reducer, createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { downloadData } from 'aws-amplify/storage';
import { v4 as uuidv4 } from 'uuid';
import { compact, first, isEmpty, isNil, last, uniqBy } from 'lodash';

import {
  DocumentCategory,
  EntityType,
  FileCategory,
  File as FileModel,
  FolderBooleanAttributes,
  FolderEntity,
  FolderErrors,
  isNilOrEmpty,
  DocumentCategoryWithoutReadIdAndClientId,
  canDrag,
  S3Object,
  DocumentModel,
  FolderModel,
  getTranslatedCategory,
  getFolderDepthStringArray,
  checkChildFolders,
  checkIfFolderHasFilesOnOtherEntities,
  filterCurrentLevelCategories,
  getCategoryDeptArray,
  getFolderDepth,
  getFolderDepthString,
  contactMostImportantType,
  getCategoriesToWorkWith,
  ExtendedSearchModel,
  ContactType,
  Contact,
  getContactNameFromObject,
  getSortableContactName,
  getLeaseExtendedName,
  getParentFolder,
  getMappedS3File,
  Building,
  Unit,
  UnitLease,
  LeaseExtended,
} from '@rentguru/commons-utils';

import { Language } from 'src/components/RentalUnits/Details/Publication/PublicationDescription/LanguageMenu';
import { CustomLabel } from 'src/models';

import { useDocumentCategory } from '../FileCategoryContext';
import { useFiles, getS3ObjectUrls } from '../FilesContext';
import { useSignatureDocuments } from '../SignatureDocumentContext';
import { useTechnics } from '../TechnicsContext';
import { useUnits } from '../UnitsContext';
import { useUser } from '../UserContext';
import { FolderState, Action, documentReducer, initialState } from './FolderReducer';
import { useContacts } from '../ContactsContext';
import { useBuildings } from '../BuildingsContext';
import { useLeases } from '../LeasesContext';
import { filterFoldersOnName } from 'src/components/Folders/FolderComponents/FoldersUtils';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { dateLocaleMap } from 'src/i18n/IntlProviderWrapper';

const basicCreateFolderObject: Partial<FolderModel> = {
  id: undefined,
  size: 0,
  updatedAt: '',
  documentModel: undefined,
};

export interface FoldersContext extends FolderState {
  calculatePageFolder: () => Promise<void>;
  renameFile: (folderToRename: FolderModel, newName: string) => void;
  renameFolder: (folder: FolderModel, newLabels: CustomLabel[]) => void;
  addFilesToFolder: (files: FileModel[], targetFolder: FolderModel) => Promise<void>;
  setFilteredFolders: (
    foldersToWorkWith: FolderModel[],
    documentsCategory?: DocumentCategoryWithoutReadIdAndClientId
  ) => void;
  setNavigation: (fromNavigation: boolean, folder?: FolderModel) => void;
  changeSelectedFolder: (add: boolean, folderId: string[]) => void;
  changeDragging: (dragging: boolean) => void;
  setSelectedFolders: (selected: boolean, folderIds: string[]) => void;
  deselectFolders: () => void;
  dropFolder: (targetFolderId: string, currentFolderId: string) => Promise<FolderErrors>;
  addNewFolder: (
    labels: CustomLabel[],
    selectedCategory?: DocumentCategoryWithoutReadIdAndClientId,
    files?: File[]
  ) => void;
  setDraggedFolders: (draggedFolders: string[]) => void;
  deleteFolders: (folderIds: string[]) => Promise<FolderErrors>;
  selectSearchFolder: (folder: FolderModel) => void;
  setIsBusy: (contextIsBusy: boolean) => void;
  changeVisibilityAllFolders: () => void;
  fetchInitialDocuments: (id: string, type: EntityType) => void;
  cleanup: () => void;
  getCategoriesForEntity: (entityType: EntityType) => DocumentCategoryWithoutReadIdAndClientId[];
  setSearchFolders: (searchFolders: FolderModel[]) => void;
  cancelSearch: () => void;
  setExtendSearchProperties: (extendSearchProperties: ExtendedSearchModel, searchText: string) => void;
  getListOfExtensionTypes: () => string[];
  setSortType: (sortType: string) => void;
}

// eslint-disable-next-line no-redeclare
export const FoldersContext = createContext<FoldersContext | null>(null);

export const DocumentsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getTechnicsFor, technicsLoading } = useTechnics();
  const { getSignatureDocumentsForEntity, loading: signatureLoading } = useSignatureDocuments();
  const {
    fileCategories,
    createFileCategory,
    updateFileCategory,
    deleteFileCategory,
    loading: documentCategoryLoading,
  } = useDocumentCategory();
  const { deleteFile, createFile, updateFile, getFiles, filesCollection, loading: filesLoading } = useFiles();
  const { language } = useUser();
  const { buildings, loading: buildingLoading } = useBuildings();
  const { leasesLoading, getLeaseFromUnitLease } = useLeases();
  const { loading: unitsLoading } = useUnits();
  const { getUnit } = useUnits();
  const { contacts, contactsLoading } = useContacts();

  const [state, dispatch] = useReducer<Reducer<FolderState, Action>>(documentReducer, initialState);
  const loadingOthers =
    (technicsLoading ?? false) ||
    signatureLoading ||
    buildingLoading ||
    unitsLoading ||
    (leasesLoading ?? false) ||
    contactsLoading;

  useEffect(() => {
    const fetch = async () => {
      await fetchAll();
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchAllFiles();
    };
    if (!filesLoading) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesCollection]);

  useEffect(() => {
    const fetch = async () => {
      await fetchAllDocumentCategories();
    };
    if (!documentCategoryLoading) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCategories]);

  useEffect(() => {
    if (!state.addingFolder) {
      if (state.isInitialLoading) {
        setFilteredFolders(state.folders);
        dispatch({
          type: 'SET_FOLDER_ATTRIBUTES',
          payload: { key: [FolderBooleanAttributes.INITIAL_LOADING], value: false },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.addingFolder]);

  useEffect(() => {
    const calculateFoldersAsync = async (id: string, entityType: EntityType) => {
      await calculateFolders(id, entityType);
    };
    if (state.isInitialLoading && !isNilOrEmpty(state.id) && !isNilOrEmpty(state.entityType) && state.addingFolder) {
      calculateFoldersAsync(state.id, state.entityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialLoading, state.id, state.entityType, state.addingFolder]);

  useEffect(() => {
    setFilteredFolders(state.folders, last(state.folderNavigation)?.documentCategory, last(state.folderNavigation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.LOADING_OTHER_CONTEXTS], value: loadingOthers },
    });
  }, [loadingOthers]);

  const fetchAllFiles = async () => {
    const entitiesArray = [
      EntityType.BUILDING,
      EntityType.UNIT,
      EntityType.LEASE,
      EntityType.CONTACT,
      EntityType.TECHNIC,
    ];

    const allFiles = entitiesArray.reduce<FileModel[]>((acc, entity) => {
      acc.push(...getFiles(entity));
      return acc;
    }, []);

    const allClientS3 = await getS3ObjectUrls(allFiles);

    const s3Files = entitiesArray.reduce<{ [key: string]: DocumentModel[] }>((acc, entity) => {
      acc[entity] = allClientS3.filter((file) => file.foreignTableName === entity);
      return acc;
    }, {});

    dispatch({
      type: 'SET_FILES',
      payload: {
        buildings: s3Files[EntityType.BUILDING],
        units: s3Files[EntityType.UNIT],
        leases: s3Files[EntityType.LEASE],
        contacts: s3Files[EntityType.CONTACT],
        technics: s3Files[EntityType.TECHNIC],
      },
    });
  };

  const fetchAllDocumentCategories = async () => {
    const categoriesArray = [EntityType.BUILDING, EntityType.UNIT, EntityType.LEASE, EntityType.CONTACT];

    const allCategories = categoriesArray.reduce<{ [key: string]: DocumentCategoryWithoutReadIdAndClientId[] }>(
      (acc, entity) => {
        acc[entity] = fileCategories.filter((category) => category.entity === entity || category.entity === null);
        return acc;
      },
      {}
    );
    dispatch({
      type: 'SET_CATEGORIES',
      payload: {
        buildingsCategory: allCategories[EntityType.BUILDING],
        unitsCategory: allCategories[EntityType.UNIT],
        leasesCategory: allCategories[EntityType.LEASE],
        contactsCategory: allCategories[EntityType.CONTACT],
      },
    });
  };

  const fetchAll = async () => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    await fetchAllFiles();
    await fetchAllDocumentCategories();

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const fetchInitialDocuments = (id: string, type: EntityType) => {
    dispatch({ type: 'SET_ID_AND_TYPE', payload: { id, type } });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.INITIAL_LOADING, FolderBooleanAttributes.FOLDER_ADDING], value: true },
    });
  };

  const calculateFolders = async (id: string, type: EntityType) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.INITIAL_LOADING], value: true },
    });
    dispatch({ type: 'SET_FOLDER_ATTRIBUTES', payload: { key: [FolderBooleanAttributes.ON_PAGE], value: false } });
    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [undefined] });

    const categories = getCategoriesForEntity(type);

    const technics = getTechnicsFor(id);

    let s3Files: DocumentModel[] = [];
    technics.forEach((technic) => {
      s3Files.push(...state.technicsClientFiles.filter((file) => file.foreignKey === technic.id));
    });

    s3Files.push(...getDocumentsListForEntity(type).filter((file) => file.foreignKey === id)!);

    const docsWithSignature = getSignatureDocumentsForEntity('Lease', id);
    if (!isNilOrEmpty(docsWithSignature)) {
      docsWithSignature[0].fileIds?.forEach((fileId) => {
        const index = s3Files.findIndex((file) => file.id === fileId);
        if (index !== -1) {
          s3Files[index].hasSignature = true;
        }
      });
    }

    s3Files = s3Files.map((file) => getMappedS3File(file, language));

    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });

    if (type !== EntityType.CONTACT) {
      setFolderList(undefined, 'rootFolder', s3Files, undefined, categories, 'rootParentFolder');
      return;
    }

    const contact = contacts.find((stateContact) => stateContact.id === id);
    if (!isNil(contact)) {
      dispatch({ type: 'SET_CONTACT', payload: contact });
      const contactType = contactMostImportantType(contact);
      if (contactType === ContactType.JOINT_OWNERSHIP) {
        calculateJointOwnershipFolders(contact);
      }
    }
    setFolderList(undefined, 'rootFolder', s3Files, undefined, categories, 'rootParentFolder');
  };

  const setJointFolders = (
    contactFolder: FolderModel,
    files: DocumentModel[],
    calculateFiles?: boolean,
    newCategories?: DocumentCategoryWithoutReadIdAndClientId[]
  ) => {
    const currentContactFiles = files.reduce<DocumentModel[]>((contactFiles, file) => {
      if (file.foreignKey !== contactFolder.entityId) return contactFiles;
      contactFiles.push(getMappedS3File(file, language));
      return contactFiles;
    }, []);
    if (!isEmpty(currentContactFiles)) {
      const categoriesToSetFolderList = getCategoriesToWorkWith(
        isNilOrEmpty(newCategories) ? getCategoriesForEntity(EntityType.CONTACT) : newCategories,
        contactFolder.documentCategory.id
      );

      setFolderList(
        contactFolder.documentCategory.id,
        calculateFiles
          ? `${contactFolder.folderDepth}.${contactFolder.documentCategory.id}`
          : `rootFolder.${contactFolder.documentCategory.id}`,
        currentContactFiles,
        calculateFiles
          ? `${contactFolder.folderDepthString}/${contactFolder.translatedLabel}`.replace('//', '/')
          : `/${contactFolder.translatedLabel}`,
        categoriesToSetFolderList,
        contactFolder.parentFolderId
      );
    }
  };

  const getEntityLabels = (label: string): CustomLabel[] => {
    return [
      {
        language: Language.EN,
        label: label,
      },
      {
        language: Language.FR,
        label: label,
      },
      {
        language: Language.NL,
        label: label,
      },
    ];
  };

  const calculateJointOwnershipFolders = (
    contact: Contact,
    parentFolder?: FolderModel,
    calculateFiles?: boolean,
    newCategories?: DocumentCategoryWithoutReadIdAndClientId[]
  ) => {
    const files = getDocumentsListForEntity(EntityType.CONTACT);
    const jointOwnersContacts = contact.jointOwners?.map((jointOwner) =>
      contacts.find((stateContact) => stateContact.id === jointOwner.contactId)
    );

    if (isNil(jointOwnersContacts)) {
      return;
    }
    const folders = jointOwnersContacts.reduce<FolderModel[]>((acc, jointOwner) => {
      if (isNil(jointOwner)) {
        return acc;
      }

      const jointOwnerCategoryId = uuidv4();
      const contactCategory: DocumentCategoryWithoutReadIdAndClientId = {
        id: jointOwnerCategoryId,
        labels: getEntityLabels(getContactNameFromObject(jointOwner)),
        parentId: calculateFiles ? parentFolder?.documentCategory.id : null,
        fileCategory: FileCategory.CUSTOM,
        entity: EntityType.CONTACT,
      };
      const contactFolderId = uuidv4();
      const contactFolder: FolderModel = {
        ...basicCreateFolderObject,
        id: contactFolderId,
        folderEntity: FolderEntity.CONTACT,
        translatedLabel: getContactNameFromObject(jointOwner),
        documentCategory: contactCategory,
        folderDepth: calculateFiles
          ? `${parentFolder?.folderDepth}.${parentFolder?.documentCategory.id}`
          : 'rootFolder',
        folderDepthString: calculateFiles
          ? `${parentFolder?.folderDepthString}/${parentFolder?.translatedLabel}`.replace('//', '/')
          : '/',
        entityId: jointOwner.id,
        labelToSortOn: getSortableContactName(jointOwner),
        parentFolderId: contactFolderId,
      } as FolderModel;
      acc.push(contactFolder);
      setJointFolders(contactFolder, files, calculateFiles, newCategories);
      return acc;
    }, []);
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: folders, foldersToDelete: [] } });
  };

  const calculateContactsFolders = (): {
    folders: FolderModel[];
    entityCategories: DocumentCategoryWithoutReadIdAndClientId[];
  } => {
    const folders: FolderModel[] = [];
    const entityCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];
    const tempContactCategoryId = uuidv4();
    const tempContactCategory: DocumentCategoryWithoutReadIdAndClientId = {
      id: tempContactCategoryId,
      labels: getEntityLabels('Contacts'),
      parentId: null,
      fileCategory: FileCategory.CUSTOM,
      entity: EntityType.CONTACT,
    };
    const rootContactFolderId = uuidv4();
    const contactsFolder: FolderModel = {
      ...basicCreateFolderObject,
      id: rootContactFolderId,
      folderEntity: FolderEntity.CONTACT,
      translatedLabel: getTranslatedCategory(tempContactCategory, language),
      documentCategory: tempContactCategory,
      folderDepth: 'rootFolder',
      folderDepthString: '/',
      entityId: 'contacts',
      parentFolderId: rootContactFolderId,
    } as FolderModel;

    folders.push(contactsFolder);
    entityCategories.push(tempContactCategory);
    const filteredContacts = contacts.filter((contact) =>
      contact.types.some((conactType) =>
        [
          ContactType.CONTRACTOR,
          ContactType.GUARANTOR,
          ContactType.JOINT_OWNERSHIP,
          ContactType.OWNER,
          ContactType.TENANT,
        ].includes(conactType)
      )
    );
    for (const contact of filteredContacts) {
      const tempFilteredContactCategoryId = uuidv4();
      const contactCategory: DocumentCategoryWithoutReadIdAndClientId = {
        id: tempFilteredContactCategoryId,
        labels: getEntityLabels(getContactNameFromObject(contact)),
        parentId: tempContactCategoryId,
        fileCategory: FileCategory.CUSTOM,
        entity: EntityType.CONTACT,
      };
      const contactFolderId = uuidv4();
      const contactFolder: FolderModel = {
        ...basicCreateFolderObject,
        id: contactFolderId,
        folderEntity: FolderEntity.CONTACT,
        translatedLabel: getContactNameFromObject(contact),
        documentCategory: contactCategory,
        folderDepth: `rootFolder.${tempContactCategoryId}`,
        folderDepthString: `/${getTranslatedCategory(tempContactCategory, language)}`,
        entityId: contact.id,
        labelToSortOn: getSortableContactName(contact),
        parentFolderId: contactFolderId,
      } as FolderModel;
      folders.push(contactFolder);
      entityCategories.push(contactCategory);

      if (contactMostImportantType(contact) === ContactType.JOINT_OWNERSHIP) {
        calculateJointOwnershipFolders(contact, contactFolder, true);
      }

      const currentContactFiles = state.contactClientFiles.reduce<DocumentModel[]>((acc, file) => {
        if (file.foreignKey === contact.id) {
          acc.push(getMappedS3File(file, language));
        }
        return acc;
      }, []);

      if (!isEmpty(currentContactFiles)) {
        const categoriesToWorkWith = getCategoriesToWorkWith(state.contactCategories, tempFilteredContactCategoryId);
        categoriesToWorkWith.push(contactCategory);
        setFolderList(
          tempFilteredContactCategoryId,
          `rootFolder.${tempContactCategoryId}.${tempFilteredContactCategoryId}`,
          currentContactFiles,
          `/${getTranslatedCategory(tempContactCategory, language)}/${getContactNameFromObject(contact)}`,
          categoriesToWorkWith,
          contactFolder.parentFolderId
        );
      }
    }
    return { folders, entityCategories };
  };

  const calculateBuildingsFolders = (
    building: Building
  ): {
    folder: FolderModel;
    entityCategory: DocumentCategoryWithoutReadIdAndClientId;
  } => {
    const tempBuildingCategoryId = uuidv4();
    const tempBuildingCategory: DocumentCategoryWithoutReadIdAndClientId = {
      id: tempBuildingCategoryId,
      labels: getEntityLabels(building.name),
      parentId: null,
      fileCategory: FileCategory.CUSTOM,
      entity: EntityType.BUILDING,
    };
    const folderId = uuidv4();
    const folder: FolderModel = {
      ...basicCreateFolderObject,
      id: folderId,
      folderEntity: FolderEntity.BUILDING,
      translatedLabel: building.name,
      documentCategory: tempBuildingCategory,
      folderDepth: 'rootFolder',
      folderDepthString: '/',
      entityId: building.id,
      parentFolderId: folderId,
    } as FolderModel;
    const currentBuildingFiles = state.buildingClientFiles.reduce<DocumentModel[]>((acc, file) => {
      if (file.foreignKey === building.id) {
        if (isNil(file.category?.parentId)) {
          file.category!.parentId = tempBuildingCategoryId;
        }
        acc.push(getMappedS3File(file, language));
      }
      return acc;
    }, []);
    if (!isEmpty(currentBuildingFiles)) {
      const categoriesToWorkWith = getCategoriesToWorkWith(state.buildingCategories, tempBuildingCategoryId);
      categoriesToWorkWith.push(tempBuildingCategory);
      setFolderList(
        tempBuildingCategoryId,
        `rootFolder.${tempBuildingCategoryId}`,
        currentBuildingFiles,
        `/${building.name}`,
        categoriesToWorkWith,
        folder.parentFolderId
      );
    }
    return { folder, entityCategory: tempBuildingCategory };
  };

  const calculateUnitFolders = (
    unit: Unit,
    buildingCategory: DocumentCategoryWithoutReadIdAndClientId,
    buildingName: string
  ): {
    unitFolder: FolderModel;
    entityCategory: DocumentCategoryWithoutReadIdAndClientId;
  } => {
    const tempUnitCategoryId = uuidv4();
    const unitDocumentCatergory: DocumentCategoryWithoutReadIdAndClientId = {
      id: tempUnitCategoryId,
      labels: getEntityLabels(unit.name),
      parentId: buildingCategory.id,
      fileCategory: FileCategory.CUSTOM,
      entity: EntityType.UNIT,
    };
    const unitFolderId = uuidv4();
    const unitFolder: FolderModel = {
      ...basicCreateFolderObject,
      id: unitFolderId,
      folderEntity: FolderEntity.UNIT,
      translatedLabel: unit.name,
      documentCategory: unitDocumentCatergory,
      folderDepth: `rootFolder.${buildingCategory.id}`,
      folderDepthString: `/${buildingName}`,
      entityId: unit.id,
      parentFolderId: unitFolderId,
    } as FolderModel;

    const currentUnitFiles = state.unitClientFiles.reduce<DocumentModel[]>((acc, file) => {
      if (file.foreignKey === unit.id) {
        acc.push(getMappedS3File(file, language));
      }
      return acc;
    }, []);

    if (!isEmpty(currentUnitFiles)) {
      const categoriesToWorkWith = getCategoriesToWorkWith(state.unitCategories, tempUnitCategoryId);
      categoriesToWorkWith.push(unitDocumentCatergory);
      setFolderList(
        tempUnitCategoryId,
        `rootFolder.${buildingCategory.id}.${tempUnitCategoryId}`,
        currentUnitFiles,
        `/${buildingName}/${unit.name}`,
        categoriesToWorkWith,
        unitFolder.parentFolderId
      );
    }

    return { unitFolder, entityCategory: unitDocumentCatergory };
  };

  const calculateLeaseFolders = (
    lease: LeaseExtended,
    unitLease: UnitLease,
    unitCategory: DocumentCategoryWithoutReadIdAndClientId,
    buildingCategory: DocumentCategoryWithoutReadIdAndClientId,
    buildingName: string,
    unitName: string
  ): {
    leaseFolder: FolderModel;
    leaseDocumentCategory: DocumentCategoryWithoutReadIdAndClientId;
  } => {
    const tempLeaseCategoryId = uuidv4();
    const leaseDocumentCategory: DocumentCategoryWithoutReadIdAndClientId = {
      id: tempLeaseCategoryId,
      labels: getEntityLabels(getLeaseExtendedName(lease, dateLocaleMap[language!])),
      parentId: unitCategory.id,
      fileCategory: FileCategory.CUSTOM,
      entity: EntityType.LEASE,
    };
    const leaseFolderId = uuidv4();
    const leaseFolder: FolderModel = {
      ...basicCreateFolderObject,
      id: leaseFolderId,
      folderEntity: FolderEntity.LEASE,
      translatedLabel: getLeaseExtendedName(lease, dateLocaleMap[language!]),
      documentCategory: leaseDocumentCategory,
      folderDepth: `rootFolder.${buildingCategory.id}.${unitCategory.id}`,
      folderDepthString: `/${buildingName}/${unitName}`,
      entityId: unitLease.leaseId,
      parentFolderId: leaseFolderId,
    } as FolderModel;
    const currentLeaseFiles = state.leaseClientFiles.reduce<DocumentModel[]>((acc, file) => {
      if (file.foreignKey === lease?.id) {
        acc.push(getMappedS3File(file, language));
      }
      return acc;
    }, []);

    if (!isEmpty(currentLeaseFiles)) {
      const categoriesToWorkWith = getCategoriesToWorkWith(state.leaseCategories, tempLeaseCategoryId);
      categoriesToWorkWith.push(leaseDocumentCategory);
      setFolderList(
        tempLeaseCategoryId,
        `rootFolder.${buildingCategory.id}.${unitCategory.id}.${tempLeaseCategoryId}`,
        currentLeaseFiles,
        `/${buildingName}/${unitName}/${getLeaseExtendedName(lease, dateLocaleMap[language!])}`,
        categoriesToWorkWith,
        leaseFolder.parentFolderId
      );
    }
    return { leaseFolder, leaseDocumentCategory };
  };

  const calculatePageFolder = async () => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: {
        key: [
          FolderBooleanAttributes.CONTEXT_IS_BUSY,
          FolderBooleanAttributes.ON_PAGE,
          FolderBooleanAttributes.INITIAL_LOADING,
        ],
        value: true,
      },
    });

    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [undefined] });
    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });

    const folders: FolderModel[] = [];
    const entityCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];

    const { folders: contactFolders, entityCategories: contactCategories } = calculateContactsFolders();
    folders.push(...contactFolders);
    entityCategories.push(...contactCategories);

    for (const building of buildings) {
      const { folder, entityCategory: buildingCategory } = calculateBuildingsFolders(building);
      entityCategories.push(buildingCategory);
      building.units?.forEach((unit) => {
        const { unitFolder, entityCategory: unitCategory } = calculateUnitFolders(
          unit,
          buildingCategory,
          building.name
        );
        folders.push(unitFolder);
        entityCategories.push(unitCategory);
        const pageUnits = getUnit(unit.id);
        pageUnits?.leases?.forEach((unitLease) => {
          const lease = getLeaseFromUnitLease(unitLease.id);
          if (isNil(lease)) return;
          const { leaseFolder, leaseDocumentCategory } = calculateLeaseFolders(
            lease,
            unitLease,
            unitCategory,
            buildingCategory,
            building.name,
            unit.name
          );
          folders.push(leaseFolder);
          entityCategories.push(leaseDocumentCategory);
        });
      });
      folders.push(folder);
    }

    dispatch({ type: 'SET_ENTITY_DOCUMENT_CATEGORIES', payload: entityCategories });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: folders, foldersToDelete: [] } });

    setFilteredFolders([...state.folders, ...folders]);

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.INITIAL_LOADING], value: false },
    });
  };

  interface UpdateContacts {
    foldersToAdd: FolderModel[];
    foldersToDelete: FolderModel[];
  }

  const updateContactsWhenRenameFile = (
    parentFolder: FolderModel,
    documentToRename: DocumentModel,
    newFolder: FolderModel
  ): UpdateContacts => {
    const foldersToAdd: FolderModel[] = [];
    const foldersToDelete: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (stateFolder) => stateFolder.entityId === parentFolder?.entityId && stateFolder.id !== parentFolder?.id
    );

    jointFolders.forEach((jointfolder) => {
      const folderToDelete = state.folders.find(
        (stateFolder) =>
          stateFolder.documentModel?.id === documentToRename.id && stateFolder.parentFolderId === jointfolder.id
      );
      foldersToDelete.push(folderToDelete!);
      const depthString = `${jointfolder.folderDepthString}/${jointfolder.translatedLabel}`;
      const depth = `${jointfolder.folderDepth}.${jointfolder.documentCategory.id}`;

      foldersToAdd.push({
        ...newFolder,
        id: uuidv4(),
        folderDepth: newFolder.folderDepth?.replace(
          `${parentFolder?.folderDepth}.${parentFolder.documentCategory.id}`,
          depth
        ),
        folderDepthString: newFolder.folderDepthString?.replace(
          `${parentFolder?.folderDepthString}/${parentFolder.translatedLabel}`,
          depthString
        ),
      });
    });

    return { foldersToAdd, foldersToDelete };
  };

  const renameFile = async (folderToRename: FolderModel, newName: string) => {
    if (isNil(folderToRename.documentModel)) return;

    const documentToRename = folderToRename.documentModel;
    if (isNil(documentToRename.mimeType)) {
      return;
    }

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    const newFile = { ...documentToRename, fileName: newName };

    const blobRes = await downloadData({ key: documentToRename.key }).result;
    const blobToCopy = await blobRes.body.blob();
    const newFileToUpload = new File([blobToCopy], encodeURI(newName), {
      type: documentToRename.mimeType,
    });

    const parentFolder = getParentFolder(state.folders, folderToRename);
    const entityType = state.onPage ? (parentFolder?.documentCategory.entity as EntityType) : state.entityType;
    const entityId = state.onPage ? parentFolder?.entityId : state.id;

    await createFile(
      newFileToUpload,
      entityType!,
      entityId!,
      documentToRename.category!.id,
      undefined,
      state.contact ? contactMostImportantType(state.contact) : undefined,
      state.contact ? state.contact.id : undefined
    );
    await deleteFile(documentToRename);

    const newDocuments = getDocumentsListForEntity(state.entityType!).map((document) => {
      if (document.id === documentToRename.id) {
        return newFile;
      }
      return document;
    });
    const foldersToAdd: FolderModel[] = [];
    const foldersToDelete: FolderModel[] = [];

    const changedFolder = state.folders.find(
      (stateFolder) =>
        stateFolder.documentModel?.id === documentToRename.id &&
        stateFolder.parentFolderId === folderToRename.parentFolderId
    );
    foldersToDelete.push(changedFolder!);

    const newFolder = { ...changedFolder, documentModel: newFile, translatedLabel: newName } as FolderModel;
    foldersToAdd.push(newFolder);

    if (parentFolder?.folderEntity === FolderEntity.CONTACT) {
      const updatedFolders = updateContactsWhenRenameFile(parentFolder, documentToRename, newFolder);
      foldersToAdd.push(...updatedFolders.foldersToAdd);
      foldersToDelete.push(...updatedFolders.foldersToDelete);
    }

    dispatch({ type: 'UPDATE_FILES', payload: { files: newDocuments, type: state.entityType! } });
    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete,
      },
    });

    const filteredFolders = state.folders.filter((stateFolder) => !foldersToDelete.includes(stateFolder));
    filteredFolders.push(...foldersToAdd);

    setFilteredFolders(filteredFolders, last(state.folderNavigation)?.documentCategory, last(state.folderNavigation));
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const deleteDocument = async (folderToDelete: FolderModel) => {
    const newDocuments: DocumentModel[] = [];
    const parentFolder = getParentFolder(state.folders, folderToDelete);
    if (state.onPage) {
      const documents = getDocumentsListForEntity(parentFolder?.documentCategory.entity as EntityType).filter(
        (document) => {
          return document.id !== folderToDelete.documentModel!.id;
        }
      );
      newDocuments.push(...documents);
    } else {
      const documents = getDocumentsListForEntity(state.entityType!).filter((document) => {
        return document.id !== folderToDelete.documentModel!.id;
      });
      newDocuments.push(...documents);
    }

    await deleteFile(folderToDelete.documentModel!);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: newDocuments,
        type: isNil(parentFolder) ? state.entityType! : (parentFolder.documentCategory.entity as EntityType),
      },
    });
  };

  const getParentFolderOfAddedFiles = (
    targetFolder: FolderModel,
    folderCategoryIdsToCheck: string[]
  ): FolderModel | undefined => {
    const foldersToCheck = compact(
      folderCategoryIdsToCheck.map((folderCategoryId) => {
        return state.folders.find(
          (stateFolder) =>
            stateFolder.documentCategory?.id === folderCategoryId &&
            stateFolder.parentFolderId === targetFolder?.parentFolderId &&
            stateFolder.folderEntity !== FolderEntity.FILE
        );
      })
    );
    return foldersToCheck.find((folder) => folder.id === targetFolder.parentFolderId);
  };

  const calculateFoldersToAdd = (
    parentFolder: FolderModel,
    targetFolder: FolderModel,
    folderCategoryIdsToCheck: string[],
    s3Files: DocumentModel[]
  ): FolderModel[] => {
    const index = folderCategoryIdsToCheck?.findIndex((folderId) => folderId === parentFolder.documentCategory?.id);
    folderCategoryIdsToCheck = folderCategoryIdsToCheck?.slice(0, index);

    const foldersToAdd = s3Files.map((file) => {
      const fileName = decodeURI(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, language);

      const category = file.category;
      if (!isNil(category) && isNil(category.parentId)) {
        file.category = { ...category, parentId: parentFolder.documentCategory.id };
      }

      return {
        ...basicCreateFolderObject,
        folderEntity: FolderEntity.FILE,
        id: uuidv4(),
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory!),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: targetFolder ? `${targetFolder.folderDepth}.${targetFolder.documentCategory?.id}` : 'rootFolder',
        folderDepthString: targetFolder
          ? `${targetFolder.folderDepthString}/${targetFolder.translatedLabel}`.replace('//', '/')
          : '/',
        parentFolderId: targetFolder.parentFolderId ?? 'rootParentFolder',
      } as FolderModel;
    });

    const size = s3Files.reduce<number>((acc, file) => {
      return acc + (file.size ?? 0);
    }, 0);

    folderCategoryIdsToCheck?.forEach((folderCategoryId) => {
      const folder = state.folders.find(
        (stateFolder) =>
          stateFolder.documentCategory?.id === folderCategoryId &&
          stateFolder.parentFolderId === parentFolder.parentFolderId &&
          stateFolder.folderEntity !== FolderEntity.FILE
      );
      if (!isNil(folder)) return;
      const category = getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType).find(
        (categoryForEntity) => categoryForEntity.id === folderCategoryId
      );
      if (isNil(category)) return;
      const categoryDepthArray = getCategoryDeptArray(
        category!,
        getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType)
      );

      let categoryDepth = !isNil(parentFolder)
        ? `${parentFolder.folderDepth}.${parentFolder.documentCategory.id}`
        : 'rootfolder.';
      let categoryDepthString = !isNil(parentFolder)
        ? `${parentFolder.folderDepthString}/${parentFolder.translatedLabel}`
        : 'rootfolder.';
      if (!isNil(categoryDepthArray) && !isNil(category.parentId)) {
        categoryDepthArray.forEach((categoryId) => {
          categoryDepth += `.${categoryId}`;
          const categorytoWorkWith = getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType).find(
            (categoryForEntity) => categoryForEntity.id === categoryId
          );
          categoryDepthString += `/${getTranslatedCategory(categorytoWorkWith!, language)}`;
        });
      }

      const newFolder = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        size,
        translatedLabel: getTranslatedCategory(category!, language),
        documentCategory: {
          ...category,
          parentId: !isNil(category.parentId) ? category.parentId : parentFolder.documentCategory.id,
        },
        folderDepth: categoryDepth,
        folderDepthString: categoryDepthString.replace('//', '/'),
        parentFolderId: parentFolder.parentFolderId ?? 'rootParentFolder',
      } as FolderModel;
      foldersToAdd.push(newFolder);
    });
    return foldersToAdd;
  };

  const addedFilesOnContacts = (parentFolder: FolderModel, foldersToAdd: FolderModel[]): FolderModel[] => {
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === parentFolder.entityId && folder.id !== parentFolder.id
    );

    const jointFoldersToAdd = jointFolders.reduce<FolderModel[]>((acc, jointfolder) => {
      const depthString = `${jointfolder.folderDepthString}/${jointfolder.translatedLabel}`;
      const depth = `${jointfolder.folderDepth}.${jointfolder.documentCategory.id}`;
      const newFoldersToAdd = foldersToAdd.map((file) => {
        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderDepth?.replace(
            `${parentFolder.folderDepth}.${parentFolder.documentCategory.id}`,
            depth
          ),
          folderDepthString: file.folderDepthString?.replace(
            `${parentFolder.folderDepthString}/${parentFolder.translatedLabel}`,
            depthString
          ),
          parentFolderId: jointfolder.id,
          documentCategory: {
            ...file.documentCategory,
            parentId:
              file.documentCategory.parentId === parentFolder.documentCategory.id
                ? jointfolder.documentCategory.id
                : file.documentCategory.parentId,
          },
        };
      });
      acc.push(...newFoldersToAdd);
      return acc;
    }, []);
    return jointFoldersToAdd;
  };

  const AddFilesToFolderWhenOnPage = (targetFolder: FolderModel, s3Files: DocumentModel[]) => {
    const folderCategoryIdsToCheck = targetFolder.folderDepth?.split('.');
    folderCategoryIdsToCheck.push(targetFolder.documentCategory?.id ?? '');
    folderCategoryIdsToCheck.reverse();

    const parentFolder = getParentFolderOfAddedFiles(targetFolder, folderCategoryIdsToCheck);

    if (isNil(parentFolder)) return;
    const foldersToAdd = calculateFoldersToAdd(parentFolder, targetFolder, folderCategoryIdsToCheck, s3Files);

    if (parentFolder.folderEntity === FolderEntity.CONTACT) {
      foldersToAdd.push(...addedFilesOnContacts(parentFolder, foldersToAdd));
    }

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete: [],
      },
    });

    const filteredFolders = [...state.folders, ...foldersToAdd];
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(filteredFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(state.entityType!), ...s3Files],
        type: parentFolder.documentCategory.entity as EntityType,
      },
    });
  };

  const addFilesToFolder = async (files: FileModel[], targetFolder: FolderModel) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });
    const s3Files = await getS3ObjectUrls(files);

    if (state.onPage) {
      AddFilesToFolderWhenOnPage(targetFolder, s3Files);
      return;
    }
    const foldersToAdd = s3Files.map((file) => {
      const fileName = decodeURI(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, language);

      return {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FILE,
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory!),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: targetFolder ? `${targetFolder.folderDepth}.${targetFolder.documentCategory.id}` : 'rootFolder',
        folderDepthString: targetFolder
          ? `${targetFolder.folderDepthString}/${targetFolder.translatedLabel}`.replace('//', '/')
          : '/',
        parentFolderId: targetFolder.parentFolderId ?? 'rootParentFolder',
      } as FolderModel;
    });

    //If the targetfolder is not in the state folder, it means that it is an invisible folder and need to be added
    const checkTargetFolder = state.folders.find((stateFolder) => stateFolder.id === targetFolder.id);

    if (isNil(checkTargetFolder)) {
      foldersToAdd.push(targetFolder);
    }

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete: [],
      },
    });

    const filteredFolders = [...state.folders, ...foldersToAdd];
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(filteredFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(state.entityType!), ...s3Files],
        type: targetFolder?.documentCategory.entity as EntityType,
      },
    });
  };

  const setFilteredFolders = (
    foldersToWorkWith: FolderModel[],
    documentsCategory?: DocumentCategoryWithoutReadIdAndClientId,
    folderModel?: FolderModel,
    categories?: DocumentCategoryWithoutReadIdAndClientId[]
  ): void => {
    dispatch({ type: 'SET_CATEGORY_FOLDERS', payload: { folders: [] } });
    const folderModelDepth = getFolderDepthStringArray(folderModel?.folderDepth ?? '');
    const filteredFolders = foldersToWorkWith.filter((folder) => {
      const rootCategory = isNil(documentsCategory) ? folder.folderDepth === 'rootFolder' : false;
      let folderCheck = false;
      if (!rootCategory && !isNil(documentsCategory)) {
        const folderDepth = getFolderDepthStringArray(folder.folderDepth);
        let entity = true;
        if (!isNil(folderModel) && folder.folderDepthString) {
          entity = folder.folderDepth.includes(folderModel.folderDepth ?? '');
        }

        folderCheck = Boolean(
          last(folderDepth)?.includes(documentsCategory.id) &&
            entity &&
            folderModelDepth.length + 1 === folderDepth.length
        );
      }

      return rootCategory || folderCheck;
    });

    const foldersToDelete: FolderModel[] = [];
    const mappedFilteredFolders = filteredFolders.map((folder) => {
      const folderDepth = `${folder.folderDepth}.${folder.documentCategory?.id}`;
      if (!(folder.folderEntity === FolderEntity.FOLDER)) {
        return folder;
      }

      const folderInfos = foldersToWorkWith.reduce<{ size: number; uploadedAt: string }>(
        (acc, stateFolder) => {
          if (
            !isNil(folder.documentCategory) &&
            stateFolder.folderDepth?.includes(folderDepth ?? '') &&
            folder.parentFolderId === stateFolder.parentFolderId &&
            stateFolder.folderEntity === FolderEntity.FILE
          ) {
            acc.size += stateFolder.size;
            if (stateFolder.updatedAt > acc.uploadedAt) acc.uploadedAt = stateFolder.updatedAt;
          }
          return {
            size: acc.size,
            uploadedAt: stateFolder.updatedAt > acc.uploadedAt ? stateFolder.updatedAt : acc.uploadedAt,
          };
        },
        { size: 0, uploadedAt: '' }
      );

      if (folderInfos.size === 0) {
        foldersToDelete.push(folder);
        return;
      }

      return {
        ...folder,
        size: folderInfos.size,
        updatedAt: folderInfos.uploadedAt,
      };
    });

    if (state.seeAllFolders) {
      changeCategoryFolders(compact(mappedFilteredFolders), folderModel, folderModel, undefined, categories);
    }

    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: compact(mappedFilteredFolders) });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const setDraggedFolders = (draggedFolders: string[]) => {
    dispatch({ type: 'SET_DRAGGED_FOLDERS', payload: draggedFolders });
  };

  const setNavigation = (fromNavigation: boolean, folder?: FolderModel): void => {
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
    if (fromNavigation) {
      const index = state.folderNavigation.findIndex((folderNavigation) => folderNavigation?.id === folder?.id);
      const newNavigation = state.folderNavigation.slice(0, index + 1);
      dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: newNavigation });
      const lastNewNavigationFolder = last(newNavigation);
      setFilteredFolders(state.folders, lastNewNavigationFolder?.documentCategory, lastNewNavigationFolder);
      return;
    }

    if (state.searchFolders) {
      selectSearchFolder(folder!);
      return;
    }
    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [...state.folderNavigation, folder] });

    setFilteredFolders(state.folders, folder?.documentCategory, folder);
  };

  const changeSelectedFolder = (add: boolean, folderId: string[]) => {
    dispatch({
      type: 'CHANGE_SELECTED_FOLDERS',
      payload: {
        selectedFoldersToAdd: add ? folderId : [],
        selectedFoldersToDelete: add ? [] : folderId,
      },
    });
  };

  const changeDragging = (dragging: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.FOLDER_DRAGGING], value: dragging },
    });
  };

  const setSelectedFolders = (selected: boolean, folderIds: string[]) => {
    if (selected) {
      dispatch({ type: 'SET_SELECTED_FOLDERS', payload: folderIds });
    } else {
      dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
    }
  };

  const deselectFolders = () => {
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
  };

  const addFileOnDropAction = async (
    targetFolder: FolderModel | undefined,
    file: DocumentModel,
    entityType: EntityType
  ): Promise<S3Object> => {
    const updatedFile = await updateFile(file, { categoryId: targetFolder?.documentCategory?.id });
    const s3Files = await getS3ObjectUrls([updatedFile]);
    const canDragFile = updatedFile.category ? canDrag(updatedFile.category.fileCategory!) : false;
    const translatedCategory = updatedFile.category ? getTranslatedCategory(updatedFile.category, language) : '';
    const updatedList = getDocumentsListForEntity(state.entityType!).map((doc) => {
      if (doc.id === file.id) {
        return { ...s3Files[0], canDrag: canDragFile, translatedCategory };
      }
      return doc;
    });

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: updatedList,
        type: entityType,
      },
    });
    return s3Files[0];
  };

  const updateFolders = (
    foreignKey: string,
    entityFolder: FolderModel,
    folderDepth: string,
    folderDepthString: string,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType
  ) => {
    const foldersToDelete: FolderModel[] = [];
    const files = compact(
      state.folders.map((folder) => {
        if (folder.documentModel?.foreignKey === foreignKey) {
          foldersToDelete.push(folder);
          return folder.documentModel!;
        }
        if (
          folder.documentCategory.entity === entityType &&
          folder.folderDepth?.includes(entityFolder.documentCategory.id)
        ) {
          foldersToDelete.push(folder);
        }
      })
    );
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    const categoriesToWorkWith = [
      ...getCategoriesToWorkWith(newCategories, entityFolder.documentCategory.id),
      entityFolder.documentCategory,
    ];
    if (isEmpty(files)) return;
    setFolderList(
      entityFolder?.documentCategory.id,
      folderDepth,
      files,
      folderDepthString,
      categoriesToWorkWith,
      entityFolder.parentFolderId
    );
  };

  const updateBuildingsFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder) || buildingFolder.id === currentContextFolder.id) return;
      updateFolders(
        building.id,
        buildingFolder,
        `rootFolder.${buildingFolder?.documentCategory.id}`,
        `/${building.name}`,
        newCategories,
        EntityType.BUILDING
      );
    });
  };

  const updateUnitFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder)) return;
      building.units?.forEach((unit) => {
        const unitFolder = state.folders.find((folder) => folder.entityId === unit.id);
        if (isNil(unitFolder) || unitFolder.id === currentContextFolder.id) return;
        updateFolders(
          unit.id,
          unitFolder,
          `rootFolder.${buildingFolder.documentCategory.id}.${unitFolder?.documentCategory.id}`,
          `/${building.name}/${unit.name}`,
          newCategories,
          EntityType.UNIT
        );
      });
    });
  };

  const updateLeaseFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder)) return;
      building.units?.forEach((unit) => {
        const unitFolder = state.folders.find((folder) => folder.entityId === unit.id);
        if (isNil(unitFolder)) return;
        const pageUnits = getUnit(unit.id);
        pageUnits?.leases?.forEach((unitLease) => {
          const lease = getLeaseFromUnitLease(unitLease.id);
          if (isNil(lease)) return;
          const leaseFolder = state.folders.find((folder) => folder.entityId === unitLease.leaseId);
          if (isNil(leaseFolder) || leaseFolder.id === currentContextFolder.id) return;
          updateFolders(
            unitLease.leaseId,
            leaseFolder,
            // eslint-disable-next-line max-len
            `rootFolder.${buildingFolder.documentCategory.id}.${unitFolder?.documentCategory.id}.${leaseFolder.documentCategory.id}`,
            `/${building.name}/${unit.name}/${getLeaseExtendedName(lease, dateLocaleMap[language!])}`,
            newCategories,
            EntityType.LEASE
          );
        });
      });
    });
  };

  const updateContacts = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    const filteredContacts = contacts.filter((contact) =>
      contact.types.some((conactType) =>
        [
          ContactType.CONTRACTOR,
          ContactType.GUARANTOR,
          ContactType.JOINT_OWNERSHIP,
          ContactType.OWNER,
          ContactType.TENANT,
        ].includes(conactType)
      )
    );
    filteredContacts.forEach((contact) => {
      const filteredContactFolder = state.folders.filter((folder) => folder.entityId === contact.id);
      filteredContactFolder.forEach((contactFolder) => {
        if (isNil(contactFolder) || contactFolder.id === currentContextFolder.id) {
          return;
        }
        setContactFiles(contact, contactFolder, newCategories, currentContextFolder);
      });
    });
  };

  const setContactFiles = (
    contact: Contact,
    currentContactFolder: FolderModel,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    const foldersToDelete: FolderModel[] = [];
    const files = compact(
      state.folders.map((folder) => {
        if (
          folder.documentModel?.foreignKey === contact.id &&
          folder.parentFolderId === currentContactFolder.parentFolderId
        ) {
          foldersToDelete.push(folder);
          return folder.documentModel!;
        }
        if (
          folder.documentCategory.entity === EntityType.CONTACT &&
          folder.folderDepth?.includes(currentContactFolder.documentCategory.id) &&
          folder.parentFolderId !== currentContextFolder.id &&
          (folder.folderEntity === FolderEntity.FOLDER || folder.folderEntity === FolderEntity.FILE)
        ) {
          foldersToDelete.push(folder);
        }
      })
    );

    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    const categoriesToWorkWith = [
      ...getCategoriesToWorkWith(newCategories, currentContactFolder.documentCategory.id),
      currentContactFolder.documentCategory,
    ];
    if (isEmpty(files)) return;
    setFolderList(
      currentContactFolder.documentCategory.id,
      `${currentContactFolder.folderDepth}.${currentContactFolder.documentCategory.id}`,
      uniqBy(files, 'id'),
      `${currentContactFolder.folderDepthString}/${getContactNameFromObject(contact)}`,
      categoriesToWorkWith,
      currentContactFolder.parentFolderId
    );
  };

  const localUpdateCategories = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType,
    currentContextFolder: FolderModel
  ) => {
    if (entityType === EntityType.BUILDING) {
      updateBuildingsFolders(newCategories, currentContextFolder);
      return;
    }
    if (entityType === EntityType.UNIT) {
      updateUnitFolders(newCategories, currentContextFolder);
    }
    if (entityType === EntityType.LEASE) {
      updateLeaseFolders(newCategories, currentContextFolder);
    }
    if (entityType === EntityType.CONTACT) {
      updateContacts(newCategories, currentContextFolder);
    }
  };

  const whenDroppedAFolder = async (
    folder: FolderModel,
    clientFolder: FolderModel,
    targetFolder: FolderModel,
    entityType: EntityType,
    currentFolder: FolderModel
  ): Promise<
    | {
        foldersToAdd: FolderModel[];
        foldersToDelete: FolderModel[];
        newCategories: DocumentCategoryWithoutReadIdAndClientId[];
      }
    | undefined
  > => {
    const foldersToDelete: FolderModel[] = [];
    const newCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];
    const stateFoldersToDelete = state.folders.filter(
      (stateFolder) =>
        !isNil(folder.documentCategory) &&
        (!isNil(clientFolder)
          ? stateFolder.parentFolderId === folder.parentFolderId
          : stateFolder.parentFolderId === 'rootParentFolder') &&
        stateFolder.folderDepth?.includes(folder.documentCategory.id)
    );
    if (isNil(folder.documentCategory)) return;
    const updatedDocumentCategory = await updateFileCategory(folder.documentCategory, {
      parentId: targetFolder?.folderEntity === FolderEntity.FOLDER ? targetFolder?.documentCategory?.id : undefined,
    });

    const entityCategories = getCategoriesForEntity(entityType);
    const newEntityCategoryIndex = entityCategories.findIndex((category) => category.id === updatedDocumentCategory.id);
    entityCategories[newEntityCategoryIndex] = updatedDocumentCategory;
    newCategories.push(...entityCategories);

    dispatch({ type: 'UPDATE_CATEGORY', payload: { category: entityCategories, type: entityType } });
    foldersToDelete.push(folder);
    if (isEmpty(stateFoldersToDelete)) return { foldersToAdd: [], foldersToDelete, newCategories };
    const foldersToAdd = stateFoldersToDelete.map((folderToDelete) => {
      const folderDepth = folderToDelete.folderDepth?.replace(
        currentFolder.folderDepth,
        targetFolder ? getFolderDepth(targetFolder) : state.onPage ? getFolderDepth(clientFolder!) : 'rootFolder'
      );
      const folderDepthString = folderToDelete.folderDepthString
        ?.replace(
          currentFolder.folderDepthString!,
          (targetFolder
            ? `${getFolderDepthString(language, targetFolder)}/`
            : state.onPage
            ? `${getFolderDepthString(language, clientFolder)}/`
            : '/'
          ).replace('//', '/')
        )
        .replace('//', '/');
      return { ...folderToDelete, folderDepth, folderDepthString };
    });
    const folderToAdd: FolderModel = {
      ...basicCreateFolderObject,
      id: uuidv4(),
      folderEntity: FolderEntity.FOLDER,
      translatedLabel: getTranslatedCategory(updatedDocumentCategory, language),
      documentCategory: updatedDocumentCategory,
      folderDepth: targetFolder
        ? getFolderDepth(targetFolder)
        : state.onPage
        ? getFolderDepth(clientFolder!)
        : 'rootFolder',
      folderDepthString: (targetFolder
        ? getFolderDepthString(language, targetFolder)
        : state.onPage
        ? getFolderDepthString(language, clientFolder!)
        : '/'
      ).replace('//', '/'),
      parentFolderId: clientFolder?.parentFolderId ?? 'rootParentFolder',
    } as FolderModel;

    foldersToAdd.push(folderToAdd);
    foldersToDelete.push(...stateFoldersToDelete);
    return { foldersToAdd, foldersToDelete, newCategories };
  };

  const droppedFolderLinkedToContact = (
    clientFolder: FolderModel,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[]
  ): UpdateContacts => {
    const foldersToDelete: FolderModel[] = [];
    const foldersToAdd: FolderModel[] = [];
    let contactId = state.id;
    if (state.onPage) {
      const contactToTest = contacts.find((contact) => contact.id === clientFolder?.id);
      if (!isNil(contactToTest)) {
        if (contactMostImportantType(contactToTest) === ContactType.JOINT_OWNERSHIP) {
          contactId = clientFolder?.id;
        } else {
          const currentJointContactFolder = state.folders.find(
            (folder) => folder.documentCategory.id === clientFolder?.documentCategory.parentId
          );
          if (!isNil(currentJointContactFolder) && currentJointContactFolder.entityId !== 'contacts') {
            contactId = currentJointContactFolder.entityId!;
          }
        }
      }
    }

    const contact = contacts.find((stateContact) => stateContact.id === contactId);
    if (isNil(contact) || contactMostImportantType(contact) !== ContactType.JOINT_OWNERSHIP)
      return { foldersToAdd, foldersToDelete };

    const jointFolders = state.folders.filter(
      (stateFolder) =>
        contact.jointOwners?.some((jointOwner) => jointOwner.contactId === stateFolder.entityId!) &&
        stateFolder.id !== clientFolder?.id
    );

    const jointFoldersToAdd = jointFolders.reduce<FolderModel[]>((acc, jointfolder) => {
      const foldersToChange = state.folders.filter(
        (stateFolder) =>
          stateFolder.parentFolderId === jointfolder.id &&
          [FolderEntity.FOLDER, FolderEntity.FILE].includes(stateFolder.folderEntity)
      );

      foldersToDelete.push(...foldersToChange);

      const newFoldersToAdd = foldersToChange.map((file) => {
        const categoryDepthArray = getCategoryDeptArray(file.documentCategory, newCategories).reduce<string>(
          (prevCategories, category) => {
            if (file.documentCategory.id === category) {
              return prevCategories;
            }
            if (isEmpty(prevCategories)) {
              return category;
            }
            return `${prevCategories}.${category}`;
          },
          ''
        );

        const newDepthBase = isNil(clientFolder)
          ? `rootFolder.${jointfolder.documentCategory.id}`
          : `${clientFolder.folderDepth}.${jointfolder.documentCategory.id}`;

        const newDepth = isEmpty(categoryDepthArray) ? newDepthBase : `${newDepthBase}.${categoryDepthArray}`;

        const depthStringArray = getFolderDepthStringArray(newDepth);
        const newDepthString = depthStringArray
          .reduce<string>((prevdepthString, categoryId) => {
            if (isEmpty(prevdepthString) && categoryId === 'rootFolder') {
              return '/';
            }
            let categoryFolder = state.folders.find(
              (stateFolder) =>
                stateFolder.documentCategory.id === categoryId && stateFolder.parentFolderId === jointfolder.id
            );
            if (isNil(categoryFolder)) {
              categoryFolder = state.categoryFolders.find(
                (stateCategoryFolder) => stateCategoryFolder.documentCategory.id === categoryId
              );
            }

            if (!isNil(categoryFolder?.documentCategory))
              return `${prevdepthString}/${getTranslatedCategory(categoryFolder.documentCategory, language)}`;
            return prevdepthString;
          }, '')
          .replace('//', '/');

        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderEntity === FolderEntity.FILE ? `${newDepth}.${file.documentCategory.id}` : newDepth,
          folderDepthString:
            file.folderEntity === FolderEntity.FILE
              ? `${newDepthString}/${getTranslatedCategory(file.documentCategory, language)}`
              : newDepthString,
        };
      });
      acc.push(...newFoldersToAdd);
      return acc;
    }, []);

    if (!state.onPage || isNil(clientFolder)) return { foldersToAdd: jointFoldersToAdd, foldersToDelete };

    const rootFoldersToChange = state.folders.filter(
      (stateFolder) => stateFolder.parentFolderId === 'rootParentFolder'
    );

    foldersToDelete.push(...rootFoldersToChange);

    const rootFoldersToAdd = rootFoldersToChange.map((file) => {
      const categoryDepthArray = getCategoryDeptArray(file.documentCategory, newCategories).reduce<string>(
        (prevCategories, category) => {
          if (file.documentCategory.id === category) {
            return prevCategories;
          }
          if (isEmpty(prevCategories)) {
            return category;
          }
          return `${prevCategories}.${category}`;
        },
        ''
      );

      const newDepth = isEmpty(categoryDepthArray) ? 'rootFolder' : `rootFolder.${categoryDepthArray}`;
      const depthStringArray = getFolderDepthStringArray(newDepth);

      const newDepthString = depthStringArray
        .reduce<string>((prevdepthString, categoryId) => {
          if (isEmpty(prevdepthString) && categoryId === 'rootFolder') {
            return '/';
          }
          const category = state.contactCategories.find((stateFolder) => stateFolder.id === categoryId);

          if (!isNil(category)) return `${prevdepthString}/${getTranslatedCategory(category, language)}`;
          return prevdepthString;
        }, '')
        .replace('//', '/');

      return {
        ...file,
        id: uuidv4(),
        folderDepth: file.folderEntity === FolderEntity.FILE ? `${newDepth}.${file.documentCategory.id}` : newDepth,
        folderDepthString:
          file.folderEntity === FolderEntity.FILE
            ? `${newDepthString}/${getTranslatedCategory(file.documentCategory, language)}`
            : newDepthString,
      };
    }, '');

    foldersToAdd.push(...rootFoldersToAdd);
    foldersToAdd.push(...jointFoldersToAdd);

    return { foldersToAdd, foldersToDelete };
  };

  const dropFolderAction = async (
    folders: FolderModel[],
    entityType: EntityType,
    currentFolder: FolderModel,
    targetFolder?: FolderModel,
    targetFolderVisible?: boolean,
    clientFolder?: FolderModel
  ) => {
    const foldersToAdd: FolderModel[] = [];
    const filesToChange: DocumentModel[] = [];
    const filesToDelete: string[] = [];
    const foldersToDelete: FolderModel[] = [];
    const newCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];
    let recalculateFolders = false;
    for (const folder of folders) {
      if (folder.folderEntity === FolderEntity.FILE) {
        const file = getDocumentsListForEntity(entityType as EntityType).find(
          (doc) => doc.id === folder.documentModel!.id
        );
        if (file) {
          const addedFile = await addFileOnDropAction(targetFolder, file, entityType as EntityType);
          const folderToAdd: FolderModel = {
            ...basicCreateFolderObject,
            id: uuidv4(),
            folderEntity: FolderEntity.FILE,
            size: addedFile.size ?? 0,
            updatedAt: addedFile.updatedAt ?? '',
            translatedLabel: decodeURI(addedFile.fileName),
            documentModel: addedFile,
            documentCategory: addedFile.category!,
            folderDepth: `${targetFolder?.folderDepth}.${targetFolder?.documentCategory?.id}`,
            folderDepthString: `${targetFolder?.folderDepthString}/${targetFolder?.translatedLabel}`.replace('//', '/'),
            parentFolderId: clientFolder?.parentFolderId ?? 'rootParentFolder',
          } as FolderModel;

          filesToDelete.push(file.id);
          filesToChange.push(addedFile);
          foldersToAdd.push(folderToAdd);
          foldersToDelete.push(folder);
        }
        continue;
      }
      recalculateFolders = true;
      const result = await whenDroppedAFolder(folder, clientFolder!, targetFolder!, entityType, currentFolder);
      if (isNil(result)) continue;
      foldersToAdd.push(...result.foldersToAdd);
      foldersToDelete.push(...result.foldersToDelete);
      newCategories.push(...result.newCategories);
    }

    if (state.onPage && recalculateFolders) {
      localUpdateCategories(newCategories, entityType, clientFolder!);
    }

    if (filesToChange.length > 0) {
      const files = getDocumentsListForEntity(entityType as EntityType);
      const newFiles = files.filter((file) => !filesToDelete.includes(file.id));

      dispatch({
        type: 'UPDATE_FILES',
        payload: { files: [...newFiles, ...filesToChange], type: entityType as EntityType },
      });
    }

    if (foldersToAdd.find((folder) => folder.folderEntity === FolderEntity.FILE && !targetFolderVisible)) {
      foldersToAdd.push(targetFolder!);
    }

    if (entityType === EntityType.CONTACT && recalculateFolders) {
      const contactUpdates = droppedFolderLinkedToContact(clientFolder!, newCategories);
      foldersToAdd.push(...contactUpdates.foldersToAdd);
      foldersToDelete.push(...contactUpdates.foldersToDelete);
    }

    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd, foldersToDelete } });

    const filteredFolders = state.folders.filter(
      (folder) => !foldersToDelete.map((folderToDelete) => folderToDelete.id).includes(folder.id)
    );
    filteredFolders.push(...foldersToAdd);

    setFilteredFolders(
      filteredFolders,
      last(state.folderNavigation)?.documentCategory,
      last(state.folderNavigation),
      isEmpty(newCategories) ? getCategoriesForEntity(entityType as EntityType) : newCategories
    );
  };

  const droppedOnRootFolder = (
    foldersToWorkWith: FolderModel[],
    categories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType,
    targetFolderCategoryId: string,
    parentFolder: FolderModel | undefined
  ) => {
    let targetFolder;
    let targetFolderVisible;
    if (foldersToWorkWith.some((folderToWorkWith) => folderToWorkWith.folderEntity === FolderEntity.FILE)) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.CANT_MOVE_FILE_TO_ENTITY_FOLDER;
    }

    const targetCategory = categories.find((category) => category.id === targetFolderCategoryId);
    if (!isNil(targetCategory)) {
      const clientCategories = getCategoriesForEntity(entityType);

      const categoryDepthArray = getCategoryDeptArray(targetCategory, clientCategories).filter(
        (category) => category !== targetCategory.id
      );

      let folderDepth = 'rootFolder';
      if (!isNil(parentFolder)) {
        folderDepth = `${parentFolder?.folderDepth}.${parentFolder?.documentCategory?.id}`;
      }
      if (!isEmpty(categoryDepthArray)) {
        folderDepth = `${folderDepth}.${categoryDepthArray.join('.')}`;
      }

      const folderDepthArary = getFolderDepthStringArray(folderDepth);

      let folderDepthString = folderDepthArary.reduce<string>((acc, folderId) => {
        if (folderId === 'rootFolder') return acc;
        const category = categories.find((categoryToCheck) => categoryToCheck.id === folderId);
        if (category) {
          acc = `${acc}/${getTranslatedCategory(category, language)}`;
          return acc;
        }
        const folder = state.folders.find((folderToCheck) => folderToCheck.documentCategory?.id === folderId);
        if (folder) {
          acc = `${acc}/${folder.translatedLabel}`;
        }
        return acc;
      }, '');

      if (folderDepthString === '') {
        folderDepthString = '/';
      }

      folderDepthString = folderDepthString.replace('//', '/');

      const newTargetCategory =
        targetCategory.parentId && state.onPage
          ? targetCategory
          : { ...targetCategory, parentId: parentFolder?.documentCategory.id };

      const folderToAdd: FolderModel = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        translatedLabel: getTranslatedCategory(targetCategory, language),
        documentCategory: newTargetCategory,
        folderDepth,
        folderDepthString,
        parentFolderId: parentFolder?.id ?? 'rootParentFolder',
      } as FolderModel;

      targetFolderVisible = false;
      targetFolder = folderToAdd;
    }
    if (parentFolder?.documentCategory.id === targetFolderCategoryId) {
      targetFolder = parentFolder;
    }
    return { targetFolder, targetFolderVisible };
  };

  const dropFolder = async (targetFolderCategoryId: string, currentFolderId: string): Promise<FolderErrors> => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    let currentFolder = state.folders.find((folder) => folder.id === currentFolderId);
    if (isNil(currentFolder)) {
      currentFolder = state.categoryFolders.find((folder) => folder.id === currentFolderId);
    }

    if (currentFolder?.documentCategory.id === targetFolderCategoryId) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.FALSE_ERROR;
    }

    if (isNil(currentFolder)) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.FOLDER_NOT_FOUND;
    }

    const foldersToWorkWith: FolderModel[] = [];
    //When moving a folder that is not selected we need only to move the folder and not the selected folders
    let resetSelected = false;
    if (state.selectedFolders?.includes(currentFolderId)) {
      foldersToWorkWith.push(...state.folders.filter((folder) => state.selectedFolders.includes(folder.id)));
      foldersToWorkWith.push(...state.categoryFolders.filter((file) => state.selectedFolders.includes(file.id)));
      resetSelected = true;
    } else {
      foldersToWorkWith.push(currentFolder);
    }
    if (foldersToWorkWith.find((folder) => folder.documentCategory?.fileCategory !== FileCategory.CUSTOM)) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.CUSTOM_FOLDER_ERROR;
    }
    const lastInFolderNavigation = last(state.folderNavigation);
    const parentFolder = state.folders.find((folder) => folder.id === lastInFolderNavigation?.parentFolderId);

    const entityType = state.onPage ? (parentFolder?.documentCategory.entity as EntityType) : state.entityType;

    const categories = getCategoriesForEntity(entityType as EntityType);

    let targetFolder = state.folders.find(
      (folder) =>
        folder.folderEntity === FolderEntity.FOLDER &&
        folder.documentCategory.id === targetFolderCategoryId &&
        (!isNil(parentFolder)
          ? folder.parentFolderId === parentFolder?.id
          : folder.parentFolderId === 'rootParentFolder')
    );

    let targetFolderVisible = true;

    //If the target folder is nil, it means that it is dropped on the root folder
    if (isNil(targetFolder)) {
      const targetFolderObject = droppedOnRootFolder(
        foldersToWorkWith,
        categories,
        entityType as EntityType,
        targetFolderCategoryId,
        parentFolder
      );
      if (targetFolderObject === FolderErrors.CANT_MOVE_FILE_TO_ENTITY_FOLDER) {
        return FolderErrors.CANT_MOVE_FILE_TO_ENTITY_FOLDER;
      }

      targetFolder = targetFolderObject.targetFolder;
      targetFolderVisible = targetFolderObject.targetFolderVisible ?? true;
    }
    await dropFolderAction(
      foldersToWorkWith,
      entityType as EntityType,
      currentFolder,
      targetFolder,
      targetFolderVisible,
      parentFolder
    );

    if (resetSelected) dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });

    return FolderErrors.NONE;
  };

  const setFolderList = (
    documentCategoryId?: string,
    depthId?: string,
    initialDocs?: DocumentModel[],
    depthString?: string,
    fetchedFileCategories?: DocumentCategoryWithoutReadIdAndClientId[],
    parentFolderId?: string
  ) => {
    const fileCategoriesToWorkWith = fetchedFileCategories ?? fileCategories;
    const currentCategory = fileCategoriesToWorkWith?.find((category) => category.id === documentCategoryId);
    const docsToWorkOn = initialDocs ?? getDocumentsListForEntity(state.entityType!);
    const currentDocs = docsToWorkOn.filter((doc) => {
      return doc.category?.id === documentCategoryId;
    });

    const currentLevelCategories = fileCategoriesToWorkWith.filter(
      (category) => category.parentId === (isNil(documentCategoryId) ? null : documentCategoryId)
    );

    const foldersToAdd: FolderModel[] = [];
    currentDocs.forEach((doc) => {
      doc.category = {
        ...doc.category!,
        parentId: currentCategory?.parentId,
      };
      const createdFolder: FolderModel = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FILE,
        size: doc.size ?? 0,
        updatedAt: doc.updatedAt ?? '',
        translatedLabel: doc.fileName,
        documentModel: doc,
        documentCategory: currentCategory!,
        folderDepth: depthId ?? 'rootFolder',
        folderDepthString: depthString ?? '/',
        parentFolderId: parentFolderId ?? 'rootParentFolder',
      };

      foldersToAdd.push(createdFolder);
    });

    currentLevelCategories.forEach((category) => {
      if (!checkChildFolders(docsToWorkOn, fileCategoriesToWorkWith, category.id)) return;
      foldersToAdd.push({
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        translatedLabel: getTranslatedCategory(category, language),
        documentCategory: category,
        folderDepth: depthId ?? 'rootFolder',
        folderDepthString: depthString ?? '/',
        parentFolderId: parentFolderId ?? 'rootParentFolder',
      } as FolderModel);

      setFolderList(
        category!.id,
        depthId + '.' + category.id,
        initialDocs,
        (depthString ?? '') + '/' + getTranslatedCategory(category, language),
        fileCategoriesToWorkWith,
        parentFolderId
      );
    });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd, foldersToDelete: [] } });
    if (depthId === 'rootFolder') {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.FOLDER_ADDING], value: false },
      });
    }
  };

  const createCategoryWhenLabelsAreFilled = async (
    labels: CustomLabel[],
    entityType: EntityType,
    selectedCategoryId: string | undefined
  ): Promise<DocumentCategoryWithoutReadIdAndClientId> => {
    const newDocumentCategory = await createFileCategory(labels, entityType as EntityType, selectedCategoryId);
    dispatch({
      type: 'SET_CATEGORIES',
      payload: {
        buildingsCategory:
          entityType === EntityType.BUILDING
            ? [...state.buildingCategories, newDocumentCategory]
            : state.buildingCategories,
        unitsCategory:
          entityType === EntityType.UNIT ? [...state.unitCategories, newDocumentCategory] : state.unitCategories,
        leasesCategory:
          entityType === EntityType.LEASE ? [...state.leaseCategories, newDocumentCategory] : state.leaseCategories,
        contactsCategory:
          entityType === EntityType.CONTACT
            ? [...state.contactCategories, newDocumentCategory]
            : state.contactCategories,
      },
    });
    return newDocumentCategory;
  };

  const createFilesForJointContacts = (folderClient: FolderModel, fileFolders: FolderModel[]): FolderModel[] => {
    const foldersToAdd: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === folderClient?.entityId && folder.id !== folderClient?.id
    );

    jointFolders.forEach((jointfolder) => {
      const depthString = `${jointfolder.folderDepthString}/${jointfolder.translatedLabel}`;
      const depth = `${jointfolder.folderDepth}.${jointfolder.documentCategory.id}`;

      const newFoldersToAdd = fileFolders.map((file) => {
        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderDepth?.replace(
            `${folderClient?.folderDepth}.${folderClient.documentCategory.id}`,
            depth
          ),
          folderDepthString: file.folderDepthString?.replace(
            `${folderClient?.folderDepthString}/${folderClient.translatedLabel}`,
            depthString
          ),
          parentFolderId: jointfolder.id,
          documentCategory: {
            ...file.documentCategory,
            parentId:
              file.documentCategory.parentId === folderClient?.documentCategory.id
                ? jointfolder.documentCategory.id
                : file.documentCategory.parentId,
          },
        };
      });
      foldersToAdd.push(...newFoldersToAdd);
    });
    return foldersToAdd;
  };

  const createFiles = async (
    files: File[],
    entityType: EntityType,
    entityId: string,
    categoryId: string,
    folderClient: FolderModel,
    fileFolderDepth: string,
    fileFolderDepthString: string,
    foldersToAdd: FolderModel[]
  ) => {
    const addFilesPromises: Promise<FileModel | null>[] = [];
    let contact = state.contact;
    const lastFolderInNavigation = last(state.folderNavigation);

    const lastInNavigationParentFolder = state.folders.find(
      (stateFolder) => stateFolder.id === lastFolderInNavigation?.parentFolderId
    );
    if (!isNil(lastInNavigationParentFolder)) {
      contact = contacts.find((stateContact) => stateContact.id === lastInNavigationParentFolder?.entityId);
    }
    files.forEach((file) =>
      addFilesPromises.push(
        createFile(
          file,
          entityType,
          entityId,
          categoryId,
          undefined,
          !isNil(contact) ? contactMostImportantType(contact) : undefined,
          contact?.id
        )
      )
    );
    const addedFiles = await Promise.all(addFilesPromises);
    const toAddFiles = addedFiles.flatMap((file) => file ?? []);

    const s3Files = await getS3ObjectUrls(toAddFiles);

    const fileFolders: FolderModel[] = s3Files.map((file) => {
      if (state.onPage && isNil(file.category?.parentId)) {
        file.category = { ...file.category!, parentId: folderClient?.documentCategory.id };
      }
      const fileName = decodeURI(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, language);
      return {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FILE,
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: fileFolderDepth,
        folderDepthString: fileFolderDepthString,
        parentFolderId: lastInNavigationParentFolder?.parentFolderId ?? 'rootParentFolder',
      } as FolderModel;
    });

    if (folderClient?.folderEntity === FolderEntity.CONTACT) {
      const contactFolders = createFilesForJointContacts(folderClient, fileFolders);
      fileFolders.push(...contactFolders);
    }

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(entityType as EntityType), ...s3Files],
        type: entityType as EntityType,
      },
    });

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd: fileFolders,
        foldersToDelete: [],
      },
    });
    setFilteredFolders(
      [...state.folders, ...foldersToAdd, ...fileFolders],
      lastFolderInNavigation?.documentCategory,
      lastFolderInNavigation
    );
  };

  const addNewFolderForJointContacts = (parentFolder: FolderModel, folderToAdd: FolderModel): FolderModel[] => {
    const foldersToAdd: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === parentFolder?.entityId && folder.id !== parentFolder?.id
    );

    jointFolders.forEach((jointfolder) => {
      const depthString = `${jointfolder.folderDepthString}/${jointfolder.translatedLabel}`;
      const depth = `${jointfolder.folderDepth}.${jointfolder.documentCategory.id}`;

      foldersToAdd.push({
        ...folderToAdd,
        id: uuidv4(),
        folderDepth: folderToAdd.folderDepth?.replace(
          `${parentFolder?.folderDepth}.${parentFolder.documentCategory.id}`,
          depth
        ),
        folderDepthString: folderToAdd.folderDepthString?.replace(
          `${parentFolder?.folderDepthString}/${parentFolder.translatedLabel}`,
          depthString
        ),
        parentFolderId: jointfolder.id,
        documentCategory: {
          ...folderToAdd.documentCategory,
          parentId:
            folderToAdd.documentCategory.parentId === parentFolder?.documentCategory.id
              ? jointfolder.documentCategory.id
              : folderToAdd.documentCategory.parentId,
        },
      });
    });
    return foldersToAdd;
  };

  const addNewFolderWithoutTargetFolder = (
    parentFolder: FolderModel,
    selectedCategory: DocumentCategoryWithoutReadIdAndClientId,
    currentEntityFolders: FolderModel[],
    entityType: EntityType,
    newDocumentCategory: DocumentCategoryWithoutReadIdAndClientId,
    labels: CustomLabel[],
    files: boolean
  ) => {
    const lastFolderInNavigation = last(state.folderNavigation);
    const foldersToAdd: FolderModel[] = [];

    let createFolderDepth: string = parentFolder ? getFolderDepth(parentFolder) : 'rootFolder';
    let createFolderDepthString: string = parentFolder ? getFolderDepthString(language, parentFolder) : '/';
    if (!isNil(selectedCategory)) {
      let categoriesToWorkWith: string[] = [];
      if (!isNil(selectedCategory.parentId)) {
        const clientCategories = state.onPage
          ? getCategoriesForEntity(
              isNil(parentFolder)
                ? (lastFolderInNavigation?.documentCategory.entity as EntityType)
                : (parentFolder.documentCategory.entity! as EntityType)
            )
          : getCategoriesForEntity(state.entityType!);
        categoriesToWorkWith = [...getCategoryDeptArray(selectedCategory, clientCategories)];
      }

      categoriesToWorkWith?.forEach((categoryId) => {
        const currentCategoryFolder = currentEntityFolders.find(
          (folder) => folder.documentCategory.id === categoryId && folder.folderEntity === FolderEntity.FOLDER
        );
        const currentCategory = getCategoriesForEntity(entityType).find(
          (stateCategory) => stateCategory.id === categoryId
        );

        if (isNil(currentCategoryFolder) && !isNil(currentCategory)) {
          const folderToAdd = {
            ...basicCreateFolderObject,
            id: uuidv4(),
            folderEntity: FolderEntity.FOLDER,
            translatedLabel: getTranslatedCategory(currentCategory!, language),
            documentCategory: currentCategory!,
            folderDepth: createFolderDepth,
            folderDepthString: createFolderDepthString,
            parentFolderId: parentFolder?.id ?? 'rootParentFolder',
          } as FolderModel;
          foldersToAdd.push(folderToAdd);
        }
        if (!isNil(selectedCategory.parentId)) {
          createFolderDepth = getFolderDepth(undefined, createFolderDepth, categoryId);
          createFolderDepthString = getFolderDepthString(
            language,
            undefined,
            createFolderDepthString,
            currentCategory!
          );
        }
      });
    }

    const categoryToWorkWith = !isNilOrEmpty(labels?.[0].label) ? newDocumentCategory! : selectedCategory;

    const category = {
      ...categoryToWorkWith,
      parentId:
        state.onPage && isNil(categoryToWorkWith?.parentId)
          ? parentFolder?.documentCategory?.id
          : categoryToWorkWith?.parentId,
    } as DocumentCategoryWithoutReadIdAndClientId;

    if (!isNil(newDocumentCategory) && !isNil(selectedCategory)) {
      createFolderDepth = getFolderDepth(parentFolder, createFolderDepth, selectedCategory.id);
      createFolderDepthString = getFolderDepthString(language, parentFolder, createFolderDepthString, selectedCategory);
    }
    const folderToAdd: FolderModel = {
      ...basicCreateFolderObject,
      id: uuidv4(),
      folderEntity: FolderEntity.FOLDER,
      translatedLabel: getTranslatedCategory(category, language),
      documentCategory: category as DocumentCategory,
      folderDepth: createFolderDepth,
      folderDepthString: createFolderDepthString,
      parentFolderId: parentFolder?.id ?? 'rootParentFolder',
    } as FolderModel;

    if (!files) return { foldersToAdd, folderDepth: '', folderDepthString: '' };

    foldersToAdd.push(folderToAdd);
    const folderDepth = getFolderDepth(folderToAdd);
    const folderDepthString = getFolderDepthString(language, folderToAdd);
    if (parentFolder?.folderEntity === FolderEntity.CONTACT) {
      const jointFolders = addNewFolderForJointContacts(parentFolder, folderToAdd);
      foldersToAdd.push(...jointFolders);
    }
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd, foldersToDelete: [] } });
    return { foldersToAdd, folderDepth, folderDepthString };
  };

  const addNewFolder = async (
    labels?: CustomLabel[],
    selectedCategory?: DocumentCategoryWithoutReadIdAndClientId,
    files?: File[]
  ) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    let currentEntityFolders: FolderModel[] = [];
    let parentFolder: FolderModel | undefined;
    const lastFolderInNavigation = last(state.folderNavigation);

    let entityType = state.entityType;
    let entityId = state.id;

    const lastInNavigationParentFolder = state.folders.find(
      (stateFolder) => stateFolder.id === lastFolderInNavigation?.parentFolderId
    );

    if (!isNil(lastInNavigationParentFolder)) {
      parentFolder = lastInNavigationParentFolder;
      entityType = lastInNavigationParentFolder.documentCategory.entity as EntityType;
      entityId = lastInNavigationParentFolder.entityId;
    }

    state.onPage
      ? currentEntityFolders.push(...state.folders.filter((folder) => folder.parentFolderId === parentFolder?.id))
      : currentEntityFolders.push(...state.folders);

    if (parentFolder?.folderEntity === FolderEntity.CONTACT && state.onPage) {
      const contact = contacts.find((stateContact) => stateContact.id === parentFolder?.entityId);
      const type = contactMostImportantType(contact!);
      if (type === ContactType.JOINT_OWNERSHIP) {
        contact?.jointOwners?.forEach((jointOwner) => {
          const jointContact = contacts.find((stateContact) => stateContact.id === jointOwner.contactId);
          currentEntityFolders = currentEntityFolders.filter(
            (entityFolder) => !entityFolder.folderDepthString.includes(getContactNameFromObject(jointContact))
          );
        });
      }
    }
    let targetFolder = currentEntityFolders.find(
      (folder) =>
        folder.documentCategory.id === selectedCategory?.id &&
        folder.folderEntity === FolderEntity.FOLDER &&
        folder.parentFolderId === (parentFolder?.id ?? 'rootParentFolder')
    );

    const fileFolderDepth: { folderDepth: string; folderDepthString: string } = {
      folderDepth: '',
      folderDepthString: '',
    };

    let newDocumentCategory: DocumentCategoryWithoutReadIdAndClientId | undefined;
    if (!isNilOrEmpty(labels?.[0].label)) {
      newDocumentCategory = await createCategoryWhenLabelsAreFilled(
        labels!,
        entityType as EntityType,
        selectedCategory?.id
      );

      targetFolder = undefined;
      if (!files) {
        seeAllFolders(newDocumentCategory);
        dispatch({
          type: 'SET_FOLDER_ATTRIBUTES',
          payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
        });
        if (!state.seeAllFolders) {
          dispatch({
            type: 'SET_FOLDER_ATTRIBUTES',
            payload: { key: [FolderBooleanAttributes.SEE_ALL_FOLDERS], value: true },
          });
          return;
        }
      }
    }

    const foldersToAdd: FolderModel[] = [];
    if (isNil(targetFolder)) {
      const addFolder = addNewFolderWithoutTargetFolder(
        parentFolder!,
        selectedCategory!,
        currentEntityFolders,
        entityType as EntityType,
        newDocumentCategory!,
        labels!,
        !!files
      );
      foldersToAdd.push(...addFolder.foldersToAdd);
      fileFolderDepth.folderDepth = addFolder.folderDepth;
      fileFolderDepth.folderDepthString = addFolder.folderDepthString;
    }

    if (files) {
      createFiles(
        files,
        entityType as EntityType,
        entityId ?? '',
        newDocumentCategory ? newDocumentCategory.id : selectedCategory!.id,
        parentFolder!,
        targetFolder ? getFolderDepth(targetFolder) : fileFolderDepth.folderDepth,
        targetFolder ? getFolderDepthString(language, targetFolder) : fileFolderDepth.folderDepthString,
        foldersToAdd
      );
    }
  };

  const deleteFolderForJointContacts = (parentFolder: FolderModel, foldersToDelete: FolderModel[]): FolderModel[] => {
    const jointFoldersToDelete = state.folders.reduce<FolderModel[]>((acc, jointfolder) => {
      if (jointfolder.entityId !== parentFolder?.entityId || jointfolder.id === parentFolder?.id) {
        return acc;
      }
      const newFoldersToDelete = compact(
        foldersToDelete.map((folder) => {
          return state.folders.find(
            (stateFolder) =>
              stateFolder.parentFolderId === jointfolder.id &&
              stateFolder.documentCategory.id === folder.documentCategory.id &&
              (folder.folderEntity === FolderEntity.FILE
                ? stateFolder.documentModel?.id === folder.documentModel?.id
                : true)
          );
        })
      );

      acc.push(...compact(newFoldersToDelete));
      return acc;
    }, []);
    return jointFoldersToDelete;
  };

  const deleteFolders = async (folderIdsToDelete: string[]): Promise<FolderErrors> => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    const folderCategoriesToDelete: FolderModel[] = [];
    const filesToDelete: string[] = [];

    const foldersToDelete = folderIdsToDelete.reduce<FolderModel[]>((acc, folderId) => {
      const folder = state.folders.find((stateFolder) => stateFolder.id === folderId);
      if (isNil(folder)) {
        const folderCategory = state.categoryFolders.find((stateFolder) => stateFolder.id === folderId);
        if (folderCategory) {
          folderCategoriesToDelete.push(folderCategory);
        }
        return acc;
      }
      if (folder?.folderEntity === FolderEntity.FILE) {
        filesToDelete.push(folder.documentModel!.id);
        acc.push(folder);
        return acc;
      }
      acc.push(folder);
      return acc;
    }, []);

    if (
      foldersToDelete.find(
        (folderToDelete) =>
          (folderToDelete?.documentCategory.fileCategory !== FileCategory.CUSTOM &&
            folderToDelete.folderEntity === FolderEntity.FOLDER) ||
          ![FolderEntity.FILE, FolderEntity.FOLDER].includes(folderToDelete.folderEntity)
      )
    ) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.CANT_DELETE_FIXES_FOLDERS;
    }

    const parentFolder = getParentFolder(state.folders, last(state.folderNavigation));
    const entity = state.onPage ? (parentFolder?.documentCategory.entity as EntityType) : state.entityType;

    const documents = getDocumentsListForEntity(entity as EntityType);
    const categoriesToDelete = foldersToDelete.filter((folder) => folder.folderEntity === FolderEntity.FOLDER);
    const currentFolderFiles = compact(
      foldersToDelete.filter((folder) => folder.folderEntity === FolderEntity.FILE).map((file) => file.documentModel)
    );

    const currentContextFiles = state.folders
      .filter(
        (stateFolder) =>
          stateFolder.parentFolderId === first(foldersToDelete)?.parentFolderId &&
          stateFolder.folderEntity === FolderEntity.FILE
      )
      .map((file) => file.documentModel?.id);

    const error = checkIfFolderHasFilesOnOtherEntities(
      [...categoriesToDelete, ...folderCategoriesToDelete],
      currentFolderFiles,
      getDocumentsListForEntity(entity as EntityType).filter((document) => !currentContextFiles.includes(document.id))
    );

    if (error) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.FOLDER_HAS_FILES_ON_OTHER_ENTITIES;
    }

    if (!isEmpty(folderCategoriesToDelete)) {
      for (const folderCategory of folderCategoriesToDelete) {
        await deleteFileCategory(folderCategory.documentCategory);
        await deleteChildCategories(folderCategory.documentCategory, entity as EntityType);
      }
    }

    const categoryIdsToDelete = [...folderCategoriesToDelete.map((folder) => folder.documentCategory.id)];

    const allFoldersToDelete = foldersToDelete.reduce<FolderModel[]>((acc, folder) => {
      if (folder.folderEntity !== FolderEntity.FOLDER) {
        acc.push(folder);
        return acc;
      }

      const allFilesToDelete = state.folders.filter((stateFolder) =>
        stateFolder.folderDepth?.includes(folder.documentCategory.id)
      );
      const uniqueFiles = allFilesToDelete.reduce<FolderModel[]>((unique, file) => {
        if (unique.some((uniqueFile) => uniqueFile.documentModel?.id === file.documentModel?.id)) {
          return unique;
        }
        unique.push(file);
        return unique;
      }, []);

      acc.push(...[folder, ...uniqueFiles]);
      return acc;
    }, []);

    const promises = allFoldersToDelete.map(async (folder) => {
      if (folder.folderEntity === FolderEntity.FILE) {
        return deleteDocument(folder);
      }
      if (folder.folderEntity === FolderEntity.FOLDER) {
        categoryIdsToDelete.push(folder.documentCategory.id);
        return deleteFileCategory(folder.documentCategory);
      }
    });

    await Promise.all(promises);

    const newCategories = getCategoriesForEntity(entity as EntityType).filter(
      (category) => !categoryIdsToDelete.includes(category.id)
    );

    const newCategoryFolders = state.categoryFolders.filter(
      (folder) => !categoryIdsToDelete.includes(folder.documentCategory.id)
    );

    const folderToDeleteId = foldersToDelete.map((folderToDelete) => folderToDelete.id);
    const newFilteredFolders = state.filteredFolders.reduce<FolderModel[]>((acc, folder) => {
      const folderNeedToBeDeleted = folderToDeleteId.includes(folder.id);
      if (!folderNeedToBeDeleted) {
        if (folder.folderEntity === FolderEntity.FOLDER) {
          newCategoryFolders.push(folder);
        }
        acc.push(folder);
      }
      return acc;
    }, []);

    const newDocuments = documents.filter((document) => !filesToDelete.includes(document.id));

    if (parentFolder?.folderEntity === FolderEntity.CONTACT) {
      newFilteredFolders.push(...deleteFolderForJointContacts(parentFolder, foldersToDelete));
    }

    dispatch({ type: 'UPDATE_CATEGORY', payload: { category: newCategories, type: entity as EntityType } });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    dispatch({ type: 'SET_CATEGORY_FOLDERS', payload: { folders: newCategoryFolders } });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: newFilteredFolders });
    dispatch({ type: 'UPDATE_FILES', payload: { files: newDocuments, type: entity as EntityType } });
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });

    return FolderErrors.NONE;
  };

  const selectSearchFolder = (folder: FolderModel) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    setFilteredFolders(
      state.folders,
      folder.documentCategory,
      folder.folderEntity === FolderEntity.FILE ? last(state.folderNavigation) : folder
    );

    const depth = getFolderDepthStringArray(folder.folderDepth);

    const depthFolders = depth?.map((id, index) =>
      state.folders.find((stateFolder) => {
        let folderInDepth;
        if (index >= 0) {
          folderInDepth = stateFolder.folderDepth?.includes(depth[index - 1]);
        }
        return stateFolder.documentCategory?.id === id && folderInDepth;
      })
    );

    if (folder.folderEntity !== FolderEntity.FILE) {
      depthFolders?.push(folder);
    }

    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: depthFolders ?? [undefined] });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.SEARCH_FOLDERS], value: false },
    });
  };

  const setIsBusy = (contextIsBusy: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: contextIsBusy },
    });
  };

  const setCategoryFolders = (
    categoriesToWorkWith: DocumentCategoryWithoutReadIdAndClientId[],
    folders: FolderModel[],
    parentFolder?: FolderModel,
    entityId?: string
  ) => {
    const foldersToAdd: FolderModel[] = [];
    const currentLevelCategories = filterCurrentLevelCategories(categoriesToWorkWith, parentFolder);
    currentLevelCategories.forEach((category) => {
      const currentCategoryFolder = folders.find(
        (stateFolder) =>
          stateFolder.documentCategory?.id === category.id &&
          stateFolder.parentFolderId === (isNil(parentFolder) ? 'rootParentFolder' : parentFolder.parentFolderId) &&
          (isNil(stateFolder.documentModel) ? true : stateFolder.documentModel.foreignKey === entityId) &&
          stateFolder.folderEntity === FolderEntity.FOLDER
      );
      if (!isNil(currentCategoryFolder)) return;

      const folderToAdd: FolderModel = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        translatedLabel: getTranslatedCategory(category, language),
        documentCategory: category,
        folderDepth: parentFolder ? `${parentFolder.folderDepth}.${parentFolder.documentCategory?.id}` : 'rootFolder',
        folderDepthString: parentFolder
          ? `${parentFolder.folderDepthString === '/' ? '' : parentFolder.folderDepthString}/${
              parentFolder.translatedLabel
            }`
          : '/',
        parentFolderId: parentFolder?.id ?? 'rootParentFolder',
      } as FolderModel;

      foldersToAdd.push(folderToAdd);
    });

    dispatch({
      type: 'SET_CATEGORY_FOLDERS',
      payload: {
        folders: foldersToAdd,
      },
    });
  };

  const changeCategoryFolders = (
    folders: FolderModel[],
    parentFolder?: FolderModel,
    documentFolder?: FolderModel,
    categoriesToAdd?: DocumentCategoryWithoutReadIdAndClientId,
    categoriesToWorkWith?: DocumentCategoryWithoutReadIdAndClientId[]
  ) => {
    if (state.onPage) {
      if (isNil(documentFolder) && isNil(parentFolder)) {
        dispatch({
          type: 'SET_CATEGORY_FOLDERS',
          payload: {
            folders: [],
          },
        });
        return;
      }
      if (isNil(documentFolder)) return;

      const parentClientFolder = state.folders.find((folder) => folder.id === documentFolder?.parentFolderId);
      const documentsCategories =
        categoriesToWorkWith ??
        getCategoriesForEntity(
          isNil(parentClientFolder)
            ? (documentFolder.documentCategory.entity as EntityType)
            : (parentClientFolder.documentCategory.entity! as EntityType)
        );
      let categoryFolders = documentsCategories;
      if (!isNil(categoriesToAdd)) {
        categoryFolders = [...documentsCategories, categoriesToAdd];
      }
      setCategoryFolders(categoryFolders, folders, parentFolder);
      return;
    }
    let categoryFolders = categoriesToWorkWith ?? getCategoriesForEntity(state.entityType!);
    if (!isNil(categoriesToAdd)) {
      categoryFolders = [...categoryFolders, categoriesToAdd];
    }
    setCategoryFolders(categoryFolders, folders, parentFolder);
  };

  const changeVisibilityAllFolders = () => {
    if (state.seeAllFolders || (state.onPage && state.folderNavigation.length === 1)) {
      dispatch({
        type: 'SET_CATEGORY_FOLDERS',
        payload: {
          folders: [],
        },
      });
    }

    if (!state.seeAllFolders) {
      seeAllFolders();
    }
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEE_ALL_FOLDERS], value: !state.seeAllFolders },
    });
  };

  const seeAllFolders = (categories?: DocumentCategoryWithoutReadIdAndClientId) => {
    const lastInFolderNavigation = last(state.folderNavigation);
    if (!state.onPage) {
      const currentFolder = lastInFolderNavigation;
      changeCategoryFolders(state.folders, currentFolder, undefined, categories);
    }
    if (state.onPage && state.folderNavigation.length > 1) {
      const parentFolder = state.folders.find((folder) => folder.id === lastInFolderNavigation?.parentFolderId);
      const documentsCategories = getCategoriesForEntity(parentFolder?.documentCategory.entity as EntityType);
      let categoriesForFolders = documentsCategories;
      if (!isNil(categories)) {
        categoriesForFolders = [...categoriesForFolders, categories];
      }
      setCategoryFolders(categoriesForFolders, state.folders, last(state.folderNavigation), parentFolder?.entityId);
    }
  };

  const renameFolder = async (folder: FolderModel, newLabels: CustomLabel[]) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });
    if (isNil(folder.documentCategory)) return;
    const updatedDocumentCategory = await updateFileCategory(folder.documentCategory, {
      labels: newLabels,
    });

    const folderIdsToChange: string[] = [];

    folderIdsToChange.push(folder.id);

    const updatedFolders = state.folders.map((stateFolder) => {
      if (
        stateFolder.documentCategory?.id === folder.documentCategory.id &&
        stateFolder.folderEntity === FolderEntity.FOLDER
      ) {
        return {
          ...stateFolder,
          documentCategory: updatedDocumentCategory,
          translatedLabel: getTranslatedCategory(updatedDocumentCategory, language),
        };
      }
      return stateFolder;
    });

    dispatch({
      type: 'INITIALIZE_FOLDERS',
      payload: updatedFolders,
    });

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(updatedFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);
  };

  const deleteChildCategories = async (category: DocumentCategoryWithoutReadIdAndClientId, entity: EntityType) => {
    const entityCategories = getCategoriesForEntity(category.entity as EntityType);
    const childCategories = entityCategories.filter((entityCategory) => entityCategory.parentId === category.id);

    if (isNilOrEmpty(childCategories)) return;

    for (const childCategory of childCategories) {
      await deleteFileCategory(childCategory);
      await deleteChildCategories(childCategory, entity);
    }
  };

  const getDocumentsListForEntity = (entityType: EntityType): DocumentModel[] => {
    if (entityType === EntityType.BUILDING) {
      return [...state.buildingClientFiles];
    }
    if (entityType === EntityType.UNIT) {
      return [...state.unitClientFiles];
    }
    if (entityType === EntityType.LEASE) {
      return [...state.leaseClientFiles];
    }
    if (entityType === EntityType.CONTACT) {
      return [...state.contactClientFiles];
    }
    return [];
  };

  const getCategoriesForEntity = (entityType: EntityType): DocumentCategoryWithoutReadIdAndClientId[] => {
    if (entityType === EntityType.BUILDING) {
      return [...state.buildingCategories];
    }
    if (entityType === EntityType.UNIT) {
      return [...state.unitCategories];
    }
    if (entityType === EntityType.LEASE) {
      return [...state.leaseCategories];
    }
    if (entityType === EntityType.CONTACT) {
      return [...state.contactCategories];
    }
    return [];
  };

  const cleanup = () => {
    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: [] });
  };

  const getFolderSizes = (folder: FolderModel): number => {
    const folders = state.folders.filter(
      (stateFolder) =>
        stateFolder.folderDepth?.includes(folder.folderDepth) &&
        stateFolder.folderEntity === FolderEntity.FILE &&
        stateFolder.documentCategory?.entity === folder.documentCategory?.entity
    );
    const size = folders.reduce((acc, stateFolder) => acc + stateFolder.size, 0);
    return size;
  };

  const setSearchFolders = (folders: FolderModel[]) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEARCH_FOLDERS], value: true },
    });
    const searchFolders = folders.map((folder) => {
      if (folder.folderEntity === FolderEntity.FOLDER) {
        return {
          ...folder,
          size: getFolderSizes(folder),
        };
      }
      return folder;
    });

    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: searchFolders });
  };

  const cancelSearch = () => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEARCH_FOLDERS], value: false },
    });
    dispatch({ type: 'SET_EXTENDED_SEARCH_PROPERTIES', payload: { extendedSearch: {} } });
    setNavigation(true, last(state.folderNavigation));
  };

  const getFilteredFolders = (folders: FolderModel[], filters: ExtendedSearchModel) => {
    return folders.filter((folder) => {
      const filterByFolderCategory = !isNil(filters.folderCategory)
        ? folder.documentCategory.id === filters.folderCategory!.id
        : true;

      const filterByFolderEntity =
        isNil(filters.folderCategory) && !isNil(filters.folderEntity)
          ? folder.documentCategory.entity === filters.folderEntity
          : true;

      let filterByDate = true;
      if (isNil(folder.updatedAt)) return false;
      if (!isNil(filters.fromDate) && !isNil(filters.toDate)) {
        filterByDate =
          (isAfter(new Date(folder.updatedAt), filters.fromDate) ||
            isSameDay(new Date(folder.updatedAt), filters.toDate)) &&
          (isBefore(new Date(folder.updatedAt), filters.toDate) ||
            isSameDay(new Date(folder.updatedAt), filters.fromDate));
      }
      if (!isNil(filters.fromDate)) {
        filterByDate =
          isAfter(new Date(folder.updatedAt), filters.fromDate) ||
          isSameDay(new Date(folder.updatedAt), filters.fromDate);
      }
      if (!isNil(filters.toDate)) {
        filterByDate =
          isBefore(new Date(folder.updatedAt), filters.toDate) || isSameDay(new Date(folder.updatedAt), filters.toDate);
      }

      return filterByFolderCategory && filterByFolderEntity && filterByDate;
    });
  };

  const setExtendSearchProperties = (extendSearchProperties: ExtendedSearchModel, searchText: string) => {
    dispatch({ type: 'SET_EXTENDED_SEARCH_PROPERTIES', payload: { extendedSearch: extendSearchProperties } });
    const foldersToWorkWith = Object.values(extendSearchProperties).every((filter) => filter === undefined)
      ? state.folders
      : getFilteredFolders(state.folders, extendSearchProperties);

    dispatch({ type: 'SET_FOLDERS_TO_SEARCH_IN', payload: { foldersToSeachIn: foldersToWorkWith } });

    if (!isEmpty(searchText)) {
      const filteredFolders = filterFoldersOnName(foldersToWorkWith, searchText);
      setSearchFolders(filteredFolders);
      return;
    }

    if (Object.values(extendSearchProperties).every((value) => isNil(value))) {
      cancelSearch();
      return;
    }
    setSearchFolders(foldersToWorkWith);
  };

  const getListOfExtensionTypes = () => {
    const filteredFoldersMimeTypes = compact(
      uniqBy(state.filteredFolders, (folder) => folder.documentModel?.mimeType).reduce<string[]>((acc, folder) => {
        if (!isNil(folder.documentModel?.mimeType)) {
          acc.push(folder.documentModel?.mimeType);
        }
        return acc;
      }, [])
    );

    state.sortType.forEach((sortType) => {
      if (!filteredFoldersMimeTypes.includes(sortType)) {
        filteredFoldersMimeTypes.push(sortType);
      }
    });
    return filteredFoldersMimeTypes;
  };

  const setSortType = (sortType: string) => {
    dispatch({ type: 'SET_SORT_TYPE', payload: { sortType } });
  };

  const values = useMemo(
    () => ({
      ...state,
      calculateFolders,
      renameFile,
      renameFolder,
      setFilteredFolders,
      setNavigation,
      addFilesToFolder,
      changeSelectedFolder,
      changeDragging,
      setSelectedFolders,
      deselectFolders,
      dropFolder,
      addNewFolder,
      setDraggedFolders,
      deleteFolders,
      selectSearchFolder,
      setIsBusy,
      changeVisibilityAllFolders,
      calculatePageFolder,
      fetchInitialDocuments,
      cleanup,
      getCategoriesForEntity,
      setSearchFolders,
      cancelSearch,
      setExtendSearchProperties,
      getListOfExtensionTypes,
      setSortType,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );
  return <FoldersContext.Provider value={values}>{children}</FoldersContext.Provider>;
};
export const useDocuments = (): FoldersContext => {
  const context = useContext<FoldersContext | null>(FoldersContext);
  if (!context) {
    throw new Error('useDocuments must be used within a DocumentsContextProvider');
  }

  return context as FoldersContext;
};
