import React, { useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { EmptyFileWithUrl } from '../../../components/LeaseInventory/LeaseInventoryEncoding/InventoryStructureEncodingExtraInfos';
import PreviewFilePDFDialogDetail from '../PreviewFilePDFDialogDetail';
import { toUpper } from 'lodash';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import SendForSignatureForm from '../Forms/FormSection/SendForSignatureForm';
import { Colors, LeaseExtended, S3Object } from '@rentguru/commons-utils';
import Dialog from 'src/components/ui/Dialog';

interface SendForSignatureDialogProps {
  lease: LeaseExtended;
  open: boolean;
  file: S3Object | EmptyFileWithUrl | null;
  onClose: () => void;
}

const SendForSignatureDialog: React.FC<SendForSignatureDialogProps> = ({
  open,
  lease,
  onClose,
  file: leaseContract,
}) => {
  const { formatMessage } = useIntl();
  const [alreadyPreviewedFile, setAlreadyPreviewedFile] = useState<boolean>(false);

  const closeDialog = () => {
    onClose();
  };

  return (
    <Dialog open={open} scroll="body" PaperProps={{ style: { maxWidth: 'none', width: 640, borderRadius: 10 } }}>
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: 18,
          marginTop: 20,
          marginLeft: 30,
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {formatMessage({ id: 'lease.detail.action.sendForSignature' })}
      </Typography>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} />
      {!alreadyPreviewedFile && (
        <>
          <PreviewFilePDFDialogDetail file={leaseContract} />
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={closeDialog}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton id="signatureNext" onClick={() => setAlreadyPreviewedFile(true)}>
              {formatMessage({ id: 'next' })}
            </LoaderButton>
          </div>
        </>
      )}
      {alreadyPreviewedFile && <SendForSignatureForm lease={lease} />}
    </Dialog>
  );
};

export default SendForSignatureDialog;
