import { makeStyles } from '@material-ui/core';
import { AccountLabel, Colors, RemittanceType, Transaction } from '@rentguru/commons-utils';
import { InvoiceWithPostings } from 'src/hooks/InvoicesContext';

export interface AddCustomInvoiceValues {
  history: CustomPosting[];
  reconciliationMethod: ReconciliationMethod;
  remittanceInformation: string;
  remittanceInformationType: RemittanceType;
  newTransactionOperationDate: Date;
  transactionToReconcile: Transaction | null;
  creditNoteToReconcile: InvoiceWithPostings | null;
}

export enum ReconciliationMethod {
  NONE,
  EXISTING_TRANSACTION,
  NEW_TRANSACTION,
  CREDIT_NOTE,
}

export interface CustomPosting {
  id: string;
  label: string | null;
  amount: number | null;
  accountLabel: AccountLabel | null;
  dueDate: Date | null;
  transactionType: string | null;
  unitId: string | null;
}

export const useAddCustomInvoiceStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
  },
  autocompleteGrid: {
    width: '92%',
    marginLeft: '7.5%',
  },
  divider: {
    margin: '10px -24px',
  },
  editableCustomPostingRow: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  leftArrow: {
    fill: Colors.BLUEY,
  },
  marginY5: {
    marginBottom: 5,
    marginTop: 5,
  },
  marginY10: {
    marginTop: 10,
    marginBottom: 10,
  },
  negativeAmount: {
    color: Colors.CARNATION_RED,
    fontWeight: 'bold',
  },
  positiveAmount: {
    color: Colors.TURQUOISE_BLUE,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});
