import { makeStyles, Tooltip, Typography, type TypographyProps } from '@material-ui/core';
import isNil from 'lodash/isNil';
import React, { useEffect, useRef, useState, CSSProperties } from 'react';

export const useOverflowTooltipStyles = makeStyles({
  tooltipPlacement: {
    marginBottom: 2,
  },
});

interface OverflowableTypographyProps extends TypographyProps {
  text: string;
  style?: CSSProperties;
}

export const OverflowableTypography: React.FC<OverflowableTypographyProps> = ({ text, style, ...props }) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [textOverflow, setTextOverflow] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const classes = useOverflowTooltipStyles();

  useEffect(() => {
    if (!isNil(ref) && !isNil(ref.current) && ref.current.offsetWidth < ref.current.scrollWidth) {
      setTextOverflow(true);
    }
  }, [ref]);

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleOpen = () => {
    if (textOverflow) {
      setTooltipOpen(true);
    }
  };

  return (
    <Tooltip
      open={tooltipOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      title={text}
      placement="top"
      classes={{ tooltipPlacementTop: classes.tooltipPlacement }}
    >
      <Typography
        ref={ref}
        style={{ ...(style ?? {}), whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
        {...props}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
