import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, useLocation, useRouteMatch, Switch } from 'react-router-dom';
import { Formik } from 'formik';
import isNil from 'lodash/isNil';
import { Toolbar, Divider, MenuList, Tab, Typography, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { LeaseInventory, LeaseInventoryType, LeaseStatus, Colors } from '@rentguru/commons-utils';
import { useLeases, LeaseExtended } from 'src/hooks/LeasesContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { ReactComponent as ClipIcon } from 'src/icons/clip.svg';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { ExitableLocation, RouteDestination } from 'src/components/Routes/Routes';
import { LEASES_DETAIL_TO } from 'src/components/Leases/Leases';
import { fromStatusToColor } from 'src/components/Leases/Details/LeaseDetail';
import { ColoredBox, CustomizedTabs , CustomIconButton } from '@up2rent/ui';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import { useStyles as useContentDetailHeaderStyles } from 'src/components/ui/ContentDetailHeader';
import FormHeader from 'src/components/ui/FormHeader';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import InventoryEncodings from './LeaseInventoryEncoding/InventoryEncodings';
import InventoryTechnicEncodings from './LeaseInventoryEncoding/InventoryTechnicEncodings';
import LeaseInventoryActionBar from './LeaseInventoryActionBar';
import { InventoryOfFixturesFormik } from './EditInventory/EditInventory';
import GeneralDetails from './GeneralDetails';

const TO_PREFIX = '';
const navItems = [
  {
    to: `${TO_PREFIX}`,
    labelId: 'leaseInventory.editMenu.info',
    exact: true,
    component: GeneralDetails,
    editable: true,
  },
  {
    to: `${TO_PREFIX}inside`,
    labelId: 'leaseInventory.topTabs.inside',
    component: InventoryEncodings,
    exact: true,
  },
  {
    to: `${TO_PREFIX}outside`,
    labelId: 'leaseInventory.topTabs.outside',
    component: InventoryEncodings,
    exact: true,
  },
  {
    to: `${TO_PREFIX}technics`,
    labelId: 'leaseInventory.topTabs.technics',
    component: InventoryTechnicEncodings,
    exact: true,
  },
];
interface LeaseInventoryProps {
  id: string;
  onClose?: () => void;
  unitId: string;
}
interface LeaseInventoryRowProps {
  leaseInventory: LeaseInventory;
  lease: LeaseExtended;
  // eslint-disable-next-line react/no-unused-prop-types
  onClose?: () => void;
}

const LeaseInventoryHeader: React.FC<LeaseInventoryRowProps> = ({ leaseInventory, lease }) => {
  const { formatMessage } = useIntl();
  const classes = useContentDetailHeaderStyles();
  if (isNil(lease)) return null;
  const statusText = formatMessage({ id: `enums.LeaseStatus.${leaseInventory.status}` });
  const statusColor = fromStatusToColor(leaseInventory.status as LeaseStatus);
  return (
    <Toolbar
      style={{
        display: 'block',
        backgroundColor: 'white',
        paddingTop: 30,
        paddingBottom: 10,
        borderRadius: '10px',
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: '20px',
      }}
    >
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item xs={1}>
          <ClipIcon style={{ fill: Colors.BLUE_GREY, transform: 'translateY(3px)' }} />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h6" color="inherit" className={classes.h6}>
            {lease.units!.map((u) => u.unit!.name).join(',')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: 'Mulish',
              fontSize: 14,
              textTransform: 'none',
              color: Colors.CHARCOAL_GREY,
              fontWeight: 600,
            }}
          >
            {leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
              ? formatMessage({ id: 'leaseInventory.entranceInventory' })
              : formatMessage({ id: 'leaseInventory.exitInventory' })}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <ColoredBox boxColor={statusColor}>{statusText}</ColoredBox>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 15, marginTop: 15 }} />
      <LeaseInventoryActionBar leaseInventory={leaseInventory} lease={lease} />
    </Toolbar>
  );
};

const LeaseInventoryDetail: React.FC<LeaseInventoryProps> = ({ id, onClose, unitId }) => {
  const { formatMessage } = useIntl();
  const { getLeaseInventory, getLease, leasesLoading } = useLeases();
  const match = useRouteMatch<{ inventoryDetailName: string }>({
    path: [
      `${RouteDestination.PROPERTIES}/:id?/:detailName?/inventory-:unitInventoryId?/:inventoryDetailName?`,
      `${RouteDestination.LEASES}/${LEASES_DETAIL_TO}/:leaseId?/inventory-:unitInventoryId?/:inventoryDetailName?`,
    ],
  });
  const { state: exitParams } = useLocation<ExitableLocation>();
  const { buildCompleteInventoriesForUnitAndLeaseInventory, unitInventoriesLoading } = useUnitInventories();
  const { listCustomItems } = useLeaseInventories();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const leaseInventory = getLeaseInventory(id);
  const lease = !isNil(leaseInventory) ? getLease(leaseInventory.leaseId) : undefined;
  const [initialValues, setInitialValues] = useState<InventoryOfFixturesFormik>({
    localUnitInventories: [],
    initialUnitInventories: [],
    localCustomItems: [],
    initialCustomItems: [],
    leaseInventory: leaseInventory!,
  });
  const loading = leasesLoading || unitInventoriesLoading;
  useEffect(() => {
    let isMounted = true;
    const fetchAll = async () => {
      if (isMounted) {
        const customItemsList = await listCustomItems();
        const unitInventoriesList = await buildCompleteInventoriesForUnitAndLeaseInventory(
          unitId,
          leaseInventory!.id,
          new Date(lease!.createdAt!),
          'fixtures'
        );

        setInitialValues({
          localUnitInventories: unitInventoriesList,
          initialUnitInventories: unitInventoriesList,
          localCustomItems: customItemsList,
          initialCustomItems: customItemsList,
          leaseInventory: leaseInventory!,
        });
        setIsFetching(false);
      }
    };

    if (!loading && !isNil(leaseInventory) && !isNil(lease)) fetchAll();

    return () => {
      isMounted = false;
    };
  }, [buildCompleteInventoriesForUnitAndLeaseInventory, listCustomItems, leaseInventory, lease, unitId, loading]);

  if (loading || isNil(match) || isNil(leaseInventory) || isNil(lease)) {
    return <SkeletonComponent loading={true} />;
  }

  return (
    <Grid container style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
      <Grid
        item
        style={{
          height: '100%',
          width: 240,
          overflow: 'visible',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <CustomIconButton
          style={{ marginTop: 30, marginBottom: 70 }}
          aria-label="Menu"
          onClick={onClose}
          Icon={ArrowBack}
          iconStyle={{ fill: Colors.BLUE_GREY }}
        />

        <Typography
          variant="subtitle1"
          style={{
            color: Colors.LIGHT_BLUE_GREY,
            fontSize: 10,
            letterSpacing: '1px',
            lineHeight: '12px',
            textTransform: 'uppercase',
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          {formatMessage({
            id: 'leaseInventory.details',
          })}
        </Typography>
        <MenuList>
          {navItems.map((m) => {
            const to = !isNil(match.params.inventoryDetailName)
              ? match.url.replace(match.params.inventoryDetailName, m.to)
              : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
            const selected =
              (!isNil(match.params.inventoryDetailName) && match.params.inventoryDetailName === m.to) ||
              (m.to === TO_PREFIX && isNil(match.params.inventoryDetailName));

            return (
              <CustomizedMenuItem key={m.to} to={to} selected={selected} historyProps={{ state: exitParams }}>
                {formatMessage({ id: m.labelId })}
              </CustomizedMenuItem>
            );
          })}
        </MenuList>
      </Grid>
      <Grid
        style={{
          flexGrow: 1,
          width: 'calc(100% - 280px)',
        }}
      >
        <LeaseInventoryHeader leaseInventory={leaseInventory} lease={lease} />
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 24,
            paddingBottom: 24,
            minHeight: 'calc(100% - 168px)',
          }}
        >
          {!isNil(match.params.inventoryDetailName) &&
            (match.params.inventoryDetailName.includes('inside') ||
              match.params.inventoryDetailName.includes('outside')) && (
              <>
                <FormHeader
                  title={formatMessage({
                    id:
                      !isNil(match.params.inventoryDetailName) && match.params.inventoryDetailName.includes('inside')
                        ? 'leaseInventory.topTabs.inside'
                        : 'leaseInventory.topTabs.outside',
                  })}
                />
                <div style={{ marginTop: 10, marginLeft: 24 }}>
                  <CustomizedTabs
                    completeHandleChange={(e, index) => setTabIndex(index)}
                    tabStyle={{ minWidth: 10 }}
                    value={tabIndex}
                    dynamicDefaultTab
                  >
                    <Tab label={formatMessage({ id: 'leaseInventory.editMenu.general' })} />
                    <Tab label={formatMessage({ id: 'leaseInventory.topTabs.finishes' })} />
                    <Tab label={formatMessage({ id: 'leaseInventory.topTabs.equipment' })} />
                  </CustomizedTabs>
                </div>
                <Divider style={{ marginBottom: 20 }} />
              </>
            )}
          {!isFetching && (
            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={() => {}}>
              {() => {
                return (
                  <Switch>
                    {navItems.map((m) => {
                      const path = match.path.replace(':inventoryDetailName?', m.to);
                      const Comp = m.component;
                      return (
                        <Route
                          path={path}
                          key={m.to}
                          exact={m.exact}
                          render={() => {
                            return (
                              <Comp
                                leaseInventory={leaseInventory}
                                inside={m.labelId.includes('inside')}
                                tab={tabIndex}
                                lease={lease}
                                readOnly={true}
                              />
                            );
                          }}
                        />
                      );
                    })}

                    <Route
                      path="*"
                      render={() => {
                        return <MenuNotFound />;
                      }}
                    />
                  </Switch>
                );
              }}
            </Formik>
          )}
          {isFetching && <SkeletonComponent loading={isFetching} />}
        </div>
      </Grid>
    </Grid>
  );
};
export default LeaseInventoryDetail;
