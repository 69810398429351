import { CircularProgress, Collapse, Typography } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import { Colors, S3Object } from '@rentguru/commons-utils';
import { isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useIntl } from 'react-intl';
import { EmptyFileWithUrl } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryStructureEncodingExtraInfos';
import { getArrayBufferFromUrl } from 'src/hooks/FilesContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as Plus } from 'src/icons/add.svg';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { ReactComponent as PlayIcon } from 'src/icons/play.svg';
import { ReactComponent as ChatIcon } from 'src/icons/textchat.svg';
import { WaveFile } from 'wavefile';
import { ActionButtonSecond, actionButtonSecondStyles, CustomPopover , CustomIconButton } from '@up2rent/ui';

interface CustomAudioPlayerProps {
  audioFile: S3Object | EmptyFileWithUrl;
  deleteFunction?: (file: S3Object | EmptyFileWithUrl) => void;
  appendTextToEnd?: (speechToText: string) => void;
}
const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({ audioFile, deleteFunction, appendTextToEnd }) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showSpeechToText, setShowSpeechToText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorPopover, setAnchorPopover] = useState<null | SVGSVGElement>(null);
  const { language } = useLocale();
  const { formatMessage } = useIntl();

  const handlePopoverClose = () => {
    setAnchorPopover(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorPopover(event.currentTarget);
  };

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: isNil(deleteFunction) ? 'center' : 'space-between', marginTop: 10 }}
      >
        <AudioPlayer
          src={audioFile.url}
          layout="horizontal-reverse"
          showSkipControls={false}
          showJumpControls={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
          customProgressBarSection={[
            RHAP_UI.CURRENT_TIME,
            // eslint-disable-next-line react/jsx-key
            <Typography style={{ color: Colors.DARK_SLATE_GREY, fontSize: 12, fontFamily: 'Mulish' }}>/</Typography>,
            RHAP_UI.DURATION,
            RHAP_UI.PROGRESS_BAR,
            // eslint-disable-next-line react/jsx-key
            <ChatIcon
              style={{ cursor: 'pointer' }}
              onMouseOver={handlePopoverOpen}
              onMouseOut={handlePopoverClose}
              onClick={async (e) => {
                setLoading(true);
                e.preventDefault();
                if (!isEmpty(showSpeechToText)) {
                  setShowSpeechToText('');
                }
                if (language !== 'en' && language !== 'fr') {
                  enqueueSnackbar(formatMessage({ id: 'unavailable' }), { variant: 'error' });
                } else {
                  const fileBlob =
                    'file' in audioFile
                      ? await audioFile.file.arrayBuffer()
                      : await getArrayBufferFromUrl(audioFile.url);

                  const wav = new WaveFile();
                  wav.fromBuffer(new Uint8Array(fileBlob));
                  // 44.1kHz (44100) to 8 kHz(8000) -> For french...
                  // wav.toSampleRate(8000);
                  // Resample depends on open issue ->
                  // const rawForPred = Array.from(wav.getSamples());
                  try {
                    const speechToText = '';
                    // const speechToTextOutput = await Predictions.convert({
                    //   transcription: {
                    //     source: {
                    //       // bytes: (wav.toBuffer() as unknown) as Blob,
                    //       bytes: rawForPred as unknown as ConvertBytes,
                    //     },
                    //     language: language === 'fr' ? 'fr-FR' : 'en-US',
                    //   },
                    // });
                    // const speechToText = speechToTextOutput.transcription.fullText;
                    setShowSpeechToText(speechToText);
                    if (isEmpty(speechToText)) {
                      enqueueSnackbar(formatMessage({ id: 'noResult' }), { variant: 'info' });
                    }
                  } catch (err) {
                    console.error('err', err);
                    enqueueSnackbar(`Error: ${err}`, { variant: 'error' });
                  }
                }
                setLoading(false);
              }}
            />,
          ]}
          customIcons={{
            play: <PlayIcon />,
            pause: <PauseIcon style={{ fill: Colors.DARK_SLATE_GREY, height: 16, width: 16 }} />,
          }}
        />
        {!isNil(deleteFunction) && (
          <CustomIconButton
            onClick={() => deleteFunction(audioFile)}
            Icon={Delete}
            iconStyle={{ fill: Colors.BLUE_GREY }}
          />
        )}
      </div>
      <Collapse in={!isEmpty(showSpeechToText) || loading} style={{ margin: 10 }}>
        <div style={{ backgroundColor: Colors.CLASSICAL_WHITE, borderRadius: 10, padding: 10 }}>
          {loading && <CircularProgress style={{ color: Colors.DODGER_BLUE }} size={20} />}
          {!loading && !isEmpty(showSpeechToText) && (
            <>
              <Typography style={{ fontSize: 12 }}>{showSpeechToText}</Typography>
              {appendTextToEnd && (
                <ActionButtonSecond
                  className={actionButtonClasses.buttonWithMargin}
                  onClick={() => appendTextToEnd(showSpeechToText)}
                >
                  <Plus style={{ fill: Colors.BLUE_GREY }} />
                  {formatMessage({ id: 'leaseInventory.addToNote' })}
                </ActionButtonSecond>
              )}
            </>
          )}
        </div>
      </Collapse>
      <CustomPopover
        open={!isNil(anchorPopover)}
        anchorEl={anchorPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        paperBorderRadius={10}
      >
        <Typography>{formatMessage({ id: 'leaseInventory.convertRecordToText' })}</Typography>
      </CustomPopover>
    </>
  );
};

export default CustomAudioPlayer;
