import { Collapse, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  formatNumberToEuroCurrency,
  getFromPartAccountOfTransaction,
  getFromPartNameOfTransaction,
  getToPartAccountOfTransaction,
  getToPartNameOfTransaction,
  getTransactionUnlinkedAmountForLease,
  Invoice,
  isNilOrEmpty,
  LeaseExtended,
  roundAtSecondDecimal,
  Transaction,
  TransactionStatus,
} from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { friendlyFormatIBAN } from 'ibantools';
import { sumBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { InvoiceWithPostings, useInvoices } from 'src/hooks/InvoicesContext';
import { fetchPostings, useTransactions } from 'src/hooks/TransactionsContext';
import { ReactComponent as ArrowRightIcon } from 'src/icons/arrow-right.svg';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';
import RemittanceInformationField from 'src/components/ui/Forms/FormField/RemittanceInformationField';
import StyledRadio from 'src/components/ui/StyledRadio';
import { AddCustomInvoiceValues, ReconciliationMethod, useAddCustomInvoiceStyles } from './addCustomInvoiceDialogUtils';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { getSafeValueInObject } from 'src/utils/object';

interface ReconciliationPartProps {
  lease: LeaseExtended;
}

interface TransactionOptionProps {
  leaseId: string;
  transaction: Transaction;
}

const TransactionOption: React.FC<TransactionOptionProps> = ({ leaseId, transaction }) => {
  const classes = useAddCustomInvoiceStyles();
  const transactionUnlinkAmount = getTransactionUnlinkedAmountForLease(transaction, leaseId);
  const totalTransactionAmount = transaction.amount;
  return (
    <>
      <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Grid container alignContent="space-between" className={classes.marginY5}>
          <Grid item xs={4}>
            <Typography style={{ fontWeight: 'bold' }}>{getFromPartNameOfTransaction(transaction)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <ArrowRightIcon className={classes.leftArrow} />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ fontWeight: 'bold' }}>{getToPartNameOfTransaction(transaction)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.positiveAmount}>
              {formatNumberToEuroCurrency(transactionUnlinkAmount)}
            </Typography>
            {totalTransactionAmount !== transactionUnlinkAmount && (
              <Typography className={classes.positiveAmount}>
                {' / '}
                {formatNumberToEuroCurrency(totalTransactionAmount)}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container alignContent="flex-start" className={classes.marginY5}>
          <Grid item xs={5}>
            <Typography>{friendlyFormatIBAN(getFromPartAccountOfTransaction(transaction), ' ')}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{friendlyFormatIBAN(getToPartAccountOfTransaction(transaction), ' ')}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

const ReconciliationPart: React.FC<ReconciliationPartProps> = ({ lease }) => {
  const { values, setFieldValue, errors } = useFormikContext<AddCustomInvoiceValues>();
  const { getTransactionsForLease } = useTransactions();
  const { getInvoicesOfLease } = useInvoices();
  const { formatMessage } = useIntl();
  const [creditNotesValues, setCreditNotesValues] = useState<Invoice[]>([]);
  const classes = useAddCustomInvoiceStyles();

  const historyAmount = roundAtSecondDecimal(sumBy(values.history, 'amount'));
  const sumOfClass = sumBy(values.history, 'accountLabel.class');
  const allTransactions = getTransactionsForLease(lease.id).filter((transaction) =>
    [TransactionStatus.TO_RECONCILE, TransactionStatus.PARTIALLY_RECONCILED].includes(transaction.status)
  );
  const allInvoices = getInvoicesOfLease(lease.id);
  const allFilteredCreditNotes = allInvoices.filter(
    (invoice) => invoice.creditNote && invoice.amount === historyAmount && !invoice.paid
  );
  const hasBankAccountIntegration = !isNilOrEmpty(lease.bankAccount?.integrations);

  const transactionsValues = useMemo(
    () =>
      allTransactions.reduce((acc, transaction) => {
        const unlinkedAmount = getTransactionUnlinkedAmountForLease(transaction, lease.id);
        if (unlinkedAmount <= historyAmount && unlinkedAmount !== 0) {
          acc.push(transaction);
        }
        return acc;
      }, [] as Transaction[]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyAmount]
  );

  useEffect(() => {
    const fetchCreditNotesValues = async () => {
      const allCreditNotesWithPostingsPromises = allFilteredCreditNotes.map(async (creditNote) => {
        const creditNotePostings = await fetchPostings('byInvoice', creditNote.id);
        return { ...creditNote, postings: creditNotePostings };
      });
      const allCreditNotesWithPostings: InvoiceWithPostings[] = await Promise.all(allCreditNotesWithPostingsPromises);
      const newCreditNotesValues = allCreditNotesWithPostings.reduce((acc, invoice) => {
        const areAllPostingsMatching = !values.history.some((history) => {
          const currentClass = history.accountLabel?.class;
          const commonPostingExist = invoice.postings.some(
            (posting) => posting.totalAmount === history.amount && posting.class === currentClass
          );
          return !commonPostingExist;
        });
        if (areAllPostingsMatching) acc.push(invoice);
        return acc;
      }, [] as InvoiceWithPostings[]);
      setCreditNotesValues(newCreditNotesValues);
    };

    if (values.reconciliationMethod === ReconciliationMethod.CREDIT_NOTE) {
      fetchCreditNotesValues();
      if (values.creditNoteToReconcile) {
        setFieldValue('creditNoteToReconcile', null);
      }
    } else if (
      values.reconciliationMethod === ReconciliationMethod.EXISTING_TRANSACTION &&
      values.transactionToReconcile &&
      getTransactionUnlinkedAmountForLease(values.transactionToReconcile, lease.id) > historyAmount
    ) {
      setFieldValue('transactionToReconcile', null);
    }

    if (values.reconciliationMethod !== ReconciliationMethod.EXISTING_TRANSACTION && values.transactionToReconcile) {
      setFieldValue('transactionToReconcile', null);
    } else if (values.reconciliationMethod !== ReconciliationMethod.CREDIT_NOTE && values.creditNoteToReconcile) {
      setFieldValue('creditNoteToReconcile', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyAmount, sumOfClass, values.reconciliationMethod]);

  const bankAccountIntegration = hasBankAccountIntegration
    ? lease.bankAccount?.integrations?.map((integration) => integration.toString()).join(', ') ?? ''
    : '';

  return (
    <>
      <Typography className={classes.subtitle}>
        {formatMessage({ id: 'transactions.lettering.reconciliation' })}
      </Typography>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <StyledRadio
          onChange={() => setFieldValue('reconciliationMethod', ReconciliationMethod.NONE)}
          checked={values.reconciliationMethod === ReconciliationMethod.NONE}
        />
        <Typography style={{ fontSize: 14 }}>
          {formatMessage({ id: 'transactions.lettering.unpaidInvoice' })}
        </Typography>
      </Grid>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <StyledRadio
          onChange={() => setFieldValue('reconciliationMethod', ReconciliationMethod.NEW_TRANSACTION)}
          checked={values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION}
        />
        <Typography style={{ fontSize: 14 }}>
          {formatMessage({ id: 'transactions.lettering.linkInvoiceToManualTransaction' })}
        </Typography>
      </Grid>
      <Collapse in={values.reconciliationMethod === ReconciliationMethod.NEW_TRANSACTION}>
        <Grid className={classes.autocompleteGrid}>
          <Grid style={{ display: 'flex', alignItems: 'end' }}>
            <CustomDatePicker
              name={`newTransactionOperationDate`}
              label={formatMessage({ id: 'date' })}
              value={values.newTransactionOperationDate}
              style={{ minWidth: 150, width: 150, marginRight: 10 }}
              keyboardIconStyle={{ width: 20 }}
              keyboardIconProps={{ size: 'small' }}
              safeDateFormat
              onChange={(date: Date | null) => setFieldValue('newTransactionOperationDate', date)}
              error={Boolean(getSafeValueInObject(errors, `newTransactionOperationDate`))}
            />
            <Grid style={{ width: 350 }}>
              <RemittanceInformationField
                remittanceInformationName="remittanceInformation"
                remittanceInformationTypeName="remittanceInformationType"
                allowGenerateStructuredRemittanceInformation
                title=" "
              />
            </Grid>
          </Grid>
          {hasBankAccountIntegration && (
            <FormAlertBox
              message1={formatMessage(
                { id: 'settings.integrations.existingIntegrationWarning' },
                { integrations: bankAccountIntegration }
              )}
              gridStyle={{ marginLeft: 0, width: '100%' }}
            />
          )}
        </Grid>
      </Collapse>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <StyledRadio
          onChange={() => setFieldValue('reconciliationMethod', ReconciliationMethod.EXISTING_TRANSACTION)}
          checked={values.reconciliationMethod === ReconciliationMethod.EXISTING_TRANSACTION}
        />
        <Typography style={{ fontSize: 14 }}>
          {formatMessage({ id: 'transactions.lettering.linkInvoiceToExistingTransaction' })}
        </Typography>
      </Grid>
      <Collapse in={values.reconciliationMethod === ReconciliationMethod.EXISTING_TRANSACTION}>
        <Grid className={classes.autocompleteGrid}>
          <Autocomplete
            options={transactionsValues}
            style={{ width: '100%' }}
            autoHighlight
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(transaction) =>
              `${formatMessage({ id: 'transactions.transaction' })} - ${getTransactionUnlinkedAmountForLease(
                transaction,
                lease.id
              )}€`
            }
            popupIcon={<DropDown />}
            renderOption={(transaction) => <TransactionOption leaseId={lease.id} transaction={transaction} />}
            value={values.transactionToReconcile}
            onChange={(_event, value) => setFieldValue('transactionToReconcile', value)}
            noOptionsText={formatMessage({ id: 'comboBox.noTransaction' })}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={formatMessage({ id: 'transactions.transaction' })}
                margin="dense"
                variant="filled"
              />
            )}
          />
        </Grid>
      </Collapse>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <StyledRadio
          onChange={() => setFieldValue('reconciliationMethod', ReconciliationMethod.CREDIT_NOTE)}
          checked={values.reconciliationMethod === ReconciliationMethod.CREDIT_NOTE}
        />
        <Typography style={{ fontSize: 14 }}>
          {formatMessage({ id: `transactions.lettering.linkInvoiceToExistingCreditNote` })}
        </Typography>
      </Grid>
      <Collapse in={values.reconciliationMethod === ReconciliationMethod.CREDIT_NOTE}>
        <Grid className={classes.autocompleteGrid}>
          <Autocomplete
            options={creditNotesValues}
            style={{ width: '100%' }}
            autoHighlight
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(creditNote) =>
              `${formatMessage({ id: `enums.CreditNoteInvoiceType.${creditNote.type}` })} - ${creditNote.amount}€`
            }
            popupIcon={<DropDown />}
            renderOption={(creditNote) => {
              return (
                <>
                  <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Grid container alignContent="space-between" className={classes.marginY5}>
                      <Grid item xs={10}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {formatMessage({ id: `enums.CreditNoteInvoiceType.${creditNote.type}` })}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.negativeAmount}>
                          {formatNumberToEuroCurrency(-creditNote.amount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              );
            }}
            value={values.creditNoteToReconcile}
            onChange={(_event, value) => setFieldValue('creditNoteToReconcile', value)}
            noOptionsText={formatMessage({ id: 'comboBox.noCreditNote' })}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={formatMessage({ id: 'accounting.creditNote.creditNote' })}
                margin="dense"
                variant="filled"
              />
            )}
          />
        </Grid>
      </Collapse>
    </>
  );
};

export default ReconciliationPart;
