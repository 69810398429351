/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-shadow */
import {
  ACCOUNT_LEASE_TOPCLASS,
  contactContainsType,
  endOfUTCDay,
  EntityType,
  FileCategory,
  getInvoicePeriodTo,
  getLegalLeaseEndDate,
  getNumberOfMonthsFromFrequency,
  getOwnerIdOfUnit,
  getTechnicsByType,
  Language,
  LeaseRentalGuarantyType,
  LeaseType,
  roundAtSecondDecimal,
  UnitType,
  getInvoiceSenderIdOfUnitOrBuilding,
  startOfUTCMonth,
  startOfUTCDay,
  isNilOrEmpty,
  ModelWithVersion,
  LeaseStatus,
  LeaseVariousOperation,
  LeaseVariousOperationType,
  VariousOperationType,
  UnitLease,
  LeaseContact,
  LeaseAdditionalClause,
  UnitInventory,
  LeasePriceHistory,
  File as FileModel,
  Building,
  Contact,
  ContactType,
  LeaseMonthlyChargesType,
  Technic,
  Unit,
  Lease,
  LeasePaymentFrequency,
  LeasePaymentInvoicePeriod,
  LeaseAmountUpdateStatus,
  Posting,
  LeasePriceHistoryType,
  InvoiceType,
  PostingType,
  LeasePriceHistoryStatus,
  LeaseActionHistory,
  LeaseAction,
  LeasePriceHistoryDetailInput,
  LeasePriceHistoryType as LeasePriceHistoryTypeAPI,
  CommunicationSettingsProfile,
  S3Object,
  Invoice,
  getVatToApply,
} from '@rentguru/commons-utils';
import {
  addMilliseconds,
  addYears,
  endOfDay,
  isAfter,
  isBefore,
  isToday,
  parseISO,
  setDay,
  subDays,
  subMonths,
} from 'date-fns';
import { FormikHelpers, useFormikContext } from 'formik';
import { get, isEmpty, isEqual, isNil, maxBy, minBy, sumBy, toNumber, toUpper } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  getUnitInventoriesGroupedByType,
  UnitInventoriesSchema,
  UnitStructure,
} from 'src/components/ui/Forms/FormField/UnitStructureFields';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { FilesContext, NewFile, useFiles } from 'src/hooks/FilesContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { LeaseContext, useLeases } from 'src/hooks/LeasesContext';
import { attachFilesToTechnic, FileCategoryTypeForm, useTechnics } from 'src/hooks/TechnicsContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { UnitContext, useUnits } from 'src/hooks/UnitsContext';
import { useUsers } from 'src/hooks/UsersContext';
import { AddLeaseFormValues } from './AddLeaseForm';
import {
  createAdditionalClauses,
  createLeaseContacts,
  fetchLeaseS3Files,
  fetchEpbFiles,
  handleAddEditSmokeDetectors,
  handleAddEditTechnicWithMaintenance,
  handleAddUtilityProvidersToLeaseId,
  handleBuildingAddressUpdates,
  handleCreationAndDeletionOfUnitInventories,
  handleEditFilesByCategory,
  handleFilesByCategory,
} from './AddLeaseUtils';
import { useHistory } from 'react-router-dom';
import { AdditionalUnit } from 'src/components/ui/Forms/FormSection/AddAdditionalUnits';
import { RouteDestination } from 'src/components/Routes/Routes';
import KeyDates from 'src/components/ui/Forms/FormSection/KeyDates';
import { KeyDatesSchema } from 'src/components/ui/Forms/FormField/KeyDatesFields';
import VatManagementForm from 'src/components/ui/Forms/FormSection/VatManagementForm';
import { VatManagementSchema } from 'src/components/ui/Forms/FormField/VatManagementFields';
import RentForm from 'src/components/ui/Forms/FormSection/RentForm';
import { getAddOperationCompleteSchemaDynamically } from 'src/components/ui/Forms/FormField/RentFields';
import SurfaceOfUnit from 'src/components/ui/Forms/FormSection/SurfaceOfUnit';
import UnitStructureForm from 'src/components/ui/Forms/FormSection/UnitStructureForm';
import SmokeDetectorsForm, {
  getDynamicSmokeDetectorsSchema,
  doesUnitTypeHaveSmokeDetectors,
} from 'src/components/ui/Forms/FormSection/SmokeDetectorsForm';
import LeasePEBForm, { FileSchema } from 'src/components/ui/Forms/FormSection/LeasePEBForm';
import { getUnitIcon } from 'src/components/ui/Forms/FormField/UnitSelectorWithLogoField';
import { getTabAssignedToSelectedLease } from '../Leases';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useUser } from 'src/hooks/UserContext';
import { OperationFormValue } from 'src/components/ui/Forms/FormField/AddOperation';
import { fetchInvoices, useInvoices } from 'src/hooks/InvoicesContext';
import { v4 as uuidV4 } from 'uuid';
import { fetchPostings, useTransactions } from 'src/hooks/TransactionsContext';
import { SurfaceSchema } from 'src/components/ui/Forms/FormField/UnitSurfaceFields';
import { EmptySchema } from 'src/utils/formutils';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { FileCategoryContext, useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { useCommunicationSettingsProfileUtils } from 'src/components/Settings/CommunicationSettings/CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';

export const isLeaseAlreadyStarted = (startDate: Date) => {
  return isBefore(startDate, new Date()) && !isToday(startDate);
};

export const resolveMainUnitAndSubUnitIndexAndPrefix = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  unitId: string | undefined,
  fieldName?: string
) => {
  if (isNil(unitId)) {
    return {
      isMainUnit: false,
      subUnitIndex: -1,
      prefixFieldName: `${fieldName ?? ''}`,
    };
  }
  const isMainUnit = ((values.unit ?? {}).id ?? '') === unitId;
  const subUnitIndex = isMainUnit
    ? -1
    : (values.subUnits ?? []).findIndex((currentAdditionalUnit: AdditionalUnit) => {
        return currentAdditionalUnit.id === unitId;
      });
  const prefixFieldName = isMainUnit ? '' : `subUnits[${subUnitIndex}].`;

  return {
    isMainUnit,
    subUnitIndex,
    prefixFieldName: !isMainUnit && subUnitIndex === -1 ? `${fieldName ?? ''}` : `${prefixFieldName}${fieldName ?? ''}`,
  };
};

export const isEpbUsefulForLease = (type: UnitType) => {
  return ![UnitType.PARKING, UnitType.OTHER].includes(type);
};

export const getPricesValuesForUnit = (values: AddLeaseFormValues, unitId: string) => {
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const startDate = new Date(get(values, `${prefixFieldName}startDate`)!);
  const leaseAlreadyStarted = isLeaseAlreadyStarted(startDate);

  const chargesActives = Boolean(get(values, `${prefixFieldName}includeMonthlyCharge`));
  const chargeType =
    get(values, `${prefixFieldName}monthlyChargesFields.monthlyChargesType`) ?? LeaseMonthlyChargesType.FixedPrice;
  const isFixedPrice = chargeType === LeaseMonthlyChargesType.FixedPrice;
  const allowChargeAdjustmentValue =
    !isFixedPrice && Boolean(get(values, `${prefixFieldName}monthlyChargesFields.allowChargeAdjustment`));
  const chargesIndexationValue =
    isFixedPrice &&
    Boolean(get(values, `${prefixFieldName}chargeIndexation`)) &&
    Boolean(get(values, `${prefixFieldName}indexation`));
  const monthlyChargesValue = toNumber(get(values, `${prefixFieldName}monthlyChargesFields.monthlyCharges`) ?? 0);
  const initialMonthlyChargesValue =
    chargesIndexationValue && leaseAlreadyStarted
      ? toNumber(get(values, `${prefixFieldName}initialChargePrice`) ?? monthlyChargesValue)
      : monthlyChargesValue;
  const rentIndexation =
    Boolean(get(values, `${prefixFieldName}indexation`)) && Boolean(get(values, `${prefixFieldName}rentalIndexation`));
  const rentalPriceValue = toNumber(get(values, `${prefixFieldName}rentalPrice`));
  const initialRentalPriceValue =
    rentIndexation && leaseAlreadyStarted
      ? toNumber(get(values, `${prefixFieldName}initialRentalPrice`) ?? get(values, `${prefixFieldName}rentalPrice`))
      : rentalPriceValue;

  const pricesValues = {
    // Charges
    allowChargeAdjustment: chargesActives ? allowChargeAdjustmentValue : false,
    chargesIndexation: chargesActives ? chargesIndexationValue : false,
    monthlyChargesType: chargesActives ? chargeType : undefined,
    monthlyCharges: chargesActives ? monthlyChargesValue : 0,
    initialMonthlyCharges: chargesActives ? initialMonthlyChargesValue : 0,

    // Rental Price
    rentalPrice: rentalPriceValue,
    initialRentalPrice: initialRentalPriceValue,

    // Indexation
    indexation: chargesIndexationValue || rentIndexation,
  };

  return pricesValues;
};

export const getLeasePricesValues = (values: AddLeaseFormValues) => {
  const mainUnitPrice = getPricesValuesForUnit(values, get(values, 'unitId')!);

  return values.subUnits.reduce(
    (result, currentUnit) => {
      const currentUnitPrices = getPricesValuesForUnit(values, currentUnit.id);
      return {
        initialRentalPrice: result.initialRentalPrice + currentUnitPrices.initialRentalPrice,
        initialChargePrice: result.initialChargePrice + currentUnitPrices.initialMonthlyCharges,
        monthlyCharges: result.monthlyCharges + currentUnitPrices.monthlyCharges,
        rentalPrice: result.rentalPrice + currentUnitPrices.rentalPrice,
      };
    },
    {
      initialRentalPrice: mainUnitPrice.initialRentalPrice,
      initialChargePrice: mainUnitPrice.initialMonthlyCharges,
      monthlyCharges: mainUnitPrice.monthlyCharges,
      rentalPrice: mainUnitPrice.rentalPrice,
    } as { initialRentalPrice: number; initialChargePrice: number; monthlyCharges: number; rentalPrice: number }
  );
};

const getIsoSignatureDate = (needToRedirectToEditSignatures: boolean, isoStartDate: string, signatureDate?: Date) => {
  if (needToRedirectToEditSignatures) {
    return undefined;
  }
  return signatureDate ? new Date(signatureDate).toISOString() : isoStartDate;
};

const getDatesValues = (values: AddLeaseFormValues, unitId: string) => {
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  const startDate = get(values, `${prefixFieldName}startDate`)!;
  const endDate = get(values, `${prefixFieldName}endDate`);
  const signatureDate = get(values, `${prefixFieldName}signatureDate`);

  const isoStartDate = startDate ? new Date(startDate).toISOString() : new Date().toISOString();
  const isoEndDate = endDate
    ? new Date(endDate).toISOString()
    : endOfUTCDay(subDays(addYears(new Date(), 100), 1)).toISOString();
  const isoSignatureDate = getIsoSignatureDate(values.needToRedirectToEditSignatures, isoStartDate, signatureDate);

  return {
    startDate: isoStartDate,
    endDate: isoEndDate,
    signatureDate: isoSignatureDate,
  };
};

export const getLastInvoiceDate = (values: AddLeaseFormValues): string | undefined => {
  const startDate = new Date(values.startDate!);
  const endDate = new Date(values.endDate!);
  const isLeaseStartingAfterToday = isAfter(endOfDay(startDate), new Date()); // first after second // start apres today
  if (isLeaseStartingAfterToday) {
    return undefined;
  }
  const isLeaseAlreadyStopped = isAfter(new Date(), endOfDay(endDate));
  if (isLeaseAlreadyStopped) {
    return startOfUTCDay(endDate).toISOString();
  }
  const indicatedLastInvoiceDate = new Date(values.paymentDueDateFields.lastInvoiceDate!);
  const amountOfMonthsFrequency = getNumberOfMonthsFromFrequency(
    values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency
  );
  if (amountOfMonthsFrequency === 0.5) {
    const dayOfMonth = indicatedLastInvoiceDate.getDate();
    if (dayOfMonth >= 15) {
      return startOfUTCDay(setDay(new Date(indicatedLastInvoiceDate), 1)).toISOString();
    }
    return startOfUTCDay(subMonths(setDay(new Date(indicatedLastInvoiceDate), 15), 1)).toISOString();
  }
  return startOfUTCDay(subMonths(new Date(indicatedLastInvoiceDate), amountOfMonthsFrequency)).toISOString();
};

const getPaymentsFields = (
  values: AddLeaseFormValues
): {
  paymentFrequency: LeasePaymentFrequency;
  paymentInvoicePeriod: LeasePaymentInvoicePeriod;
  paymentInvoicePeriodCustomDay?: number;
} => {
  const paymentFrequency = values.paymentDueDateFields.paymentFrequency as LeasePaymentFrequency;
  const paymentInvoicePeriod = values.paymentDueDateFields.paymentInvoicePeriod as LeasePaymentInvoicePeriod;
  const paymentInvoicePeriodCustomDay =
    paymentInvoicePeriod === LeasePaymentInvoicePeriod.CUSTOM_PERIOD
      ? values.paymentDueDateFields.paymentInvoicePeriodCustomDay ?? 1
      : undefined;

  return { paymentFrequency, paymentInvoicePeriod, paymentInvoicePeriodCustomDay };
};

const getLeaseVariousOperationValues = (values: AddLeaseFormValues, operation: OperationFormValue) => {
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, operation.unitId);

  const { paymentInvoicePeriod, paymentInvoicePeriodCustomDay } = getPaymentsFields(values);

  const lastInvoiceDate = getLastInvoiceDate(values);

  const endDateCleaned = new Date(operation.endDate!);
  const endDate = startOfUTCMonth(endDateCleaned).toISOString();
  const startDateCleaned = new Date(operation.startDate!);
  const startDate = startOfUTCMonth(startDateCleaned).toISOString();

  const hasVat = get(values, `${prefixFieldName}applyVat`);
  const isChargeOrService = [LeaseVariousOperationType.CHARGE, LeaseVariousOperationType.SERVICE].includes(
    operation.operationType as LeaseVariousOperationType
  );

  const amount = toNumber(operation.amount ?? 0);

  const isSeparateInvoice = operation.separateInvoice ?? false;
  const operationPaymentFrequency = operation.paymentFrequency;
  const operationPaymentInvoicePeriod = paymentInvoicePeriod;
  const operationPaymentInvoicePeriodCustomDay = paymentInvoicePeriodCustomDay;
  const type =
    operation.type === 'DURATION' ? VariousOperationType.RECURRING : (operation.type as VariousOperationType);

  return {
    amount,
    endDate,
    label: operation.label!,
    leaseVariousOperationHistories: [{ amount, periodFrom: startDate, periodTo: endDate }],
    operationType: operation.operationType as LeaseVariousOperationType,
    paymentFrequency: operationPaymentFrequency as LeasePaymentFrequency,
    paymentInvoicePeriod: operationPaymentInvoicePeriod as LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay: operationPaymentInvoicePeriodCustomDay,
    lastInvoiceDate, // Put same as lease
    reason: operation.reason,
    separateInvoice: isSeparateInvoice,
    startDate,
    type,
    // Only for charges or services
    ...(hasVat && isChargeOrService
      ? {
          vatRate: toNumber(operation.vatRate!) ?? 0,
        }
      : {}),
  };
};

export const isAtLeastOneUnitFurnished = (values: AddLeaseFormValues) => {
  if (values.furnishedRental.furnishedRental) {
    return true;
  }

  return values.subUnits.some((subUnit) => Boolean(subUnit.furnishedRental.furnishedRental));
};

export const getTechnicsAndFilesByCategoriesInitValuesForUnit = async (
  technics: Technic[],
  unitId: string,
  buildingId: string,
  leaseId: string | undefined,
  hideTechnicOnBuilding: boolean,
  isMainUnit: boolean,
  getDocumentCategory: FileCategoryContext['getDocumentCategoryByFileCategory']
) => {
  // Called from: onChangeUnit, add subUnit (AddAdditionalUnits) and getInitialValues (useAddEditLeaseInitialValues)
  const [pebs, smokeDetectors, fuelTanks, heating, chimneys, utilityProviders] = getTechnicsByType(
    technics,
    unitId,
    hideTechnicOnBuilding ? undefined : buildingId,
    leaseId,
    isMainUnit
  );

  const technicsWithMaintenance = [...fuelTanks, ...heating, ...chimneys];
  const technicsWithMaintenanceAndFiles = await attachFilesToTechnic(technicsWithMaintenance);

  const filesPromises: Promise<FileCategoryTypeForm | FileCategoryTypeForm[] | undefined>[] = pebs.map((peb: Technic) =>
    fetchEpbFiles(peb, getDocumentCategory)
  );

  if (isMainUnit) {
    filesPromises.push(fetchLeaseS3Files(leaseId));
  }
  const filesByCategoriesResults = await Promise.all(filesPromises);
  const filesByCategories = filesByCategoriesResults.flat().filter((r) => !isNil(r)) as FileCategoryTypeForm[];

  if (filesByCategories.filter((f) => f.category.fileCategory === FileCategory.PEB).length === 0) {
    filesByCategories.push({
      category: getDocumentCategory(FileCategory.PEB),
      files: [],
      unitId,
      ignore: false,
    });
    filesByCategories.push({
      category: getDocumentCategory(FileCategory.PEB),
      files: [],
      unitId: null,
      ignore: false,
    });
  }
  return {
    smokeDetectors,
    originalSmokeDetectors: smokeDetectors,
    technics: technicsWithMaintenanceAndFiles,
    originalTechnics: technicsWithMaintenanceAndFiles,
    includeFuelTank: !isEmpty(fuelTanks),
    includeHeating: !isEmpty(heating),
    includeChimney: !isEmpty(chimneys),
    filesByCategories,
    originalFilesByCategories: filesByCategories,
    originalUtilityProviders: utilityProviders,
  };
};

const handleContractsDocumentsCreationAndDeletion = async (
  values: AddLeaseFormValues,
  leaseId: string,
  createFile: FilesContext['createFile'],
  deleteFile: FilesContext['deleteFile'],
  getDocumentCategoryByFileCategory: FileCategoryContext['getDocumentCategoryByFileCategory']
) => {
  const contract = values.contract;
  const originalContract = values.originalContract;
  const contractAmendment = values.contractAmendment;
  const originalContractAmendment = values.originalContractAmendment;

  // Check if we need to create new files
  const filesPromises: Promise<FileModel | null>[] = [];
  if (
    !isNilOrEmpty(contract.files) &&
    isNil((contract.files[0] as S3Object).id) &&
    !isNil((contract.files[0] as NewFile).file)
  ) {
    filesPromises.push(
      createFile(
        (contract.files[0] as NewFile).file,
        EntityType.LEASE,
        leaseId,
        getDocumentCategoryByFileCategory(FileCategory.LEASE_CONTRACT)?.id
      )
    );
  }
  if (!isNilOrEmpty(originalContract.files) && isNilOrEmpty(contract.files)) {
    // Delete contract
    filesPromises.push(deleteFile(originalContract.files[0] as S3Object));
  }

  contractAmendment.files.forEach((contractAmendmentFile) => {
    const contractAmendmentFileNewFile: NewFile = contractAmendmentFile as NewFile;
    if (isNil((contractAmendmentFile as S3Object).id) && !isNil(contractAmendmentFileNewFile.file)) {
      filesPromises.push(
        createFile(
          contractAmendmentFileNewFile.file,
          EntityType.LEASE,
          leaseId,
          getDocumentCategoryByFileCategory(FileCategory.CONTRACT)?.id
        )
      );
    }
  });
  originalContractAmendment.files.forEach((contractAmendmentFile) => {
    const found = contractAmendment.files.find(
      (file) => (file as S3Object).id === (contractAmendmentFile as S3Object).id
    );
    if (!found) {
      filesPromises.push(deleteFile(contractAmendmentFile as S3Object));
    }
  });
  await Promise.all(filesPromises);
};

const handleCreationAndDeletionOfUnitsAndSubUnits = async (
  allUnitIds: string[],
  lease: Lease,
  newLease: boolean,
  getLease: LeaseContext['getLease'],
  createUnitLease: LeaseContext['createUnitLease'],
  updateUnitLease: LeaseContext['updateUnitLease'],
  deleteUnitLease: LeaseContext['deleteUnitLease'],
  getUnit: UnitContext['getUnit'],
  values: AddLeaseFormValues
): Promise<UnitLease[]> => {
  const actualLease = getLease(lease.id);
  // Should never happen.
  if (isNil(actualLease) && !newLease) return [];

  const [unitLeaseToCheckForUpdates, unitLeaseToDelete] = (actualLease?.units ?? []).reduce(
    (acc, currentUnitLease) => {
      const isUnitLeaseStillInList = allUnitIds.includes(currentUnitLease.unit!.id);
      if (isUnitLeaseStillInList) {
        acc[0].push(currentUnitLease);
        return acc;
      }
      acc[1].push(currentUnitLease);
      return acc;
    },
    [[], []] as [UnitLease[], UnitLease[]]
  );

  const unitIdsThatStillNeedToBeCreatedAsUnitLeases = allUnitIds.filter((unitId) =>
    isNil(
      // actualLease is Nil at creation of lease.
      (actualLease?.units ?? []).find((currentUnitLease) => currentUnitLease.unit!.id === unitId)
    )
  );
  // ---------------Deletes---------------

  const unitLeaseDeletePromises = unitLeaseToDelete.map((currentUnitLease) => deleteUnitLease(currentUnitLease.id));
  await Promise.all(unitLeaseDeletePromises);

  // ---------------Creations---------------

  const unitLeasePromises = unitIdsThatStillNeedToBeCreatedAsUnitLeases.map((currentUnitId) => {
    const actualUnit = getUnit(currentUnitId);
    const { isMainUnit, prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, currentUnitId);

    const prices = getPricesValuesForUnit(values, currentUnitId);

    const dates = getDatesValues(values, currentUnitId);

    return createUnitLease({
      ...prices,
      leaseId: lease.id,
      unitId: actualUnit!.id,
      ...dates,
      mainUnit: isMainUnit,
      authorizeProfessionalActivity: Boolean(
        get(values, `${prefixFieldName}profesionnalActivity.profesionnalActivity`)
      ),
      furnishedRental: Boolean(get(values, `${prefixFieldName}furnishedRental.furnishedRental`)),
      furnishedRentalInsuranceByTenant: Boolean(
        get(values, `${prefixFieldName}furnishedRental.funrituresShouldBeCoveredByInsurance`)
      ),
      furnishedRentalRentalPricePercentage: toNumber(
        get(values, `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`)
      ),
    });
  });

  // ---------------Updates---------------
  const unitLeaseToCheckForUpdatesPromises = unitLeaseToCheckForUpdates.map((currentUnitLeaseToUpdate) => {
    return possiblyUpdateUnitLease(currentUnitLeaseToUpdate, actualLease as Lease, getUnit, updateUnitLease, values);
  });

  const unitLeaseResults = await Promise.all([...unitLeaseToCheckForUpdatesPromises, ...unitLeasePromises]);

  return unitLeaseResults.filter((unitLeaseCreated) => !isNil(unitLeaseCreated)) as UnitLease[];
};

const possiblyUpdateUnitLease = async (
  currentUnitLeaseToUpdate: UnitLease,
  lease: Lease,
  getUnit: UnitContext['getUnit'],
  updateUnitLease: LeaseContext['updateUnitLease'],
  values: AddLeaseFormValues
) => {
  const currentUnitId = currentUnitLeaseToUpdate.unit!.id;
  const actualUnit = getUnit(currentUnitId);
  const { isMainUnit, prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, currentUnitId);

  const prices = getPricesValuesForUnit(values, currentUnitId);
  const dates = getDatesValues(values, currentUnitId);

  const potentialReplacementData = {
    ...prices,
    leaseId: lease.id,
    unitId: actualUnit!.id,
    ...dates,
    mainUnit: isMainUnit,
    authorizeProfessionalActivity: Boolean(get(values, `${prefixFieldName}profesionnalActivity.profesionnalActivity`)),
    furnishedRental: Boolean(get(values, `${prefixFieldName}furnishedRental.furnishedRental`)),
    furnishedRentalInsuranceByTenant: Boolean(
      get(values, `${prefixFieldName}furnishedRental.funrituresShouldBeCoveredByInsurance`)
    ),
    furnishedRentalRentalPricePercentage: toNumber(
      get(values, `${prefixFieldName}furnishedRental.percentageOfRentAssignedToFurnitures`)
    ),
  };

  const {
    endDate,
    initialMonthlyCharges,
    initialRentalPrice,
    mainUnit,
    monthlyChargesType,
    monthlyCharges,
    allowChargeAdjustment,
    rentalPrice,
    signatureDate,
    startDate,
    unit,
  } = currentUnitLeaseToUpdate;

  const actualData = {
    endDate,
    initialMonthlyCharges,
    initialRentalPrice,
    mainUnit,
    monthlyChargesType,
    allowChargeAdjustment,
    monthlyCharges,
    rentalPrice,
    signatureDate,
    startDate,
    unit,
  };

  if (!isEqual(potentialReplacementData, actualData)) {
    return await updateUnitLease(currentUnitLeaseToUpdate, potentialReplacementData);
  }
  return null;
};

export const useAddEditLeaseUtils = () => {
  const { setValues, values, touched } = useFormikContext<AddLeaseFormValues>();
  const { getUnit, updateUnit } = useUnits();
  const { formatMessage } = useIntl();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { createTechnic, updateTechnic, technics, deepDeleteTechnic, deleteTechnic } = useTechnics();
  const { createFile, deleteFile, updateFile } = useFiles();
  const { createUnitInventory, deleteUnitInventory } = useUnitInventories();
  const { getContact, inviteContactToClientAccount } = useContacts();
  const { getClientContact } = useUsers();
  const { deepDeleteLeaseInventoryEncoding } = useLeaseInventories();
  const { updateAddress, getBuilding } = useBuildings();
  const { createInvoice, deleteInvoice } = useInvoices();
  const { createPosting, getAccountLabel, deletePosting, accountLabels } = useTransactions();
  const {
    createLease,
    createLeaseContact,
    createUnitLease,
    createAdditionalClause,
    getLease,
    updateLease,
    deleteLeaseContact,
    deleteUnitLease,
    updateUnitLease,
    getUnitsOfLease,
  } = useLeases();
  const { createLeasePriceHistory, updateLeasePriceHistory, getLeasePriceHistoriesOfLease } = useLeasePriceHistories();
  const { createLeaseActionHistory, updateLeaseActionHistory, getLeaseActionHistoriesOfLease } =
    useLeaseActionHistories();
  const {
    createLeaseVariousOperation,
    updateLeaseVariousOperation,
    deleteLeaseVariousOperation,
    getLeaseVariousOperationsFromLease,
  } = useLeaseVariousOperations();
  const { memberId } = useUser();
  const history = useHistory();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const { updateEntityCommunicationSettingsProfile } = useCommunicationSettingsProfileUtils();
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  const getSubStepRentFormsDependingOnUnit = useCallback(
    (errorInStep: boolean, currentUnitId: string) => {
      const { isMainUnit, prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, currentUnitId);
      const fullUnit = getUnit(currentUnitId);

      // Handled in getSubUnitSchema for subUnits
      const surfaceValidationSchema = isMainUnit ? SurfaceSchema : EmptySchema;
      return {
        name: 'RentForm',
        labelId: 'lease.addLease.mainUnit',
        errorInSubStep: errorInStep,
        components: [
          {
            component: KeyDates,
            validationSchema: KeyDatesSchema,
            alreadyInContainer: true,
            componentProps: { isMainUnit, unitId: currentUnitId },
          },
          {
            component: VatManagementForm,
            validationSchema: VatManagementSchema,
            alreadyInContainer: true,
            componentProps: { isMainUnit, unitId: currentUnitId },
          },
          {
            component: RentForm,
            validationSchema: getAddOperationCompleteSchemaDynamically(values.startDate, values.endDate),
            alreadyInContainer: true,
            componentProps: { unitId: currentUnitId, unitType: fullUnit!.type },
          },
          ...(![UnitType.PARKING].includes(fullUnit!.type as UnitType)
            ? [
                {
                  component: SurfaceOfUnit,
                  componentProps: { fieldName: `${prefixFieldName}surface` },
                  validationSchema: surfaceValidationSchema,
                },
              ]
            : []),
          ...(![UnitType.PARKING, UnitType.OTHER].includes(fullUnit!.type as UnitType)
            ? [
                {
                  component: UnitStructureForm,
                  validationSchema: UnitInventoriesSchema,
                  alreadyInContainer: true,
                  componentProps: {
                    helpIcon: true,
                    unitId: currentUnitId,
                    unitType: fullUnit!.type,
                  },
                },
              ]
            : []),
          ...(doesUnitTypeHaveSmokeDetectors(fullUnit!.type as UnitType)
            ? [
                {
                  component: SmokeDetectorsForm,
                  validationSchema: getDynamicSmokeDetectorsSchema(),
                  componentProps: {
                    unitId: currentUnitId,
                  },
                },
              ]
            : []),
          ...(isEpbUsefulForLease(fullUnit!.type as UnitType)
            ? [
                {
                  component: LeasePEBForm,
                  componentProps: {
                    unitId: currentUnitId,
                  },
                  alreadyInContainer: true,
                  validationSchema: FileSchema,
                },
              ]
            : []),
        ],
        secondaryTitle: formatMessage({ id: `lease.addLease.${isMainUnit ? 'mainUnit' : 'subUnit'}` }, { value: 1 }),
        mainTitle: fullUnit!.name ?? '',
        endAdornment: getUnitIcon(fullUnit?.type as UnitType, { width: 24, height: 24 }),
      };
    },
    [formatMessage, getUnit, values]
  );

  const getMonthlyChargesFieldsAndUnit = (unit: Unit, originalBuilding: Building) => {
    const unitChargePrice = unit.advertisedMonthlyCharges;
    return {
      monthlyChargesFields: {
        allowChargeAdjustment: true,
        monthlyCharges: unitChargePrice ?? 0,
        monthlyChargesType: LeaseMonthlyChargesType.MonthlyProvisioned,
      },
      includeMonthlyCharge: !isNil(unitChargePrice),
      initialChargePrice: unitChargePrice ?? 0,
      unit: {
        id: unit.id,
        type: unit.type,
        building: {
          id: unit.building!.id,
          ...(!isNil(originalBuilding?.address?.region)
            ? { address: originalBuilding!.address! }
            : { address: { region: '' } }),
        },
      },
      surface: unit.surface,
    };
  };

  const onChangeUnit = useCallback(
    async (unit?: Unit) => {
      if (isNil(unit)) return;
      // Reset some values
      const valuesToReset = {
        history: [],
        openBalance: 0,
        overlappingNonEditedEvent: null,
        leaseOperations: [],
        discounts: [],
        lessors: [{ id: '' }],
      };

      let conditionalValues = {};
      const ownerId = getOwnerIdOfUnit(unit, getBuilding);
      const unitOwnerId = ownerId;
      const additionalUnit = false;
      const subUnits = [] as AdditionalUnit[];

      // eslint-disable-next-line default-case
      switch (unit?.type) {
        case UnitType.RESIDENTIAL:
        case UnitType.COMMERCIAL:
        case UnitType.STUDENT:
        case UnitType.OFFICE:
          conditionalValues = { ...conditionalValues, includeMonthlyCharge: true };
          break;
        case UnitType.PARKING:
        case UnitType.OTHER:
          conditionalValues = { ...conditionalValues, includeMonthlyCharge: false };
          break;
      }

      if (!isNil(unit)) {
        let monthlyChargesFieldsAndUnit = {};
        const leaseType =
          unit.type === UnitType.PARKING
            ? LeaseType.PARKING
            : [UnitType.COMMERCIAL, UnitType.OFFICE].includes(unit.type as UnitType)
            ? LeaseType.COMMERCIAL
            : unit.type === UnitType.STUDENT
            ? LeaseType.STUDENT
            : LeaseType.MAIN_RESIDENCE_9_YEARS;
        if (!isNil(unit.building)) {
          const originalBuilding = getBuilding(unit.building.id!);
          monthlyChargesFieldsAndUnit = getMonthlyChargesFieldsAndUnit(unit, originalBuilding!);
          if (originalBuilding?.address?.country === 'BE') {
            conditionalValues = {
              ...conditionalValues,
              legalCompetentAuthority: formatMessage({ id: 'lease.addLease.defaultCompetentAuthority' }),
            };
          }
        }
        let firstDayOfLease = new Date();
        if (!isNil(unit.leases) && !isEmpty(unit.leases)) {
          const maxEndDate = maxBy([...unit.leases.map((unitLease) => unitLease.lease), ...unit.events!], 'endDate');
          if (maxEndDate && maxEndDate.endDate) {
            firstDayOfLease = addMilliseconds(new Date(maxEndDate!.endDate!), 1);
            conditionalValues = { ...conditionalValues, startDate: firstDayOfLease };
          }
        }
        const endDate = getLegalLeaseEndDate(leaseType, firstDayOfLease, true);

        // Unit inventories
        const unitInventories = getUnitInventoriesFor(unit.id, new Date());
        const originalUnitInventories = unitInventories;
        const unitStructures = getUnitInventoriesGroupedByType(unitInventories);

        if (!touched.rentalPrice && !isNil(unit.advertisedRentalPrice)) {
          const rentalPrice = unit.advertisedRentalPrice;
          const initialRentalPrice = rentalPrice;
          const month = leaseType === LeaseType.COMMERCIAL ? 6 : 2;
          conditionalValues = {
            ...conditionalValues,
            initialRentalPrice,
            rentalPrice,
            guarantee: {
              hasGuarantee: true,
              selectedTab: 'amountOfMonths',
              rentalGuarantyType: 'BANK_BLOCKED',
              rentalGuarantyAmount: unit.advertisedRentalPrice * month,
              rentalGuarantyAmountSetByUser: -1,
              rentalGuarantyFinancialInstitution: '',
              includeChargesInAmount: false,
              amountOfMonths: month,
            },
          };
        }

        const filesAndTechnics = await getTechnicsAndFilesByCategoriesInitValuesForUnit(
          technics,
          unit.id,
          unit.building!.id,
          undefined,
          false,
          true,
          getDocumentCategoryByFileCategory
        );

        const finalNewValues = {
          ...values,
          ...valuesToReset,
          ...(!isNil(unitOwnerId) ? { unitOwnerId } : {}),
          ...conditionalValues,
          ...monthlyChargesFieldsAndUnit, // Placed after conditional to override some values if needed
          additionalUnit: additionalUnit,
          subUnits,
          type: leaseType,
          endDate,
          originalUnitInventories,
          unitStructures,
          ...filesAndTechnics,
        };
        setValues(finalNewValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, getBuilding, getUnitInventoriesFor, technics, touched.rentalPrice, setValues, values]
  );

  const getTechnicPromise = (
    allUnitIds: string[],
    lease: Lease,
    unitInventoriesIdsDic: {
      [key: string]: string;
    }[],
    fieldName: 'smokeDetectors' | 'filesByCategories' | 'technics' | 'other',
    originalFieldName:
      | 'originalSmokeDetectors'
      | 'originalFilesByCategories'
      | 'originalTechnics'
      | 'originalUtilityProviders',
    isNewLease: boolean
  ) => {
    return allUnitIds.map((currentId, index) => {
      const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, currentId);
      const currentUnit = getUnit(currentId);
      const currentBuilding = getBuilding(currentUnit!.building!.id);

      const targetedElement = get(values, `${prefixFieldName}${fieldName}`)!;
      const originalTargetedElement = get(values, `${prefixFieldName}${originalFieldName}`)!;

      if (fieldName === 'smokeDetectors') {
        return handleAddEditSmokeDetectors(
          targetedElement,
          originalTargetedElement,
          lease,
          currentUnit!,
          unitInventoriesIdsDic[index],
          createTechnic,
          deleteTechnic
        );
      }

      if (fieldName === 'filesByCategories') {
        if (isNewLease) {
          return handleFilesByCategory(
            targetedElement,
            originalTargetedElement,
            currentUnit!,
            currentBuilding!,
            lease,
            // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
            defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
            createTechnic,
            createFile
          );
        }
        return handleEditFilesByCategory(
          targetedElement,
          originalTargetedElement,
          currentUnit!,
          currentBuilding!,
          lease,
          // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
          defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
          createTechnic,
          deleteTechnic,
          createFile,
          updateFile,
          deleteFile
        );
      }

      // Utility providers
      if (originalFieldName === 'originalUtilityProviders') {
        return handleAddUtilityProvidersToLeaseId(originalTargetedElement, lease, createTechnic);
      }

      return handleAddEditTechnicWithMaintenance(
        get(values, `${prefixFieldName}technics`)!,
        get(values, `${prefixFieldName}originalTechnics`)!,
        lease,
        get(values, `${prefixFieldName}includeChimney`)!,
        get(values, `${prefixFieldName}includeFuelTank`)!,
        get(values, `${prefixFieldName}includeHeating`)!,
        createFile,
        createTechnic,
        updateTechnic,
        getDocumentCategoryByFileCategory,
        unitInventoriesIdsDic[index],
        deepDeleteTechnic
      );
    });
  };

  const createNewLease = async (values: AddLeaseFormValues, status: LeaseStatus, invoiceSenderId: string) => {
    const {
      applyVat,
      bankAccountId,
      includeInventoryFixtureInLease,
      inventoryOfFixturesType,
      inventoryOfFixturesMethod,
      inventoryOfFixturesExpert,
      monthlyChargesType,
      type,
      vatRateCharge,
      vatRateRent,
      language,
      guarantee,
      openBalance,
      includeRealEstateInLeaseContract,
      legalCompetentAuthority,
      leaseName,
      remittanceInformation,
      unit,
      newLeaseId,
    } = values;

    const communicationSettingsProfileId = await updateEntityCommunicationSettingsProfile(values);

    const lastInvoiceDate = getLastInvoiceDate(values);
    const { initialChargePrice, initialRentalPrice, monthlyCharges, rentalPrice } = getLeasePricesValues(values);

    const dates = getDatesValues(values, unit!.id!);
    const { paymentFrequency, paymentInvoicePeriod, paymentInvoicePeriodCustomDay } = getPaymentsFields(values);

    // Create lease
    const lease = await createLease({
      id: newLeaseId!,
      balance: openBalance,
      includeRealEstateInLeaseContract,
      inventoryOfFixturesType: includeInventoryFixtureInLease ? inventoryOfFixturesType : null,
      inventoryOfFixturesMethod: includeInventoryFixtureInLease ? inventoryOfFixturesMethod : null,
      inventoryOfFixturesExpert: includeInventoryFixtureInLease ? inventoryOfFixturesExpert : null,
      language: toUpper(language) as Language,
      legalCompetentAuthority,
      name: leaseName,
      paymentFrequency,
      paymentInvoicePeriod,
      paymentInvoicePeriodCustomDay,
      remittanceInformation,
      rentalGuarantyAmount: values.guarantee.hasGuarantee
        ? values.guarantee.selectedTab === 'amountOfMonths'
          ? values.guarantee.rentalGuarantyAmount
          : values.guarantee.rentalGuarantyAmountSetByUser
        : 0,
      rentalGuarantyFinancialInstitution: guarantee.rentalGuarantyFinancialInstitution,
      rentalGuarantyType: guarantee.rentalGuarantyType as LeaseRentalGuarantyType,
      status,
      totalInitialRentalPrice: initialRentalPrice,
      totalInitialMonthlyCharges: initialChargePrice,
      totalMonthlyCharges: monthlyCharges,
      totalRentalPrice: rentalPrice,
      vatRateCharge: values.applyVat ? 21 : undefined,
      vatRateRent: values.applyVat ? 21 : undefined,
      lastInvoiceDate,
      communicationSettingsProfileId,
      // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
      technicCommunicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
      type: type as LeaseType,
      ...(applyVat && { vatRateRent }),
      bankAccountId,
      ...(applyVat && monthlyChargesType !== LeaseMonthlyChargesType.MonthlyProvisioned && { vatRateCharge }),
      invoiceSenderId,
      ...dates,
    });

    return lease;
  };

  const updateExistingLease = async (values: AddLeaseFormValues, status: LeaseStatus, invoiceSenderId: string) => {
    const {
      applyVat,
      bankAccountId,
      id,
      includeInventoryFixtureInLease,
      inventoryOfFixturesType,
      inventoryOfFixturesMethod,
      inventoryOfFixturesExpert,
      monthlyChargesType,
      type,
      vatRateCharge,
      vatRateRent,
      language,
      guarantee,
      openBalance,
      includeRealEstateInLeaseContract,
      legalCompetentAuthority,
      leaseName,
      remittanceInformation,
      unit,
    } = values;
    const currentLease = getLease(id!);

    const communicationSettingsProfileId = await updateEntityCommunicationSettingsProfile(
      values,
      currentLease?.communicationSettingsProfileId
    );

    const lastInvoiceDate = getLastInvoiceDate(values);
    const { initialChargePrice, initialRentalPrice, monthlyCharges, rentalPrice } = getLeasePricesValues(values);
    const { paymentFrequency, paymentInvoicePeriod, paymentInvoicePeriodCustomDay } = getPaymentsFields(values);
    const dates = getDatesValues(values, unit!.id!);
    const lease = await updateLease(currentLease!, {
      // No need for ID, appsync will do it.
      balance: openBalance,
      includeRealEstateInLeaseContract,
      inventoryOfFixturesType: includeInventoryFixtureInLease ? inventoryOfFixturesType : null,
      inventoryOfFixturesMethod: includeInventoryFixtureInLease ? inventoryOfFixturesMethod : null,
      inventoryOfFixturesExpert: includeInventoryFixtureInLease ? inventoryOfFixturesExpert : null,
      language: toUpper(language) as Language,
      legalCompetentAuthority,
      name: leaseName,
      paymentFrequency,
      paymentInvoicePeriod,
      paymentInvoicePeriodCustomDay,
      remittanceInformation,
      rentalGuarantyAmount: values.guarantee.hasGuarantee
        ? values.guarantee.selectedTab === 'amountOfMonths'
          ? values.guarantee.rentalGuarantyAmount
          : values.guarantee.rentalGuarantyAmountSetByUser
        : 0,
      rentalGuarantyFinancialInstitution: guarantee.rentalGuarantyFinancialInstitution,
      rentalGuarantyType: guarantee.rentalGuarantyType as LeaseRentalGuarantyType,
      totalInitialRentalPrice: initialRentalPrice,
      totalInitialMonthlyCharges: monthlyCharges,
      totalMonthlyCharges: initialChargePrice,
      totalRentalPrice: rentalPrice,
      vatRateCharge: values.applyVat ? 21 : undefined,
      vatRateRent: values.applyVat ? 21 : undefined,
      status,
      lastInvoiceDate,
      type: type as LeaseType,
      ...(applyVat && { vatRateRent }),
      bankAccountId,
      ...(applyVat && monthlyChargesType !== LeaseMonthlyChargesType.MonthlyProvisioned && { vatRateCharge }),
      invoiceSenderId,
      communicationSettingsProfileId,
      // Dates
      ...dates,
    });

    return lease;
  };

  const inviteNeededTenantsToAccount = async (tenants: Contact[], language?: string) => {
    const clientContact = getClientContact();
    const inviteTenantsPromises = tenants.map((tenant) => {
      if (tenant.sendInvitation) {
        inviteContactToClientAccount(tenant, clientContact!, language);
      }
    });
    await Promise.all(inviteTenantsPromises);
  };

  const handleLeaseAction = (newLease: boolean, leaseId: string, unitLeases: UnitLease[]) => {
    const historyDetails = unitLeases.map((unitLease) => ({
      unitId: unitLease!.unit!.id,
      previousStartDate: unitLease!.startDate,
      newStartDate: unitLease!.startDate,
      previousEndDate: unitLease!.endDate,
      newEndDate: unitLease!.endDate,
    }));

    if (!newLease) {
      const leaseActionHistories = getLeaseActionHistoriesOfLease(leaseId!);
      if (!isNilOrEmpty(leaseActionHistories)) {
        // There is only one possible action history for draft lease
        const { id, _version } = leaseActionHistories[0] as ModelWithVersion<LeaseActionHistory>;
        return updateLeaseActionHistory({
          id,
          _version,
          historyDetails,
        });
      }
    }
    return createLeaseActionHistory({
      contactId: memberId!,
      action: LeaseAction.INITIAL_DATE,
      leaseId,
      historyDetails,
    });
  };

  const handleCreationAndDeletionOfContacts = async (
    lease: Lease,
    guarantors: Contact[],
    tenants: Contact[],
    lessors: Contact[],
    newLease: boolean
  ) => {
    // Lease is new, we just create all these contacts since they do not exist yet.
    const lessorsSplit = lessors.reduce(
      (result, currentLessor) => {
        const contact = getContact(currentLessor.id);
        if (!contact) return result; // Should never happen
        // Priority is owner
        if (contactContainsType(contact, ContactType.OWNER)) {
          result.owners.push(contact);
          return result;
        }

        result.members.push(contact);
        return result;
      },
      { owners: [], members: [] } as { owners: Contact[]; members: Contact[] }
    );

    if (newLease) {
      const contactPromise = [
        { contacts: guarantors, type: ContactType.GUARANTOR },
        { contacts: tenants, type: ContactType.TENANT },
        { contacts: lessorsSplit.owners, type: ContactType.OWNER },
        { contacts: lessorsSplit.members, type: ContactType.MEMBER },
      ].map((currentGroup) => {
        return createLeaseContacts(currentGroup.contacts, currentGroup.type, lease, getContact, createLeaseContact);
      });
      const flattedResults = contactPromise.flat();

      return await Promise.all(flattedResults);
    }

    // lease is not new, contacts might have been modified.
    const actualLease = getLease(lease.id);
    if (isNil(actualLease) || isNil(actualLease.contacts)) return;
    // Find the contacts that shall be deleted and delete it
    const contactPromises = actualLease.contacts.reduce((acc, currentContact) => {
      const contactAlreadyExists =
        guarantors.find((guarantor) => guarantor.id === currentContact.contact!.id!) ||
        tenants.find((tenant) => tenant.id === currentContact.contact!.id) ||
        lessors.find((lessor) => lessor.id === currentContact.contact!.id);
      if (!contactAlreadyExists) {
        acc.push(deleteLeaseContact(currentContact.id));
      }
      return acc;
    }, [] as Promise<LeaseContact | null>[]);

    // Add contacts that do not exist yet.
    const filteredContacts = [
      { contacts: guarantors, type: ContactType.GUARANTOR },
      { contacts: tenants, type: ContactType.TENANT },
      { contacts: lessorsSplit.owners, type: ContactType.OWNER },
      { contacts: lessorsSplit.members, type: ContactType.MEMBER },
    ].reduce(
      (acc, currentGroup, index) => {
        const contactToBeCreated = currentGroup.contacts.filter((currentGroupContact) => {
          return isNil(
            actualLease.contacts?.find((leaseContact) => leaseContact.contact!.id === currentGroupContact.id)
          );
        });
        acc[index].contacts = contactToBeCreated;
        return acc;
      },
      [
        { contacts: [], type: ContactType.GUARANTOR },
        { contacts: [], type: ContactType.TENANT },
        { contacts: [], type: ContactType.OWNER },
        { contacts: [], type: ContactType.MEMBER },
      ] as { contacts: Contact[]; type: ContactType }[]
    );

    filteredContacts.forEach((currentGroup) => {
      contactPromises.push(
        ...createLeaseContacts(currentGroup.contacts, currentGroup.type, lease, getContact, createLeaseContact)
      );
    });

    return await Promise.all(contactPromises);
  };

  const handleSurfaceUpdateOfUnits = async (allUnitIds: string[]) => {
    const updateUnitPromises = allUnitIds.reduce((acc, currentUnitId) => {
      const currentUnit = getUnit(currentUnitId);
      if (!currentUnit) {
        return acc;
      }
      const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, currentUnitId);

      const surfaceInForm = get(values, `${prefixFieldName}surface`);

      if (surfaceInForm && surfaceInForm !== currentUnit.surface && surfaceInForm !== 0) {
        acc.push(updateUnit(currentUnit, { surface: surfaceInForm }));
      }

      return acc;
    }, [] as Promise<Unit>[]);

    await Promise.all(updateUnitPromises);
  };

  const handleUnitInventoriesForAllUnits = (
    allUnitIds: string[],
    lease: Lease,
    unitStructures: UnitStructure[],
    originalUnitInventories: UnitInventory[] | undefined
  ) => {
    const unitInventoriesPromise = allUnitIds.map((_id, index) => {
      const isMainUnit = index === 0;
      return handleCreationAndDeletionOfUnitInventories(
        lease,
        isMainUnit ? unitStructures : values.subUnits[index - 1].unitStructures,
        isMainUnit ? originalUnitInventories : values.subUnits[index - 1].originalUnitInventories,
        createUnitInventory,
        deleteUnitInventory,
        deepDeleteLeaseInventoryEncoding
      );
    });
    return unitInventoriesPromise;
  };

  const handleCreationAndDeletionOfTechnics = (
    allUnitIds: string[],
    lease: Lease,
    unitInventoriesIdsDic: {
      [key: string]: string;
    }[],
    isNewLease: boolean
  ) => {
    const fileByCategoryPromises = getTechnicPromise(
      allUnitIds,
      lease,
      unitInventoriesIdsDic,
      'filesByCategories',
      'originalFilesByCategories',
      isNewLease
    );
    const smokeDetectorsPromises = getTechnicPromise(
      allUnitIds,
      lease,
      unitInventoriesIdsDic,
      'smokeDetectors',
      'originalSmokeDetectors',
      isNewLease
    );
    const technicsWithMaintenancePromises = getTechnicPromise(
      allUnitIds,
      lease,
      unitInventoriesIdsDic,
      'technics',
      'originalTechnics',
      isNewLease
    );
    const utilityProvidersPromises = getTechnicPromise(
      allUnitIds,
      lease,
      unitInventoriesIdsDic,
      'other',
      'originalUtilityProviders',
      isNewLease
    );

    return {
      fileByCategoryPromises,
      smokeDetectorsPromises,
      technicsWithMaintenancePromises,
      utilityProvidersPromises,
    };
  };

  const handleCreationAndDeletionOfLeaseVariousOperations = (lease: Lease) => {
    const allOperations = [
      ...values.leaseOperations,
      ...values.subUnits.map((subUnit) => subUnit.leaseOperations).flat(),
    ];
    const allDiscounts = [...values.discounts, ...values.subUnits.map((subUnit) => subUnit.discounts).flat()];
    const operationsAndDiscount = [...allOperations, ...allDiscounts];

    // Get original Lease various operations
    const originalLeaseVariousOperations = getLeaseVariousOperationsFromLease(lease.id) ?? [];

    const [operationToCheckForUpdate, operationToDelete] = originalLeaseVariousOperations.reduce(
      (acc, currentOperation) => {
        const isOperationInList = operationsAndDiscount.some(
          (operationOrDiscount) => operationOrDiscount.id === currentOperation.id
        );
        if (isOperationInList) {
          acc[0].push(currentOperation);
        } else {
          acc[1].push(currentOperation);
        }
        return acc;
      },
      [[], []] as [LeaseVariousOperation[], LeaseVariousOperation[]]
    );

    const operationThatNeedsToBeCreated = operationsAndDiscount.filter((operationOrDiscount) =>
      isNilOrEmpty(operationOrDiscount.id)
    );

    // Create
    const leaseVariousOperationsCreatePromise = operationThatNeedsToBeCreated.map((operation) => {
      const operationValues = getLeaseVariousOperationValues(values, operation);

      return createLeaseVariousOperation({
        ...operationValues,
        contactId: memberId!,
        leaseId: (lease as Lease).id,
        unitId: operation.unitId,
      });
    });

    // Update
    const leaseVariousOperationsUpdatePromise = operationToCheckForUpdate.reduce((acc, currentOperation) => {
      const operation = operationsAndDiscount.find(
        (operationOrDiscount) => operationOrDiscount.id === currentOperation.id
      );
      if (!operation) {
        return acc;
      }
      const operationValues = getLeaseVariousOperationValues(values, operation);
      const originalValues = {
        amount: currentOperation.amount,
        endDate: currentOperation.endDate,
        label: currentOperation.label,
        leaseVariousOperationHistories: currentOperation.leaseVariousOperationHistories,
        lastInvoiceDate: currentOperation.lastInvoiceDate,
        operationType: currentOperation.operationType,
        paymentFrequency: currentOperation.paymentFrequency,
        paymentInvoicePeriod: currentOperation.paymentInvoicePeriod,
        paymentInvoicePeriodCustomDay: currentOperation.paymentInvoicePeriodCustomDay,
        reason: currentOperation.reason,
        separateInvoice: currentOperation.separateInvoice,
        startDate: currentOperation.startDate,
        type: currentOperation.type,
      };
      if (!isEqual(operationValues, originalValues)) {
        const { id, _version } = currentOperation as ModelWithVersion<LeaseVariousOperation>;
        acc.push(updateLeaseVariousOperation({ id, _version, ...operationValues }));
      }
      return acc;
    }, [] as Promise<LeaseVariousOperation>[]);

    // Delete
    const leaseVariousOperationsDeletePromise = operationToDelete.map((operation) =>
      deleteLeaseVariousOperation(operation)
    );

    return [
      ...leaseVariousOperationsCreatePromise,
      ...leaseVariousOperationsUpdatePromise,
      ...leaseVariousOperationsDeletePromise,
    ];
  };

  /**
   * We are regenerating the invoice on each save to avoid data changes problems (vat, tenant,...)
   * @param lease
   * @returns created invoice
   */
  const handleHistoricInvoiceIfNeeded = async (
    lease: Lease,
    deleteExistingInvoices: boolean = true,
    invoiceIsPaid: boolean = false
  ) => {
    const leaseId = lease.id;
    const today = new Date();

    const currentUnitLease = getUnitsOfLease(leaseId);
    const invoices = await fetchInvoices('byLease', leaseId);

    if (!isNil(invoices[0]) && deleteExistingInvoices) {
      const leasePostings = await fetchPostings('byInvoice', invoices[0]?.id ?? '');
      const deletePostingsPromise = leasePostings.map((posting) => deletePosting(posting));
      await Promise.all(deletePostingsPromise);
      await deleteInvoice(invoices[0]);
    }

    const minDate = minBy(values.history, 'dueDate');
    const minDueDate = startOfUTCDay(new Date(minDate?.dueDate ?? today)).toISOString();
    const invoiceId = uuidV4();
    const unitId = currentUnitLease.find((unit) => unit!.mainUnit)?.unit?.id ?? '';
    const postingPromises = values.history.map((history) => {
      const currentAccountLabel = getAccountLabel(history.transactionType);
      const vatRate = getVatToApply(lease, currentAccountLabel?.class ?? 0);
      const vatAmount = vatRate ? roundAtSecondDecimal((vatRate * history.amount) / 100) : 0;
      const totalAmount = roundAtSecondDecimal(history.amount + vatAmount);
      const amountVatExcluded = roundAtSecondDecimal(history.amount);

      const periodFrom = startOfUTCMonth(new Date(history.dueDate));

      return createPosting({
        class: currentAccountLabel?.class!,
        topClass: currentAccountLabel?.topClass!,
        accountLabelId: currentAccountLabel?.id,
        leaseId,
        unitId: history.unitId,
        invoiceId,
        type: PostingType.CREDIT,
        totalAmount,
        amountVatExcluded,
        vatAmount,
        vatRate,
        customLabel: history.label,
        periodFrom: periodFrom.toISOString(),
        periodTo: getInvoicePeriodTo(
          periodFrom,
          lease.paymentFrequency,
          lease.paymentInvoicePeriod,
          lease.paymentInvoicePeriodCustomDay
        ).toISOString(),
        createdAt: today.toISOString(),
      });
    });
    const postings = await Promise.all(postingPromises);
    let createdInvoice: Invoice | undefined;
    if (!isEmpty(values.history)) {
      const leaseAccountLabel = accountLabels.find((accountLabel) => {
        return accountLabel.class === ACCOUNT_LEASE_TOPCLASS;
      });
      const vatRate = getVatToApply(lease, leaseAccountLabel?.class ?? 0) ?? null;
      const totalAmount = roundAtSecondDecimal(sumBy(postings, 'totalAmount'));
      const amountVatExcluded = roundAtSecondDecimal(sumBy(postings, 'amountVatExcluded'));
      const vatAmount = totalAmount - amountVatExcluded;
      await createPosting({
        class: leaseAccountLabel?.class!,
        topClass: leaseAccountLabel?.topClass!,
        accountLabelId: leaseAccountLabel?.id,
        leaseId,
        unitId,
        invoiceId,
        type: PostingType.DEBIT,
        totalAmount,
        amountVatExcluded,
        vatAmount,
        vatRate,
        periodFrom: minDueDate,
        periodTo: getInvoicePeriodTo(
          new Date(minDueDate),
          lease.paymentFrequency,
          lease.paymentInvoicePeriod,
          lease.paymentInvoicePeriodCustomDay
        ).toISOString(),
        createdAt: today.toISOString(),
      });
      const totalAmountPosting = sumBy(postings, 'totalAmount');
      createdInvoice = await createInvoice({
        id: invoiceId,
        amount: totalAmountPosting,
        bankAccountId: (lease as Lease).bankAccountId!,
        paid: invoiceIsPaid,
        invoiceDate: today.toISOString(),
        type: InvoiceType.RENT,
        leaseId,
        dueDate: minDueDate,
        remittanceInformation: (lease as Lease).remittanceInformation,
        unitId,
        creditNote: totalAmountPosting < 0,
        createdAt: today.toISOString(),
      });
    }
    return createdInvoice;
  };

  const handleCreateLease = useCallback(
    async (
      values: AddLeaseFormValues,
      { setSubmitting, setStatus }: FormikHelpers<AddLeaseFormValues>,
      status: LeaseStatus
    ) => {
      const {
        additionalClauses,
        guarantors,
        id,
        lessors,
        newLease,
        originalUnitInventories,
        tenants,
        unit,
        unitStructures,
        subUnits,
      } = values;
      let lease: Lease | null = null;
      const originalUnit = getUnit(unit.id!);
      const invoiceSenderId = getInvoiceSenderIdOfUnitOrBuilding(originalUnit!, getBuilding);
      if (isNil(id)) {
        // eslint-disable-next-line max-len
        // TODO : the following try catch is a quick fix while waiting for the ticket REN-2657 (errors management) to be implemented
        try {
          lease = await createNewLease(values, status, invoiceSenderId);
        } catch (e) {
          const allErrors = (e as { errors: { errorType: string }[] }).errors;
          // To avoid duplicate lease
          if (allErrors.find((currentError) => currentError.errorType === 'ConditionalCheckFailedException')) {
            history.push(`${RouteDestination.LEASES}`);
            window.scrollTo(0, 0);
            return;
          }
          throw e;
        }
      } else {
        lease = await updateExistingLease(values, status, invoiceSenderId);
      }
      const allUnitIds = subUnits.reduce(
        (allIds, currentSubUnit) => [...allIds, ...(values.additionalUnit ? [currentSubUnit.id] : [])],
        [unit.id!] as string[]
      );

      const addLeasePromises: Promise<
        | File
        | FileModel
        | UnitLease
        | Unit[]
        | LeaseContact
        | LeaseAdditionalClause
        | void
        | UnitInventory
        | LeaseActionHistory
        | LeasePriceHistory
        | string
        | Technic
        | LeaseVariousOperation
        | Posting
        | null
      >[] = [];

      // Updating building region if needed
      if (!isNil(originalUnit) && !isNil(originalUnit.building)) {
        const originalBuilding = getBuilding(originalUnit.building.id);
        if (!isNil(originalBuilding)) {
          addLeasePromises.push(
            handleBuildingAddressUpdates(originalBuilding, unit.building as unknown as Building, updateAddress)
          );
        }
      }

      // ---------------------------------------------------Unit----------------------------

      await handleSurfaceUpdateOfUnits(allUnitIds);

      // ---------------------------------------------------Contacts----------------------------

      await handleCreationAndDeletionOfContacts(
        lease as Lease,
        guarantors as unknown as Contact[],
        tenants as unknown as Contact[],
        lessors as unknown as Contact[],
        newLease
      );

      if (status === LeaseStatus.Active) {
        const tenantsForInvite = tenants.map((tenant) => getContact(tenant.id)!);
        await inviteNeededTenantsToAccount(tenantsForInvite, lease.language);
      }

      // ---------------------------------------------------UnitLease-------------------------------

      const unitLeases = await handleCreationAndDeletionOfUnitsAndSubUnits(
        allUnitIds,
        lease as Lease,
        newLease,
        getLease,
        createUnitLease,
        updateUnitLease,
        deleteUnitLease,
        getUnit,
        values
      );

      // ---------------------------------------------------UnitsInventories-------------------------

      const unitInventoriesPromise = handleUnitInventoriesForAllUnits(
        allUnitIds,
        lease as Lease,
        unitStructures,
        originalUnitInventories
      );
      // Need to create all unitInventories before handling the technics
      const unitInventoriesIdsDic = await Promise.all(unitInventoriesPromise);

      // ---------------------------------------------------AdditionalClauses------------------------

      // Additional clauses
      const creationOfAdditionalClauses = createAdditionalClauses(
        additionalClauses,
        lease as Lease,
        createAdditionalClause
      );

      // ---------------------------------------------------Technics--------------------------------

      const {
        fileByCategoryPromises,
        smokeDetectorsPromises,
        technicsWithMaintenancePromises,
        utilityProvidersPromises,
      } = handleCreationAndDeletionOfTechnics(allUnitIds, lease as Lease, unitInventoriesIdsDic, isNil(id));

      // ---------------------------------------------------Lease Various Operations-------------------

      const leaseVariousOperationsPromise = handleCreationAndDeletionOfLeaseVariousOperations(lease as Lease);

      // ---------------------------------------------------Lease Contract----------------------------------

      await handleContractsDocumentsCreationAndDeletion(
        values,
        lease.id,
        createFile,
        deleteFile,
        getDocumentCategoryByFileCategory
      );

      // ---------------------------------------------------Lease Indexation--------------------------
      const leaseTotalPrices = getLeasePricesValues(values);
      const amountDetails = unitLeases.map((unitLease) => {
        const unitId = unitLease!.unit!.id;
        const unitPrices = getPricesValuesForUnit(values, unitId);

        return {
          unitId: unitLease!.unit!.id,
          indexationBaseIndex: -1,
          indexationNewIndex: -1,
          previousRentalPrice: unitPrices.initialRentalPrice,
          newRentalPrice: unitPrices.rentalPrice,
          rentalStatus: LeasePriceHistoryStatus.NOT_APPLICABLE,
          previousMonthlyChargesPrice: unitPrices.initialMonthlyCharges,
          newMonthlyChargesPrice: unitPrices.monthlyCharges,
          monthlyChargesStatus: LeasePriceHistoryStatus.NOT_APPLICABLE,
          monthlyChargesType: unitLease?.monthlyChargesType,
        };
      }) as unknown as LeasePriceHistoryDetailInput[];
      if (!newLease) {
        const leasePriceHistoriesOfLease = getLeasePriceHistoriesOfLease(id!);
        if (!isNilOrEmpty(leasePriceHistoriesOfLease)) {
          addLeasePromises.push(
            updateLeasePriceHistory({
              id: leasePriceHistoriesOfLease[0].id, // There is only one possible price history for draft lease
              _version: (leasePriceHistoriesOfLease[0] as ModelWithVersion<LeasePriceHistory>)._version,
              previousTotalRentalPrice: leaseTotalPrices.initialRentalPrice,
              previousTotalMonthlyCharge: leaseTotalPrices.initialChargePrice,
              totalRentalPrice: leaseTotalPrices.rentalPrice,
              totalMonthlyCharges: leaseTotalPrices.monthlyCharges,
              applicationDate: parseISO((lease as Lease).startDate).toJSON(),
              amountDetails,
            })
          );
        }
      } else if (newLease) {
        addLeasePromises.push(
          createLeasePriceHistory({
            previousTotalRentalPrice: leaseTotalPrices.initialRentalPrice,
            previousTotalMonthlyCharge: leaseTotalPrices.initialChargePrice,
            totalRentalPrice: leaseTotalPrices.rentalPrice,
            totalMonthlyCharges: leaseTotalPrices.monthlyCharges,
            applicationDate: parseISO((lease as Lease).startDate).toJSON(),
            status: LeaseAmountUpdateStatus.APPLIED,
            type: LeasePriceHistoryType.INITIAL_PRICE as LeasePriceHistoryTypeAPI,
            leaseId: (lease as Lease).id,
            amountDetails,
          })
        );
      }

      // ---------------------------------------------------LeaseAction--------------------

      addLeasePromises.push(handleLeaseAction(newLease, lease.id, unitLeases));

      // ---------------------------------------------------History--------------------

      await handleHistoricInvoiceIfNeeded(lease as Lease);

      // ---------------------------------------------------All Promises---------------

      addLeasePromises.push(
        ...[
          ...smokeDetectorsPromises.flat(),
          ...fileByCategoryPromises.flat(),
          ...technicsWithMaintenancePromises.flat(),
          ...utilityProvidersPromises.flat(),
          ...addLeasePromises,
          ...creationOfAdditionalClauses,
          ...leaseVariousOperationsPromise,
        ]
      );

      await Promise.all(addLeasePromises);

      // ---------------------------------------------------Redirection-------------------------------

      // eslint-disable-next-line no-unused-expressions
      values.needToRedirectToEditSignatures
        ? history.replace({
            pathname: RouteDestination.EDIT_SIGNATURES,
            state: {
              lease,
              idsOfContactsWhoStillNeedToSign: [],
              goBackLink: `${RouteDestination.LEASES}/detail/${(lease as Lease).id}`,
            },
          })
        : history.replace({
            pathname: `${RouteDestination.LEASES}/detail/${(lease as Lease).id}`,
            state: {
              goBackUrl: `${RouteDestination.LEASES}/${
                getTabAssignedToSelectedLease((lease as Lease).status as LeaseStatus).to
              }`,
            },
          });
      setStatus(true);
      setSubmitting(false);
    },
    // eslint-disable-next-line
    [
      createLeaseVariousOperation,
      createAdditionalClause,
      createFile,
      createLeaseContact,
      createLeasePriceHistory,
      createUnitInventory,
      createUnitLease,
      deepDeleteLeaseInventoryEncoding,
      deleteUnitInventory,
      getBuilding,
      getClientContact,
      getContact,
      getUnit,
      history,
      inviteContactToClientAccount,
      updateAddress,
      updateUnit,
      memberId,
      values,
    ]
  );

  return { onChangeUnit, handleCreateLease, getSubStepRentFormsDependingOnUnit, handleHistoricInvoiceIfNeeded };
};
