/* eslint-disable react/no-array-index-key */
/* eslint-disable no-promise-executor-return */
import React, { useEffect, useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import TextDetail from '../ui/TextDetail';
import { isNil, isEqual, isEmpty } from 'lodash';
import TemplateEditor from '../ui/TemplateEditor';
import { EditorState } from 'draft-js';
import { Button, Divider, Grid, ListItemAvatar, Typography, TextField, Chip } from '@material-ui/core';
import { useLeases } from '../../hooks/LeasesContext';
import {
  Contact,
  ContactType,
  File as FileModel,
  getContactNameFromObject,
  EntityType,
  Colors,
  CommunicationStatus,
  CommunicationChannel,
  BlockedEmail,
  TemplateSubType,
  FileCategory,
  S3Object,
} from '@rentguru/commons-utils';
import { Autocomplete } from '@material-ui/lab';
import {
  CustomMenuItemType,
  getOptionLabelFromCustomMenuItemType,
  useCustomizedComboStyles,
} from 'src/components/ui/ComboBox/TextComboBox';
import { getS3ObjectUrls, NewFile, useFiles } from 'src/hooks/FilesContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import FileItemRendering from '../ui/FileItemRendering';
import PreviewFileDialogs from '../ui/PreviewFileDialog';
import { isLetterCommunication, CommunicationWithTemplate } from 'src/utils/communicationUtils';
import CommunicationDetailsContentHeader from './CommunicationDetailsContentHeader';

interface CommunicationDetailContentProps {
  communication: CommunicationWithTemplate;
  recipients: (CustomMenuItemType | string)[];
  setRecipients: (recipient: (CustomMenuItemType | string)[]) => void;
  bodyEditor: EditorState;
  setBody: (body: EditorState) => void;
  titleEditor: EditorState;
  setTitle: (edit: EditorState) => void;
  editable?: boolean;
  emailError: string | null;
}

const getCustomMenuItemForContactsWithType = (
  contacts: Contact[],
  type: ContactType,
  formatMessage: IntlFormatters['formatMessage']
) => {
  return contacts.reduce((acc: CustomMenuItemType[], value: Contact) => {
    if (!isNil(value.email)) {
      const itemToPush: CustomMenuItemType = {
        primary: getContactNameFromObject(value),
        secondary: value.email,
        value: value.email,
        group: formatMessage({ id: `contact.type.${type.toLowerCase()}` }),
      };
      if (isNil(acc.find((item) => isEqual(itemToPush, item)))) acc.push(itemToPush);
    }
    return acc;
  }, []);
};

const getFilteredInvoices = (invoices: FileModel[], templateSubType?: TemplateSubType) => {
  if (templateSubType === TemplateSubType.LEASE_PAYMENT_RECEIPT) {
    return invoices.filter((invoice) => invoice.category?.fileCategory === FileCategory.INVOICE_RECEIPT);
  }
  return invoices.filter((invoice) => invoice.category?.fileCategory !== FileCategory.INVOICE_RECEIPT);
};

const CommunicationDetailContent: React.FC<CommunicationDetailContentProps> = ({
  communication,
  recipients,
  setRecipients,
  bodyEditor,
  setBody,
  titleEditor,
  setTitle,
  emailError,
  editable = false,
}) => {
  const { formatMessage } = useIntl();
  const classes = useCustomizedComboStyles();
  const { getAllContactsOwnersFromLease, getLease } = useLeases();
  const linkedOwnersContacts = !isNil(communication.lease) ? getAllContactsOwnersFromLease(communication.lease.id) : [];
  const { loading: invoicesLoading } = useInvoices();
  const { loading: signatureDocumentsLoading, getSignatureDocument } = useSignatureDocuments();
  const { fetchFileFromId, getFiles, loading: fileLoading } = useFiles();
  const [S3Objects, setS3Objects] = useState<S3Object[] | null>(null);
  const [fileToPreview, setFileToPreview] = useState<S3Object | null>(null);

  let linkedTenantsContacts: Contact[] = [];
  let linkedGuarantorsContacts: Contact[] = [];
  if (communication.lease && communication.lease.id) {
    const lease = getLease(communication.lease.id);
    linkedTenantsContacts = lease?.tenants || [];
    linkedGuarantorsContacts = lease?.guarantors || [];
  }

  useEffect(() => {
    const buildingInvoicesWithFiles = async () => {
      const invoiceFiles = communication.invoiceId ? getFiles(EntityType.INVOICE, [communication.invoiceId]) : [];
      const statementFiles = communication.statementId
        ? getFiles(EntityType.STATEMENT, [communication.statementId])
        : [];
      const communicationFiles = communication.id ? getFiles(EntityType.COMMUNICATION, [communication.id]) : [];
      const leasePriceHistoryFiles = communication.leasePriceHistoryId
        ? getFiles(EntityType.LEASE_PRICE_HISTORY, [communication.leasePriceHistoryId])
        : [];
      const invoices = getFilteredInvoices(invoiceFiles, communication?.template?.subType);
      const urls = await getS3ObjectUrls([
        ...invoices,
        ...statementFiles,
        ...communicationFiles,
        ...leasePriceHistoryFiles,
      ]);
      setS3Objects(urls);
    };
    if (!invoicesLoading && !fileLoading) buildingInvoicesWithFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoading, fileLoading]);

  const signatureDocument =
    communication.signatureDocument ?? getSignatureDocument(communication.signatureDocumentId ?? '');

  useEffect(() => {
    const buildingFiles = async () => {
      if (!communication.signatureDocumentId) {
        return;
      }

      if (!signatureDocument || !signatureDocument.fileIds) {
        return;
      }

      const filesPromises = signatureDocument.fileIds.map((fileId) => fetchFileFromId(fileId));
      const files = await Promise.all(filesPromises);
      setS3Objects((s3Objects) => [...(s3Objects ?? []), ...files.flat()]);
    };
    if (!signatureDocumentsLoading) buildingFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureDocumentsLoading, communication.signatureDocumentId, communication.id]);

  if (isNil(communication.id)) {
    return null;
  }

  const autoCompleteOptions: CustomMenuItemType[] = [
    ...getCustomMenuItemForContactsWithType(linkedOwnersContacts, ContactType.OWNER, formatMessage),
    ...getCustomMenuItemForContactsWithType(linkedTenantsContacts, ContactType.TENANT, formatMessage),
    ...getCustomMenuItemForContactsWithType(linkedGuarantorsContacts, ContactType.GUARANTOR, formatMessage),
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: any) => {
    switch (event.key) {
      case ';':
      case ',':
      case ' ': {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          setRecipients([...recipients, event.target.value]);
        }
        break;
      }
      default:
    }
  };

  const toSend = communication.status === CommunicationStatus.TO_SEND;
  const dateLabelId = `communications.list.${toSend ? 'startDate' : 'sentDate'}`;
  const dateToUse = toSend ? communication.createdAt : communication.sentAt;
  const isLetter = isLetterCommunication(communication.channel as CommunicationChannel);
  const filteredRecipients = recipients.filter((recipient) => recipient !== '');

  return (
    <>
      <CommunicationDetailsContentHeader
        date={dateToUse}
        dateLabelId={dateLabelId}
        signatureDocument={signatureDocument}
        isLetter={isLetter}
        gridItemsStyle={{ paddingTop: 12, paddingLeft: 24 }}
        lease={communication.lease}
        status={communication.status as CommunicationStatus}
        blockedEmails={communication.blockedEmails as BlockedEmail[]}
        linkedCommunicationsIds={communication.linkedCommunicationsIds as string[]}
      />
      <Divider style={{ marginLeft: -30, marginRight: -20, marginBottom: 12, marginTop: 12 }} />
      {!isLetter && (
        <>
          <Grid style={{ padding: '0px 24px 12px 24px' }}>
            <TextDetail
              title={formatMessage({ id: 'communications.detail.recipient' })}
              style={{ paddingBottom: 12 }}
            />
            {!isEmpty(filteredRecipients) || editable ? (
              <Autocomplete
                multiple
                id="tags-filled"
                value={filteredRecipients}
                onChange={(_event, newValue) => setRecipients(newValue)}
                filterSelectedOptions
                autoSelect
                options={autoCompleteOptions}
                disabled={!editable}
                groupBy={(option) => {
                  if (typeof option === 'string') return 'custom';
                  return option.group as string;
                }}
                freeSolo
                getOptionSelected={(option, value) => {
                  const str1 = typeof option === 'string' ? option : option.value;
                  const str2 = typeof value === 'string' ? value : value.value;
                  return isEqual(str1, str2);
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return getOptionLabelFromCustomMenuItemType(option);
                }}
                renderTags={(value: (CustomMenuItemType | string)[], getTagProps) => {
                  if (!isEqual(recipients, value)) setRecipients(value);
                  return value.map((option: CustomMenuItemType | string, index: number) => (
                    <Chip
                      key={`${index}`}
                      variant="outlined"
                      disabled={!editable}
                      label={typeof option === 'string' ? option : option.value}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                renderOption={(option) => (
                  <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
                    <Grid item xs={6}>
                      <Typography className={classes.primaryText}>
                        {typeof option === 'string' ? option : option.primary}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      {typeof option !== 'string' && !isNil(option.secondary) && (
                        <Typography className={classes.secondaryText}>{option.secondary}</Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                renderInput={(params) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (params.inputProps as any).onKeyDown = handleKeyDown;
                  return (
                    <TextField
                      {...params}
                      InputProps={{ disableUnderline: true, ...params.InputProps }}
                      label=""
                      placeholder="Emails"
                      fullWidth
                    />
                  );
                }}
              />
            ) : (
              <Typography style={{ fontSize: 14, color: Colors.TOWER_GREY }}>
                {formatMessage({ id: 'noRecipient' })}
              </Typography>
            )}

            {!isNil(emailError) && (
              <Typography style={{ fontSize: 14, color: Colors.BURNING_ORANGE }}>
                {formatMessage({ id: 'communications.detail.emailWarning' })}
              </Typography>
            )}
          </Grid>
          <Divider style={{ marginLeft: -30, marginRight: -20 }} />
        </>
      )}
      <TemplateEditor
        title={!isLetter ? titleEditor : undefined}
        setTitle={!isLetter ? setTitle : undefined}
        body={bodyEditor}
        setBody={setBody}
        editable={editable}
        editMode={editable}
        dividerStyle={{ marginLeft: -30, marginRight: -20 }}
        maxHeight={!isEmpty(S3Objects) && !editable ? 'calc(100vh - 350px)' : 'unset'}
        editorPadding={'0 0 12px 24px'}
      />
      {!editable && (
        <Grid
          style={{
            width: 'calc(100% - 160px)',
            position: 'absolute',
            bottom: 10,
            backgroundColor: 'white',
            zIndex: 2000,
          }}
        >
          {!isEmpty(S3Objects) && <Divider style={{ marginLeft: -30, marginRight: -20, marginBottom: 10 }} />}
          {S3Objects?.map((s3File: S3Object | NewFile) => {
            const fileType = 'file' in s3File ? s3File.file.type : s3File.mimeType;
            const fileName = 'file' in s3File ? s3File.file.name : s3File.fileName;
            const fileUrl = 'url' in s3File ? s3File.url : URL.createObjectURL(s3File.file);

            return (
              <Button
                key={fileName}
                onClick={() => setFileToPreview(s3File as S3Object)}
                style={{
                  padding: 0,
                  position: 'relative',
                  height: 80,
                  width: 50,
                  right: 24,
                  left: 30,
                  marginLeft: 20,
                }}
              >
                <ListItemAvatar>
                  <FileItemRendering
                    fileType={fileType as string}
                    fileUrl={fileUrl}
                    fileName={fileName}
                    height={80}
                    width={50}
                  />
                </ListItemAvatar>
              </Button>
            );
          })}

          {!isNil(fileToPreview) && (
            <PreviewFileDialogs
              open={!isNil(fileToPreview)}
              file={fileToPreview}
              onClose={() => setFileToPreview(null)}
              withFileName={false}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default CommunicationDetailContent;
