/* eslint-disable no-unused-expressions */
import React from 'react';
import { FieldFilterSelector, FilterCheckBox } from 'src/components/ui/FieldFilterSelector';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import union from 'lodash/union';
import { ListItem, ListItemText, Divider, Typography } from '@material-ui/core';
import {
  startOfMonth,
  endOfMonth,
  isSameMonth,
  parseISO,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  setSeconds,
  setMinutes,
  setHours,
} from 'date-fns';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { useIntl } from 'react-intl';
import FieldFilterResetButton from 'src/components/ui/FieldFilterResetButton';
import { useTickets } from 'src/hooks/TicketsContext';
import { useLocation } from 'react-router-dom';
import { useFilters, TicketsFilters as TicFilters, FilterEntity } from '../../hooks/FiltersContext';
import { Ticket, Contact, Building, isDateValid } from '@rentguru/commons-utils';
import { RouteDestination } from 'src/components/Routes/Routes';
import { getSorting, stableSort } from '@up2rent/ui';

interface TicketsFiltersProps {
  unitId?: string;
  buildingId?: string;
}

const TicketsFilters: React.FC<TicketsFiltersProps> = ({ unitId, buildingId }) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const {
    ticketsAssignedToMe,
    ticketsAssignedToOthers,
    ticketsUnassigned,
    ticketsResolved,
    ticketsArchived,
    getBuildingTickets,
    getUnitTickets,
  } = useTickets();
  const {
    ticketsFilters: { filters, ticketsFromTo, plannedFromTo },
    updateFilters,
    resetFilters,
  } = useFilters();

  const unitTicketsList: Ticket[] = !isNil(unitId) && !isEmpty(unitId) ? getUnitTickets(unitId) : [];
  const buildingTicketsList: Ticket[] =
    !isNil(buildingId) && !isEmpty(buildingId) ? getBuildingTickets(buildingId) : [];

  let data = ticketsAssignedToMe;
  if (pathname.includes('to-others')) {
    data = ticketsAssignedToOthers;
  } else if (pathname.includes('unassigned')) {
    data = ticketsUnassigned;
  } else if (pathname.includes('resolved')) {
    data = ticketsResolved;
  } else if (pathname.includes('archived')) {
    data = ticketsArchived;
  } else if (!isNil(unitTicketsList) && !isEmpty(unitTicketsList)) {
    data = unitTicketsList;
  } else if (!isNil(buildingTicketsList) && !isEmpty(buildingTicketsList)) {
    data = buildingTicketsList;
  }
  const handleFilterChange = (
    selectedItems: { value: string; label: string }[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => {
    if (menuClosed) {
      return; // Don't set filter again
    }
    if (
      filterName === 'contact' ||
      filterName === 'sourceType' ||
      filterName === 'status' ||
      filterName === 'building' ||
      filterName === 'user'
    ) {
      const filter = { name: filterName, field: fieldName, items: selectedItems.map((i) => i.value) };
      let newFilters = filters;

      const index = newFilters.findIndex((f) => f.name === filterName);
      if (index === -1) {
        // if the chosen filter is not found, add it to the existing roster.
        if (!isEmpty(selectedItems)) newFilters = [...newFilters, filter];
      } else {
        // if the chosen filter is found, update its value to current value.
        // newFilters[index] = filter;
        newFilters = [...newFilters.filter((f) => f.name !== filterName), filter];
      }
      updateFilters<TicFilters>(FilterEntity.TICKETS, { filters: newFilters, ticketsFromTo, plannedFromTo });
    }
  };
  // Temporal filters
  const handleFilterReset = (filterName: string) => {
    if (filterName === 'plannedDate') {
      updateFilters<TicFilters>(FilterEntity.TICKETS, { filters, ticketsFromTo, plannedFromTo: null });
    } else if (filterName === 'creationTime') {
      updateFilters<TicFilters>(FilterEntity.TICKETS, { filters, ticketsFromTo: null, plannedFromTo });
    }
  };

  const handleFilterDateFromTo = (from: null | Date, to: null | Date) => {
    const oldFromTo = ticketsFromTo;
    let newFrom = null;
    let newTo = null;
    if (isNil(from)) {
      newFrom = isNil(oldFromTo) ? null : oldFromTo.from;
    } else {
      from = setSeconds(from, 0);
      from = setMinutes(from, 0);
      from = setHours(from, 0);
      newFrom = from.toISOString();
    }
    if (isNil(to)) {
      newTo = isNil(oldFromTo) ? null : oldFromTo.to;
    } else {
      to = setSeconds(to, 0);
      to = setMinutes(to, 0);
      to = setHours(to, 0);
      newTo = to.toISOString();
    }
    // Reset
    if (isNil(from) && isNil(to)) {
      updateFilters<TicFilters>(FilterEntity.TICKETS, { filters, ticketsFromTo: null, plannedFromTo });
    } else {
      updateFilters<TicFilters>(FilterEntity.TICKETS, {
        filters,
        ticketsFromTo: { from: newFrom!, to: newTo! },
        plannedFromTo,
      });
    }
  };
  const handleFilterPlannedFromTo = (from: null | Date, to: null | Date) => {
    const oldFromTo2 = plannedFromTo;
    let newFrom = null;
    let newTo = null;
    if (isNil(from)) {
      newFrom = isNil(oldFromTo2) ? null : oldFromTo2.from;
    } else {
      from = setSeconds(from, 0);
      from = setMinutes(from, 0);
      from = setHours(from, 0);
      newFrom = from.toISOString();
    }
    if (isNil(to)) {
      newTo = isNil(oldFromTo2) ? null : oldFromTo2.to;
    } else {
      to = setSeconds(to, 0);
      to = setMinutes(to, 0);
      to = setHours(to, 0);
      newTo = to.toISOString();
    }
    // Reset
    if (isNil(from) && isNil(to)) {
      updateFilters<TicFilters>(FilterEntity.TICKETS, { filters, ticketsFromTo, plannedFromTo: null });
    } else {
      updateFilters<TicFilters>(FilterEntity.TICKETS, {
        filters,
        ticketsFromTo,
        plannedFromTo: { from: newFrom!, to: newTo! },
      });
    }
  };

  // Date filter rendering
  const startDateFilter = isNil(ticketsFromTo)
    ? new Date()
    : isNil(ticketsFromTo.from)
    ? new Date()
    : parseISO(ticketsFromTo.from);
  const endDateFilter = isNil(ticketsFromTo)
    ? new Date()
    : isNil(ticketsFromTo.to)
    ? new Date()
    : parseISO(ticketsFromTo.to);

  const startPlannedFilter = isNil(plannedFromTo)
    ? new Date()
    : isNil(plannedFromTo.from)
    ? new Date()
    : parseISO(plannedFromTo.from);
  const endPlannedFilter = isNil(plannedFromTo)
    ? new Date()
    : isNil(plannedFromTo.to)
    ? new Date()
    : parseISO(plannedFromTo.to);

  let today = new Date();
  today = setSeconds(today, 0);
  today = setMinutes(today, 0);
  today = setHours(today, 0);
  const creationMonthChecked =
    isFirstDayOfMonth(startDateFilter) &&
    isLastDayOfMonth(endDateFilter) &&
    isSameMonth(today, startDateFilter) &&
    isSameMonth(today, endDateFilter);
  const plannedMonthChecked =
    isFirstDayOfMonth(startPlannedFilter) &&
    isLastDayOfMonth(endPlannedFilter) &&
    isSameMonth(today, startPlannedFilter) &&
    isSameMonth(today, endPlannedFilter);
  const creationTimeMenu = [
    <ListItem
      key="thisMonth"
      onClick={() => {
        isFirstDayOfMonth(startDateFilter) && isLastDayOfMonth(endDateFilter)
          ? handleFilterDateFromTo(null, null)
          : handleFilterDateFromTo(startOfMonth(today), endOfMonth(today));
      }}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <FilterCheckBox isChecked={creationMonthChecked} />
      <ListItemText primary={formatMessage({ id: 'transactions.filter.thisMonth' })} />
    </ListItem>,
    <Divider key="divider1" />,
    <ListItem key="selectFromTo" style={{ marginTop: 10, marginBottom: 10, paddingTop: 0, paddingBottom: 0 }}>
      <CustomDatePicker
        name="from"
        label={formatMessage({ id: 'transactions.filter.from' })}
        value={isNil(ticketsFromTo) ? null : parseISO(ticketsFromTo.from)}
        style={{ width: 150, marginRight: 20 }}
        onChange={(creationTime) => handleFilterDateFromTo(creationTime, null)}
        keyboardIconStyle={{ width: '20px' }}
        keyboardIconProps={{ size: 'small' }}
        safeDateFormat={true}
      />
      <CustomDatePicker
        name="to"
        label={formatMessage({ id: 'transactions.filter.to' })}
        value={isNil(ticketsFromTo) ? null : parseISO(ticketsFromTo.to)}
        style={{ width: 150 }}
        minDate={
          !isNil(ticketsFromTo) && isDateValid(parseISO(ticketsFromTo.from)) ? parseISO(ticketsFromTo.from) : undefined
        }
        onChange={(creationTime) => handleFilterDateFromTo(null, creationTime)}
        keyboardIconStyle={{ width: '20px' }}
        keyboardIconProps={{ size: 'small' }}
        safeDateFormat={true}
      />
    </ListItem>,
  ];
  const plannedDateMenu = [
    <ListItem
      key="thisMonth"
      onClick={() => {
        isFirstDayOfMonth(startPlannedFilter) && isLastDayOfMonth(endPlannedFilter)
          ? handleFilterPlannedFromTo(null, null)
          : handleFilterPlannedFromTo(startOfMonth(today), endOfMonth(today));
      }}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <FilterCheckBox isChecked={plannedMonthChecked} />
      <ListItemText primary={formatMessage({ id: 'transactions.filter.thisMonth' })} />
    </ListItem>,
    <Divider key="divider1" />,
    <ListItem key="selectFromTo" style={{ marginTop: 10, marginBottom: 10, paddingTop: 0, paddingBottom: 0 }}>
      <CustomDatePicker
        name="from"
        label={formatMessage({ id: 'transactions.filter.from' })}
        value={isNil(plannedFromTo) ? null : parseISO(plannedFromTo.from)}
        style={{ width: 150, marginRight: 20 }}
        keyboardIconStyle={{ width: '20px' }}
        keyboardIconProps={{ size: 'small' }}
        onChange={(plannedDate) => handleFilterPlannedFromTo(plannedDate, null)}
        safeDateFormat={true}
      />
      <CustomDatePicker
        name="to"
        label={formatMessage({ id: 'transactions.filter.to' })}
        value={isNil(plannedFromTo) ? null : parseISO(plannedFromTo.to)}
        style={{ width: 150 }}
        minDate={
          !isNil(plannedFromTo) && isDateValid(parseISO(plannedFromTo.from)) ? parseISO(plannedFromTo.from) : undefined
        }
        keyboardIconStyle={{ width: '20px' }}
        keyboardIconProps={{ size: 'small' }}
        onChange={(plannedDate) => handleFilterPlannedFromTo(null, plannedDate)}
        safeDateFormat={true}
      />
    </ListItem>,
  ];
  // Render the filters

  /// //////
  let contactNames = data
    .reduce((currentList: Contact[], currentObject: Ticket) => {
      if (!isNil(currentObject.contact) && !isEmpty(currentObject.contact)) {
        return unionBy([currentObject.contact], currentList, 'id');
      }
      return currentList;
    }, [] as Contact[])
    .map((o: Contact) => ({ value: o.id, label: `${o.firstName} ${o.lastName}` }));

  contactNames = stableSort(contactNames, getSorting('asc', 'label'));
  const initialContactSelectedItemsFilters = filters.filter((f) => f.name === 'contact');
  const initialContactSelectedItems = isEmpty(initialContactSelectedItemsFilters)
    ? null
    : initialContactSelectedItemsFilters[0].items.map((b) => ({ value: b }));
  /// /
  let statusNames = data
    .reduce((currentList: string[], currentObject: Ticket) => {
      if (!isNil(currentObject.status) && !isEmpty(currentObject.status)) {
        return union([currentObject.status], currentList);
      }
      return currentList;
    }, [] as string[])
    .map((o: string) => ({ value: o, label: formatMessage({ id: `enums.TicketStatus.${o}` }) }));
  statusNames = stableSort(statusNames, getSorting('asc', 'label'));
  const initialStatusSelectedItemsFilters = filters.filter((f) => f.name === 'status');
  const initialStatusSelectedItems = isEmpty(initialStatusSelectedItemsFilters)
    ? null
    : initialStatusSelectedItemsFilters[0].items.map((b) => ({ value: b }));
  ///
  let buildingNames = data
    .reduce((currentList: Building[], currentObject: Ticket) => {
      if (!isNil(currentObject.building) && !isEmpty(currentObject.building)) {
        return unionBy([currentObject.building], currentList, 'id');
      }
      return currentList;
    }, [] as Building[])
    .map((o: Building) => ({ value: o.id, label: o.name }));
  buildingNames = stableSort(buildingNames, getSorting('asc', 'label'));
  const initialBuildingSelectedItemsFilters = filters.filter((f) => f.name === 'building');
  const initialBuildingSelectedItems = isEmpty(initialBuildingSelectedItemsFilters)
    ? null
    : initialBuildingSelectedItemsFilters[0].items.map((b) => ({ value: b }));
  ///
  let userNames = data
    .reduce((currentList: Contact[], currentObject: Ticket) => {
      if (!isNil(currentObject.assignedTo) && !isEmpty(currentObject.assignedTo)) {
        return unionBy([currentObject.assignedTo], currentList, 'id');
      }
      return currentList;
    }, [] as Contact[])
    .map((o: Contact) => ({ value: o.id, label: `${o.firstName} ${o.lastName}` }));
  userNames = stableSort(userNames, getSorting('asc', 'label'));
  const initialUserSelectedItemsFilters = filters.filter((f) => f.name === 'user');
  const initialUserSelectedItems = isEmpty(initialUserSelectedItemsFilters)
    ? null
    : initialUserSelectedItemsFilters[0].items.map((b) => ({ value: b }));
  ///

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {pathname === `${RouteDestination.TICKETS}/resolved` && (
        <Typography style={{ marginLeft: 15, marginTop: 15, alignSelf: 'center' }}>
          {formatMessage({ id: 'tickets.menu.automatic' })}
        </Typography>
      )}
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 15,
          marginRight: 15,
        }}
      >
        <FieldFilterSelector
          label={formatMessage({ id: 'tickets.filter.sender' })}
          multipleSelection
          showResetButton
          filterName="contact"
          fieldName="contact.id"
          textReset={formatMessage({ id: 'reinitFilter' })}
          textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
          textSearchField={formatMessage({ id: 'search' })}
          textSelectAll={formatMessage({ id: 'selectAll' })}
          onChange={handleFilterChange}
          initialSelectedItems={initialContactSelectedItems}
        >
          {contactNames}
        </FieldFilterSelector>
        {pathname === `${RouteDestination.TICKETS}/` ||
        pathname === `${RouteDestination.TICKETS}/to-others` ||
        pathname === `${RouteDestination.TICKETS}/unassigned` ||
        (!isNil(unitTicketsList) && !isEmpty(unitTicketsList)) ||
        (!isNil(buildingTicketsList) && !isEmpty(buildingTicketsList)) ? (
          <FieldFilterSelector
            label={formatMessage({ id: 'tickets.filter.status' })}
            multipleSelection
            showResetButton
            filterName="status"
            fieldName="status"
            textReset={formatMessage({ id: 'reinitFilter' })}
            textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
            textSearchField={formatMessage({ id: 'search' })}
            textSelectAll={formatMessage({ id: 'selectAll' })}
            onChange={handleFilterChange}
            initialSelectedItems={initialStatusSelectedItems}
            showSearchField
          >
            {statusNames}
          </FieldFilterSelector>
        ) : (
          ''
        )}
        <FieldFilterSelector
          label={formatMessage({ id: 'tickets.filter.creationTime' })}
          showResetButton
          multipleSelection
          showSearchField={false}
          filterName="creationTime"
          fieldName="creationTime"
          textReset={formatMessage({ id: 'reinitFilter' })}
          textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
          textSearchField={formatMessage({ id: 'search' })}
          textSelectAll={formatMessage({ id: 'selectAll' })}
          onChange={handleFilterChange}
          onReset={handleFilterReset}
          minSelectorHeight={700}
          minSelectorWidth={1500}
          selected={!isNil(ticketsFromTo)}
        >
          {creationTimeMenu}
        </FieldFilterSelector>

        {(!isNil(unitTicketsList) && !isEmpty(unitTicketsList)) ||
          (!isNil(buildingTicketsList) && !isEmpty(buildingTicketsList) && (
            <FieldFilterSelector
              label={formatMessage({ id: 'tickets.filter.building' })}
              multipleSelection
              showResetButton
              filterName="building"
              fieldName="building.id"
              textReset={formatMessage({ id: 'reinitFilter' })}
              textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
              textSearchField={formatMessage({ id: 'search' })}
              textSelectAll={formatMessage({ id: 'selectAll' })}
              onChange={handleFilterChange}
              initialSelectedItems={initialBuildingSelectedItems}
            >
              {buildingNames}
            </FieldFilterSelector>
          ))}
        {pathname !== `${RouteDestination.TICKETS}/unassigned` ? (
          <FieldFilterSelector
            label={formatMessage({ id: 'tickets.filter.user' })}
            multipleSelection
            showResetButton
            filterName="user"
            fieldName="user.id"
            textReset={formatMessage({ id: 'reinitFilter' })}
            textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
            textSearchField={formatMessage({ id: 'search' })}
            textSelectAll={formatMessage({ id: 'selectAll' })}
            onChange={handleFilterChange}
            initialSelectedItems={initialUserSelectedItems}
          >
            {userNames}
          </FieldFilterSelector>
        ) : (
          ''
        )}
        <FieldFilterSelector
          label={formatMessage({ id: 'tickets.filter.plannedDate' })}
          showResetButton
          multipleSelection
          showSearchField={false}
          filterName="plannedDate"
          fieldName="plannedDate"
          textReset={formatMessage({ id: 'reinitFilter' })}
          textNoMatchFound={formatMessage({ id: 'noMatchFound' })}
          textSearchField={formatMessage({ id: 'search' })}
          textSelectAll={formatMessage({ id: 'selectAll' })}
          onChange={handleFilterChange}
          onReset={handleFilterReset}
          minSelectorHeight={700}
          minSelectorWidth={1500}
          selected={!isNil(plannedFromTo)}
        >
          {plannedDateMenu}
        </FieldFilterSelector>
        <FieldFilterResetButton
          resetFilterValues={() => {
            resetFilters(FilterEntity.TICKETS);
          }}
        />
      </div>
    </div>
  );
};

export default TicketsFilters;
