/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useMemo } from 'react';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import {
  syncUsers as syncQuery,
  syncUserTeams as syncUserTeamsQuery,
  getUser as getQuery,
  getUserTeam as getUserTeamQuery,
  CreateUserMutationVariables as CreateMutationVariables,
  DeleteUserMutationVariables as DeleteMutationVariables,
  CreateUserInput as CreateInput,
  InviteUserToClientAccountMutationVariables,
  MutationStatus,
  User,
  UserTeam,
  Contact,
  ContactType,
  inviteUserToClientAccount,
  updateUser as updateMutation,
  deleteUser as deleteMutation,
  createUserTeam as createUserTeamMutation,
  updateUserTeam as updateUserTeamMutation,
  deleteUserTeam as deleteUserTeamMutation,
  resolveManyToMany,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  getHash,
  contactContainsType,
  getContactNameFromObject,
  resolveOneToOne,
  Model,
  isNilOrEmpty,
  cleanInputCreate,
  DeleteUserTeamMutationVariables,
  OnCreateUserSubscription,
  OnUpdateUserSubscription,
  OnDeleteUserSubscription,
  onCreateUser,
  onUpdateUser,
  onDeleteUser,
  OnCreateUserTeamSubscription,
  OnUpdateUserTeamSubscription,
  OnDeleteUserTeamSubscription,
  onCreateUserTeam,
  onUpdateUserTeam,
  onDeleteUserTeam,
  UserStatus,
} from '@rentguru/commons-utils';
import {
  deleteEntityWithFetchBefore,
  get,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { useContacts } from './ContactsContext';

const ENTITY_MODEL_NAME: Model = 'User';
const ENTITY_MODEL_NAME_USER_TEAM: Model = 'UserTeam';

export type ActionUser =
  | {
      type: 'SHOULD_FETCH_USER' | 'IS_FETCHING_USER';
    }
  | {
      type: 'ADD_USER';
      payload: { user: User };
    }
  | {
      type: 'FETCHED_USER';
      payload: { users: User[] };
    }
  | {
      type: 'UPDATE_USER';
      payload: { user: User };
    }
  | {
      type: 'DELETE_USER';
      payload: { id: string };
    };

export type ActionUserTeam =
  | {
      type: 'SHOULD_FETCH_USER_TEAM' | 'IS_FETCHING_USER_TEAM';
    }
  | {
      type: 'ADD_USER_TEAM';
      payload: { userTeam: UserTeam };
    }
  | {
      type: 'FETCHED_USER_TEAM';
      payload: { userTeams: UserTeam[] };
    }
  | {
      type: 'UPDATE_USER_TEAM';
      payload: { userTeam: UserTeam };
    }
  | {
      type: 'DELETE_USER_TEAM';
      payload: { id: string };
    };

export interface UsersContext {
  loading: boolean;
  error: string | null;
  users: User[];
  getUser: (id: string) => User | undefined;
  getUserFromContact: (contactId: string) => User | undefined;
  getCurrentCognitoUser: () => User | undefined;
  getCurrentContact: () => Contact | undefined;
  getClientContact: () => Contact | undefined;
  isAccountPrivate: () => boolean | undefined;
  updateUser: (original: User, updates: Partial<User>) => Promise<User>;
  deleteUser: (id: string) => Promise<void>;
  disableUser: (id: string) => Promise<User>;
  enableUser: (id: string) => Promise<User>;
  deepDeleteUser: (user: User) => Promise<void>;
  createUserTeam: (input: Omit<UserTeam, 'id' | 'clientId' | 'readId'>) => Promise<UserTeam>;
  updateUserTeam: (original: UserTeam, updates: Partial<UserTeam>) => Promise<UserTeam>;
  deleteUserTeam: (id: string) => Promise<UserTeam>;
  inviteMemberToAccount: (email: string, roleId: string, teamId: string) => Promise<MutationStatus>;
  setFetchUser: () => void;
  setFetchUserTeam: () => void;
}

export const userReducerDelegation = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_USER':
      if (state.User.loading || state.User.shouldFetch) {
        return state;
      }
      return {
        ...state,
        User: { ...state.User, shouldFetch: true },
      };
    case 'IS_FETCHING_USER':
      if (state.User.loading) {
        return state;
      }
      return {
        ...state,
        User: { ...state.User, loading: true },
      };
    case 'FETCHED_USER':
      return {
        ...state,
        User: {
          ...state.User,
          data: action.payload.users,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_USER':
      // Check if already present - If already added by this user or coming from another user
      if (state.User.data?.find((object) => object.id === action.payload.user.id)) {
        return state;
      }

      return {
        ...state,
        User: {
          ...state.User,
          data: [...state.User.data, action.payload.user],
        },
      };
    case 'UPDATE_USER':
      // No data
      if (isNilOrEmpty(state.User.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.User.data?.find((object) => object.id === action.payload.user.id);
      if (!currentObject) {
        return {
          ...state,
          User: {
            ...state.User,
            data: [...state.User.data, action.payload.user],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.user)) {
        return state;
      }

      // Update
      return {
        ...state,
        User: {
          ...state.User,
          data: state.User.data.map((object) => {
            if (object.id === action.payload.user.id) {
              return action.payload.user;
            }
            return object;
          }),
        },
      };
    case 'DELETE_USER':
      if (isNilOrEmpty(state.User.data)) {
        return state;
      }
      return {
        ...state,
        User: {
          ...state.User,
          data: state.User.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

export const userTeamReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_USER_TEAM':
      if (state.UserTeam.loading || state.UserTeam.shouldFetch) {
        return state;
      }
      return {
        ...state,
        UserTeam: { ...state.UserTeam, shouldFetch: true },
      };
    case 'IS_FETCHING_USER_TEAM':
      if (state.UserTeam.loading) {
        return state;
      }
      return {
        ...state,
        UserTeam: { ...state.UserTeam, loading: true },
      };
    case 'FETCHED_USER_TEAM':
      return {
        ...state,
        UserTeam: {
          ...state.UserTeam,
          data: action.payload.userTeams,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_USER_TEAM':
      // Check if already present - If already added by this user or coming from another user
      if (state.UserTeam.data?.find((object) => object.id === action.payload.userTeam.id)) {
        return state;
      }

      return {
        ...state,
        UserTeam: {
          ...state.UserTeam,
          data: [...state.UserTeam.data, action.payload.userTeam],
        },
      };
    case 'UPDATE_USER_TEAM':
      // No data
      if (isNilOrEmpty(state.UserTeam.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.UserTeam.data?.find((object) => object.id === action.payload.userTeam.id);
      if (!currentObject) {
        return {
          ...state,
          UserTeam: {
            ...state.UserTeam,
            data: [...state.UserTeam.data, action.payload.userTeam],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.userTeam)) {
        return state;
      }

      // Update
      return {
        ...state,
        UserTeam: {
          ...state.UserTeam,
          data: state.UserTeam.data.map((object) => {
            if (object.id === action.payload.userTeam.id) {
              return action.payload.userTeam;
            }
            return object;
          }),
        },
      };
    case 'DELETE_USER_TEAM':
      if (isNilOrEmpty(state.UserTeam.data)) {
        return state;
      }
      return {
        ...state,
        UserTeam: {
          ...state.UserTeam,
          data: state.UserTeam.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchUsers = async (
  by: 'byClientId' | 'byEmail' | 'byContact',
  byValue: string,
  additionalFilter?: object
): Promise<User[]> => {
  return await list<User>(syncQuery, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

const fetchUser = async (userId: string): Promise<User> => {
  const userResult = await get<User>(getQuery, userId);
  return userResult;
};

const fetchUserTeams = async (clientId: string, additionalFilter?: object): Promise<UserTeam[]> => {
  return await list<UserTeam>(
    syncUserTeamsQuery,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME_USER_TEAM),
    additionalFilter
  );
};

export const UsersContext = React.createContext<UsersContext | null>(null);

export const UsersContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Contact: { data: contacts, loading: contactsLoading },
    User: { data: usersLoader, loading: usersLoading, shouldFetch },
    UserTeam: { data: userTeams, loading: userTeamsLoading, shouldFetch: shouldFetchUserTeam },
    UserRole: { data: userRoles, loading: userRolesLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { userId, clientId, language, memberId, tenantId, ownerId, accountType } = useUser();
  const { deleteContact } = useContacts();
  const loading = contactsLoading || usersLoading || userTeamsLoading || userRolesLoading;

  const { users, currentContact } = useMemo<{ users: User[]; currentContact: Contact | undefined }>(() => {
    if (loading) {
      return { users: [], currentContact: undefined };
    }

    const usersReduced = usersLoader.reduce((acc: User[], user: User) => {
      acc.push({
        ...user,
        teams: resolveManyToMany(user, 'user', userTeams),
        ...(user.userRoleId ? { userRole: resolveOneToOne(user.userRoleId, userRoles, 'id') } : {}),
      });
      return acc;
    }, []);

    const contact = contacts.find((contact) =>
      !isNil(ownerId) ? ownerId === contact.id : !isNil(tenantId) ? tenantId === contact.id : memberId === contact.id
    );

    return { users: usersReduced, currentContact: contact };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersLoader, userTeams, contacts, memberId, ownerId, tenantId, userId, loading, userRoles]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_USER' });
      const result = await fetchUsers('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME));
      contextDispatch({ type: 'FETCHED_USER', payload: { users: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_USER_TEAM' });
      const result = await fetchUserTeams(clientId!);
      contextDispatch({ type: 'FETCHED_USER_TEAM', payload: { userTeams: result } });
    };
    if (shouldFetchUserTeam) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchUserTeam]);

  useSubscriptions<OnCreateUserSubscription, OnUpdateUserSubscription, OnDeleteUserSubscription>(
    onCreateUser,
    onUpdateUser,
    onDeleteUser,
    (data) => {
      contextDispatch({
        type: 'ADD_USER',
        payload: { user: data.onCreateUser as User },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_USER',
        payload: { user: data.onUpdateUser as User },
      });
    },
    (data) => {
      const { id } = data.onDeleteUser as User;
      contextDispatch({
        type: 'DELETE_USER',
        payload: { id },
      });
    }
  );

  useSubscriptions<OnCreateUserTeamSubscription, OnUpdateUserTeamSubscription, OnDeleteUserTeamSubscription>(
    onCreateUserTeam,
    onUpdateUserTeam,
    onDeleteUserTeam,
    (data) => {
      contextDispatch({
        type: 'ADD_USER_TEAM',
        payload: { userTeam: data.onCreateUserTeam as UserTeam },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_USER_TEAM',
        payload: { userTeam: data.onUpdateUserTeam as UserTeam },
      });
    },
    (data) => {
      const { id } = data.onDeleteUserTeam as UserTeam;
      contextDispatch({
        type: 'DELETE_USER_TEAM',
        payload: { id },
      });
    }
  );

  const setFetchUser = () => {
    contextDispatch({ type: 'SHOULD_FETCH_USER' });
  };

  const setFetchUserTeam = () => {
    contextDispatch({ type: 'SHOULD_FETCH_USER_TEAM' });
  };

  const getUser = (id: string): User | undefined => {
    if (loading) {
      return undefined;
    }
    return users.find((u) => u.id === id);
  };

  const getUserFromContact = (contactId: string): User | undefined => {
    if (loading) {
      return undefined;
    }
    return users.find((u) => u.contact && u.contact.id === contactId);
  };

  const getCurrentCognitoUser = (): User | undefined => {
    if (loading) {
      return undefined;
    }
    if (isNil(userId)) {
      return undefined;
    }
    return users.find((u) => u.id === userId);
  };

  const getCurrentContact = (): Contact | undefined => {
    return currentContact;
  };

  const getClientContact = (): Contact | undefined => {
    return contacts.find((contact) => contactContainsType(contact, ContactType.CLIENT));
  };

  const isAccountPrivate = (): boolean | undefined => {
    const clientContact = getClientContact();
    return clientContact && clientContact.types.length > 1;
  };

  const inviteMemberToAccount = async (email: string, roleId: string, teamId: string): Promise<MutationStatus> => {
    const clientName = getContactNameFromObject(getClientContact());
    const result = (await mutation<{ status: boolean; id: string }, InviteUserToClientAccountMutationVariables>(
      inviteUserToClientAccount,
      {
        input: {
          clientId: clientId!,
          email,
          hash: getHash(clientName, email, ContactType.MEMBER),
          roleId,
          teamId,
          language,
          accountType,
        },
      }
    )) as MutationStatus;

    return result;
  };

  const updateUser = async (original: User, updates: Partial<User>): Promise<User> => {
    const result = await mutation<User>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_USER', payload: { user: result } });
    return result;
  };

  const deleteUser = async (id: string) => {
    await deleteEntityWithFetchBefore<Pick<User, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_USER', payload: { id } });
  };

  const disableUser = async (id: string) => {
    const fetchedUser = await fetchUser(id);
    const result = await mutation<User>(updateMutation, {
      input: { id: fetchedUser.id, status: UserStatus.DISABLED, refreshToken: true, _version: fetchedUser._version },
    });
    contextDispatch({ type: 'UPDATE_USER', payload: { user: result } });
    return result;
  };

  const enableUser = async (id: string) => {
    const fetchedUser = await fetchUser(id);
    const newStatus = fetchedUser.cognitoSub ? UserStatus.JOINED : UserStatus.PENDING;
    const result = await mutation<User>(updateMutation, {
      input: { id: fetchedUser.id, status: newStatus, refreshToken: true, _version: fetchedUser._version },
    });
    contextDispatch({ type: 'UPDATE_USER', payload: { user: result } });
    return result;
  };

  const deepDeleteUser = async (user: User) => {
    const deletePromises: Promise<UserTeam | void>[] = [];
    deletePromises.push(deleteUser(user.id));
    if (user.contact) deletePromises.push(deleteContact(user.contact.id));
    userTeams.forEach((userTeam) => {
      if (userTeam.user && userTeam.user.id === user.id) deletePromises.push(deleteUserTeam(userTeam.id));
    });
    await Promise.all(deletePromises);
  };

  const getUserTeam = (id: string): UserTeam | undefined => {
    if (loading) {
      return undefined;
    }
    return userTeams.find((userTeam) => userTeam.id === id);
  };

  const createUserTeam = async (input: Omit<UserTeam, 'id' | 'clientId' | 'readId'>): Promise<UserTeam> => {
    const userTeam = await mutation<UserTeam, CreateMutationVariables>(createUserTeamMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_USER_TEAM),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME_USER_TEAM),
      },
    });
    contextDispatch({ type: 'ADD_USER_TEAM', payload: { userTeam } });
    return userTeam;
  };

  const updateUserTeam = async (original: UserTeam, updates: Partial<UserTeam>) => {
    const result = await mutation<UserTeam>(updateUserTeamMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_USER_TEAM', payload: { userTeam: result } });
    return result;
  };

  const deleteUserTeam = async (id: string) => {
    const userTeam = getUserTeam(id)!;
    await deleteEntityWithFetchBefore<Pick<UserTeam, 'id'>, DeleteUserTeamMutationVariables>(
      { id },
      getUserTeamQuery,
      deleteUserTeamMutation
    );

    contextDispatch({ type: 'DELETE_USER_TEAM', payload: { id } });
    return userTeam;
  };

  const values = useMemo(
    () => ({
      users,
      error: null,
      loading,
      getUser,
      getUserFromContact,
      getCurrentCognitoUser,
      getCurrentContact,
      getClientContact,
      isAccountPrivate,
      inviteMemberToAccount,
      deleteUser,
      disableUser,
      enableUser,
      deepDeleteUser,
      updateUser,
      createUserTeam,
      updateUserTeam,
      deleteUserTeam,
      setFetchUser,
      setFetchUserTeam,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, loading]
  );

  return <UsersContext.Provider value={values}>{children}</UsersContext.Provider>;
};

export const useUsers = (): UsersContext => {
  const context = useContext<UsersContext | null>(UsersContext);

  if (context === undefined) {
    throw new Error('`useUsers` hook must be used within a `UsersContextProvider` component');
  }
  return context as UsersContext;
};
