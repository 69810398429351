import React, { useState } from 'react';
import { makeStyles, Typography, Divider, DialogTitle, DialogContent } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import isNil from 'lodash/isNil';
import { ActionButton } from '@up2rent/ui';
import TextButton from './TextButton';
import { useIntl } from 'react-intl';
import { EmptyFileWithUrl } from '../LeaseInventory/LeaseInventoryEncoding/InventoryStructureEncodingExtraInfos';
import PictureAnnotatorDialog from './PictureAnnotatorDialog';
import PreviewFilePDFDialogDetail from './PreviewFilePDFDialogDetail';
import { Skeleton } from '@material-ui/lab';
import { S3Object } from '@rentguru/commons-utils';

const useStyles = makeStyles({
  paperWidthFalse: {
    maxWidth: 'none',
    borderRadius: 10,
  },
});

interface PreviewFileDialogsProps {
  file: S3Object | EmptyFileWithUrl | null;
  open: boolean;
  editFunction?: (oldFile: EmptyFileWithUrl | S3Object, file: File) => void;
  onClose: () => void;
  paperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  withSignatures?: boolean;
  withFileName?: boolean;
  loading?: boolean;
}

const PreviewFileDialog: React.FC<PreviewFileDialogsProps> = ({
  file,
  open,
  editFunction = undefined,
  onClose,
  style = {},
  paperStyle = {},
  withSignatures,
  withFileName = true,
  loading = false,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [editPicture, setEditPicture] = useState(false);

  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.paperWidthFalse }}
        PaperProps={{ style: { width: 640, ...paperStyle } }}
        style={style}
      >
        <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
          <Skeleton width={600} />
        </DialogContent>
      </Dialog>
    );
  }

  if (isNil(file)) return null;
  const mimeType = 'file' in file ? file.file.type : file.mimeType;
  const fileName = 'file' in file ? file.file.name : file.fileName;

  if (editPicture) {
    return (
      <PictureAnnotatorDialog
        file={file}
        open={editPicture}
        onClose={() => setEditPicture(false)}
        editFunction={editFunction!}
      />
    );
  }
  const isPDF = !isNil(mimeType) && mimeType.includes('pdf');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paperWidthFalse }}
      PaperProps={isPDF ? { style: paperStyle } : { style: { width: 640, ...paperStyle } }}
      style={style}
    >
      {isPDF && <PreviewFilePDFDialogDetail file={file} withSignatures={withSignatures} />}
      {!isNil(mimeType) && mimeType.includes('image') && (
        <>
          <DialogTitle>
            <div
              style={{
                marginLeft: 30,
                marginRight: 30,
                display: 'flex',
                justifyContent: !isNil(editFunction) ? 'space-between' : 'flex-start',
                alignItems: 'center',
              }}
            >
              {withFileName && <Typography style={{ fontSize: 18, fontWeight: 700 }}>{fileName}</Typography>}
              {!isNil(editFunction) && (
                <TextButton
                  style={{ fontSize: 11, letterSpacing: 1.1, fontWeight: 800 }}
                  onClick={() => {
                    setEditPicture(true);
                  }}
                >
                  {formatMessage({ id: 'leaseInventory.editFile.annotate' })}
                </TextButton>
              )}
            </div>
          </DialogTitle>
          <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={file.url} style={{ maxHeight: 550, maxWidth: 550 }} alt={fileName} />
          </DialogContent>
        </>
      )}
      <Divider style={{ marginBottom: 20, marginTop: 10 }} />
      <div
        style={{
          marginLeft: 30,
          marginRight: 30,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 20,
        }}
      >
        <TextButton style={{ fontSize: 11, letterSpacing: 1.1, fontWeight: 800 }} onClick={onClose}>
          {formatMessage({ id: 'close' })}
        </TextButton>
        <ActionButton
          style={{ minWidth: 120, height: 50, fontSize: 14, fontWeight: 800 }}
          href={file.url}
          target="_blank"
        >
          {formatMessage({ id: 'download' })}
        </ActionButton>
      </div>
    </Dialog>
  );
};

export default PreviewFileDialog;
