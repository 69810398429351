/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Dialog from 'src/components/ui/Dialog';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil, isNull, isNumber, toNumber, toUpper } from 'lodash';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Add from '@material-ui/icons/Add';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { filterLeasesForLink, getLeaseCustomMenuItems, getContractorCustomMenuItems } from '../utils';
import {
  filterAndReturnFilteredElements,
  formatNumber,
  LinkTo,
  linkTransactionToInvoice,
  LinkType,
  saveAccountAndTransactionsWithLink,
  uniquePush,
  roundAtSecondDecimal,
  Colors,
  TransactionLinkType,
  TransactionStatus,
  ModelWithVersion,
  Transaction,
} from '@rentguru/commons-utils';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import SplitTransactionDialogRow from './SplitTransactionDialogRow';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import { InvoiceWithPostings, useInvoices } from 'src/hooks/InvoicesContext';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
  leaseSelectPaper: {
    width: '350px',
    transform: 'translateX(-35px)', // (350px - 280px) / 2
  },
});

const SplitTransactionSchema = Yup.object().shape({
  totalAmount: Yup.number()
    .required()
    .test('totalAmountReached', 'TOTAL_AMOUNT_PROBLEM', function (value) {
      if (!this.parent.links) {
        return false;
      }
      const amounts = this.parent.links.reduce((total: number, acc: LinkTo) => {
        if (!acc.amount || !isNumber(acc.amount)) {
          return total;
        }
        return total + acc.amount;
      }, 0);

      return roundAtSecondDecimal(amounts) === Math.abs(toNumber(value));
    }),
  links: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().positive().required(),
      type: Yup.string().required(),
      id: Yup.string().when('type', {
        is: 'Lease',
        then: Yup.string().nullable(),
        otherwise: Yup.string().required().min(1),
      }),
      leaseId: Yup.string().when('type', {
        is: 'Lease',
        then: Yup.string().required().min(1),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
});

interface LinkValue {
  type?: LinkType;
  leaseId?: string;
  id?: string; // contact id
  invoiceMatch?: InvoiceWithPostings | null; // In case of agency transactions must be linked to invoices for lease
  amount: number;
}

export interface SplitTransactionValues {
  totalAmount: number;
  links: LinkValue[];
}

interface SplitTransactionDialogProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const SplitTransactionDialog: React.FC<SplitTransactionDialogProps> = ({ open, onClose, id }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const history = useHistory();
  const classes = useStyles();
  const {
    updateTransaction,
    createPosting,
    getTransaction,
    transactionRefetch,
    getAccountLabelForBankAccountOrInsert,
    getAccountLabelForLease,
    getAccountLabelForContactOrInsert,
    transactionsLoading,
  } = useTransactions();
  const { updateInvoice } = useInvoices();
  const { leasesLoading, leases, getLease } = useLeases();
  const { getContact, contactsLoading, contractors } = useContacts();
  const { units, unitsLoading } = useUnits();
  const transaction = getTransaction(id);

  const dataLoading = leasesLoading || contactsLoading || unitsLoading || transactionsLoading;

  if (dataLoading || !transaction) {
    return null;
  }

  const handleSplit = async (
    values: SplitTransactionValues,
    { setSubmitting, setStatus }: FormikHelpers<SplitTransactionValues>
  ) => {
    const links = values.links.filter((link) => !isNil(link.type));
    const leaseIdsToReconcile: string[] = [];
    const linkPromises: Promise<void>[] = [];
    // In case of agency we link to the invoice for the leases linksS
    const [contractorLinks, leaseLinks] = filterAndReturnFilteredElements(
      links,
      (link: LinkValue) => link.type === LinkType.Contractor
    );
    // Same logic as other for contractors (no lettering)
    linkPromises.push(
      saveAccountAndTransactionsWithLink(
        transaction,
        contractorLinks.map((link) => ({
          ...link,
          type: link.type!,
          // Return negative value for posting creation in debit/credit
          amount: values.totalAmount < 0 ? link.amount * -1 : link.amount,
        })) as LinkTo[],
        units,
        getLease,
        getContact,
        getAccountLabelForBankAccountOrInsert,
        getAccountLabelForLease,
        getAccountLabelForContactOrInsert,
        createPosting
      )
    );
    // Link the leases to their invoice or add their leaseId for reconciliation
    for (const leaseLink of leaseLinks) {
      if (leaseLink.invoiceMatch) {
        const lease = getLease(leaseLink.leaseId!);
        const accountForLease = getAccountLabelForLease();
        const accountForBankAccount = await getAccountLabelForBankAccountOrInsert(transaction.bankAccount!);
        if (lease)
          linkPromises.push(
            linkTransactionToInvoice(
              transaction,
              lease,
              leaseLink.invoiceMatch,
              leaseLink.invoiceMatch.postings,
              accountForLease,
              accountForBankAccount,
              createPosting,
              updateInvoice
            )
          );
      } else {
        uniquePush(leaseIdsToReconcile, leaseLink.leaseId!);
      }
    }

    const linkPromise = Promise.all(linkPromises);
    const transactionReconciled = isEmpty(leaseIdsToReconcile);
    // Partially reconciled = Reconciliation needed on
    // a lease but some links were reconciled with contractor and/or lease
    const transactionPartiallyReconciled =
      !isEmpty(contractorLinks) || leaseIdsToReconcile.length !== leaseLinks.length;
    const newTransactionStatus = transactionReconciled
      ? TransactionStatus.RECONCILED
      : transactionPartiallyReconciled
      ? TransactionStatus.PARTIALLY_RECONCILED
      : TransactionStatus.TO_RECONCILE;

    const splits = links.map((link) => {
      return {
        amount: values.totalAmount < 0 ? link.amount * -1 : link.amount,
        linkId: link.id ?? (link.leaseId as string),
        linkType: link.id ? TransactionLinkType.CONTACT : TransactionLinkType.LEASE,
      };
    });
    const splitPromise = updateTransaction({
      id: transaction.id,
      _version: (transaction as ModelWithVersion<Transaction>)._version,
      links: splits,
      status: newTransactionStatus,
    });
    await Promise.all([linkPromise, splitPromise]);

    setStatus(true);
    setSubmitting(false);
    if (!isEmpty(leaseIdsToReconcile)) {
      // We need to reconcile transactions
      history.push({ pathname: RouteDestination.RECONCILE_TRANSACTION, state: { leaseIds: leaseIdsToReconcile } });
    }
    await transactionRefetch();
    onClose();
  };

  const initialValues: SplitTransactionValues = {
    totalAmount: transaction.amount,
    links: [{ amount: Math.abs(transaction.amount / 2) }, { amount: Math.abs(transaction.amount / 2) }],
  };

  // Link To display
  const filteredLeases = filterLeasesForLink(leases);
  const leasesMenuItems = getLeaseCustomMenuItems(transaction, filteredLeases);
  const contractorsMenuItems = getContractorCustomMenuItems(transaction, contractors);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      scroll="body"
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { width: 640 } }}
    >
      <Formik initialValues={initialValues} validationSchema={SplitTransactionSchema} onSubmit={handleSplit}>
        {({ values, isSubmitting, status, setFieldValue }) => {
          const [selectedLeaseIds, selectedContractorsIds] = values.links.reduce(
            (acc: [string[], string[]], link) => {
              const { type, id, leaseId } = link;
              if (type === LinkType.Contractor && id) {
                acc[1].push(id);
              } else if (type === LinkType.Lease && leaseId) {
                acc[0].push(leaseId);
              }
              return acc;
            },
            [[], []]
          );
          // Cannot split on same lease or same contractor !
          const filteredLeaseCustomItems = leasesMenuItems.filter(
            (c) => c.value.leaseId && !selectedLeaseIds.includes(c.value.leaseId)
          );
          const filteredContractorCustomItems = contractorsMenuItems.filter(
            (c) => c.value.contractorId && !selectedContractorsIds.includes(c.value.contractorId)
          );

          const addNewEmptySplit = () => {
            setFieldValue('links', [...values.links, { amount: 0 }]);
          };
          const decreaseNumberOfSplits = (index: number) => {
            setFieldValue(
              'links',
              values.links.filter((_m, idx) => index !== idx)
            );
          };
          const currentAmount = roundAtSecondDecimal(
            values.links.reduce((total: number, acc: Partial<LinkTo>) => {
              if (!acc.amount || !isNumber(acc.amount)) {
                return total;
              }
              return total + acc.amount;
            }, 0)
          );
          const warning = values.links.some((l) => isNull(l.invoiceMatch));
          return (
            <Form>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: 30 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                    {formatMessage({ id: `transactions.addTransaction.split` })}
                  </Typography>
                  <HelpIconPopover
                    helperText={formatMessage({ id: `transactions.lettering.splitHelper` })}
                    paperStyle={{ maxWidth: 300 }}
                    iconStyle={{ marginLeft: 10 }}
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      display: 'inline-block',
                      color:
                        currentAmount !== Math.abs(values.totalAmount) ? Colors.CARNATION_RED : Colors.TURQUOISE_BLUE,
                      fontSize: 14,
                      fontWeight: 700,
                      marginRight: 5,
                    }}
                  >
                    {formatNumber(currentAmount, language, { style: 'currency', currency: 'EUR' })}
                  </Typography>
                  <Typography
                    style={{ display: 'inline-block', color: Colors.CHARCOAL_GREY, fontSize: 14, fontWeight: 700 }}
                  >
                    {` / ${formatNumber(Math.abs(values.totalAmount), language, {
                      style: 'currency',
                      currency: 'EUR',
                    })}`}
                  </Typography>
                </div>
              </div>
              <Divider style={{ marginBottom: 20 }} />
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                {values.links.map((link, index) => {
                  const { type, leaseId, id } = link;
                  const splitFilteredLeaseCustomItems = [...filteredLeaseCustomItems];
                  const splitFilteredContractorCustomItems = [...filteredContractorCustomItems];

                  if (type === LinkType.Lease && leaseId) {
                    const currentLease = getLease(leaseId);
                    if (currentLease) {
                      splitFilteredLeaseCustomItems.push(...getLeaseCustomMenuItems(transaction, [currentLease]));
                    }
                  } else if (type === LinkType.Contractor && id) {
                    const currentContractor = getContact(id);
                    if (currentContractor) {
                      splitFilteredContractorCustomItems.push(
                        ...getContractorCustomMenuItems(transaction, [currentContractor])
                      );
                    }
                  }
                  return (
                    <SplitTransactionDialogRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      index={index}
                      leasesMenuItems={splitFilteredLeaseCustomItems}
                      contractorsMenuItems={splitFilteredContractorCustomItems}
                      decreaseNumberOfSplits={decreaseNumberOfSplits}
                      transaction={transaction}
                    />
                  );
                })}
                {warning && (
                  <Typography style={{ color: Colors.BURNING_ORANGE }}>
                    {formatMessage({ id: `transactions.lettering.splitWarning` })}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 30,
                  marginBottom: 20,
                  alignItems: 'center',
                }}
              >
                <ActionButton onClick={addNewEmptySplit} className={classes.addButton} data-test="add-mortgage">
                  <Add />
                  {toUpper(formatMessage({ id: 'transactions.list.split' }))}
                </ActionButton>
                <div
                  style={{
                    display: 'flex',
                    float: 'right',
                    alignItems: 'center',
                  }}
                >
                  <ActionButton
                    onClick={() => onClose()}
                    style={{
                      background: 'none',
                      color: Colors.DARK_SLATE_GREY,
                      marginRight: 20,
                    }}
                  >
                    {toUpper(formatMessage({ id: 'cancel' }))}
                  </ActionButton>
                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({ id: 'save' })}
                  </LoaderButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default SplitTransactionDialog;
