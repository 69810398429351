import React, { useContext, useEffect, useMemo } from 'react';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { useUser } from './UserContext';
import {
  Team,
  UserTeam,
  User,
  cleanInputCreate,
  cleanInputUpdate,
  resolveManyToMany,
  getReadId,
  getTableClientId,
  syncTeams as syncQuery,
  getTeam as getQuery,
  createTeam as createMutation,
  updateTeam as updateMutation,
  deleteTeam as deleteMutation,
  CreateTeamMutationVariables as CreateMutationVariables,
  DeleteTeamMutationVariables as DeleteMutationVariables,
  CreateTeamInput as CreateInput,
  Model,
  isNilOrEmpty,
  OnCreateTeamSubscription,
  OnUpdateTeamSubscription,
  OnDeleteTeamSubscription,
  onCreateTeam,
  onUpdateTeam,
  onDeleteTeam,
} from '@rentguru/commons-utils';
import { UsersContext, useUsers } from './UsersContext';
import {
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  useSubscriptions,
} from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'Team';

export type ActionTeam =
  | {
      type: 'SHOULD_FETCH_TEAM' | 'IS_FETCHING_TEAM';
    }
  | {
      type: 'ADD_TEAM';
      payload: { team: Team };
    }
  | {
      type: 'FETCHED_TEAM';
      payload: { teams: Team[] };
    }
  | {
      type: 'UPDATE_TEAM';
      payload: { team: Team };
    }
  | {
      type: 'DELETE_TEAM';
      payload: { id: string };
    };
export interface TeamsContext {
  loading: boolean;
  error: string | null;
  teams: Team[];
  getTeam: (id: string) => Team | undefined;
  getTreeTeams: () => Team[];
  createTeam: (input: Omit<Team, 'id' | 'clientId' | 'readId'>) => Promise<Team>;
  updateTeam: (original: Team, updates: Partial<Team>) => Promise<Team>;
  deleteTeam: (id: string) => Promise<void>;
  getUserTeams: (userId: string) => Team[];
  getTeamUsers: (teamId: string) => User[];
  updateUserTeam: UsersContext['updateUserTeam'];
  deleteUserTeam: UsersContext['deleteUserTeam'];
  setFetchTeam: () => void;
}

export const teamReducerDelegation = (state: ContextLoaderStore, action: ContextLoaderAction): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_TEAM':
      if (state.Team.loading || state.Team.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Team: { ...state.Team, shouldFetch: true },
      };
    case 'IS_FETCHING_TEAM':
      if (state.Team.loading) {
        return state;
      }
      return {
        ...state,
        Team: { ...state.Team, loading: true },
      };
    case 'FETCHED_TEAM':
      return {
        ...state,
        Team: {
          ...state.Team,
          data: action.payload.teams,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_TEAM':
      // Check if already present - If already added by this user or coming from another user
      if (state.Team.data?.find((object) => object.id === action.payload.team.id)) {
        return state;
      }

      return {
        ...state,
        Team: {
          ...state.Team,
          data: [...state.Team.data, action.payload.team],
        },
      };
    case 'UPDATE_TEAM':
      // No data
      if (isNilOrEmpty(state.Team.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Team.data?.find((object) => object.id === action.payload.team.id);
      if (!currentObject) {
        return {
          ...state,
          Team: {
            ...state.Team,
            data: [...state.Team.data, action.payload.team],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.team)) {
        return state;
      }

      // Update
      return {
        ...state,
        Team: {
          ...state.Team,
          data: state.Team.data.map((object) => {
            if (object.id === action.payload.team.id) {
              return action.payload.team;
            }
            return object;
          }),
        },
      };
    case 'DELETE_TEAM':
      if (isNilOrEmpty(state.Team.data)) {
        return state;
      }
      return {
        ...state,
        Team: {
          ...state.Team,
          data: state.Team.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

const fetchTeams = async (
  by: 'byClientId' | 'byParent',
  byValue: string,
  additionalFilter?: object
): Promise<Team[]> => {
  return await list<Team>(syncQuery, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

// eslint-disable-next-line no-redeclare
export const TeamsContext = React.createContext<TeamsContext | null>(null);

export const TeamsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    Team: { data: teamsLoader, loading: teamLoading, shouldFetch },
    UserTeam: { data: userTeams, loading: userTeamsLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { clientId } = useUser();
  const { updateUserTeam, deleteUserTeam } = useUsers();
  const loading = teamLoading || userTeamsLoading;

  const setFetchTeam = () => {
    contextDispatch({ type: 'SHOULD_FETCH_TEAM' });
  };

  const teams = useMemo<Team[]>(() => {
    if (loading) {
      return [];
    }

    const teamsReduced = teamsLoader.reduce((acc: Team[], team: Team) => {
      acc.push({
        ...team,
        users: resolveManyToMany(team, 'team', userTeams),
      });
      return acc;
    }, []);
    return teamsReduced;
  }, [teamsLoader, userTeams, loading]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_TEAM' });
      const result = await fetchTeams('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME));
      contextDispatch({ type: 'FETCHED_TEAM', payload: { teams: result } });
    };
    if (shouldFetch) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useSubscriptions<OnCreateTeamSubscription, OnUpdateTeamSubscription, OnDeleteTeamSubscription>(
    onCreateTeam,
    onUpdateTeam,
    onDeleteTeam,
    (data) => {
      contextDispatch({
        type: 'ADD_TEAM',
        payload: { team: data.onCreateTeam as Team },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_TEAM',
        payload: { team: data.onUpdateTeam as Team },
      });
    },
    (data) => {
      const { id } = data.onDeleteTeam as Team;
      contextDispatch({
        type: 'DELETE_TEAM',
        payload: { id },
      });
    }
  );

  const createTeam = async (input: Omit<Team, 'id' | 'clientId' | 'readId'>) => {
    const team = await mutation<Team, CreateMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_TEAM', payload: { team } });
    return team;
  };

  const updateTeam = async (original: Team, updates: Partial<Team>) => {
    const result = await mutation<Team>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_TEAM', payload: { team: result } });
    return result;
  };

  const deleteTeam = async (id: string) => {
    await deleteEntityWithFetchBefore<Pick<Team, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);

    contextDispatch({ type: 'DELETE_TEAM', payload: { id } });
  };

  const getTeam = (id: string): Team | undefined => {
    if (loading) {
      return undefined;
    }
    return teams.find((t) => t.id === id);
  };

  const getTreeTeams = () => {
    if (loading) {
      return [];
    }
    const hashTable = Object.create(null);
    // eslint-disable-next-line no-return-assign
    teams.forEach((team) => (hashTable[team.id] = { ...team, children: [] }));
    const dataTree: Team[] = [];
    teams.forEach((team) => {
      if (team.parentId && hashTable[team.parentId]) hashTable[team.parentId].children.push(hashTable[team.id]);
      else dataTree.push(hashTable[team.id]);
    });
    return dataTree;
  };

  const getUserTeams = (userId: string): Team[] => {
    return userTeams.reduce((acc: Team[], userTeam: UserTeam) => {
      if (userTeam.user!.id === userId) acc.push(userTeam.team!);
      return acc;
    }, []);
  };

  const getTeamUsers = (teamId: string): User[] => {
    return userTeams.reduce((acc: User[], userTeam: UserTeam) => {
      if (userTeam.team!.id === teamId) acc.push(userTeam.user!);
      return acc;
    }, []);
  };

  const values = useMemo(
    () => ({
      teams,
      error: null,
      loading,
      getTeam,
      getTreeTeams,
      createTeam,
      updateTeam,
      deleteTeam,
      getUserTeams,
      getTeamUsers,
      updateUserTeam,
      deleteUserTeam,
      setFetchTeam,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams, loading]
  );

  return <TeamsContext.Provider value={values}>{children}</TeamsContext.Provider>;
};

export const useTeams = (): TeamsContext => {
  const context = useContext<TeamsContext | null>(TeamsContext);

  if (context === undefined) {
    throw new Error('`useTeams` hook must be used within a `TeamsContextProvider` component');
  }
  return context as TeamsContext;
};
