/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { ElevatedPaper, MOBILE_MAX_WIDTH } from '@up2rent/ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InputBase, ClickAwayListener, Grid, useMediaQuery } from '@material-ui/core';
import { ReactComponent as CommunicationSvg } from 'src/icons/communication.svg';
import { useIntl } from 'react-intl';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { isNil } from 'lodash';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, FormikHelpers } from 'formik';

import LoaderButtonVerySmall from 'src/components/ui/LoaderButtonVerySmall';
import { stateToHTML } from 'draft-js-export-html';
import { ReactComponent as SendSvg } from 'src/icons/send.svg';
import {
  Ticket,
  File as DynamoFile,
  TicketSourceType,
  Contact,
  File as FileModel,
  TicketStatus,
  FileCategory,
  getFirstLettersUppercase,
  EntityType,
  Colors,
  MAX_SIZE_ATTACHMENT,
  fileExists,
} from '@rentguru/commons-utils';
import { stripHTML } from 'src/utils/stripHTML';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import ConditionalParentElement from 'src/components/RentalUnits/Details/Publication/EditPublication/ConditionalParentElement';
import { TicketContext } from 'src/hooks/TicketsContext';
import { v4 as uuidv4 } from 'uuid';

import DropZoneField from 'src/components/ui/Forms/FormField/DropZoneField';
import { CSSProperties } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        color: Colors.ROLLING_STONE_GREY,
      },
    },
    h6: {
      textAlign: 'left',
      paddingTop: 12,
      color: Colors.CLASSICAL_BLACK,
      fontFamily: 'Mulish',
      fontSize: '15px',
      fontWeight: 'bold',
    },
    wrapperInner: {
      zIndex: 1,
    },
    sendButton: {
      width: '20%',
    },
  })
);

const getStateFromHTML = (htmlContent: string) => {
  const blocksFromHTML = convertFromHTML(htmlContent);
  const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
  return state;
};
interface TicketDetailNewMessageProps {
  name: string;
  color: string;
  newMessageSubject: string;
  ticket?: Ticket;
  currentUser: Contact;
  createTicket: TicketContext['createTicket'];
  createFile: (
    file: File,
    entityType: EntityType,
    entityId: string,
    category?: FileCategory
  ) => Promise<FileModel | null>;
  isTenant?: boolean;
  leaseId?: string;
  onCloseDialog?: () => void;
  style: CSSProperties;
}

interface NewMessageObject {
  file: File[];
}
const TicketDetailNewMessage: React.FC<TicketDetailNewMessageProps> = ({
  name,
  color,
  newMessageSubject,
  ticket,
  createFile,
  currentUser,
  createTicket,
  isTenant = false,
  leaseId,
  onCloseDialog,
  style,
}) => {
  const [totalFileSize, setTotalFileSize] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [textCollapse, setTextCollapse] = useState(isNil(ticket));
  const [myFiles, setMyFiles] = useState([] as File[]);
  const [subjectEditor, setSubjectEditor] = React.useState(
    !isNil(newMessageSubject)
      ? EditorState.createWithContent(getStateFromHTML(newMessageSubject))
      : EditorState.createEmpty()
  );
  const [bodyEditor, setBodyEditor] = React.useState(EditorState.createEmpty());
  const [subjectError, setSubjectError] = React.useState<boolean>(false);
  const [contentError, setContentError] = React.useState<boolean>(false);
  const errorMessage: string = formatMessage({ id: 'tickets.detail.newConversationError' });
  const errorSubjectMessage: string = formatMessage({ id: 'tickets.detail.newSubject' });
  const white = Colors.CLASSICAL_WHITE;
  const isMobileLayout = useMediaQuery(MOBILE_MAX_WIDTH);
  const tenantMobileView = isTenant && isMobileLayout;

  useEffect(() => {
    // cursor automatically set in text area when not collapsed
    if (textCollapse) {
      const selectionState = bodyEditor.getSelection();
      const newEditorState = EditorState.forceSelection(bodyEditor, selectionState);
      setBodyEditor(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textCollapse]);

  useEffect(() => {
    // error message disappears if text is not empty
    const currentMessage = stateToHTML(bodyEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    if (contentError && !isEmpty(stripHTML(currentMessage))) {
      setContentError(false);
    }
  }, [bodyEditor, contentError, setContentError]); // message content

  useEffect(() => {
    // error message disappears if text is not empty
    const currentSubject = stateToHTML(subjectEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    if (subjectError && !isEmpty(stripHTML(currentSubject))) {
      setSubjectError(false);
    }
  }, [subjectEditor, subjectError, setSubjectError]); // message subject

  const handleClick = () => {
    setTextCollapse(true);
  };
  const handleClickAway = () => {
    const currentMessage = stateToHTML(bodyEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    // if empty text and no file => collapse else not
    if (isEmpty(stripHTML(currentMessage)) && isEmpty(myFiles)) setTextCollapse(false);
  };

  const handleAddConversation = async (
    newMessageValues: NewMessageObject,
    { setSubmitting, setStatus }: FormikHelpers<NewMessageObject>
  ) => {
    const creationDate = new Date().toJSON();
    const cleanedSubject = stateToHTML(subjectEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');
    const cleanedContent = stateToHTML(bodyEditor.getCurrentContent()).replace(/(\r\n|\n|\r)/gm, '');

    if (
      !isNil(stripHTML(cleanedContent)) &&
      !isEmpty(stripHTML(cleanedContent)) &&
      !isNil(stripHTML(cleanedSubject)) &&
      !isEmpty(stripHTML(cleanedSubject))
    ) {
      // Create conversation
      const conversationInput = {
        id: uuidv4(),
        createdAt: creationDate,
        subject: cleanedSubject,
        contactId: currentUser.id,
        sourceType: TicketSourceType.WEB,
        content: cleanedContent,
        parentId: !isNil(ticket) ? ticket.id : undefined,
        status: !isNil(ticket) ? undefined : TicketStatus.PENDING,
        unread: true,
      };

      // Files

      const attachmentsPromises: Promise<DynamoFile | null>[] = [];
      // Add to bucket
      for (const fileItem of newMessageValues.file) {
        attachmentsPromises.push(createFile(fileItem, EntityType.TICKET, conversationInput.id));
      }
      await Promise.all(attachmentsPromises);

      const conversation = await createTicket(conversationInput);

      setContentError(false);
      setStatus(true);
      setSubmitting(false);
      if (!isTenant) setTextCollapse(false);
      if (!isNil(onCloseDialog)) onCloseDialog();
      if (isNil(ticket)) {
        if (!isTenant) history.push(`${RouteDestination.TICKETS_DETAILS}/${conversation.id}`);
        else history.push(`${RouteDestination.LEASES}/${leaseId}${RouteDestination.TICKETS}`);
      } else setBodyEditor(EditorState.createEmpty());
    } else {
      if (isNil(stripHTML(cleanedContent)) || isEmpty(stripHTML(cleanedContent))) setContentError(true);
      if (isNil(stripHTML(cleanedSubject)) || isEmpty(stripHTML(cleanedSubject))) setSubjectError(true);
      setStatus(false);
      setSubmitting(false);
    }
  };
  return (
    <>
      <ConditionalParentElement
        parentElement={!isNil(ticket)}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(children: React.ReactNode) => (
          <ElevatedPaper style={{ textAlign: 'left', paddingLeft: 10, paddingRight: 10 }}>{children}</ElevatedPaper>
        )}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Collapse
            collapsedSize={48}
            in={tenantMobileView || textCollapse}
            classes={{ wrapperInner: classes.wrapperInner }}
          >
            <Grid className={classes.root} onClick={handleClick} style={style}>
              {!textCollapse ? (
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs={2} style={{ display: 'flex' }}>
                    <Avatar
                      style={{
                        fill: white,
                        backgroundColor: Colors.SLATE_GREY,
                        paddingBottom: 1,
                        paddingTop: 1,
                        paddingLeft: 1,
                        paddingRight: 1,
                        borderRadius: 50,
                      }}
                    >
                      <CommunicationSvg />
                    </Avatar>
                    <Avatar
                      style={{
                        color: `${color}`,
                        backgroundColor: white,
                        border: `1.5px ${color} solid`,
                        boxShadow: `0px 0px 0px 2px ${white}`,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginLeft: -3,
                      }}
                    >
                      {getFirstLettersUppercase(name)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <InputBase
                      id="standard-basic"
                      placeholder={formatMessage({ id: 'tickets.detail.newConversation' })}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                <Formik
                  initialValues={{
                    file: [] as File[],
                  }}
                  onSubmit={handleAddConversation}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ values, setFieldValue, isSubmitting, status }) => {
                    return (
                      <Form>
                        <Grid
                          container
                          style={{
                            display: 'flex',
                            flexFlow: 'row wrap',
                            justifyContent: 'flex-end',
                          }}
                          direction="column"
                        >
                          <Grid item xs={12} style={{ marginBottom: 180 }}>
                            <TemplateEditor
                              title={subjectEditor}
                              setTitle={setSubjectEditor}
                              body={bodyEditor}
                              setBody={setBodyEditor}
                              editable={true}
                              editMode={true}
                              error={contentError}
                              errorMessage={errorMessage}
                              errorSubject={subjectError}
                              errorSubjectMessage={errorSubjectMessage}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <DropZoneField
                              displayingPictures
                              onDrop={(acceptedFiles: File[]) => {
                                if (isEmpty(acceptedFiles)) {
                                  return;
                                }
                                const uniqueNewFiles = acceptedFiles.filter((file) => !fileExists(file, myFiles));

                                const updatedFiles = [...myFiles, ...uniqueNewFiles];
                                setMyFiles(updatedFiles);
                                setFieldValue('file', [...values.file, ...uniqueNewFiles]);
                              }}
                              currentFiles={
                                values.file
                                  ? values.file.map((acceptedFile: File) => ({
                                      mimeType: acceptedFile.type,
                                      name: acceptedFile.name,
                                      size: acceptedFile.size,
                                      file: acceptedFile,
                                    }))
                                  : []
                              }
                              isDisplayList={true}
                              multiple={true}
                              maxSize={MAX_SIZE_ATTACHMENT}
                              height={60}
                              width={'100%'}
                              disableGuttersForAdd
                              accept={[
                                'application/vnd.ms-excel',
                                'application/pdf',
                                'application/msword',
                                'application/zip',
                                'text/csv',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'image/*',
                                'video/*',
                                'text/plain',
                              ]}
                              disabled={isSubmitting}
                              removeFileByIndex={(index: number) => {
                                const newFiles = [...myFiles];
                                if (index > -1) {
                                  newFiles.splice(index, 1);
                                }
                                setMyFiles(newFiles);
                                setFieldValue('file', newFiles);
                              }}
                              onTotalSizeChange={setTotalFileSize}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <LoaderButtonVerySmall
                                className={classes.sendButton}
                                disabled={totalFileSize > MAX_SIZE_ATTACHMENT}
                                loading={isSubmitting}
                                success={status}
                              >
                                <SendSvg style={{ width: 17, height: 17, fill: 'white' }} />
                              </LoaderButtonVerySmall>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Grid>
          </Collapse>
        </ClickAwayListener>
      </ConditionalParentElement>
    </>
  );
};

export default TicketDetailNewMessage;
