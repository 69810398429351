import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  DocumentCategory,
  Model,
  getDocumentCategory,
  deleteDocumentCategory as deleteMutation,
  updateDocumentCategory as updateMutation,
  getTableClientId,
  FileCategory,
  syncDocumentCategories,
  createDocumentCategory as createMutation,
  CreateDocumentCategoryMutationVariables as createMutationVariables,
  getReadId,
  cleanInputUpdate,
  CustomLabel,
  EntityType,
  DocumentCategoryWithoutReadIdAndClientId,
} from '@rentguru/commons-utils';

import { useUser } from './UserContext';
import { deleteAndHideEntityWithFetchBefore, getFilterFieldNameForIndex, list, mutation } from '@up2rent/fetch-utils';

const ENTITY_MODEL_NAME: Model = 'DocumentCategory';

export interface FileCategoryContext {
  fileCategories: DocumentCategoryWithoutReadIdAndClientId[];
  loading: boolean;
  fetchDocumentCategories: (entityId: string) => Promise<DocumentCategoryWithoutReadIdAndClientId[]>;
  createFileCategory: (
    fileCategory: CustomLabel[],
    entity: EntityType,
    parentId?: string
  ) => Promise<DocumentCategoryWithoutReadIdAndClientId>;
  updateFileCategory: (
    original: DocumentCategoryWithoutReadIdAndClientId,
    updates: Partial<DocumentCategoryWithoutReadIdAndClientId>
  ) => Promise<DocumentCategoryWithoutReadIdAndClientId>;
  deleteFileCategory: (
    fileCategory: DocumentCategoryWithoutReadIdAndClientId
  ) => Promise<DocumentCategoryWithoutReadIdAndClientId>;
  getDocumentCategoryByFileCategory: (fileCategory: FileCategory) => DocumentCategoryWithoutReadIdAndClientId;
}

const fetchDocumentCategoryWithEntity = async (
  clientId: string,
  entityId: string
): Promise<DocumentCategoryWithoutReadIdAndClientId[]> => {
  const fileCategories = await list<DocumentCategoryWithoutReadIdAndClientId>(
    syncDocumentCategories,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    { entity: { eq: entityId } }
  );
  return fileCategories ?? [];
};

const fetchDocumentCategory = async (clientId: string): Promise<DocumentCategoryWithoutReadIdAndClientId[]> => {
  const fileCategories = await list<DocumentCategoryWithoutReadIdAndClientId>(
    syncDocumentCategories,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME)
  );
  return fileCategories ?? [];
};

// eslint-disable-next-line no-redeclare
export const FileCategoryContext = createContext<FileCategoryContext | null>(null);

export const DocumentCategoryContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { clientId } = useUser();
  const [fileCategories, setDocumentCategories] = useState<DocumentCategoryWithoutReadIdAndClientId[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      if (!clientId) throw new Error('Client id is missing');
      const categories = await fetchDocumentCategory(clientId);
      setDocumentCategories(categories);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDocumentCategories = async (entityId: string): Promise<DocumentCategoryWithoutReadIdAndClientId[]> => {
    if (!clientId) throw new Error('Client id is missing');
    setIsLoading(true);
    const categoriesWithEntity = await fetchDocumentCategoryWithEntity(clientId, entityId);
    const categories = await fetchDocumentCategory(clientId);
    setDocumentCategories([...categories, ...categoriesWithEntity]);
    setIsLoading(false);
    return [...categories, ...categoriesWithEntity];
  };

  const createFileCategory = async (
    labels: CustomLabel[],
    entity: EntityType,
    parentId?: string
  ): Promise<DocumentCategoryWithoutReadIdAndClientId> => {
    if (!clientId) throw new Error('Client id is missing');
    const newCategory = await mutation<DocumentCategory, createMutationVariables>(createMutation, {
      input: {
        labels,
        parentId,
        id: uuidv4(),
        fileCategory: FileCategory.CUSTOM,
        readId: getReadId(clientId, 'DocumentCategory'),
        clientId: getTableClientId(clientId, 'DocumentCategory'),
        entity,
      },
    });
    setDocumentCategories((prevDocumentCategories) => [...prevDocumentCategories, newCategory]);
    return newCategory;
  };

  const updateFileCategory = async (
    original: DocumentCategoryWithoutReadIdAndClientId,
    updates: Partial<DocumentCategoryWithoutReadIdAndClientId>
  ): Promise<DocumentCategoryWithoutReadIdAndClientId> => {
    const updatedCategory = await mutation<DocumentCategory>(updateMutation, {
      input: {
        id: original.id,
        _version: original._version,
        ...cleanInputUpdate(updates, false),
      },
    });
    setDocumentCategories((documentCategoriesState) =>
      documentCategoriesState.map((category) => (category.id === updatedCategory.id ? updatedCategory : category))
    );
    return updatedCategory;
  };

  const deleteFileCategory = async (
    fileCategory: DocumentCategoryWithoutReadIdAndClientId
  ): Promise<DocumentCategoryWithoutReadIdAndClientId> => {
    const result = await deleteAndHideEntityWithFetchBefore<DocumentCategoryWithoutReadIdAndClientId>(
      fileCategory,
      getDocumentCategory,
      deleteMutation,
      updateMutation
    );

    if (result) {
      setDocumentCategories(fileCategories.filter((category) => category.id !== fileCategory.id));
    }

    return result;
  };

  const getDocumentCategoryByFileCategory = (fileCategory: FileCategory): DocumentCategoryWithoutReadIdAndClientId => {
    const selectedfileCategory = fileCategories.find((category) => category.fileCategory === fileCategory);
    if (!selectedfileCategory)
      throw new Error(`Document category with file category ${selectedfileCategory} not found`);
    return selectedfileCategory;
  };

  const values = useMemo(
    () => ({
      fileCategories,
      loading,
      fetchDocumentCategories,
      createFileCategory,
      deleteFileCategory,
      getDocumentCategoryByFileCategory,
      updateFileCategory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileCategories, loading]
  );

  return <FileCategoryContext.Provider value={values}>{children}</FileCategoryContext.Provider>;
};

export const useDocumentCategory = (): FileCategoryContext => {
  const context = useContext<FileCategoryContext | null>(FileCategoryContext);
  if (!context) {
    throw new Error('useDocumentCategory must be used within a DocumentCategoryProvider');
  }
  return context as FileCategoryContext;
};
