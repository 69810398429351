/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, isNil, toUpper } from 'lodash';
import { Button, Chip, Collapse, Divider, Grid, ListItemAvatar, makeStyles, Typography } from '@material-ui/core';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  InventoryItemType,
  LeaseInventoryEncoding,
  File as FileModel,
  Colors,
  S3Object,
} from '@rentguru/commons-utils';
import { classifyInventoryCheckedItems, useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { ExtendedLeaseInventoryEncoding } from 'src/hooks/UnitInventoryContext';
import { getS3ObjectUrls } from 'src/hooks/FilesContext';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { ActionButton , CustomIconButton } from '@up2rent/ui';
import CustomAudioPlayer from 'src/components/ui/CustomAudioPlayer';
import FileItemRendering from 'src/components/ui/FileItemRendering';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { EmptyFile, getEmptyFile } from './InventoryStructureEncoding';
import { AddCheckedItemDialogBundle, FilePreviewDialogBundle } from './InventoryEncodings';

const useStyles = makeStyles({
  addButton: {
    background: Colors.PORCELAIN_GREY_1,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
  removeButton: {
    background: Colors.PORCELAIN_GREY_1,
    color: Colors.CARNATION_RED,
    fontWeight: 600,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

export interface EmptyFileWithUrl extends EmptyFile {
  url: string;
}

interface InventoryStructureEncodingExtraInfosProps {
  encoding?: ExtendedLeaseInventoryEncoding;
  previewFileFunction: (fileBundle: FilePreviewDialogBundle) => void;
  encodeDefects: (defectBundle: AddCheckedItemDialogBundle) => void;
  updateEncoding: (
    updateFunction: (encoding: ExtendedLeaseInventoryEncoding) => ExtendedLeaseInventoryEncoding
  ) => void;
  removeStructure: (b: boolean) => void;
  setFileToDelete: (file: S3Object | EmptyFile) => void;
  readOnly: boolean;
  deletable: boolean;
}
const InventoryStructureEncodingExtraInfos: React.FC<InventoryStructureEncodingExtraInfosProps> = ({
  encoding,
  previewFileFunction,
  encodeDefects,
  updateEncoding,
  removeStructure,
  setFileToDelete,
  readOnly,
  deletable,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [audioFiles, setAudioFiles] = useState<(S3Object | EmptyFileWithUrl)[]>([]);
  const [pictureFiles, setPictureFiles] = useState<(S3Object | EmptyFileWithUrl)[]>([]);
  const [showNote, setShowNote] = useState<boolean>(!isNil(encoding) && !isNil(encoding.note));
  const { getCheckedItemsForLeaseInventoryEncoding } = useLeaseInventories();

  useEffect(() => {
    let unmounted = false;
    if (!isNil(encoding) && !isNil(encoding.medias)) {
      const fetchFilesOnS3 = async () => {
        const audioFileNested: (S3Object | EmptyFileWithUrl)[] = [];
        const pictureFilesNested: (S3Object | EmptyFileWithUrl)[] = [];
        const files = (encoding.medias as unknown as (FileModel | EmptyFile)[]).reduce(
          (acc: FileModel[], media: EmptyFile | FileModel) => {
            if ('file' in media) {
              const emptyMediaWithUrl = { ...media, url: URL.createObjectURL(media.file) };
              if (media.file.type.includes('audio')) {
                audioFileNested.push(emptyMediaWithUrl);
              } else if (media.file.type.includes('image')) {
                pictureFilesNested.push(emptyMediaWithUrl);
              }
            } else {
              acc.push(media);
            }
            return acc;
          },
          []
        );
        const filesPromise = getS3ObjectUrls(files);
        const s3Objects = await filesPromise;
        s3Objects.forEach((s3Object) => {
          if (!isNil(s3Object.mimeType)) {
            if (s3Object.mimeType.includes('audio')) {
              audioFileNested.push(s3Object);
            } else if (s3Object.mimeType.includes('image')) {
              pictureFilesNested.push(s3Object);
            }
          }
        });
        if (!unmounted) {
          setAudioFiles(audioFileNested);
          setPictureFiles(pictureFilesNested);
        }
      };

      fetchFilesOnS3();
    }

    return () => {
      unmounted = true;
    };
  }, [encoding, getCheckedItemsForLeaseInventoryEncoding]);

  const addSpeechToTextToNote = (speechToText: string) => {
    updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
      const oldValue = !isNil(encoding) && !isNil(encoding.note) ? encoding.note : '';
      return {
        ...encoding,
        note: `${oldValue}${isEmpty(oldValue) ? '' : '\n'}${speechToText}`,
      };
    });
    if (!showNote) {
      setShowNote(true);
    }
  };

  const [defects, colors, materials] =
    !isNil(encoding) && !isNil(encoding.checkedItems)
      ? classifyInventoryCheckedItems(encoding.checkedItems)
      : [[], [], []];
  return (
    <div style={{ backgroundColor: Colors.PORCELAIN_GREY_1, borderRadius: 5, padding: 20 }}>
      {!isEmpty(audioFiles) && (
        <>
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 10, textTransform: 'uppercase', letterSpacing: 1 }}>
            {formatMessage({ id: 'leaseInventory.room.structureDetails.audio' })}
          </Typography>
          {audioFiles.map((audioFile) => (
            <CustomAudioPlayer
              key={audioFile.id}
              audioFile={audioFile}
              deleteFunction={readOnly ? undefined : () => setFileToDelete(audioFile)}
              appendTextToEnd={addSpeechToTextToNote}
            />
          ))}
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </>
      )}
      {!isEmpty(pictureFiles) && (
        <div>
          <Typography
            style={{
              color: Colors.SLATE_GREY,
              fontSize: 10,
              textTransform: 'uppercase',
              marginBottom: 10,
              letterSpacing: 1,
            }}
          >
            {formatMessage({ id: 'rentalUnit.detail.advertisement.pictures' })}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {pictureFiles.map((pf, index) => {
              return (
                <Button
                  key={`${pf.id}-${index}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    const replaceFileEncodingFunction = (oldFile: EmptyFileWithUrl | S3Object, newFile: File) => {
                      const emptyFile = getEmptyFile(newFile);
                      const emptyFileWithUrl = { ...emptyFile, url: URL.createObjectURL(newFile) };
                      updateEncoding((encoding: LeaseInventoryEncoding) => {
                        return {
                          ...encoding,
                          medias: [
                            ...encoding.medias!.filter((m) => m.id !== oldFile.id),
                            emptyFileWithUrl,
                          ] as FileModel[],
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any;
                      });
                      previewFileFunction({ file: null, onUpdatedFile: undefined });
                    };
                    previewFileFunction({ file: pf, onUpdatedFile: replaceFileEncodingFunction });
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <ListItemAvatar>
                    <FileItemRendering
                      key={pf.id}
                      fileType={'file' in pf ? pf.file.type : pf.mimeType!}
                      fileUrl={pf.url}
                      fileName={'file' in pf ? pf.file.name : pf.fileName}
                      style={{ width: 45, height: 45 }}
                      deleteIconStyle={{ width: 18, height: 18 }}
                      removeFunction={readOnly ? undefined : () => setFileToDelete(pf)}
                    />
                  </ListItemAvatar>
                </Button>
              );
            })}
          </div>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </div>
      )}
      {!isEmpty(defects) && (
        <div>
          <Typography
            style={{
              color: Colors.SLATE_GREY,
              fontSize: 10,
              textTransform: 'uppercase',
              marginBottom: 10,
              letterSpacing: 1,
            }}
          >
            {formatMessage(
              {
                id: 'leaseInventory.room.structureDetails.defects',
              },
              { value: 2 }
            )}
          </Typography>
          <Grid container>
            {defects.map((defect) => {
              const defectName = `${defect.totalDefects} ${
                defect.custom
                  ? defect.customItem!.value
                  : formatMessage({
                      id: `enums.leaseInventoryDefects.${defect.value}`,
                    })
              }`;
              return (
                <Grid
                  item
                  xs={
                    defectName.length < 10
                      ? 3
                      : (Math.min(12, Math.ceil(defectName.length / 4)) as 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)
                  }
                  style={{ marginBottom: 10 }}
                >
                  <Chip
                    key={defect.id}
                    label={defectName}
                    component="a"
                    href={undefined}
                    style={{ marginRight: 5, backgroundColor: Colors.CLASSICAL_WHITE }}
                    clickable
                    onDelete={
                      readOnly
                        ? undefined
                        : () => {
                            updateEncoding((encoding: LeaseInventoryEncoding) => {
                              return {
                                ...encoding,
                                checkedItems: encoding.checkedItems!.filter((ch) => ch.id !== defect.id),
                              };
                            });
                          }
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </div>
      )}
      {!isEmpty(materials) && (
        <div>
          <Typography
            style={{
              color: Colors.SLATE_GREY,
              fontSize: 10,
              textTransform: 'uppercase',
              marginBottom: 10,
              letterSpacing: 1,
            }}
          >
            {formatMessage(
              {
                id: 'leaseInventory.room.structureDetails.materials',
              },
              { value: 2 }
            )}
          </Typography>
          <Grid container>
            {materials.map((material) => {
              const materialName = material.custom
                ? material.customItem!.value
                : formatMessage({
                    id: `enums.leaseInventoryMaterials.${material.value}`,
                  });
              return (
                <Grid
                  item
                  xs={
                    materialName.length < 10
                      ? 3
                      : (Math.min(12, Math.ceil(materialName.length / 4)) as 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)
                  }
                  style={{ marginBottom: 10 }}
                >
                  <Chip
                    key={material.id}
                    label={materialName}
                    component="a"
                    href={undefined}
                    style={{ marginRight: 5, backgroundColor: Colors.CLASSICAL_WHITE }}
                    clickable
                    onDelete={
                      readOnly
                        ? undefined
                        : () => {
                            updateEncoding((encoding: LeaseInventoryEncoding) => {
                              return {
                                ...encoding,
                                checkedItems: encoding.checkedItems!.filter((ch) => ch.id !== material.id),
                              };
                            });
                          }
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </div>
      )}
      {!isEmpty(colors) && (
        <div>
          <Typography
            style={{
              color: Colors.SLATE_GREY,
              fontSize: 10,
              textTransform: 'uppercase',
              marginBottom: 10,
              letterSpacing: 1,
            }}
          >
            {formatMessage(
              {
                id: 'leaseInventory.room.structureDetails.colors',
              },
              { value: 2 }
            )}
          </Typography>
          <Grid container>
            {colors.map((color) => {
              return (
                <Grid item xs={1} style={{ marginBottom: 10 }}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 30,
                        backgroundColor: color.custom ? color.customItem!.value : color.value ?? '',
                        border: '1px solid gray',
                      }}
                    />
                    {!readOnly && (
                      <CloseIcon
                        style={{
                          position: 'absolute',
                          top: -7,
                          right: -7,
                          cursor: 'pointer',
                          width: 16,
                          height: 16,
                          fill: Colors.LIGHT_BLUE_GREY,
                        }}
                        onClick={() => {
                          updateEncoding((encoding: LeaseInventoryEncoding) => {
                            return {
                              ...encoding,
                              checkedItems: encoding.checkedItems!.filter((ch) => ch.id !== color.id),
                            };
                          });
                        }}
                      />
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </div>
      )}
      <Collapse in={showNote}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Typography style={{ color: Colors.SLATE_GREY, fontSize: 10, textTransform: 'uppercase', letterSpacing: 1 }}>
            {formatMessage(
              {
                id: 'leaseInventory.room.structureDetails.notes',
              },
              { value: 1 }
            )}
          </Typography>
          {!readOnly && (
            <CustomIconButton
              onClick={() => {
                updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
                  return {
                    ...encoding,
                    note: undefined,
                  };
                });
                setShowNote(false);
              }}
              size="small"
              Icon={Delete}
              iconStyle={{ fill: Colors.BLUE_GREY }}
            />
          )}
        </div>
        <TextFieldArea
          value={!isNil(encoding) ? encoding.note : ''}
          onChange={(value: string) => {
            updateEncoding((encoding: ExtendedLeaseInventoryEncoding) => {
              return {
                ...encoding,
                note: value,
              };
            });
          }}
          rows={4}
          rowsMax={8}
          style={{ backgroundColor: Colors.CLASSICAL_WHITE, borderRadius: 5 }}
          disabled={readOnly}
        />
      </Collapse>

      {!readOnly && (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ActionButton onClick={() => setShowNote(true)} className={classes.addButton}>
              <AddIcon />
              {toUpper(
                formatMessage(
                  {
                    id: 'leaseInventory.room.structureDetails.notes',
                  },
                  { value: 1 }
                )
              )}
            </ActionButton>
            <ActionButton
              onClick={() => encodeDefects({ encoding, updateEncoding, type: InventoryItemType.DEFECT })}
              style={{
                marginLeft: 20,
              }}
              className={classes.addButton}
            >
              <AddIcon />
              {toUpper(
                formatMessage(
                  {
                    id: 'leaseInventory.room.structureDetails.defects',
                  },
                  { value: 1 }
                )
              )}
            </ActionButton>
            <ActionButton
              onClick={() => encodeDefects({ encoding, updateEncoding, type: InventoryItemType.MATERIAL })}
              style={{
                marginLeft: 20,
              }}
              className={classes.addButton}
            >
              <AddIcon />
              {toUpper(
                formatMessage(
                  {
                    id: 'leaseInventory.room.structureDetails.materials',
                  },
                  { value: 1 }
                )
              )}
            </ActionButton>
            <ActionButton
              onClick={() => encodeDefects({ encoding, updateEncoding, type: InventoryItemType.COLOR })}
              style={{
                marginLeft: 20,
              }}
              className={classes.addButton}
            >
              <AddIcon />
              {toUpper(
                formatMessage(
                  {
                    id: 'leaseInventory.room.structureDetails.colors',
                  },
                  { value: 1 }
                )
              )}
            </ActionButton>
          </div>
          {deletable && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <ActionButton
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeStructure(true);
                }}
                style={{ marginTop: 10 }}
                className={classes.removeButton}
              >
                {toUpper(formatMessage({ id: 'leaseInventory.deleteItem' }))}
              </ActionButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InventoryStructureEncodingExtraInfos;
