import React from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTechnics, FileCategoryTypeForm as FileCategory } from 'src/hooks/TechnicsContext';
import { FilesContext, NewFile } from 'src/hooks/FilesContext';
import * as Yup from 'yup';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import {
  ContactType,
  File as FileModel,
  Technic,
  FileCategory as FileCategoryCtx,
  Colors,
  EntityType,
} from '@rentguru/commons-utils';
import { Divider, Grid, Typography } from '@material-ui/core';
import ContactSelectForm from 'src/components/ui/Forms/FormSection/ContactSelectForm';
import FileFields from 'src/components/ui/Forms/FormField/FileByCategoryFields';
import { isAfter } from 'date-fns';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';

const HORIZONTAL_PADDING = 20;

interface AddChimneyControlProps {
  chimney: Technic;
  createFile: FilesContext['createFile'];
  afterSubmit: () => void;
  cancelSubmit: () => void;
}

const ChimneySchema = Yup.object().shape({
  date: Yup.date().required(),
});

interface AddChimneyControlValues {
  date: Date;
  filesByCategories: FileCategory[];
  contact: { id: string }[];
}

const AddChimneyControl: React.FC<AddChimneyControlProps> = ({ chimney, createFile, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { updateTechnic } = useTechnics();

  const handleAfterSubmit = () => {
    afterSubmit();
  };
  const handleCancel = () => {
    cancelSubmit();
  };

  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const chimneyDocumentCategory = getDocumentCategoryByFileCategory(FileCategoryCtx.CHIMNEY);

  const handleControlCreate = async (
    values: AddChimneyControlValues,
    { setSubmitting, setStatus }: FormikHelpers<AddChimneyControlValues>
  ): Promise<void> => {
    // Create file if attached
    const fileChimney = values.filesByCategories.find(
      (fileCategory) => fileCategory.category.fileCategory === FileCategoryCtx.CHIMNEY
    );
    let file: FileModel | null = null;
    if (!isNil(fileChimney)) {
      // Compare with originalFile
      const attachmentsPromises = fileChimney.files.map((fileItem) =>
        createFile((fileItem as NewFile).file, EntityType.TECHNIC, chimney.id, chimneyDocumentCategory.id)
      );

      file = (await Promise.all(attachmentsPromises))[0];
    }
    // Update technic
    const previousHistory = !isNil(chimney.maintenanceHistory) ? chimney.maintenanceHistory : [];
    const contractorId = values.contact[0].id === '' ? undefined : values.contact[0].id;
    const fileId = !isNil(file) ? file.id : undefined;
    await updateTechnic(chimney, {
      chimneyLastControlDate:
        isNil(chimney.chimneyLastControlDate) || isAfter(values.date, new Date(chimney.chimneyLastControlDate))
          ? values.date.toISOString()
          : chimney.chimneyLastControlDate,
      maintenanceHistory: [...previousHistory, { date: values.date.toISOString(), contactId: contractorId, fileId }],
    });
    setStatus(true);
    handleAfterSubmit();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        date: new Date(),
        contact: [{ id: '' }],
        filesByCategories: [{ category: chimneyDocumentCategory, files: [] }],
      }}
      validationSchema={ChimneySchema}
      onSubmit={handleControlCreate}
    >
      {({ values, errors, touched, isSubmitting, status, setFieldValue, handleChange, handleBlur }) => (
        <Form>
          <Grid
            container
            direction="column"
            style={{ paddingLeft: HORIZONTAL_PADDING, paddingRight: HORIZONTAL_PADDING }}
          >
            <Grid item xs={12} style={{ marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'technic.chimneyTab.addSweeping' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: 10 }} />
            </Grid>
            <Grid container style={{ alignItems: 'baseline' }}>
              <Grid item xs={12}>
                <FormikDatePicker
                  name="date"
                  label={formatMessage({ id: 'technic.chimneyTab.chimneyLastControlDate' })}
                  value={values.date}
                  error={Boolean(errors.date && touched.date)}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactSelectForm
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fieldLabel={`${formatMessage({
                    id: 'technic.chimneyTab.chimneySweeper',
                  })} ${formatMessage({
                    id: 'optional',
                  })}`}
                  addLabel={formatMessage({
                    id: 'technic.chimneyTab.chimneySweeper',
                  })}
                  type={ContactType.CONTRACTOR}
                  fieldName="contact"
                  showPercent={false}
                  multiple={false}
                  checkAdress={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FileFields
                  documentCategory={chimneyDocumentCategory}
                  categoryType="noHeader"
                  centerSelectedFile
                  maxFiles={1}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
              </Grid>
              <Grid
                container
                style={{
                  marginBottom: 20,
                  marginRight: 30,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={handleCancel}
                  style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
                >
                  {toUpper(formatMessage({ id: 'cancel' }))}
                </ActionButton>
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'save',
                  })}
                </LoaderButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddChimneyControl;
