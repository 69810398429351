/* eslint-disable no-redeclare */
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  Integration,
  syncIntegrations,
  getIntegration as getIntegrationQuery,
  createIntegration as createMutation,
  updateIntegration as updateMutation,
  deleteIntegration as deleteMutation,
  verifyPontoIntegration as verifyIntegrationMutation,
  revokePontoIntegration as revokeIntegrationMutation,
  CreateIntegrationMutationVariables,
  DeleteIntegrationMutationVariables,
  CreateIntegrationInput,
  UpdateIntegrationInput,
  VerifyPontoIntegrationInput,
  VerifyPontoIntegrationMutation,
  VerifyPontoIntegrationMutationVariables,
  RevokePontoIntegrationMutation,
  RevokePontoIntegrationMutationVariables,
  cleanInputUpdate,
  cleanInputCreate,
  getReadId,
  getTableClientId,
  LooseObject,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import { useUser } from './UserContext';
import isNil from 'lodash/isNil';
import { differenceInMinutes } from 'date-fns';

export interface IntegrationContext extends IntegrationState {
  createIntegration: (
    input: CreateIntegrationInput | Omit<CreateIntegrationInput, 'clientId' | 'readId'>
  ) => Promise<Integration>;
  getIntegration: (id: string) => Integration | undefined;
  getIntegrationByName: (name: string) => Integration | undefined;
  updateIntegration: (updates: UpdateIntegrationInput) => Promise<Integration>;
  deleteIntegration: (integration: Integration) => Promise<Integration | null>;
  setFetchIntegrations: () => void;
  fetchAndSetIntegrations: () => Promise<void>;
  verifyPontoIntegration: (
    input: Omit<VerifyPontoIntegrationInput, 'clientId'>
  ) => Promise<VerifyPontoIntegrationMutation>;
  revokePontoIntegration: () => Promise<RevokePontoIntegrationMutation>;
}

interface IntegrationState {
  integrations: Integration[] | null;
  error: string | undefined;
  loading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_INTEGRATION';
      payload: { integration: Integration };
    }
  | {
      type: 'FETCHED';
      payload: { integrations: Integration[] };
    }
  | {
      type: 'UPDATE_INTEGRATION';
      payload: { integration: Integration };
    }
  | {
      type: 'DELETE_INTEGRATION';
      payload: { id: string };
    };

const initialState: IntegrationState = {
  loading: false,
  error: undefined,
  integrations: null,
  shouldFetch: false,
  lastFetch: null,
};

const integrationReducer = (state: IntegrationState, action: Action): IntegrationState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      // Check //lastFetchForAgencyRates TODO
      if (
        state.loading ||
        state.shouldFetch ||
        (state.lastFetch && differenceInMinutes(new Date(), state.lastFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        integrations: action.payload.integrations,
        lastFetch: new Date(),
      };
    case 'ADD_INTEGRATION':
      return {
        ...state,
        integrations: [...(state.integrations ?? []), action.payload.integration],
      };
    case 'UPDATE_INTEGRATION':
      if (!state.integrations) {
        return state;
      }
      return {
        ...state,
        integrations: state.integrations.map((integration) => {
          if (integration.id === action.payload.integration.id) {
            return action.payload.integration;
          }
          return integration;
        }),
      };
    case 'DELETE_INTEGRATION':
      if (!state.integrations) {
        return state;
      }
      return {
        ...state,
        integrations: state.integrations.filter((integration) => integration.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchIntegrations = async (clientId: string, additionalFilter?: LooseObject): Promise<Integration[]> => {
  return await list<Integration>(syncIntegrations, 'clientId', clientId, additionalFilter);
};

export const IntegrationContext = React.createContext<IntegrationContext | null>(null);

export const IntegrationContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<IntegrationState, Action>>(integrationReducer, initialState);
  const { clientId, userId } = useUser();

  const setFetchIntegrations = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    if (state.shouldFetch) fetchAndSetIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const createIntegration = async (
    input: CreateIntegrationInput | Omit<CreateIntegrationInput, 'clientId' | 'readId'>
  ): Promise<Integration> => {
    let integration: Integration | undefined;
    try {
      integration = await mutation<Integration, CreateIntegrationMutationVariables>(createMutation, {
        input: {
          ...(cleanInputCreate(input) as CreateIntegrationInput),
          clientId: getTableClientId(clientId!, 'Integration'),
          readId: getReadId(clientId!, 'Integration'),
        },
      });
      dispatch({ type: 'ADD_INTEGRATION', payload: { integration } });
    } catch (error) {
      state.error = error as string;
    }
    return integration!;
  };

  const updateIntegration = async (updates: UpdateIntegrationInput): Promise<Integration> => {
    let integration: Integration | undefined;
    try {
      integration = await mutation<Integration>(updateMutation, {
        input: { ...cleanInputUpdate(updates, false) },
      });
      if (integration) {
        dispatch({ type: 'UPDATE_INTEGRATION', payload: { integration } });
        dispatch({ type: 'SHOULD_FETCH' });
      }
    } catch (error) {
      state.error = error as string;
    }
    return integration!;
  };

  const deleteIntegration = async (integration: Integration): Promise<Integration | null> => {
    let deletedIntegration: Integration | null | undefined;
    try {
      deletedIntegration = await deleteEntityWithFetchBefore<Integration, DeleteIntegrationMutationVariables>(
        integration,
        getIntegrationQuery,
        deleteMutation
      );

      dispatch({ type: 'DELETE_INTEGRATION', payload: { id: integration.id } });
      return deletedIntegration;
    } catch (error) {
      state.error = error as string;
    }
    return deletedIntegration!;
  };

  const getIntegration = (id: string) => {
    const integration = (state.integrations ?? []).find((integrationElement) => integrationElement.id === id);
    return integration;
  };

  const getIntegrationByName = (name: string) => {
    const integration = (state.integrations ?? []).find((integrationElement) => integrationElement.name === name);
    return integration;
  };
  const fetchAndSetIntegrations = async () => {
    try {
      dispatch({ type: 'IS_FETCHING' });
      const integrations = await fetchIntegrations(getTableClientId(clientId!, 'Integration'));
      dispatch({ type: 'FETCHED', payload: { integrations } });
    } catch (error) {
      state.error = error as string;
    }
  };

  const verifyPontoIntegration = async (
    input: Omit<VerifyPontoIntegrationInput, 'clientId'>
  ): Promise<VerifyPontoIntegrationMutation> => {
    const response = await mutation<VerifyPontoIntegrationMutation, VerifyPontoIntegrationMutationVariables>(
      verifyIntegrationMutation,
      {
        input: {
          ...(cleanInputCreate(input) as VerifyPontoIntegrationInput),
          clientId: clientId!,
        },
      }
    );
    return response;
  };

  const revokePontoIntegration = async (): Promise<RevokePontoIntegrationMutation> => {
    const response = await mutation<RevokePontoIntegrationMutation, RevokePontoIntegrationMutationVariables>(
      revokeIntegrationMutation,
      {
        input: {
          userId: userId!,
          clientId: clientId!,
        },
      }
    );
    return response;
  };

  const values = useMemo(
    () => ({
      ...state,
      createIntegration,
      getIntegration,
      getIntegrationByName,
      updateIntegration,
      deleteIntegration,
      setFetchIntegrations,
      fetchAndSetIntegrations,
      verifyPontoIntegration,
      revokePontoIntegration,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <IntegrationContext.Provider value={values}>{children}</IntegrationContext.Provider>;
};

export const useIntegrations = (): IntegrationContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<IntegrationContext | null>(IntegrationContext);

  if (isNil(context)) {
    throw new Error('`useIntegrations` hook must be used within a `IntegrationContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.integrations)) {
      context.setFetchIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
