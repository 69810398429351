import React, { Reducer, useReducer, useState, useMemo, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Tooltip, Divider, TableRow, Grid } from '@material-ui/core';
import { SaveRounded } from '@material-ui/icons';
import isNil from 'lodash/isNil';
import {
  buildBestTransactionRule,
  LinkTo,
  LinkType,
  saveAccountAndTransactionsWithLink,
  linkTransactionToInvoice,
  getOldestInvoiceOfExactAmount,
  uniquePush,
  Colors,
  TransactionLinkType,
  ContactType,
  Transaction,
  TransactionStatus,
} from '@rentguru/commons-utils';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { InvoiceWithPostings, useInvoices } from 'src/hooks/InvoicesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { getTransaction, useTransactions } from 'src/hooks/TransactionsContext';
import { ReactComponent as CircleMinus } from 'src/icons/circle-minus.svg';
import { ReactComponent as CircleCheck } from 'src/icons/circle-check.svg';
import { ReactComponent as Alternate } from 'src/icons/alternate.svg';
import { ReactComponent as ReconcileIcon } from 'src/icons/reconcile.svg';
import { ReactComponent as TrashIcon } from 'src/icons/trash.svg';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import CustomizedSwitch from 'src/components/ui/CustomizedSwitch';
import { CustomTableCell, CustomLoadableIconButton, CustomIconButton } from '@up2rent/ui';
import CustomizedComboBoxWithTabs from 'src/components/ui/ComboBox/CustomizedComboBoxWithTabs';
import { RouteDestination } from 'src/components/Routes/Routes';
import { ROW_HEIGHT } from 'src/components/Dashboard/DashboardTodos/DashboardTransactions';
import TransactionBasicColumns from './TransactionBasicColumns';
import {
  filterLeasesForLink,
  getLeaseCustomMenuItems,
  getContractorCustomMenuItems,
  getLeaseInvoicesWithPostings,
  getContractorCustomMenuItem,
} from './utils';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';

interface LineFormState {
  loading: boolean;
  success: boolean;
  validated: boolean;
  rememberRule: boolean;
  link: LinkTo | null;
}

type Action =
  | {
      type: 'VALIDATE_FORM';
    }
  | {
      type: 'FORM_COMPLETED';
      payload: { error: boolean };
    }
  | {
      type: 'CHANGE_REMEMBER_RULE';
      payload: { checked: boolean };
    }
  | {
      type: 'CHANGE_LINK';
      payload: { link: LinkTo | null };
    }
  | {
      type: 'LINK_COMPLETED';
      payload: { error: boolean };
    };

const IconWidth = 44;
const ReconcileIconWidth = 64;

const lineFormReducer = (state: LineFormState, action: Action): LineFormState => {
  switch (action.type) {
    case 'VALIDATE_FORM':
      if (state.loading || state.validated || state.success) {
        return state;
      }

      return {
        ...state,
        validated: true,
        loading: true,
      };

    case 'FORM_COMPLETED':
      return {
        ...state,
        validated: true,
        loading: false,
        success: !action.payload.error,
      };
    case 'CHANGE_REMEMBER_RULE':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        rememberRule: action.payload.checked,
      };
    case 'CHANGE_LINK':
      if (state.loading) {
        return state;
      }
      return {
        ...state,
        link: action.payload.link,
        loading: true,
      };
    case 'LINK_COMPLETED':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

interface TransactionToCheckRowProps extends Transaction {
  isSelected: boolean;
  _version: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string;
  actionMenu: (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    toDelete?: boolean,
    toSplit?: boolean
  ) => void;
  children?: React.ReactNode;
}

const TransactionsToCheckRow: React.FC<TransactionToCheckRowProps> = ({
  isSelected,
  formatMessage,
  actionMenu,
  children: _children,
  ...transaction
}) => {
  const {
    id,
    proposedLeaseId: initialProposedLeaseId,
    proposedContractorId: initialProposedContractorId,
    manualEntry,
    links,
    amount,
  } = transaction;
  const { financialTransactionsWrite, financialTransactionsDelete } = usePermissions();
  const history = useHistory();

  const proposedLeaseId =
    links && links.length === 1 && links[0].linkType === TransactionLinkType.LEASE
      ? links[0].linkId
      : initialProposedLeaseId;
  const proposedContractorId =
    links && links.length === 1 && links[0].linkType === TransactionLinkType.CONTACT
      ? links[0].linkId
      : initialProposedContractorId;

  // Line Form
  const initialState: LineFormState = {
    loading: false,
    success: false,
    validated: false,
    rememberRule: true,
    link: proposedLeaseId
      ? { type: LinkType.Lease, leaseId: proposedLeaseId!, amount: transaction.amount }
      : proposedContractorId
      ? { type: LinkType.Contractor, id: proposedContractorId!, amount: transaction.amount }
      : null,
  };
  const [state, dispatch] = useReducer<Reducer<LineFormState, Action>>(lineFormReducer, initialState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLinkToChange = async (_event: any, tabKey: string, value: CustomMenuItemType | undefined) => {
    dispatch({
      type: 'CHANGE_LINK',
      payload: {
        link: isNil(value)
          ? null
          : {
              amount: transaction.amount,
              ...(tabKey === LinkType.Lease
                ? { type: LinkType.Lease, leaseId: value!.value.leaseId, unitId: value!.value.unitId }
                : { type: LinkType.Contractor, id: value!.value.contractorId }),
            },
      },
    });
    if (isNil(value)) {
      const latestVersionedTransaction: Transaction & { _version: number } = (await getTransaction(
        id
      )) as Transaction & { _version: number };
      await updateTransaction({
        id,
        _version: latestVersionedTransaction._version,
        links: [],
      });
    }
    dispatch({ type: 'LINK_COMPLETED', payload: { error: false } });
  };

  // Data hooks
  const { leases, getLease, leasesLoading } = useLeases();
  const { getContact, contactsLoading, contractors } = useContacts();
  const { units, unitsLoading } = useUnits();
  const {
    createPosting,
    createTransactionRule,
    updateTransactionRule,
    updateTransaction,
    getAccountLabelForBankAccountOrInsert,
    getAccountLabelForLease,
    getAccountLabelForContactOrInsert,
    transactionsLoading,
    transactionsRules,
  } = useTransactions();
  const { updateInvoice } = useInvoices();
  const [invoicesOfLink, setInvoicesOfLink] = useState<InvoiceWithPostings[] | null>(null);

  /* If we are an agency and we have a link on a Lease, we must fetch the unpaid invoices of the lease
  to see if we can make a direct match or if we have to reconcilialte the transactions
  */
  useEffect(() => {
    const getUnPaidInvoicesOfLeaseLink = async (leaseId: string) => {
      const invoicesWithPostings = await getLeaseInvoicesWithPostings(leaseId, { paid: { eq: false } });
      setInvoicesOfLink(invoicesWithPostings);
    };
    // We only reconcile postive transactions for linked to leases by agencies
    if (state.link && state.link.type === LinkType.Lease && transaction.amount > 0) {
      getUnPaidInvoicesOfLeaseLink(state.link.leaseId);
    } else {
      setInvoicesOfLink([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.link]);

  const invoiceMatch = useMemo(() => {
    if (isNil(invoicesOfLink)) return null;
    return getOldestInvoiceOfExactAmount(invoicesOfLink, transaction.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesOfLink]);

  const dataLoading = leasesLoading || contactsLoading || unitsLoading || transactionsLoading || isNil(invoicesOfLink);
  if (dataLoading) {
    return null;
  }

  const shouldReconcileTransaction = state.link && state.link.type === LinkType.Lease && isNil(invoiceMatch);

  // Actions methods
  const handleValidateTransaction = async () => {
    dispatch({ type: 'VALIDATE_FORM' });
    if (isNil(state.link)) {
      dispatch({ type: 'FORM_COMPLETED', payload: { error: true } });
      return;
    }
    if (!state.loading && !state.success && !isNil(state.link)) {
      const latestVersionedTransaction: Transaction & { _version: number } = (await getTransaction(
        id
      )) as Transaction & { _version: number };
      const isLeaseTransactionSplit =
        transaction.links &&
        transaction.links.length > 1 &&
        transaction.links.every((link) => link.linkType === TransactionLinkType.LEASE);

      if (state.rememberRule && !isLeaseTransactionSplit) {
        // Create rule
        const transactionRule = buildBestTransactionRule(
          latestVersionedTransaction,
          false,
          state.link.type === LinkType.Lease ? state.link.leaseId : undefined,
          undefined,
          state.link.type === LinkType.Contractor ? state.link.id : undefined
        );

        // Check if transaction rule already exist
        const transactionRuleAlreadyExist = transactionsRules.find(
          (currentTransactionRule) =>
            state.link &&
            ((state.link.type === LinkType.Lease && currentTransactionRule.leaseId === state.link.leaseId) ||
              (state.link.type === LinkType.Contractor && currentTransactionRule.contractorId === state.link.id))
        );
        if (transactionRuleAlreadyExist && transactionRuleAlreadyExist.usedFields !== transactionRule.usedFields) {
          // Update the rule
          await updateTransactionRule({ id: transactionRuleAlreadyExist.id, ...transactionRule });
        } else if (!transactionRuleAlreadyExist) {
          await createTransactionRule(transactionRule);
        }
      }

      // If the transaction is split => redirect on all its leases reconciliations
      if (isLeaseTransactionSplit) {
        const splitLeaseIds: string[] = [];
        transaction.links!.forEach((s) => {
          if (s.linkType === TransactionLinkType.LEASE) uniquePush(splitLeaseIds, s.linkId);
        });
        history.push({ pathname: RouteDestination.RECONCILE_TRANSACTION, state: { leaseIds: splitLeaseIds } });
        return;
      }

      // If we try to link on a lease
      if (state.link.type === LinkType.Lease) {
        if (shouldReconcileTransaction) {
          // but no matching invoice => redirect on reconciliation
          if (
            !latestVersionedTransaction.links ||
            !latestVersionedTransaction.links.some(
              (l) =>
                l.linkId ===
                (
                  state.link as {
                    type: LinkType.Lease;
                    leaseId: string;
                    amount: number;
                  }
                ).leaseId
            )
          ) {
            // If the transaction doe not have links yet or have the incorrect one
            await updateTransaction({
              id,
              _version: latestVersionedTransaction._version,
              links: [
                {
                  amount,
                  linkId: state.link.leaseId,
                  linkType: TransactionLinkType.LEASE,
                },
              ],
            });
          }
          history.push({ pathname: RouteDestination.RECONCILE_TRANSACTION, state: { leaseIds: [state.link.leaseId] } });
          return;
        }
        // Link to the matched invoice!
        const lease = getLease(state.link.leaseId);
        const [accountForLease, accountForBankAccount] = await Promise.all([
          getAccountLabelForLease(),
          getAccountLabelForBankAccountOrInsert(transaction.bankAccount!),
        ]);
        if (isNil(lease)) return;
        await linkTransactionToInvoice(
          transaction,
          lease,
          invoiceMatch!,
          invoiceMatch!.postings,
          accountForLease,
          accountForBankAccount,
          createPosting,
          updateInvoice
        );
        await updateTransaction({
          id,
          _version: latestVersionedTransaction._version,
          status: TransactionStatus.RECONCILED,
          links: [
            {
              amount,
              linkId: state.link.leaseId,
              linkType: TransactionLinkType.LEASE,
            },
          ],
        });
      } else if (state.link.type === LinkType.Contractor) {
        // Link to the contractor
        await saveAccountAndTransactionsWithLink(
          transaction,
          [state.link],
          units,
          getLease,
          getContact,
          getAccountLabelForBankAccountOrInsert,
          getAccountLabelForLease,
          getAccountLabelForContactOrInsert,
          createPosting
        );
        await updateTransaction({
          id,
          _version: latestVersionedTransaction._version,
          status: TransactionStatus.RECONCILED,
          links: [
            {
              amount,
              linkId: state.link.id,
              linkType: TransactionLinkType.CONTACT,
            },
          ],
        });
      }

      dispatch({ type: 'FORM_COMPLETED', payload: { error: false } });
    }
  };

  const handleExcludeTransaction = async () => {
    dispatch({ type: 'VALIDATE_FORM' });
    if (!state.loading && !state.success) {
      const latestVersionedTransaction: Transaction & { _version: number } = (await getTransaction(
        id
      )) as Transaction & { _version: number };
      if (state.rememberRule) {
        // Create rule
        const transactionRule = buildBestTransactionRule(transaction, true);
        await createTransactionRule(transactionRule);
      }
      await updateTransaction({
        id,
        _version: latestVersionedTransaction._version,
        status: TransactionStatus.EXCLUDED,
        links: [],
      });
      dispatch({ type: 'FORM_COMPLETED', payload: { error: false } });
    }
  };

  // Line display
  const filteredLeases = filterLeasesForLink(leases);
  const leasesMenuItems = getLeaseCustomMenuItems(transaction, filteredLeases);
  const contractorsMenuItems = getContractorCustomMenuItems(transaction, contractors);
  const initialCustomBoxValue = proposedLeaseId
    ? leasesMenuItems.find((lmi: CustomMenuItemType) => lmi.value.leaseId === proposedLeaseId)
    : proposedContractorId
    ? contractorsMenuItems.find((lmi: CustomMenuItemType) => lmi.value.contractorId === proposedContractorId)
    : undefined;

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10, minHeight: ROW_HEIGHT, height: ROW_HEIGHT }}
    >
      <TransactionBasicColumns transaction={transaction} />

      {financialTransactionsWrite && (
        <CustomTableCell scope="row" padding="none">
          <Divider orientation="vertical" style={{ backgroundColor: Colors.GEYSER_GREY, height: 30 }} />
        </CustomTableCell>
      )}

      {financialTransactionsWrite && (
        <CustomTableCell scope="row" padding="none" align="left">
          <CustomizedComboBoxWithTabs
            id={transaction.id}
            datas={[
              { tabLabel: formatMessage({ id: 'lease.title' }), tabKey: LinkType.Lease, datas: leasesMenuItems },
              {
                tabLabel: formatMessage({ id: 'contact.type.contractor' }),
                tabKey: LinkType.Contractor,
                datas: contractorsMenuItems,
                addContactOption: {
                  enable: true,
                  getMenuItemOption: getContractorCustomMenuItem,
                  contactType: ContactType.CONTRACTOR,
                },
              },
            ]}
            label={formatMessage({ id: 'transactions.addTransaction.linkToLeaseTitle' })}
            inputStyle={{ width: '220px' }}
            error={state.validated && isNil(state.link)}
            disabled={state.loading || state.success}
            onChange={handleLinkToChange}
            initialValue={
              initialCustomBoxValue
                ? { tabKey: proposedLeaseId ? LinkType.Lease : LinkType.Contractor, value: initialCustomBoxValue }
                : undefined
            }
          />
        </CustomTableCell>
      )}
      {financialTransactionsWrite && (
        <CustomTableCell scope="row" padding="none" align="left">
          <CustomizedSwitch
            onMouseDown={stopPropagation}
            color="primary"
            checked={state.rememberRule}
            onChange={(_e, checked: boolean) => {
              dispatch({ type: 'CHANGE_REMEMBER_RULE', payload: { checked } });
            }}
            disabled={state.loading || state.success}
            switchOnText={formatMessage({ id: 'transactions.list.rememberRuleInfo' })}
          />
        </CustomTableCell>
      )}
      {financialTransactionsWrite && (
        <CustomTableCell scope="row" padding="none">
          <Divider orientation="vertical" style={{ backgroundColor: Colors.GEYSER_GREY, height: 30 }} />
        </CustomTableCell>
      )}
      {financialTransactionsWrite && (
        <CustomTableCell scope="row" padding="none">
          <Grid container justifyContent="center">
            <Grid item style={{ width: ReconcileIconWidth }}>
              <Tooltip
                title={
                  shouldReconcileTransaction
                    ? formatMessage({ id: 'transactions.lettering.reconcile' })
                    : formatMessage({ id: 'transactions.list.validate' })
                }
              >
                <CustomLoadableIconButton
                  onMouseDown={stopPropagation}
                  isLoading={state.loading}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await handleValidateTransaction();
                  }}
                  loadingStyle={{ color: Colors.DODGER_BLUE }}
                  Icon={state.success ? SaveRounded : shouldReconcileTransaction ? ReconcileIcon : CircleCheck}
                  iconStyle={!state.success && !shouldReconcileTransaction ? { fill: Colors.DODGER_BLUE } : {}}
                />
              </Tooltip>
            </Grid>
            <Grid item style={{ width: IconWidth }}>
              <Tooltip title={formatMessage({ id: 'transactions.list.split' })}>
                <CustomIconButton
                  onMouseDown={stopPropagation}
                  onClick={async (e) => {
                    e.stopPropagation();
                    actionMenu(e, id, false, true);
                  }}
                  Icon={Alternate}
                  iconStyle={{ fill: Colors.BLUEY }}
                />
              </Tooltip>
            </Grid>
            <Grid item style={{ width: IconWidth }}>
              <Tooltip title={formatMessage({ id: 'transactions.list.exclude' })}>
                <CustomIconButton
                  onMouseDown={stopPropagation}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await handleExcludeTransaction();
                  }}
                  Icon={CircleMinus}
                  iconStyle={{ fill: Colors.BLUEY }}
                />
              </Tooltip>
            </Grid>
            {manualEntry && financialTransactionsDelete && (
              <Grid item style={{ width: IconWidth }}>
                <Tooltip title={formatMessage({ id: 'transactions.list.delete' })}>
                  <CustomIconButton
                    onMouseDown={stopPropagation}
                    onClick={(e) => {
                      e.stopPropagation();
                      actionMenu(e, id, true);
                    }}
                    Icon={TrashIcon}
                    iconStyle={{ fill: Colors.BURNING_ORANGE }}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      )}
    </TableRow>
  );
};

export default TransactionsToCheckRow;
