/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-shadow */
import { CircularProgress, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Colors, S3Object } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { EmptyFileWithUrl } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryStructureEncodingExtraInfos';
import { CustomIconButton } from '@up2rent/ui';
import PDFViewer from './PDFViewer';

interface PreviewFilePDFDialogDetailProps {
  file: S3Object | EmptyFileWithUrl | null;
  withSignatures?: boolean;
}
const PreviewFilePDFDialogDetail: React.FC<PreviewFilePDFDialogDetailProps> = ({ file, withSignatures }) => {
  const { formatMessage } = useIntl();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  if (isNil(file)) {
    return null;
  }
  const mimeType = 'file' in file ? file.file.type : file.mimeType;
  if (isNil(mimeType) || !mimeType.includes('pdf')) {
    return null;
  }

  return (
    <>
      <DialogTitle>
        <Grid container style={{ marginLeft: 30, marginRight: 30 }}>
          <Grid item xs={2}>
            <Typography style={{ fontSize: 12, fontWeight: 600 }}>
              {formatMessage({ id: 'lease.detail.action.pageOf' }, { pageNumber, numPages })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {withSignatures && (
              <Typography style={{ color: Colors.BURNING_ORANGE }}>
                {formatMessage({ id: 'lease.detail.action.digitalSignaturePreview' })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <CustomIconButton
              size="small"
              disabled={pageNumber === 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              Icon={SkipPreviousIcon}
            />
            <CustomIconButton
              size="small"
              disabled={pageNumber === numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              Icon={SkipNextIcon}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isNil(numPages) && (
          <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        )}
        <PDFViewer
          url={file.url}
          width={600}
          pageToShow={pageNumber}
          onLoadSuccess={onDocumentLoadSuccess}
          shouldShowLoading={false}
        />
      </DialogContent>
    </>
  );
};

export default PreviewFilePDFDialogDetail;
