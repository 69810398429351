/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useUser } from './UserContext';
import { TransactionContextProvider } from './TransactionsContext';
import { CommunicationContextProvider } from './CommunicationsContext';
import { BuildingContextProvider } from './BuildingsContext';
import { ContactContextProvider } from './ContactsContext';
import { SettingContextProvider } from './SettingsContext';
import { TemplateContextProvider } from './TemplatesContext';
import { TicketContextProvider } from './TicketsContext';
import { UnitContextProvider } from './UnitsContext';
import { LeaseContextProvider } from './LeasesContext';
import { BankAccountContextProvider } from './BankAccountsContext';
import { ContextLoaderProvider } from './ContextLoader';
import { UnitInventoryContextProvider } from './UnitInventoryContext';
import { UsersContextProvider } from './UsersContext';
import { TeamsContextProvider } from './TeamsContext';
import { TechnicsContextProvider } from './TechnicsContext';
import { UserRolesContextProvider } from './UserRolesContext';
import { isNil, isEmpty } from 'lodash';
import { TenantContextProvider } from '../tenantHooks/TenantContext';
import { LeaseInventoryContextProvider } from './LeaseInventoryContext';
import { NotificationsContextProvider } from './NotificationsContext';
import { PermissionsContextProvider, TenantPermissionsContextProvider } from './utils/PermissionsContext';
import { useUp2RentVersion } from './utils/useUp2RentVersion';
import { VariousOperationsContextProvider } from './VariousOperationsContext';
import { AgencyRatesContextProvider } from './AgencyRatesContext';
import { CustomSubscriptionContextProvider } from './CustomSubscriptionContext';
import { SignatureDocumentsContextProvider } from './SignatureDocumentContext';
import { LeaseVariousOperationContextProvider } from './LeaseVariousOperationsContext';
import { InvoiceContextProvider } from './InvoicesContext';
import { LeasePriceHistoryContextProvider } from './LeasePriceHistoriesContext';
import { SendingSourceContextProvider } from './SendingSourceContext';
import { IntegrationContextProvider } from './IntegrationsContext';
import { LeaseActionHistoryContextProvider } from './LeaseActionHistoriesContext';
import { AddressContextProvider } from './AddressContext';
import { LeaseContactContextProvider } from './LeaseContactContext';
import { UnitLeaseContextProvider } from './UnitLeaseContext';
import { InventoryCustomItemsContextProvider } from './InventoryCustomItemContext';
import { LeaseInventoryEncodingsContextProvider } from './LeaseInventoryEncodingContext';
import { LeaseInventoryCheckedItemsContextProvider } from './LeaseInventoryCheckedItemContext';
import { CommunicationSettingsProfileContextProvider } from './CommunicationSettingsProfilesContext';
import { FieldFilterResetProvider } from 'src/components/ui/FieldFilterSelector';
import { DocumentCategoryContextProvider } from './FileCategoryContext';
import { ClientContextProvider } from './ClientContext';
import { ChargeContextProvider } from './ChargesContext';
import { FilesContextProvider } from './FilesContext';
import { DocumentsContextProvider } from './Folderhook/FolderContext';

const EntityConditionalHooks: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { clientId, tenantId, isFetchingUser, userAttributes } = useUser();
  const isAuthenticated = !isEmpty(userAttributes?.email);
  const { shouldClearCache, clearCache } = useUp2RentVersion();

  useEffect(() => {
    if (shouldClearCache && isAuthenticated && !isNil(clientId) && !isFetchingUser) {
      clearCache();
    }
  }, [shouldClearCache, clearCache, clientId, isAuthenticated, isFetchingUser]);

  if (!isAuthenticated || isNil(clientId)) {
    // No query for an authenticated user
    return <>{children}</>;
  }

  if (shouldClearCache) {
    return null;
  }

  if (!isNil(tenantId)) {
    return (
      <TenantPermissionsContextProvider>
        <FieldFilterResetProvider>
          <FilesContextProvider>
            <TenantContextProvider>{children}</TenantContextProvider>
          </FilesContextProvider>
        </FieldFilterResetProvider>
      </TenantPermissionsContextProvider>
    );
  }

  return (
    <ContextLoaderProvider>
      <ClientContextProvider>
        <PermissionsContextProvider>
          <FilesContextProvider>
            <AddressContextProvider>
              <BuildingContextProvider>
                <UnitContextProvider>
                  <TemplateContextProvider>
                    <SettingContextProvider>
                      <ContactContextProvider>
                        <UsersContextProvider>
                          <UserRolesContextProvider>
                            <TeamsContextProvider>
                              <BankAccountContextProvider>
                                <TicketContextProvider>
                                  <NotificationsContextProvider>
                                    <TechnicsContextProvider>
                                      <InventoryCustomItemsContextProvider>
                                        <LeaseInventoryCheckedItemsContextProvider>
                                          <LeaseInventoryEncodingsContextProvider>
                                            <UnitInventoryContextProvider>
                                              <LeaseInventoryContextProvider>
                                                <SignatureDocumentsContextProvider>
                                                  <LeaseContactContextProvider>
                                                    <UnitLeaseContextProvider>
                                                      <LeaseContextProvider>
                                                        <TransactionContextProvider>
                                                          <InvoiceContextProvider>
                                                            <CommunicationContextProvider>
                                                              <VariousOperationsContextProvider>
                                                                <LeaseVariousOperationContextProvider>
                                                                  <CustomSubscriptionContextProvider>
                                                                    <AgencyRatesContextProvider>
                                                                      <LeasePriceHistoryContextProvider>
                                                                        <LeaseActionHistoryContextProvider>
                                                                          <SendingSourceContextProvider>
                                                                            <IntegrationContextProvider>
                                                                              <ChargeContextProvider>
                                                                                <DocumentCategoryContextProvider>
                                                                                  <DocumentsContextProvider>
                                                                                    <CommunicationSettingsProfileContextProvider>
                                                                                      {children}
                                                                                    </CommunicationSettingsProfileContextProvider>
                                                                                  </DocumentsContextProvider>
                                                                                </DocumentCategoryContextProvider>
                                                                              </ChargeContextProvider>
                                                                            </IntegrationContextProvider>
                                                                          </SendingSourceContextProvider>
                                                                        </LeaseActionHistoryContextProvider>
                                                                      </LeasePriceHistoryContextProvider>
                                                                    </AgencyRatesContextProvider>
                                                                  </CustomSubscriptionContextProvider>
                                                                </LeaseVariousOperationContextProvider>
                                                              </VariousOperationsContextProvider>
                                                            </CommunicationContextProvider>
                                                          </InvoiceContextProvider>
                                                        </TransactionContextProvider>
                                                      </LeaseContextProvider>
                                                    </UnitLeaseContextProvider>
                                                  </LeaseContactContextProvider>
                                                </SignatureDocumentsContextProvider>
                                              </LeaseInventoryContextProvider>
                                            </UnitInventoryContextProvider>
                                          </LeaseInventoryEncodingsContextProvider>
                                        </LeaseInventoryCheckedItemsContextProvider>
                                      </InventoryCustomItemsContextProvider>
                                    </TechnicsContextProvider>
                                  </NotificationsContextProvider>
                                </TicketContextProvider>
                              </BankAccountContextProvider>
                            </TeamsContextProvider>
                          </UserRolesContextProvider>
                        </UsersContextProvider>
                      </ContactContextProvider>
                    </SettingContextProvider>
                  </TemplateContextProvider>
                </UnitContextProvider>
              </BuildingContextProvider>
            </AddressContextProvider>
          </FilesContextProvider>
        </PermissionsContextProvider>
      </ClientContextProvider>
    </ContextLoaderProvider>
  );
};

export default EntityConditionalHooks;
