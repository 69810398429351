import React from 'react';

import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { LeaseAction } from './useLeaseActionsMenuUtils';
import IntermediateLeaseActionMenuDialogs from './IntermediateLeaseActionMenuDialog';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useStatementSettings } from 'src/hooks/StatementSettingsContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { getMainUnit, S3Object } from '@rentguru/commons-utils';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';

interface LeaseActionMenuDialogsProps {
  lease: LeaseExtended;
  open: boolean;
  type: LeaseAction | null;
  onCloseDialog: () => void;
  leaseContract: S3Object | null | undefined;
}

const LeaseActionMenuDialogs: React.FC<LeaseActionMenuDialogsProps> = ({
  lease,
  open,
  type,
  onCloseDialog,
  leaseContract,
}) => {
  const { deleteLeasePriceHistory } = useLeasePriceHistories();
  const { deleteLeaseVariousOperation } = useLeaseVariousOperations();
  const { deepDeleteLease, updateLease } = useLeases();
  const { deleteInvoice } = useInvoices();
  const { deletePosting } = useTransactions();
  const { deleteStatementSetting } = useStatementSettings(false);
  const { batchDeleteCommunications } = useCommunications();
  const { deleteLeaseActionHistory } = useLeaseActionHistories();
  const { deleteCommunicationSettingsProfile } = useCommunicationSettingsProfiles();
  const { getUnit } = useUnits();

  const mainUnitId = getMainUnit(lease.units ?? [])?.id;
  const mainUnit = getUnit(mainUnitId ?? '');

  return (
    <IntermediateLeaseActionMenuDialogs
      lease={lease}
      mainUnit={mainUnit}
      open={open}
      type={type}
      onCloseDialog={onCloseDialog}
      leaseContract={leaseContract}
      deepDeleteLease={deepDeleteLease}
      updateLease={updateLease}
      deleteFunctions={{
        deleteLeasePriceHistory,
        deleteLeaseVariousOperation,
        deleteInvoice,
        deletePosting,
        deleteStatementSetting,
        deleteLeaseActionHistory,
        deleteCommunicationSettingsProfile,
        batchDeleteCommunications,
      }}
    />
  );
};

export default LeaseActionMenuDialogs;
