import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import { NewFile } from '../../../../hooks/FilesContext';
import * as Yup from 'yup';
import FormikDatePicker from '../../FormikDatePicker';
import { Divider, Typography } from '@material-ui/core';
import ContactSelector from '../../SimpleSelectors/SimpleContactSelector';
import { useContacts } from 'src/hooks/ContactsContext';
import isEmpty from 'lodash/isEmpty';
import DropZoneField from './DropZoneField';
import { NewMaintenanceHistory } from './MaintenanceFields';
import { EmptyFileWithUrl } from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryStructureEncodingExtraInfos';
import { v4 as uuidv4 } from 'uuid';
import { Colors, ContactType } from '@rentguru/commons-utils';

interface MaintenanceControlFieldsProps {
  afterSubmit: (newMaintenance: NewMaintenanceHistory) => void;
  cancelSubmit: () => void;
}

const MaintenanceSchema = Yup.object().shape({
  date: Yup.date().required(),
});

interface AddMaintenanceControlValues {
  date: Date;
  maintenanceFiles: NewFile[];
  contactId: string;
}
// Mandatory to split in two components because of the onDrop.
// => Cannot use callback inside callback
const MaintenanceControlFieldsFormik: React.FC<{ cancelSubmit: () => void }> = ({ cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const { contractors } = useContacts();
  const { values, errors, touched, isSubmitting, status, setFieldValue } =
    useFormikContext<AddMaintenanceControlValues>();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const filesToAdd: NewFile[] = acceptedFiles.map((acceptedFile: File) => ({
        mimeType: acceptedFile.type,
        name: acceptedFile.name,
        size: acceptedFile.size,
        file: acceptedFile,
      }));
      setFieldValue(`maintenanceFiles`, filesToAdd);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue]
  );

  const removeFileIndex = (index: number) => {
    setFieldValue(
      `maintenanceFiles`,
      values.maintenanceFiles.filter((_file, i) => i !== index)
    );
  };

  return (
    <Form>
      <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: 20,
            textAlign: 'left',
          }}
        >
          {formatMessage({ id: 'technic.addControl' })}
        </Typography>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
        <FormikDatePicker
          name="date"
          label={formatMessage({ id: 'technic.heatingTab.heatingLastControlDate' })}
          value={values.date}
          style={{ width: 560 }}
          error={Boolean(errors.date && touched.date)}
        />
        <ContactSelector
          fieldLabel={`
      ${formatMessage({
        id: 'technic.contractorName',
      })} ${formatMessage({
            id: 'optional',
          })}`}
          contacts={contractors}
          fieldName="contactId"
          inputWidth={560}
          type={[ContactType.CONTRACTOR]}
        />
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <DropZoneField
        onDrop={onDrop}
        currentFiles={values.maintenanceFiles}
        maxFiles={1}
        centerSelectedFile
        removeFileByIndex={removeFileIndex}
      />
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <div
        style={{
          marginBottom: 20,
          marginRight: 30,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <ActionButton
          onClick={() => {
            cancelSubmit();
          }}
          style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
        >
          {toUpper(formatMessage({ id: 'cancel' }))}
        </ActionButton>
        <LoaderButton loading={isSubmitting} success={status}>
          {formatMessage({
            id: 'save',
          })}
        </LoaderButton>
      </div>
    </Form>
  );
};

const MaintenanceControlFields: React.FC<MaintenanceControlFieldsProps> = ({ afterSubmit, cancelSubmit }) => {
  const handleControlCreate = (
    values: AddMaintenanceControlValues,
    { setSubmitting, setStatus }: FormikHelpers<AddMaintenanceControlValues>
  ): void => {
    const { date, contactId, maintenanceFiles } = values;
    const maintenanceFile = maintenanceFiles[0];
    let maintenanceFileWithUrl: EmptyFileWithUrl | undefined;
    if (!isNil(maintenanceFile)) {
      maintenanceFileWithUrl = {
        id: `NEW-${uuidv4()}`,
        file: maintenanceFile.file,
        url: URL.createObjectURL(maintenanceFile.file),
      };
    }
    const newMaintenance = { id: `NEW-${uuidv4()}`, contactId, date: date.toISOString(), file: maintenanceFileWithUrl };
    afterSubmit(newMaintenance);
    setStatus(true);
    setSubmitting(false);
    cancelSubmit();
  };

  return (
    <Formik
      initialValues={{
        date: new Date(),
        contactId: '',
        maintenanceFiles: [] as NewFile[],
      }}
      validationSchema={MaintenanceSchema}
      onSubmit={handleControlCreate}
    >
      {() => {
        return <MaintenanceControlFieldsFormik cancelSubmit={cancelSubmit} />;
      }}
    </Formik>
  );
};

export default MaintenanceControlFields;
