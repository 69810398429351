/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import React, { useContext, useEffect, useMemo } from 'react';
import { useUser } from './UserContext';
import {
  deleteAndHideEntity,
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  recordWasUpdated,
  removeAddressObject,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import {
  Contact,
  Address,
  BuildingOwner,
  UnitOwner,
  ContactType,
  UserRightChangeEvent,
  JointOwner,
  StatementSetting,
  Ticket,
  AgencyRateOwner,
  TransactionRule,
  File as FileModel,
  User,
  inviteContactToClientAccount as inviteContactToClientAccountMutation,
  handleUserRightsChange,
  syncContacts as syncQuery,
  getContact as getQuery,
  createBuildingOwner as createBuildingOwnerMutation,
  updateBuildingOwner as updateBuildingOwnerMutation,
  deleteBuildingOwner as deleteBuildingOwnerMutation,
  createUnitOwner as createUnitOwnerMutation,
  updateUnitOwner as updateUnitOwnerMutation,
  deleteUnitOwner as deleteUnitOwnerMutation,
  createContact as createMutation,
  updateContact as updateMutation,
  deleteContact as deleteMutation,
  CreateContactMutationVariables as CreateMutationVariables,
  DeleteContactMutationVariables as DeleteMutationVariables,
  resolveManyToMany,
  resolveOneToOne,
  cleanInputCreate,
  cleanInputUpdate,
  getReadId,
  getTableClientId,
  getHash,
  contactContainsType,
  getContactNameFromObject,
  InviteContactToClientAccountMutationVariables,
  InviteContactToClientAccountType,
  Model,
  isNilOrEmpty,
  syncBuildingOwners,
  syncUnitOwners,
  CreateBuildingOwnerMutationVariables,
  CreateBuildingOwnerInput,
  CreateUnitOwnerMutationVariables,
  CreateUnitOwnerInput,
  OnCreateBuildingOwnerSubscription,
  OnUpdateBuildingOwnerSubscription,
  OnDeleteBuildingOwnerSubscription,
  onCreateBuildingOwner,
  onUpdateBuildingOwner,
  onDeleteBuildingOwner,
  OnCreateUnitOwnerSubscription,
  OnUpdateUnitOwnerSubscription,
  OnDeleteUnitOwnerSubscription,
  onCreateUnitOwner,
  onUpdateUnitOwner,
  onDeleteUnitOwner,
  OnCreateContactSubscription,
  OnUpdateContactSubscription,
  OnDeleteContactSubscription,
  onCreateContact,
  onUpdateContact,
  onDeleteContact,
  ContactStatus,
} from '@rentguru/commons-utils';
import { isNil, isEmpty } from 'lodash';
import { ContextLoaderAction, ContextLoaderStore, useContextLoader } from './ContextLoader';
import { usePermissions } from './utils/PermissionsContext';
import { getExistingJointOwnershipOfOwners } from 'src/utils/contactutils';
import { TicketContext } from './TicketsContext';
import { TransactionContext } from './TransactionsContext';
import { StatementSettingContext } from './StatementSettingsContext';
import { AgencyRatesContext } from './AgencyRatesContext';
import { FilesContext } from './FilesContext';
import { UsersContext } from './UsersContext';
import { AddressContext, useAddresses } from './AddressContext';

const ENTITY_MODEL_NAME: Model = 'Contact';
const ENTITY_MODEL_NAME_BUILDING_OWNER: Model = 'BuildingOwner';
const ENTITY_MODEL_NAME_UNIT_OWNER: Model = 'UnitOwner';

export type ActionContact =
  | {
      type: 'SHOULD_FETCH_CONTACT' | 'IS_FETCHING_CONTACT';
    }
  | {
      type: 'ADD_CONTACT';
      payload: { contact: Contact };
    }
  | {
      type: 'FETCHED_CONTACT';
      payload: { contacts: Contact[] };
    }
  | {
      type: 'UPDATE_CONTACT';
      payload: { contact: Contact };
    }
  | {
      type: 'DELETE_CONTACT';
      payload: { id: string };
    };

export type ActionBuildingOwner =
  | {
      type: 'SHOULD_FETCH_BUILDING_OWNER' | 'IS_FETCHING_BUILDING_OWNER';
    }
  | {
      type: 'ADD_BUILDING_OWNER';
      payload: { buildingOwner: BuildingOwner };
    }
  | {
      type: 'FETCHED_BUILDING_OWNER';
      payload: { buildingOwners: BuildingOwner[] };
    }
  | {
      type: 'UPDATE_BUILDING_OWNER';
      payload: { buildingOwner: BuildingOwner };
    }
  | {
      type: 'DELETE_BUILDING_OWNER';
      payload: { id: string };
    };

export type ActionUnitOwner =
  | {
      type: 'SHOULD_FETCH_UNIT_OWNER' | 'IS_FETCHING_UNIT_OWNER';
    }
  | {
      type: 'ADD_UNIT_OWNER';
      payload: { unitOwner: UnitOwner };
    }
  | {
      type: 'FETCHED_UNIT_OWNER';
      payload: { unitOwners: UnitOwner[] };
    }
  | {
      type: 'UPDATE_UNIT_OWNER';
      payload: { unitOwner: UnitOwner };
    }
  | {
      type: 'DELETE_UNIT_OWNER';
      payload: { id: string };
    };

type ContactNoId = Omit<Contact, 'id' | 'clientId' | 'readId'>;

interface CreateContact extends Omit<ContactNoId, 'address'> {
  address?: Omit<Address, 'id' | 'clientId' | 'readId'> | null;
}

export interface ContactContext {
  contacts: Contact[];
  tenants: Contact[];
  guarantors: Contact[];
  owners: Contact[];
  members: Contact[];
  contractors: Contact[];
  jointOwners: Contact[];
  ocrDrafts: Contact[];
  getContact: (id: string) => Contact | undefined;
  createBuildingOwner: (
    input: Omit<BuildingOwner, 'id' | 'readId'> | Omit<BuildingOwner, 'id' | 'clientId' | 'readId'>
  ) => Promise<BuildingOwner>;
  updateBuildingOwner: (original: BuildingOwner, updates: Partial<BuildingOwner>) => Promise<void>;
  deleteBuildingOwner: (id: string) => Promise<void>;
  deleteUnitOwner: (id: string) => Promise<void>;
  updateUnitOwner: (original: UnitOwner, updates: Partial<UnitOwner>) => Promise<void>;
  createUnitOwner: (input: Omit<UnitOwner, 'id' | 'clientId' | 'readId'>) => Promise<UnitOwner>;
  createContact: (input: CreateContact) => Promise<Contact>;
  createContactWithoutAddress: (input: Omit<Contact, 'id' | 'clientId' | 'readId' | 'address'>) => Promise<Contact>;
  updateContact: (original: Contact, updates: Partial<Contact>) => Promise<Contact>;
  deleteContact: (id: string) => Promise<void>;
  deepDeleteContact: (
    contact: Contact,
    contactTickets: Ticket[],
    contactTransactionRules: TransactionRule[],
    statementSettings: StatementSetting[],
    contactAgencyRates: AgencyRateOwner[],
    contactFiles: FileModel[],
    contactUser: User | undefined,
    deleteTicket: TicketContext['deleteTicket'],
    deleteTransactionRule: TransactionContext['deleteTransactionRule'],
    deleteStatementSetting: StatementSettingContext['deleteStatementSetting'],
    deleteAgencyRateOwner: AgencyRatesContext['deleteAgencyRateOwner'],
    deleteFile: FilesContext['deleteFile'],
    deleteUser: UsersContext['deleteUser']
  ) => Promise<void>;
  inviteContactToClientAccount: (contact: Contact, clientContact: Contact, language?: string) => Promise<string>;
  addOwnerTypeIfContactIsMember: (contact: Contact) => Promise<Contact>;
  createAddress: AddressContext['createAddress'];
  updateAddress: AddressContext['updateAddress'];
  deleteAddress: AddressContext['deleteAddress'];
  getExistingJointOwnershipOfOwnersOrCreateIfNotExists: (
    existingJointOwnerships: Contact[],
    ownerIds: string[]
  ) => Promise<Contact>;
  contactsLoading: boolean;
  contactsError: string | undefined;
  setFetchContacts: () => void;
  setFetchBuildingOwners: () => void;
  setFetchUnitOwners: () => void;
}

export const contactReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_CONTACT':
      if (state.Contact.loading || state.Contact.shouldFetch) {
        return state;
      }
      return {
        ...state,
        Contact: { ...state.Contact, shouldFetch: true },
      };
    case 'IS_FETCHING_CONTACT':
      if (state.Contact.loading) {
        return state;
      }
      return {
        ...state,
        Contact: { ...state.Contact, loading: true },
      };
    case 'FETCHED_CONTACT':
      return {
        ...state,
        Contact: {
          ...state.Contact,
          data: action.payload.contacts,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_CONTACT':
      // Check if already present - If already added by this user or coming from another user
      if (state.Contact.data?.find((object) => object.id === action.payload.contact.id)) {
        return state;
      }

      return {
        ...state,
        Contact: {
          ...state.Contact,
          data: [...state.Contact.data, action.payload.contact],
        },
      };
    case 'UPDATE_CONTACT':
      // No data
      if (isNilOrEmpty(state.Contact.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.Contact.data?.find((object) => object.id === action.payload.contact.id);
      if (!currentObject) {
        return {
          ...state,
          Contact: {
            ...state.Contact,
            data: [...state.Contact.data, action.payload.contact],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.contact)) {
        return state;
      }

      // Update
      return {
        ...state,
        Contact: {
          ...state.Contact,
          data: state.Contact.data.map((object) => {
            if (object.id === action.payload.contact.id) {
              return action.payload.contact;
            }
            return object;
          }),
        },
      };
    case 'DELETE_CONTACT':
      if (isNilOrEmpty(state.Contact.data)) {
        return state;
      }
      return {
        ...state,
        Contact: {
          ...state.Contact,
          data: state.Contact.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

export const buildingOwnerReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_BUILDING_OWNER':
      if (state.BuildingOwner.loading || state.BuildingOwner.shouldFetch) {
        return state;
      }
      return {
        ...state,
        BuildingOwner: { ...state.BuildingOwner, shouldFetch: true },
      };
    case 'IS_FETCHING_BUILDING_OWNER':
      if (state.BuildingOwner.loading) {
        return state;
      }
      return {
        ...state,
        BuildingOwner: { ...state.BuildingOwner, loading: true },
      };
    case 'FETCHED_BUILDING_OWNER':
      return {
        ...state,
        BuildingOwner: {
          ...state.BuildingOwner,
          data: action.payload.buildingOwners,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_BUILDING_OWNER':
      // Check if already present - If already added by this user or coming from another user
      if (state.BuildingOwner.data?.find((object) => object.id === action.payload.buildingOwner.id)) {
        return state;
      }

      return {
        ...state,
        BuildingOwner: {
          ...state.BuildingOwner,
          data: [...state.BuildingOwner.data, action.payload.buildingOwner],
        },
      };
    case 'UPDATE_BUILDING_OWNER':
      // No data
      if (isNilOrEmpty(state.BuildingOwner.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.BuildingOwner.data?.find((object) => object.id === action.payload.buildingOwner.id);
      if (!currentObject) {
        return {
          ...state,
          BuildingOwner: {
            ...state.BuildingOwner,
            data: [...state.BuildingOwner.data, action.payload.buildingOwner],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.buildingOwner)) {
        return state;
      }

      // Update
      return {
        ...state,
        BuildingOwner: {
          ...state.BuildingOwner,
          data: state.BuildingOwner.data.map((object) => {
            if (object.id === action.payload.buildingOwner.id) {
              return action.payload.buildingOwner;
            }
            return object;
          }),
        },
      };
    case 'DELETE_BUILDING_OWNER':
      if (isNilOrEmpty(state.BuildingOwner.data)) {
        return state;
      }
      return {
        ...state,
        BuildingOwner: {
          ...state.BuildingOwner,
          data: state.BuildingOwner.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

export const unitOwnerReducerDelegation = (
  state: ContextLoaderStore,
  action: ContextLoaderAction
): ContextLoaderStore => {
  switch (action.type) {
    case 'SHOULD_FETCH_UNIT_OWNER':
      if (state.UnitOwner.loading || state.UnitOwner.shouldFetch) {
        return state;
      }
      return {
        ...state,
        UnitOwner: { ...state.UnitOwner, shouldFetch: true },
      };
    case 'IS_FETCHING_UNIT_OWNER':
      if (state.UnitOwner.loading) {
        return state;
      }
      return {
        ...state,
        UnitOwner: { ...state.UnitOwner, loading: true },
      };
    case 'FETCHED_UNIT_OWNER':
      return {
        ...state,
        UnitOwner: {
          ...state.UnitOwner,
          data: action.payload.unitOwners,
          loading: false,
          shouldFetch: false,
          lastFetch: new Date(),
        },
      };
    case 'ADD_UNIT_OWNER':
      // Check if already present - If already added by this user or coming from another user
      if (state.UnitOwner.data?.find((object) => object.id === action.payload.unitOwner.id)) {
        return state;
      }

      return {
        ...state,
        UnitOwner: {
          ...state.UnitOwner,
          data: [...state.UnitOwner.data, action.payload.unitOwner],
        },
      };
    case 'UPDATE_UNIT_OWNER':
      // No data
      if (isNilOrEmpty(state.UnitOwner.data)) {
        return state;
      }

      // Already present and same object
      const currentObject = state.UnitOwner.data?.find((object) => object.id === action.payload.unitOwner.id);
      if (!currentObject) {
        return {
          ...state,
          UnitOwner: {
            ...state.UnitOwner,
            data: [...state.UnitOwner.data, action.payload.unitOwner],
          },
        };
      }
      if (!recordWasUpdated(currentObject, action.payload.unitOwner)) {
        return state;
      }

      // Update
      return {
        ...state,
        UnitOwner: {
          ...state.UnitOwner,
          data: state.UnitOwner.data.map((object) => {
            if (object.id === action.payload.unitOwner.id) {
              return action.payload.unitOwner;
            }
            return object;
          }),
        },
      };
    case 'DELETE_UNIT_OWNER':
      if (isNilOrEmpty(state.UnitOwner.data)) {
        return state;
      }
      return {
        ...state,
        UnitOwner: {
          ...state.UnitOwner,
          data: state.UnitOwner.data.filter((object) => object.id !== action.payload.id),
        },
      };

    default:
      return state;
  }
};

export const fetchBuildingOwners = async (
  by: 'byClientId' | 'byBuilding' | 'byContact',
  byValue: string,
  additionalFilter?: object
): Promise<BuildingOwner[]> => {
  return await list<BuildingOwner>(syncBuildingOwners, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

export const fetchUnitOwners = async (
  by: 'byClientId' | 'byUnit' | 'byOwner',
  byValue: string,
  additionalFilter?: object
): Promise<UnitOwner[]> => {
  return await list<UnitOwner>(syncUnitOwners, getFilterFieldNameForIndex(by), byValue, additionalFilter);
};

const fetchContacts = async (
  clientId: string,
  additionalFilter?: object,
  isOwner: boolean = false
): Promise<Contact[]> => {
  const query = isOwner ? removeAddressObject(syncQuery) : syncQuery;
  const contacts = await list<Contact>(query, getFilterFieldNameForIndex('byClientId'), clientId, additionalFilter);
  const cleanedContacts = contacts.map((contact) => cleanInputUpdate(contact, false, true)) as Contact[];
  return cleanedContacts;
};

export const ContactContext = React.createContext<ContactContext | null>(null);

export const ContactContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const {
    BuildingOwner: { data: buildingOwners, loading: buildingOwnersLoading, shouldFetch: shouldFetchBuildingOwner },
    Contact: { data: contactLoader, loading: contactsLoading, shouldFetch: shouldFetchContact },
    LeaseContact: { data: leaseContacts, loading: leaseContactsLoading },
    UnitOwner: { data: unitOwners, loading: unitOwnersLoading, shouldFetch: shouldFetchUnitOwner },
    User: { data: users },
    Address: { data: address, loading: addressesLoading },
    dispatch: contextDispatch,
  } = useContextLoader();
  const { buildingsUnitsDetailsDelete, contactsDetailsDelete } = usePermissions();
  const { clientId, accountType, isOwner } = useUser();
  const { createAddress, updateAddress, deleteAddress } = useAddresses();
  const loading =
    buildingOwnersLoading || contactsLoading || leaseContactsLoading || unitOwnersLoading || addressesLoading;

  const contacts = useMemo<{
    contacts: Contact[];
    tenants: Contact[];
    guarantors: Contact[];
    owners: Contact[];
    members: Contact[];
    contractors: Contact[];
    jointOwners: Contact[];
    ocrDrafts: Contact[];
  }>(() => {
    if (loading) {
      return {
        contacts: [],
        tenants: [],
        guarantors: [],
        owners: [],
        members: [],
        contractors: [],
        jointOwners: [],
        ocrDrafts: [],
      };
    }

    const contactsReduced = contactLoader.reduce(
      (
        result: {
          contacts: Contact[];
          tenants: Contact[];
          guarantors: Contact[];
          owners: Contact[];
          members: Contact[];
          contractors: Contact[];
          jointOwners: Contact[];
          ocrDrafts: Contact[];
        },
        contact: Contact
      ) => {
        const contactObj = {
          ...contact,
          leases: resolveManyToMany(contact, 'contact', leaseContacts),
          units: resolveManyToMany(contact, 'owner', unitOwners),
          buildings: resolveManyToMany(contact, 'owner', buildingOwners),
          ...(contact.addressId ? { address: resolveOneToOne(contact.addressId, address, 'id') } : {}),
        };
        if (contact.status === ContactStatus.DRAFT_OCR) {
          result.ocrDrafts.push(contactObj);
        } else {
          if (contactContainsType(contact, ContactType.GUARANTOR)) {
            result.guarantors.push(contactObj);
          }
          if (contactContainsType(contact, ContactType.TENANT)) {
            result.tenants.push(contactObj);
          }
          if (contactContainsType(contact, ContactType.OWNER)) {
            result.owners.push(contactObj);
          }
          if (contactContainsType(contact, ContactType.MEMBER)) {
            result.members.push(contactObj);
          }
          if (contactContainsType(contact, ContactType.CONTRACTOR)) {
            result.contractors.push(contactObj);
          }
          if (contactContainsType(contact, ContactType.JOINT_OWNERSHIP)) {
            result.jointOwners.push(contactObj);
          }
          result.contacts.push(contactObj);
        }
        return result;
      },
      {
        contacts: [],
        tenants: [],
        owners: [],
        guarantors: [],
        members: [],
        contractors: [],
        jointOwners: [],
        ocrDrafts: [],
      }
    );
    return contactsReduced;
  }, [contactLoader, unitOwners, buildingOwners, leaseContacts, loading, address]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_BUILDING_OWNER' });
      const result = await fetchBuildingOwners(
        'byClientId',
        getTableClientId(clientId!, ENTITY_MODEL_NAME_BUILDING_OWNER)
      );
      contextDispatch({ type: 'FETCHED_BUILDING_OWNER', payload: { buildingOwners: result } });
    };
    if (shouldFetchBuildingOwner) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchBuildingOwner]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_UNIT_OWNER' });
      const result = await fetchUnitOwners('byClientId', getTableClientId(clientId!, ENTITY_MODEL_NAME_UNIT_OWNER));
      contextDispatch({ type: 'FETCHED_UNIT_OWNER', payload: { unitOwners: result } });
    };
    if (shouldFetchUnitOwner) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchUnitOwner]);

  useEffect(() => {
    const fetchAndSet = async () => {
      contextDispatch({ type: 'IS_FETCHING_CONTACT' });
      const result = await fetchContacts(
        getTableClientId(clientId!, ENTITY_MODEL_NAME_BUILDING_OWNER),
        undefined,
        isOwner
      );
      contextDispatch({ type: 'FETCHED_CONTACT', payload: { contacts: result } });
    };
    if (shouldFetchContact) fetchAndSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchContact]);

  useSubscriptions<
    OnCreateBuildingOwnerSubscription,
    OnUpdateBuildingOwnerSubscription,
    OnDeleteBuildingOwnerSubscription
  >(
    onCreateBuildingOwner,
    onUpdateBuildingOwner,
    onDeleteBuildingOwner,
    (data) => {
      contextDispatch({
        type: 'ADD_BUILDING_OWNER',
        payload: { buildingOwner: data.onCreateBuildingOwner as BuildingOwner },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_BUILDING_OWNER',
        payload: { buildingOwner: data.onUpdateBuildingOwner as BuildingOwner },
      });
    },
    (data) => {
      const { id } = data.onDeleteBuildingOwner as BuildingOwner;
      contextDispatch({
        type: 'DELETE_BUILDING_OWNER',
        payload: { id },
      });
    }
  );
  useSubscriptions<OnCreateUnitOwnerSubscription, OnUpdateUnitOwnerSubscription, OnDeleteUnitOwnerSubscription>(
    onCreateUnitOwner,
    onUpdateUnitOwner,
    onDeleteUnitOwner,
    (data) => {
      contextDispatch({
        type: 'ADD_UNIT_OWNER',
        payload: { unitOwner: data.onCreateUnitOwner as UnitOwner },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_UNIT_OWNER',
        payload: { unitOwner: data.onUpdateUnitOwner as UnitOwner },
      });
    },
    (data) => {
      const { id } = data.onDeleteUnitOwner as UnitOwner;
      contextDispatch({
        type: 'DELETE_UNIT_OWNER',
        payload: { id },
      });
    }
  );
  useSubscriptions<OnCreateContactSubscription, OnUpdateContactSubscription, OnDeleteContactSubscription>(
    onCreateContact,
    onUpdateContact,
    onDeleteContact,
    (data) => {
      contextDispatch({
        type: 'ADD_CONTACT',
        payload: { contact: data.onCreateContact as Contact },
      });
    },
    (data) => {
      contextDispatch({
        type: 'UPDATE_CONTACT',
        payload: { contact: data.onUpdateContact as Contact },
      });
    },
    (data) => {
      const { id } = data.onDeleteContact as Contact;
      contextDispatch({
        type: 'DELETE_CONTACT',
        payload: { id },
      });
    }
  );
  const setFetchBuildingOwners = () => {
    contextDispatch({ type: 'SHOULD_FETCH_BUILDING_OWNER' });
  };

  const setFetchUnitOwners = () => {
    contextDispatch({ type: 'SHOULD_FETCH_UNIT_OWNER' });
  };

  const setFetchContacts = () => {
    contextDispatch({ type: 'SHOULD_FETCH_CONTACT' });
  };

  const createContact = async (input: CreateContact): Promise<Contact> => {
    const { address, ...remainingInput } = input;
    if (address && address.street) {
      const newAddress = await createAddress({
        ...(cleanInputCreate(address) as Omit<Address, 'id' | 'clientId' | 'readId'>),
      });
      remainingInput.addressId = newAddress.id;
    }

    const cleanedInput = cleanInputCreate(remainingInput) as Omit<Contact, 'id' | 'clientId' | 'readId'>;
    const contact = await mutation<Contact, CreateMutationVariables>(createMutation, {
      input: {
        ...cleanedInput,
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME),
      },
    });
    contextDispatch({ type: 'ADD_CONTACT', payload: { contact } });
    return contact;
  };

  const createContactWithoutAddress = async (
    input: Omit<Contact, 'id' | 'clientId' | 'readId' | 'address'>
  ): Promise<Contact> => {
    const contact = await createContact(input);
    return contact;
  };

  const inviteContactToClientAccount = async (contact: Contact, clientContact: Contact, leaseLanguage?: string) => {
    const isTenant = contactContainsType(contact, ContactType.TENANT);
    const isOwner = contactContainsType(contact, ContactType.OWNER);
    if (isNil(contact.email) || isEmpty(contact.email) || (!isOwner && !isTenant)) {
      return '';
    }
    const clientName = getContactNameFromObject(clientContact);
    const result = await mutation<{ status: boolean; id: string }, InviteContactToClientAccountMutationVariables>(
      inviteContactToClientAccountMutation,
      {
        input: {
          clientId: clientId!,
          email: contact.email,
          hash: getHash(
            clientName,
            contact.email,
            isOwner ? ContactType.OWNER : ContactType.TENANT,
            leaseLanguage ?? contact.language,
            contact.firstName,
            contact.lastName
          ),
          contactId: contact.id,
          language: leaseLanguage ?? contact.language,
          accountType,
          invitationType: isOwner
            ? InviteContactToClientAccountType.INVITE_OWNER
            : InviteContactToClientAccountType.INVITE_TENANT,
        },
      }
    );

    return result.id;
  };

  const addOwnerTypeIfContactIsMember = async (contact: Contact) => {
    const isMember = contactContainsType(contact, ContactType.MEMBER);
    const isOwner = contactContainsType(contact, ContactType.OWNER);
    if (!isMember || isOwner) {
      return contact;
    }
    return await updateContact(contact, { types: [...(contact.types as ContactType[]), ContactType.OWNER] });
  };

  const updateContact = async (original: Contact, updates: Partial<Contact>) => {
    const result = await mutation<Contact>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false, true) },
    });
    contextDispatch({ type: 'UPDATE_CONTACT', payload: { contact: result } });
    return result;
  };

  const deleteContact = async (id: string) => {
    if (!contactsDetailsDelete) {
      return;
    }

    await deleteEntityWithFetchBefore<Pick<Contact, 'id'>, DeleteMutationVariables>({ id }, getQuery, deleteMutation);
    contextDispatch({ type: 'DELETE_BUILDING', payload: { id } });
  };

  const createBuildingOwner = async (
    input: Omit<BuildingOwner, 'id'> | Omit<BuildingOwner, 'id' | 'clientId' | 'readId'>
  ) => {
    const buildingOwner = await mutation<BuildingOwner, CreateBuildingOwnerMutationVariables>(
      createBuildingOwnerMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateBuildingOwnerInput),
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_BUILDING_OWNER),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME_BUILDING_OWNER),
        },
      }
    );
    contextDispatch({ type: 'ADD_BUILDING_OWNER', payload: { buildingOwner } });
    return buildingOwner;
  };

  const updateBuildingOwner = async (original: BuildingOwner, updates: Partial<BuildingOwner>) => {
    const result = await mutation<BuildingOwner>(updateBuildingOwnerMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_BUILDING_OWNER', payload: { buildingOwner: result } });
  };

  const deleteBuildingOwner = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    const fromStore = buildingOwners.find((bo) => bo.id === id);
    if (!isNil(fromStore)) {
      const ownerUser = users.find((u) => !isNil(u.contact) && u.contact.id === fromStore.owner!.id);
      if (!isNil(ownerUser)) {
        await mutation(handleUserRightsChange, {
          input: {
            userId: ownerUser.id,
            event: UserRightChangeEvent.DELETE_OWNER_BUILDING_ACCESS,
            clientId,
            buildingId: fromStore.building!.id,
          },
        });
      }
    }
    await deleteAndHideEntity<BuildingOwner>(fromStore!, deleteBuildingOwnerMutation, updateBuildingOwnerMutation);
  };

  const deleteUnitOwner = async (id: string) => {
    if (!buildingsUnitsDetailsDelete) {
      return;
    }
    const fromStore = unitOwners.find((uo) => uo.id === id);
    if (!isNil(fromStore)) {
      const ownerUser = users.find((u) => !isNil(u.contact) && u.contact.id === fromStore.owner!.id);
      if (!isNil(ownerUser)) {
        await mutation(handleUserRightsChange, {
          input: {
            userId: ownerUser.id,
            event: UserRightChangeEvent.DELETE_OWNER_UNIT_ACCESS,
            clientId,
            unitId: fromStore.unit!.id,
          },
        });
      }
    }
    await deleteAndHideEntity<UnitOwner>(fromStore!, deleteUnitOwnerMutation, updateUnitOwnerMutation);
  };

  const updateUnitOwner = async (original: UnitOwner, updates: Partial<UnitOwner>) => {
    const result = await mutation<UnitOwner>(updateUnitOwnerMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    contextDispatch({ type: 'UPDATE_UNIT_OWNER', payload: { unitOwner: result } });
  };

  const createUnitOwner = async (input: Omit<UnitOwner, 'id' | 'clientId' | 'readId'>): Promise<UnitOwner> => {
    const unitOwner = await mutation<UnitOwner, CreateUnitOwnerMutationVariables>(createUnitOwnerMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateUnitOwnerInput),
        clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME_UNIT_OWNER),
        readId: getReadId(clientId!, ENTITY_MODEL_NAME_UNIT_OWNER),
      },
    });
    contextDispatch({ type: 'ADD_UNIT_OWNER', payload: { unitOwner } });
    return unitOwner;
  };

  const getContact = (id: string) => {
    return contacts.contacts.find((c) => c.id === id) ?? contacts.ocrDrafts.find((c) => c.id === id);
  };

  const getExistingJointOwnershipOfOwnersOrCreateIfNotExists = async (
    existingJointOwnerships: Contact[],
    ownerIds: string[]
  ) => {
    const existingJointOwnership = getExistingJointOwnershipOfOwners(existingJointOwnerships, ownerIds);
    let jointOwnership: Contact;
    if (existingJointOwnership) {
      // Already existing joint ownership
      jointOwnership = existingJointOwnership;
    } else {
      const [jointOwnershipName, jointOwners] = ownerIds.reduce(
        (acc: [string, JointOwner[]], ownerId) => {
          const contact = getContact(ownerId);
          if (contact) {
            const contactName = getContactNameFromObject(contact);
            if (acc[0] === '') acc[0] = contactName;
            else acc[0] = `${acc[0]}, ${contactName}`;
          }
          acc[1].push({ contactId: ownerId, contactPerson: true });
          return acc;
        },
        ['', []]
      );
      // Create joint ownership
      jointOwnership = await createContactWithoutAddress({
        types: [ContactType.JOINT_OWNERSHIP],
        jointOwners,
        companyName: jointOwnershipName,
        sendInvitation: false,
      });
    }
    return jointOwnership;
  };

  const deepDeleteContact = async (
    contact: Contact,
    contactTickets: Ticket[],
    contactTransactionRules: TransactionRule[],
    statementSettings: StatementSetting[],
    contactAgencyRates: AgencyRateOwner[],
    contactFiles: FileModel[],
    contactUser: User | undefined,
    deleteTicket: TicketContext['deleteTicket'],
    deleteTransactionRule: TransactionContext['deleteTransactionRule'],
    deleteStatementSetting: StatementSettingContext['deleteStatementSetting'],
    deleteAgencyRateOwner: AgencyRatesContext['deleteAgencyRateOwner'],
    deleteFile: FilesContext['deleteFile'],
    deleteUser: UsersContext['deleteUser']
  ) => {
    if (!contactsDetailsDelete) {
      return;
    }
    const deletePromises: Promise<
      Ticket | TransactionRule | StatementSetting | AgencyRateOwner | FileModel | void | null
    >[] = [];
    for (const contactTicket of contactTickets) {
      deletePromises.push(deleteTicket(contactTicket.id));
    }
    for (const contactTransactionRule of contactTransactionRules) {
      deletePromises.push(deleteTransactionRule(contactTransactionRule));
    }
    for (const statementSetting of statementSettings) {
      deletePromises.push(deleteStatementSetting(statementSetting));
    }
    for (const contactAgencyRate of contactAgencyRates) {
      deletePromises.push(deleteAgencyRateOwner(contactAgencyRate));
    }
    for (const contactFile of contactFiles) {
      deletePromises.push(deleteFile(contactFile));
    }

    if (contactUser) {
      deletePromises.push(deleteUser(contactUser.id));
    }
    deletePromises.push(deleteContact(contact.id));
    await Promise.all(deletePromises);
  };

  const values = useMemo(
    () => ({
      ...contacts,
      createContact,
      createContactWithoutAddress,
      getContact,
      createBuildingOwner,
      updateBuildingOwner,
      deleteBuildingOwner,
      deleteUnitOwner,
      updateUnitOwner,
      createUnitOwner,
      updateContact,
      deleteContact,
      deepDeleteContact,
      inviteContactToClientAccount,
      addOwnerTypeIfContactIsMember,
      createAddress,
      updateAddress,
      deleteAddress,
      getExistingJointOwnershipOfOwnersOrCreateIfNotExists,
      contactsError: undefined,
      contactsLoading: loading,
      setFetchContacts,
      setFetchBuildingOwners,
      setFetchUnitOwners,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contacts, loading]
  );

  return <ContactContext.Provider value={values}>{children}</ContactContext.Provider>;
};

export const useContacts = (): ContactContext => {
  const context = useContext<ContactContext | null>(ContactContext);

  if (isNil(context)) {
    throw new Error('`useContacts` hook must be used within a `ContactContextProvider` component');
  }

  return context as ContactContext;
};
