/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  CreateFileMutationVariables as CreateMutationVariables,
  EntityType,
  FileCategory,
  File as FileModel,
  GenerateCommunicationPdfInput,
  GenerateCommunicationPdfMutationVariables,
  GenerateCommunicationPdfResult,
  GenerateDocumentInput,
  GenerateDocumentMutationVariables,
  LeasePriceHistory,
  LooseObject,
  Model,
  MutationStatus,
  cleanInputUpdate,
  createFile as createMutation,
  deleteFile as deleteMutation,
  generateCommunicationPdf as generateCommunicationPdfMutation,
  generateDocument as generateDocumentMutation,
  getFile,
  getFileKey,
  getReadId,
  getSanitizedFile,
  getTableClientId,
  syncFiles,
  updateFile as updateMutation,
  onCreateFile,
  onUpdateFile,
  onDeleteFile,
  OnCreateFileSubscription,
  OnUpdateFileSubscription,
  OnDeleteFileSubscription,
  isNilOrEmpty,
  S3Object,
} from '@rentguru/commons-utils';
import { downloadData, getUrl, remove, uploadData } from 'aws-amplify/storage';
import { isArray, isEmpty, isEqual, isError, uniq } from 'lodash';
import isNil from 'lodash/isNil';
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { syncFilesWithCategoryLabels, syncFiles as syncTenantFiles } from 'src/tenantHooks/graphqlHelper';
import amplifyConfiguration from '../aws-exports-oauth';
import { handleNewHEICFile } from '../utils/heicConvertor';
import { useUser } from './UserContext';
import {
  NUMBER_OF_MINUTES_FOR_REFETCH,
  deleteAndHideEntityWithFetchBefore,
  get,
  getFilterFieldNameForIndex,
  list,
  mutation,
  removeCategoryObject,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';

const ENTITY_MODEL_NAME: Model = 'File';

export interface FilesGroupedByCategory {
  category: FileCategory;
  files: S3Object[];
}

export interface NewFile {
  size: number;
  mimeType: string;
  name: string;
  file: File;
}

export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const b: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
};

export const getFileBlobFromUrl = async (url: string) => {
  const blobResponse = await fetch(url).then((r) => r.blob());
  return blobResponse;
};

export const getArrayBufferFromUrl = async (url: string) => {
  const blobResponse = await fetch(url).then((r) => r.arrayBuffer());
  return blobResponse;
};

export interface FilesContext extends Omit<FileState, 'loading'> {
  createFile: (
    file: File,
    entityType: EntityType,
    entityId: string,
    categoryId: string,
    options?: LooseObject,
    contactType?: string,
    contactId?: string
  ) => Promise<FileModel | null>;
  updateFile: (original: FileModel, updates: Partial<FileModel>) => Promise<FileModel>;
  deleteFile: (file: FileModel) => Promise<FileModel>;
  fetchFileFromId: (fileId: string) => Promise<S3Object[]>;
  generateDocument: (input: GenerateDocumentInput) => Promise<MutationStatus>;
  generateCommunicationPdf: (input: GenerateCommunicationPdfInput) => Promise<GenerateCommunicationPdfResult>;
  copyFile: (file: FileModel, entityType: EntityType, entityId: string, categoryId: string) => Promise<FileModel>;
  error: string | null;
  fetchFiles: (entityType: EntityType, entityId?: string) => Promise<FileModel[]>;
  fetchFilesByCategoryId: (categoryId: string) => Promise<FileModel[]>;
  getFiles: (entityType: EntityType, entityIds?: string[], category?: FileCategory) => FileModel[];
  loading: boolean;
}

interface FileState {
  filesCollection: {
    [key: string]: FileModel[];
  };
  loading: {
    [key: string]: boolean;
  };
  subscriptionsLoading: boolean;
  lastSync: {
    [fileId: string]: Date;
  };
  shouldFetch: { entityType?: EntityType; entityIds?: string[] }[];
  entityTypes?: EntityType[];
  entityIds?: string[];
  syncedEntityTypes: {
    [entityType: string]: boolean;
  };
}

type Action =
  | {
      type: 'IS_FETCHING';
      payload: { entityType: EntityType };
    }
  | {
      type: 'SHOULD_FETCH';
      payload: { entityType?: EntityType; entityIds?: string[] };
    }
  | {
      type: 'SET_SUBSCRIPTION_LOADING';
      payload: { subscriptionLoading: boolean };
    }
  | {
      type: 'ADD_FILE' | 'UPDATE_FILE';
      payload: { file: FileModel };
    }
  | {
      type: 'FETCHED';
      payload: { files: FileModel[]; entityType: EntityType; error?: string; keyIdsFetched: string[] };
    }
  | {
      type: 'ERROR';
      payload: { error: string };
    }
  | {
      type: 'DELETE_FILE';
      payload: { id: string; entityType: EntityType };
    };

const initialState: FileState = {
  filesCollection: {},
  loading: {},
  shouldFetch: [],
  lastSync: {},
  subscriptionsLoading: false,
  syncedEntityTypes: {},
};

const fileReducer = (state: FileState, action: Action): FileState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      const currentEntityType = action.payload.entityType;
      const currentEntityIds = action.payload.entityIds ?? [];

      if (!isEmpty(currentEntityIds)) {
        const idsToFetch = currentEntityIds.filter((entityId) => {
          const lastSyncForThisId = state.lastSync[entityId];
          return !(
            lastSyncForThisId && differenceInMinutes(new Date(), lastSyncForThisId) < NUMBER_OF_MINUTES_FOR_REFETCH
          );
        });

        if (state.loading[currentEntityType!] || isEmpty(idsToFetch)) {
          return state;
        }

        const existingEntityIds =
          state.shouldFetch.find((shouldFetchObject) => shouldFetchObject.entityType === currentEntityType)
            ?.entityIds ?? [];

        if (isEqual(existingEntityIds, idsToFetch) && !isEmpty(existingEntityIds)) {
          return state;
        }
        const newEntityIds = uniq([...idsToFetch, ...existingEntityIds]);
        const newShouldFetch = { entityType: currentEntityType, entityIds: newEntityIds };

        return {
          ...state,
          shouldFetch: [
            ...state.shouldFetch.filter((shouldFetchObject) => shouldFetchObject.entityType !== currentEntityType),
            newShouldFetch,
          ],
        };
      }

      if (
        state.syncedEntityTypes[currentEntityType!] ||
        !isNil(state.shouldFetch.find((value) => value.entityType === currentEntityType))
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: [...state.shouldFetch, { entityType: currentEntityType }],
      };
    case 'IS_FETCHING':
      if (state.loading[action.payload.entityType]) {
        return state;
      }
      return {
        ...state,
        loading: { ...state.loading, [action.payload.entityType]: true },
      };
    case 'FETCHED':
      if (action.payload.error) {
        return {
          ...state,
          filesCollection: {},
          loading: {},
          shouldFetch: [],
          lastSync: {},
        };
      }
      const files = action.payload.files;

      const fileIds = files.map((file) => file.id);
      const foreignKeysFetched = action.payload.keyIdsFetched;

      const entityType = action.payload.entityType;
      const shouldFetchUpdated = state.shouldFetch;
      shouldFetchUpdated.shift();

      const newLastSync = { ...state.lastSync };
      foreignKeysFetched.forEach((key) => (newLastSync[key] = new Date()));

      return {
        ...state,
        filesCollection: {
          ...state.filesCollection,
          [entityType]: [
            ...(state.filesCollection[entityType] ?? []).filter((file) => !fileIds.includes(file.id)),
            ...files,
          ],
        },
        loading: { ...state.loading, [action.payload.entityType]: false },
        shouldFetch: shouldFetchUpdated,
        lastSync: newLastSync,
        syncedEntityTypes: isNilOrEmpty(foreignKeysFetched)
          ? { ...state.syncedEntityTypes, [entityType]: true }
          : state.syncedEntityTypes,
      };
    case 'ADD_FILE':
      const newFile = action.payload.file;
      const newFileCollection = (state.filesCollection[newFile.foreignTableName] = [
        ...(state.filesCollection[newFile.foreignTableName] ?? []).filter((oldFile) => oldFile.id !== newFile.id),
        newFile,
      ]);

      return { ...state, filesCollection: { ...state.filesCollection, [newFile.foreignTableName]: newFileCollection } };
    case 'UPDATE_FILE':
      if (!state.filesCollection) {
        return state;
      }
      const updatedFile = action.payload.file;
      const oldFiles = state.filesCollection[updatedFile.foreignTableName];

      state.filesCollection[updatedFile.foreignTableName] = [
        ...oldFiles.filter((oldFile) => oldFile.id !== updatedFile.id),
        updatedFile,
      ];

      return { ...state };
    case 'DELETE_FILE':
      if (!state.filesCollection) {
        return state;
      }
      const entityTypeFromAction = action.payload.entityType;
      if (entityTypeFromAction) {
        state.filesCollection[entityTypeFromAction] = (state.filesCollection[entityTypeFromAction] ?? []).filter(
          (file) => file.id !== action.payload.id
        );
      }
      return { ...state };
    case 'SET_SUBSCRIPTION_LOADING':
      const newSubscriptionLoading = action.payload.subscriptionLoading;
      return { ...state, subscriptionsLoading: newSubscriptionLoading };
    default:
      return state;
  }
};

const getSyncQuery = (isTenant: boolean = false, isOwner: boolean = false) => {
  if (isTenant) {
    return syncTenantFiles;
  }
  if (isOwner) {
    return removeCategoryObject(syncFilesWithCategoryLabels);
  }
  return syncFilesWithCategoryLabels;
};

const fetchFilesWithFilters = async (
  clientId?: string,
  entityType?: EntityType | EntityType[],
  entityId?: string,
  isTenant: boolean = false,
  isOwner: boolean = false
) => {
  const syncQuery = getSyncQuery(isTenant, isOwner);

  if (!isNil(entityType) && !isNil(entityId) && !isArray(entityType)) {
    return await list<FileModel>(syncQuery, getFilterFieldNameForIndex('byForeignKey'), entityId, {
      foreignTableName: { eq: entityType },
    });
  }
  if (!isNil(entityType) && !isArray(entityType) && !isNil(clientId)) {
    return await list<FileModel>(
      syncQuery,
      getFilterFieldNameForIndex('byClientId'),
      getTableClientId(clientId, ENTITY_MODEL_NAME),
      {
        foreignTableName: { eq: entityType },
      }
    );
  }
  if (!isNil(entityType) && isArray(entityType) && !isNil(clientId)) {
    return await list<FileModel>(
      syncQuery,
      getFilterFieldNameForIndex('byClientId'),
      getTableClientId(clientId, ENTITY_MODEL_NAME),
      {
        or: [
          {
            foreignTableName: { eq: entityType[0] },
          },
          {
            foreignTableName: { eq: entityType[1] },
          },
        ],
      }
    );
  }
  if (!isNil(entityId)) {
    return await list<FileModel>(syncQuery, getFilterFieldNameForIndex('byForeignKey'), entityId);
  }
  if (!isNil(clientId)) {
    return await list<FileModel>(
      syncQuery,
      getFilterFieldNameForIndex('byClientId'),
      getTableClientId(clientId, ENTITY_MODEL_NAME)
    );
  }

  return null;
};

export const getS3ObjectUrls = async (
  files: FileModel[] | undefined | null,
  metadata: boolean | undefined = false
): Promise<S3Object[]> => {
  let s3Objects: S3Object[] = [];
  if (!isNil(files)) {
    const fileNames: string[] = [];
    /* Reconstruct the file keys and fetch the S3 objects */
    const filesPromise = (files as FileModel[]).map((file) => {
      fileNames.push(file.key.substring(file.key.indexOf('_') + 1));
      return getUrl({
        key: file.key,
      });
    });
    /* After fetched, build the 1-to-1 relation and set the hook to it in order to re-render */
    const urls = await Promise.all(filesPromise);

    s3Objects = fileNames.map((fileName: string, index: number) => {
      return { ...(files as FileModel[])[index], fileName, url: urls[index].url.toString() };
    });
    if (metadata) {
      // When downloading, we are no more receiving url.
      // We are receiving an object with Blob property so we need to do it in 2 parts
      const filesPromise = (files as FileModel[]).map((file) => downloadData({ key: file.key }).result);
      const metadatas = await Promise.all(filesPromise);
      s3Objects = s3Objects.map((s3Object, index) => {
        return {
          ...s3Object,
          lastModified: metadatas[index].lastModified,
          metadata: metadatas[index].metadata,
        };
      });
    }
  }
  return s3Objects;
};

export const fetchFilesAndGetS3ObjectUrls = async (
  entityType?: EntityType | EntityType[],
  entityId?: string
): Promise<Error | S3Object[]> => {
  const files = await fetchFilesWithFilters(undefined, entityType, entityId);

  if (isError(files)) {
    return files;
  }

  const s3Objects = await getS3ObjectUrls(files);
  return s3Objects;
};

export const fetchFilesOfLeasePriceHistory = async (
  leasePriceHistory: LeasePriceHistory | undefined
): Promise<FileModel[]> => {
  if (isNil(leasePriceHistory)) return [];
  const leaseFiles = await list<FileModel>(syncFiles, 'foreignKey', leasePriceHistory!.id);
  return leaseFiles;
};

export const FilesContext = React.createContext<FilesContext | null>(null);

export const FilesContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<FileState, Action>>(fileReducer, initialState);
  const { clientId, isOwner, isTenant, userId } = useUser();
  useSubscriptions<OnCreateFileSubscription, OnUpdateFileSubscription, OnDeleteFileSubscription>(
    onCreateFile,
    onUpdateFile,
    onDeleteFile,
    (data) => {
      const file = data.onCreateFile as FileModel;
      dispatch({ type: 'ADD_FILE', payload: { file } });
      // The following line and its corresponding useEffect exist because we want to change the loading status to
      // enable components to receive the last file. It is not possible by doing IS_FETCHING and then FETCHED because it
      // will be executed too quickly and the loading will stay at false.
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: { subscriptionLoading: true } });
    },
    (data) => {
      const file = data.onUpdateFile as FileModel;
      dispatch({ type: 'UPDATE_FILE', payload: { file } });
      // The following line and its corresponding useEffect exist because we want to change the loading status to
      // enable components to receive the last file. It is not possible by doing IS_FETCHING and then FETCHED because it
      // will be executed too quickly and the loading will stay at false.
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: { subscriptionLoading: true } });
    },
    (data) => {
      const file = data.onDeleteFile as FileModel;
      dispatch({ type: 'DELETE_FILE', payload: { id: file.id, entityType: file.foreignTableName as EntityType } });
      // The following line and its corresponding useEffect exist because we want to change the loading status to
      // enable components to receive the last file. It is not possible by doing IS_FETCHING and then FETCHED because it
      // will be executed too quickly and the loading will stay at false.
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: { subscriptionLoading: true } });
    }
  );

  useEffect(() => {
    let unmounted = false;
    const fetch = async () => {
      const currentFetchObject = state.shouldFetch[0];
      const entityIds = currentFetchObject.entityIds ?? [];
      const entityType = currentFetchObject.entityType!;
      if (state.loading[entityType]) return;
      let fetchedFiles;
      if (entityIds?.length > 1) {
        fetchedFiles = await fetchFilesByForeignKeys(entityIds, entityType as EntityType);
      } else {
        const entityId = entityIds?.[0];
        fetchedFiles = await fetchFiles(entityType, entityId);
      }
      if (!unmounted) {
        dispatch({
          type: 'FETCHED',
          payload: { files: fetchedFiles, entityType: entityType!, keyIdsFetched: entityIds },
        });
      }
    };
    if (!isEmpty(state.shouldFetch)) {
      fetch();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch, state.loading]);

  useEffect(() => {
    if (state.subscriptionsLoading) {
      dispatch({ type: 'SET_SUBSCRIPTION_LOADING', payload: { subscriptionLoading: false } });
    }
  }, [state.subscriptionsLoading]);

  const createFile = async (
    file: File,
    entityType: EntityType,
    entityId: string,
    categoryId: string,
    options: LooseObject = {},
    contactType?: string,
    contactId?: string
  ): Promise<FileModel | null> => {
    const readers = isOwner || isTenant ? [userId!] : undefined;
    let fileToCreate = getSanitizedFile(file);
    if (fileToCreate.type === 'image/heic') {
      const convertedFileBlob = await handleNewHEICFile(file);
      if (!convertedFileBlob) {
        return null;
      }
      const fileName = fileToCreate.name.replace('.heic', '.jpg');
      fileToCreate = new File([convertedFileBlob], fileName, {
        type: 'image/jpeg',
        lastModified: new Date().getTime(),
      });
    }
    try {
      const fileKey = getFileKey(clientId!, entityType, fileToCreate.name, false, contactType, contactId);

      await uploadData({
        key: fileKey,
        data: fileToCreate,
        options: {
          contentDisposition: `attachment; filename="${fileToCreate.name}"`,
          ...options,
        },
      }).result;
      const newFile = await mutation<FileModel, CreateMutationVariables>(createMutation, {
        input: {
          bucket: amplifyConfiguration.aws_user_files_s3_bucket,
          region: amplifyConfiguration.aws_user_files_s3_bucket_region,
          mimeType: fileToCreate.type,
          size: fileToCreate.size,
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME),
          key: fileKey,
          categoryId: categoryId,
          foreignKey: entityId,
          foreignTableName: entityType,
          ...(!isNil(readers) && { readers }),
        },
      });
      dispatch({ type: 'ADD_FILE', payload: { file: newFile } });
      return newFile;
    } catch (err) {
      console.error('Error creating file', err);
      return null;
    }
  };

  const copyFile = async (
    fileModelToCopy: FileModel,
    entityType: EntityType,
    entityId: string,
    categoryId: string
  ): Promise<FileModel> => {
    const newFileModel: FileModel = {
      // Watch that we keep the key of the file, which means that entityType
      // in the path might not be the same as entityType here
      ...fileModelToCopy,
      categoryId: categoryId,
      foreignKey: entityId,
      foreignTableName: entityType,
    };
    const newFile = await mutation<FileModel, CreateMutationVariables>(createMutation, {
      input: newFileModel,
    });
    dispatch({ type: 'ADD_FILE', payload: { file: newFile } });

    return newFile;
  };

  const updateFile = async (original: FileModel, updates: Partial<FileModel>): Promise<FileModel> => {
    const result = await mutation<FileModel>(updateMutation, {
      input: { id: original.id, _version: original._version, ...cleanInputUpdate(updates, false) },
    });
    dispatch({ type: 'UPDATE_FILE', payload: { file: result } });
    return result;
  };

  const deleteFile = async (file: FileModel): Promise<FileModel> => {
    await remove({
      key: file.key,
    });

    const result = await deleteAndHideEntityWithFetchBefore<FileModel>(file, getFile, deleteMutation, updateMutation);
    dispatch({ type: 'DELETE_FILE', payload: { id: file.id, entityType: file.foreignTableName as EntityType } });
    return result;
  };

  const fetchFiles = async (entityType: EntityType, entityId?: string): Promise<FileModel[]> => {
    dispatch({ type: 'IS_FETCHING', payload: { entityType } });
    const fetchedFiles = (await fetchFilesWithFilters(clientId!, entityType, entityId, isTenant, isOwner)) ?? [];
    const entityIds = entityId ? [entityId] : [];
    dispatch({
      type: 'FETCHED',
      payload: { files: fetchedFiles, entityType: entityType!, keyIdsFetched: entityIds },
    });
    return fetchedFiles;
  };

  const getFiles = (entityType: EntityType, entityIds?: string[], category?: FileCategory) => {
    dispatch({ type: 'SHOULD_FETCH', payload: { entityType, entityIds } });
    if (state.loading[entityType]) {
      return [];
    }

    let selectedFiles = state.filesCollection[entityType] ?? [];
    if (category) {
      selectedFiles = selectedFiles.filter((file) => file.category?.fileCategory === category);
    }
    if (entityIds) {
      return selectedFiles.filter((file) => entityIds.includes(file.foreignKey));
    }
    return selectedFiles;
  };

  const fetchFilesByCategoryId = async (categoryId: string) =>
    await list<FileModel>(syncFilesWithCategoryLabels, getFilterFieldNameForIndex('byCategory'), categoryId);

  const fetchFileFromId = async (fileId: string) => {
    const file = await get<FileModel>(getFile, fileId);
    return await getS3ObjectUrls([file]);
  };

  const generateDocument = async (input: GenerateDocumentInput): Promise<MutationStatus> => {
    const result = await mutation<MutationStatus, GenerateDocumentMutationVariables>(generateDocumentMutation, {
      input,
    });
    return result;
  };

  const generateCommunicationPdf = async (
    input: GenerateCommunicationPdfInput
  ): Promise<GenerateCommunicationPdfResult> => {
    const result = await mutation<GenerateCommunicationPdfResult, GenerateCommunicationPdfMutationVariables>(
      generateCommunicationPdfMutation,
      {
        input,
      }
    );
    return result;
  };

  const fetchFilesByForeignKeys = async (foreignKeys: string[], foreignTableName: string | EntityType) => {
    const allIdsFilter = foreignKeys.map((key) => ({ foreignKey: { eq: key } }));
    if (isEmpty(allIdsFilter)) {
      return [];
    }
    dispatch({ type: 'IS_FETCHING', payload: { entityType: foreignTableName as EntityType } });
    const filesPromises = foreignKeys.map((foreignKey) =>
      list<FileModel>(
        isTenant ? syncTenantFiles : syncFilesWithCategoryLabels,
        getFilterFieldNameForIndex('byClientId'),
        getTableClientId(clientId!, ENTITY_MODEL_NAME),
        {
          and: [{ foreignTableName: { eq: foreignTableName } }, { foreignKey: { eq: foreignKey } }],
        }
      )
    );

    const fetchedFiles = (await Promise.all(filesPromises)).flat();

    dispatch({
      type: 'FETCHED',
      payload: {
        files: fetchedFiles,
        entityType: foreignTableName as EntityType,
        keyIdsFetched: foreignKeys,
      },
    });

    return fetchedFiles;
  };

  const getLoadingFromState = () =>
    Object.values(state.loading).reduce((acc, current) => acc || current, false) || state.subscriptionsLoading;

  const values = useMemo(
    () => ({
      ...state,
      loading: getLoadingFromState(),
      error: null,
      createFile,
      updateFile,
      deleteFile,
      fetchFileFromId,
      generateDocument,
      generateCommunicationPdf,
      copyFile,
      fetchFiles,
      fetchFilesByCategoryId,
      getFiles,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <FilesContext.Provider value={values}>{children}</FilesContext.Provider>;
};

export const useFiles = (): FilesContext => {
  const context = useContext<FilesContext | null>(FilesContext);

  if (isNil(context)) {
    throw new Error('`useFiles` hook must be used within a `FilesContextProvider` component');
  }

  return context as FilesContext;
};
