import React, { Suspense } from 'react';
import { Route, Redirect, RouteProps, Link, useLocation, useHistory } from 'react-router-dom';
import { navItems, ownerNavItems, RouteDestination } from './Routes';
import { isMenuVisibleWithRight } from './routeUtils';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AccountListItem from '../Auth/AccountListItem';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useTransactions, TransactionContext } from '../../hooks/TransactionsContext';
import { useCommunications, CommunicationContext } from '../../hooks/CommunicationsContext';
import { useTickets, TicketContext } from '../../hooks/TicketsContext';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import { Grid, makeStyles } from '@material-ui/core';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import DashboardLoader from '../Dashboard/DashboardLoader';
import { Colors } from '@rentguru/commons-utils';
import { useContextLoader } from 'src/hooks/ContextLoader';
import { LoadingProgress } from '@up2rent/ui';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
const hideBadge = (
  communicationResults: CommunicationContext,
  transactionResults: TransactionContext,
  ticketResults: TicketContext,
  componentTo: string
) => {
  const splittedUrl = componentTo.split('/');
  const componentName = splittedUrl[splittedUrl.length - 1];
  if (`/${componentName}` === RouteDestination.COMMUNICATIONS) {
    const { communicationsError, communicationsLoading, communicationsToSendOutboxRaw } = communicationResults;
    if (communicationsError || communicationsLoading) {
      return true;
    }
    return isEmpty(communicationsToSendOutboxRaw);
  }
  if (`/${componentName}` === RouteDestination.ACCOUNTING) {
    const { transactionsLoading, transactionsError, transactionsToCheck } = transactionResults;
    if (transactionsError || transactionsLoading || isEmpty(transactionsToCheck)) {
      return true;
    }
    return !(transactionsToCheck.length > 0);
  }
  if (`/${componentName}` === RouteDestination.TICKETS) {
    const { ticketsLoading, ticketsError, ticketsAssignedToMe } = ticketResults;
    if (ticketsError || ticketsLoading || isEmpty(ticketsAssignedToMe)) {
      return true;
    }
    return !(ticketsAssignedToMe.length > 0);
  }
  return true;
};

interface LayoutProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  showMenu?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ component: Component, showMenu }) => {
  const { loading } = useContextLoader();
  const permissions = usePermissions();
  const transactionResults = useTransactions();
  const communicationResults = useCommunications();
  const location = useLocation();
  const history = useHistory();
  const { isOwner } = useUser();
  const ticketResults = useTickets();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const menuItems = isOwner ? ownerNavItems : navItems;

  const handleClick = () => {
    if (!loading) {
      history.push(`${RouteDestination.DASHBOARD}`);
    }
  };

  return (
    <>
      {showMenu ? (
        <Drawer open variant="permanent" style={{ width: 100 }} PaperProps={{ square: true }}>
          <Grid style={{ margin: '15px 20px 0px 15px' }}>
            <LoadingProgress loading={loading} width={65} height={65} handleClick={handleClick} />
          </Grid>
          <List
            component="nav"
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}
          >
            {menuItems.map(({ divider, space, ...route }) => {
              if (divider) {
                return <Divider key="divider" style={{ marginBottom: 30 }} />;
              }
              if (space) {
                return <div key="space" style={{ margin: 10 }} />;
              }
              if (!isNil(route.menu) && !route.menu) {
                return null;
              }
              if (!isOwner && !isMenuVisibleWithRight(route.to as RouteDestination, permissions)) {
                return null;
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const SvgComponent = route.svgicon as any;
              const svgFill =
                location.pathname.includes(route.to as string) ||
                (location.pathname === '/' && route.to === `/dashboard`)
                  ? Colors.CARNATION_RED
                  : Colors.BLUE_GREY;
              return (
                <Tooltip key={route.to} title={formatMessage({ id: route.labelId })} placement="right">
                  <ListItem
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    component={Link as React.ComponentType<any>}
                    to={route.to}
                    button
                    classes={{ root: classes.root, button: classes.root }}
                  >
                    <Badge
                      overlap="rectangular"
                      color="secondary"
                      variant="dot"
                      invisible={hideBadge(communicationResults, transactionResults, ticketResults, route.to as string)}
                    >
                      <SvgComponent style={{ fill: svgFill }} />
                    </Badge>
                  </ListItem>
                </Tooltip>
              );
            })}
            <Tooltip key="notifications" title={formatMessage({ id: 'accountMenu.notifications' })} placement="right">
              <Grid style={{ height: '100%' }} id="notifications">
                <AccountListItem />
              </Grid>
            </Tooltip>
          </List>
        </Drawer>
      ) : null}
      <Suspense
        fallback={
          <Grid style={{ paddingLeft: 220 }}>
            <DashboardLoader height={190} width="calc(100% - 100px)" />
            <DashboardLoader height={430} width="calc(100% - 100px)" />
          </Grid>
        }
      >
        <Component />
      </Suspense>
    </>
  );
};
interface AuthenticatedRouteProps extends RouteProps {
  isAuthenticated?: boolean;
  showMenu?: boolean;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  component,
  showMenu,
  isAuthenticated,
  path,
  exact,
}) => {
  if (!component) {
    return null;
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={() => (isAuthenticated ? <Layout component={component} showMenu={showMenu} /> : <Redirect to="/" />)}
    />
  );
};

export default AuthenticatedRoute;
