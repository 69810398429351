import React from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import { ActionButton , LoaderButton } from '@up2rent/ui';
import { Formik, Form, FormikHelpers } from 'formik';
import { LeaseInventoryType, LeaseStatus, Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import { Collapse, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import UnitSelectorField from '../../../ui/Forms/FormField/UnitSelectorField';
import LeaseSelectorField from 'src/components/ui/Forms/FormField/LeaseSelectorField';
import { useLeases } from '../../../../hooks/LeasesContext';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useHistory } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { CustomItemIdMapping, copyLastUnitInventories } from 'src/utils/unitInventoryUtils';
import { useFiles } from 'src/hooks/FilesContext';
import { AddInventoryProps, InventorySchema, InventoryValues, useStyles } from './AddInventoryForm';
import { RouteDestination } from 'src/components/Routes/Routes';

const AddFurnitureForm: React.FC<AddInventoryProps> = ({ units, leaseInventories, afterSubmit, cancelSubmit }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { getLease, leases } = useLeases();
  const { createFile, deleteFile } = useFiles();
  const {
    createLeaseInventory,
    createLeaseInventoryCheckedItem,
    createLeaseInventoryEncoding,
    updateLeaseInventoryCheckedItem,
    updateLeaseInventoryEncoding,
    deleteLeaseInventoryCheckedItem,
  } = useLeaseInventories();
  const {
    createUnitInventory,
    createUnitInventoryStructure,
    updateUnitInventoryStructure,
    deleteUnitInventoryStructure,
    buildCompleteInventoriesForUnitAndLeaseInventory,
  } = useUnitInventories();

  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) {
      afterSubmit();
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleInventoryCreate = async (
    values: InventoryValues,
    { setSubmitting, setStatus }: FormikHelpers<InventoryValues>
  ) => {
    if (!isNil(values.inventoryType)) {
      const input = {
        status: LeaseStatus.Draft,
        inventoryType: values.inventoryType,
        leaseId: values.leaseId!,
        createdAt: new Date().toISOString(),
      };
      const newLeaseInventoryResult = await createLeaseInventory(input);
      if (values.copyLast && !isEmpty(leaseInventories)) {
        const latestLeaseInventory = leaseInventories.reduce((latest, li) => {
          if (isAfter(new Date(li.createdAt!), new Date(latest.createdAt!))) {
            return li;
          }
          return latest;
        });
        const latestUnitInventories = await buildCompleteInventoriesForUnitAndLeaseInventory(
          values.unit ?? '',
          latestLeaseInventory.id,
          new Date(),
          'furnitures'
        );

        await copyLastUnitInventories(
          newLeaseInventoryResult,
          latestUnitInventories,
          {
            createUnitInventory,
            createUnitInventoryStructure,
            updateUnitInventoryStructure,
            deleteUnitInventoryStructure: (id: string) => deleteUnitInventoryStructure(id),
            createLeaseInventoryEncoding,
            updateLeaseInventoryEncoding,
            createLeaseInventoryCheckedItem,
            updateLeaseInventoryCheckedItem,
            deleteLeaseInventoryCheckedItem,
            createFile,
            deleteFile,
          },
          [] as CustomItemIdMapping[]
        );
      }
      setStatus(true);
      setSubmitting(false);
      history.push({
        pathname: RouteDestination.EDIT_FURNITURE_INVENTORY,
        state: {
          goBackUrl: history.location,
          leaseInventory: newLeaseInventoryResult,
          unitId: values.unit ?? '',
        },
      });
    }

    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit();
  };

  return (
    <Formik
      initialValues={
        {
          inventoryType: LeaseInventoryType.FURNITURES_ENTRANCE,
          copyLast: true,
          unit: units.length === 1 ? units[0].id : '',
          leaseId: '',
        } as InventoryValues
      }
      validationSchema={InventorySchema}
      onSubmit={handleInventoryCreate}
    >
      {({ values, isSubmitting, status, setFieldValue }) => {
        let copyAvailable = false;
        if (!isNil(values.leaseId)) {
          const lease = getLease(values.leaseId);
          if (!isNil(lease)) {
            const inventories = lease.leaseInventories;
            if (!isNil(inventories) && !isEmpty(inventories)) {
              const entranceInventories = inventories.filter(
                (i) => i.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE
              );
              const exitInventories = inventories.filter((i) => i.inventoryType === LeaseInventoryType.FURNITURES_EXIT);
              copyAvailable = !isEmpty(entranceInventories) || !isEmpty(exitInventories);
              if (isEmpty(entranceInventories) && !isEmpty(exitInventories)) {
                values.inventoryType = LeaseInventoryType.FURNITURES_ENTRANCE;
              } else if (isEmpty(exitInventories) && !isEmpty(entranceInventories)) {
                values.inventoryType = LeaseInventoryType.FURNITURES_EXIT;
              } else if (!isEmpty(exitInventories) && !isEmpty(entranceInventories)) {
                values.inventoryType = null;
              }
            }
          }
        }

        const filteredLeases = leases.filter((lease) => {
          return !isNil(lease.units)
            ? lease.units.some(
                (unitLease) => values.unit === unitLease.unit!.id && unitLease.lease?.status === LeaseStatus.Active
              )
            : false;
        });

        return (
          <Form style={{ width: 640, maxWidth: 640 }}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: 30,
              }}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  marginBottom: 10,
                  marginTop: 30,
                  marginLeft: 30,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'rentalUnit.detail.inventory.addFurnitureInventory' })}
              </Typography>
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div style={{ marginRight: 30, marginLeft: 30 }}>
              <UnitSelectorField units={units} fieldName="unit" initialValue={units.length === 1 ? units[0].id : ''} />
              <Collapse in={!isNil(values.unit)}>
                <LeaseSelectorField
                  initialValue={!isNilOrEmpty(filteredLeases) ? filteredLeases[0].id : undefined}
                  leases={filteredLeases}
                  fieldName="leaseId"
                />
              </Collapse>
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div style={{ marginRight: 30, marginLeft: 30 }}>
              <div style={{ backgroundColor: Colors.PORCELAIN_GREY_3, borderRadius: 20, marginBottom: 30 }}>
                <Tabs
                  value={!isNil(values.inventoryType) ? values.inventoryType : false}
                  variant="fullWidth"
                  centered
                  classes={{ indicator: classes.indicatorStyle }}
                  disabled={true}
                >
                  <Tab
                    value={LeaseInventoryType.FURNITURES_ENTRANCE}
                    classes={{
                      root: classes.customTab,
                      selected: classes.customSelectedTab,
                      wrapper:
                        values.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE
                          ? classes.wrapperSelected
                          : classes.wrapperNotSelected,
                    }}
                    label={formatMessage({ id: 'rentalUnit.detail.inventory.entrance' })}
                  />
                  <Tab
                    value={LeaseInventoryType.FURNITURES_EXIT}
                    classes={{
                      root: classes.customTab,
                      selected: classes.customSelectedTab,
                      wrapper:
                        values.inventoryType === LeaseInventoryType.FURNITURES_EXIT
                          ? classes.wrapperSelected
                          : classes.wrapperNotSelected,
                    }}
                    label={formatMessage({ id: 'rentalUnit.detail.inventory.exit' })}
                  />
                </Tabs>
              </div>
              {copyAvailable && (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <CustomCheckBox
                    checkedColor={Colors.DODGER_BLUE}
                    isChecked={values.copyLast}
                    disabled={values.inventoryType === LeaseInventoryType.FURNITURES_EXIT}
                    onCheck={(e, checked) => setFieldValue('copyLast', checked)}
                  />
                  <Typography style={{ marginLeft: 20, fontWeight: 'bold', fontSize: 14 }}>
                    {formatMessage({ id: 'rentalUnit.detail.inventory.copyFurnitureMessage' })}
                  </Typography>
                </div>
              )}
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginBottom: 20,
                marginRight: 30,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  handleCancel();
                }}
                style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
              >
                {toUpper(formatMessage({ id: 'cancel' }))}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddFurnitureForm;
