import React, { useState } from 'react';
import TicketDetailConversations from 'src/components/Tickets/Details/TicketDetailConversations';
import TicketDetailAttachments from 'src/components/Tickets/Details/TicketDetailAttachments';
import TicketDetailTabHeader from 'src/components/Tickets/Details/TicketDetailTabHeader';
import { useRouteMatch } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import { CustomizedTabs, ElevatedPaperTable } from '@up2rent/ui';
import { useFiles } from 'src/hooks/FilesContext';
import { useIntl } from 'react-intl';
import { useTenants } from 'src/tenantHooks/TenantContext';
import { Typography } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { EntityType, Ticket, Unit } from '@rentguru/commons-utils';
import { Skeleton } from '@material-ui/lab';
import ConditionalParentElement from 'src/components/RentalUnits/Details/Publication/EditPublication/ConditionalParentElement';

interface TicketDetailTenantProps {
  leaseId?: string;
  onCloseDialog?: () => void;
}

const TicketDetailTenant: React.FC<TicketDetailTenantProps> = ({ leaseId, onCloseDialog }) => {
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ id: string }>();

  const { getLease } = useTenants();
  const completeLease = getLease(leaseId ?? '');
  const mainUnit = (completeLease?.units ?? []).find((unit) => unit.mainUnit)?.unit;

  const [tabIndex, setTabIndex] = useState<number>(0);
  const { getTicket, createTicket, tenant, tenantsLoading, tenantsError } = useTenants();
  const { createFile, loading, getFiles } = useFiles();
  const ticket: Ticket | undefined = getTicket(match.params.id);
  const files = ticket?.id ? getFiles(EntityType.TICKET, [ticket.id]) : [];

  if (tenantsLoading || isNil(tenant)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton />
      </div>
    );
  }
  if (tenantsError) {
    return <Typography>{tenantsError}</Typography>;
  }
  const handleChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <div style={{ marginRight: 'auto', marginLeft: 'auto', maxWidth: 640 }}>
      <ConditionalParentElement
        parentElement={!isNil(ticket)}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(children: React.ReactNode) => <ElevatedPaperTable>{children}</ElevatedPaperTable>}
      >
        {!isNil(ticket) && (
          <>
            <TicketDetailTabHeader ticket={ticket} deletable={false} />
            <CustomizedTabs handleChange={handleChange} value={tabIndex} tabStyle={{ zIndex: 1 }}>
              <Tab
                label={formatMessage({
                  id: 'tickets.detail.conversation',
                })}
              />
              <Tab
                label={formatMessage({
                  id: 'tickets.detail.attachments',
                })}
              />
            </CustomizedTabs>
          </>
        )}
      </ConditionalParentElement>
      {tabIndex === 0 && (
        <TicketDetailConversations
          ticket={ticket}
          fileArray={files}
          loading={loading}
          createFile={createFile}
          createTicket={(input) =>
            createTicket(
              {
                ...input,
                unitId: mainUnit?.id,
                buildingId: (mainUnit as Unit & { buildingId?: string })?.buildingId ?? '',
              },
              true
            )
          }
          currentUser={tenant}
          isTenant
          leaseId={leaseId}
          onCloseDialog={onCloseDialog}
        />
      )}
      {tabIndex === 1 && <TicketDetailAttachments ticket={ticket} fileArray={files} />}
    </div>
  );
};

export default TicketDetailTenant;
