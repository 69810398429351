/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// eslint-disable-next-line import/named
import { Colors, LooseObject } from '@rentguru/commons-utils';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React from 'react';
import { ButtonBase, Grid } from '@material-ui/core';
import { ActionButtonSecond } from '../buttons';
import { CustomTableCell } from './CustomTableCell';
import { StyledCheckbox } from '../checkboxes';
import { SortLabel } from './SortLabel';
import { TablePlaceHolder } from './TablePlaceHolder';
import {
  EnhanceTableColumn,
  EnhanceTableRowLooseData,
  EnhanceTableRowProps,
  EnhanceTableShareContextValues,
} from './EnhancedTable';
import { EnhancedTableSharedContextConsumer } from './EnhancedTableSharedContext';
import { SELECTED_COMPONENT_Z_INDEX } from '../../utils';
import { type IntlFormatters } from 'react-intl';
import CollapsingChevron from 'src/components/ui/CollapsingChevron';

export interface SelectedComponentProps {
  selectedItems: string[];
  handleUnselect: (ids: string[]) => void;
  [key: string]: any;
}

//@ts-ignore: wip
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface EnhancedTableSortedProps<T extends EnhanceTableRowProps> {
  noMinWidth?: boolean;
  datas: EnhanceTableRowLooseData[];
  columns: EnhanceTableColumn[];
  rowComponent: any; // à changer !!!!!!!!!!!!
  rowCount: number;
  defaultOrderBy: string;
  menuShown?: boolean;
  placeHolder?: React.ReactNode;
  filtersSelected?: boolean;
  resetFiltersFunction?: () => void;
  SelectedComponent?: React.ComponentType<SelectedComponentProps>;
  selectedComponentProps?: LooseObject;
  InfoIcon?: React.ReactNode;
  NoResultIcon: React.ReactNode;
  formatMessage: IntlFormatters['formatMessage'];
  displayChevron?: boolean;
  setChevronOpen?: (open: boolean) => void;
  isChevronOpen?: boolean;
}

export const EnhancedTableSorted = <T extends EnhanceTableRowProps = { isSelected: boolean }>({
  // piste 1
  noMinWidth = false,
  datas,
  columns,
  rowComponent: RowComponent,
  defaultOrderBy,
  placeHolder = null,
  filtersSelected = false,
  menuShown = true,
  rowCount,
  resetFiltersFunction = () => {},
  SelectedComponent,
  selectedComponentProps = {},
  InfoIcon,
  NoResultIcon,
  formatMessage,
  displayChevron = false,
  setChevronOpen,
  isChevronOpen = false,
}: EnhancedTableSortedProps<T>) => {
  return (
    <EnhancedTableSharedContextConsumer>
      {({
        handleClick,
        createSortHandler,
        order,
        orderBy,
        orderBySortField,
        numSelected,
        handleSelectAllClick,
        handleUnselect,
        selected,
        handleDetail,
        handleActionMenu,
      }: EnhanceTableShareContextValues) => {
        let orderByColumn = isEmpty(orderBy) ? defaultOrderBy : orderBy;
        orderByColumn = !isNil(orderBySortField) ? orderBySortField : orderByColumn;
        return (
          <>
            <Table style={{ minWidth: noMinWidth ? 0 : 1020, overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  {displayChevron && (
                    <CustomTableCell
                      key={'chevron'}
                      padding="checkbox"
                      style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}
                    >
                      <CollapsingChevron isOpen={isChevronOpen} setOpen={setChevronOpen} />
                    </CustomTableCell>
                  )}
                  {columns.map((column) => {
                    if (!isNil(column.checkbox) && column.checkbox) {
                      return (
                        <CustomTableCell
                          padding="checkbox"
                          key={column.id}
                          {...(column.style && { style: column.style })}
                        >
                          <StyledCheckbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount && rowCount !== 0}
                            onChange={handleSelectAllClick}
                          />
                        </CustomTableCell>
                      );
                    }
                    if (!isNil(column.empty) && column.empty) {
                      return (
                        <CustomTableCell
                          key={column.id}
                          padding="checkbox"
                          {...(column.style && { style: column.style })}
                        />
                      );
                    }
                    if (!isNil(column.sortable) && column.sortable) {
                      return (
                        <CustomTableCell
                          key={column.id}
                          align={
                            column.numeric
                              ? 'right'
                              : column.labelId === 'communications.list.communicationChannel'
                                ? 'center'
                                : 'inherit'
                          }
                          padding={column.disablePadding ? 'none' : 'normal'}
                          {...(column.style && { style: column.style })}
                        >
                          <Tooltip
                            title={formatMessage({ id: 'sort' })}
                            placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                          >
                            <SortLabel
                              active={
                                (isNil(orderBySortField) && orderByColumn === column.id) ||
                                (!isNil(orderBySortField) && orderByColumn === column.sortField)
                              }
                              direction={order}
                              onClick={() => {
                                createSortHandler(column);
                              }}
                            >
                              {formatMessage({ id: column.labelId })}
                            </SortLabel>
                          </Tooltip>
                        </CustomTableCell>
                      );
                    }
                    if (column.infoTextMessageId && InfoIcon) {
                      return (
                        <CustomTableCell
                          key={column.id}
                          align={column.numeric ? 'right' : 'inherit'}
                          padding={column.disablePadding ? 'none' : 'normal'}
                          {...(column.style && { style: column.style })}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isNil(column.labelId) || isEmpty(column.labelId) ? (
                              ''
                            ) : (
                              <Typography style={{ color: Colors.BLUE_GREY, marginRight: 10 }}>
                                {formatMessage({ id: column.labelId })}
                              </Typography>
                            )}
                            <Tooltip
                              title={formatMessage({ id: column.infoTextMessageId })}
                              placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                              enterDelay={300}
                            >
                              <span>{InfoIcon}</span>
                            </Tooltip>
                          </div>
                        </CustomTableCell>
                      );
                    }
                    return (
                      <CustomTableCell
                        key={column.id}
                        align={
                          column.numeric
                            ? 'right'
                            : ['communications.list.actions', 'lease.addLease.currentStatus'].includes(column.labelId)
                              ? 'center'
                              : 'inherit'
                        }
                        padding={column.disablePadding ? 'none' : 'normal'}
                        {...(column.style && { style: column.style })}
                      >
                        {isNil(column.labelId) || isEmpty(column.labelId) ? (
                          ''
                        ) : (
                          <ButtonBase
                            style={{
                              color: Colors.BLUE_GREY,
                              cursor: 'text',
                            }}
                            component="span"
                            disableRipple
                          >
                            {formatMessage({ id: column.labelId })}
                          </ButtonBase>
                        )}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((data: EnhanceTableRowLooseData) => {
                  const isSelected = (selected as string[]).indexOf(data.id) !== -1;
                  return (
                    <RowComponent // piste 2
                      key={data.id}
                      isSelected={isSelected}
                      {...data}
                      handleSelect={handleClick}
                      handleUnselect={handleUnselect}
                      handleDetail={handleDetail}
                      actionMenu={handleActionMenu}
                      formatMessage={formatMessage}
                      selectable={columns.some((column) => column.checkbox)}
                    />
                  );
                })}
                {!datas ||
                  (datas.length === 0 && (
                    <TableRow style={{ height: 50 }}>
                      <CustomTableCell colSpan={columns.length}>
                        {filtersSelected ? (
                          <TablePlaceHolder
                            Icon={NoResultIcon}
                            mainText={formatMessage({ id: 'noResult' })}
                            subText=""
                            actionButton={
                              <ActionButtonSecond onClick={resetFiltersFunction}>
                                {formatMessage({ id: 'reinitFilterLong' })}
                              </ActionButtonSecond>
                            }
                          />
                        ) : (
                          placeHolder
                        )}
                      </CustomTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {SelectedComponent && selected.length > 0 && (
              <Grid
                style={{
                  position: 'fixed',
                  bottom: 0,
                  width: menuShown ? 'calc(100% - 100px)' : '100%',
                  height: 50,
                  backgroundColor: Colors.DODGER_BLUE,
                  zIndex: SELECTED_COMPONENT_Z_INDEX,
                  left: menuShown ? 100 : 0,
                }}
              >
                <SelectedComponent
                  selectedItems={selected}
                  handleUnselect={handleUnselect!}
                  {...selectedComponentProps}
                />
              </Grid>
            )}
          </>
        );
      }}
    </EnhancedTableSharedContextConsumer>
  );
};
