import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { ConfirmDialog } from '@up2rent/ui';
import { DeepDeleteLeaseBundle, LeaseContext, LeaseExtended } from 'src/hooks/LeasesContext';
import { LeaseStatus, LeaseVariousOperationType, Unit, S3Object } from '@rentguru/commons-utils';
import { LeaseAction } from './useLeaseActionsMenuUtils';
import PreviewFileDialogs from '../../../ui/PreviewFileDialog';
import AddLeaseVariousOperationDialog from 'src/components/ui/Dialogs/AddLeaseVariousOperationDialog';
import AddCreditNoteDialog from 'src/components/ui/Dialogs/AddCreditNoteDialog';
import EditBankAccountDialog from 'src/components/ui/Dialogs/EditBankAccountDialog';
import ChangeProfessionalActivityDialog from 'src/components/ui/ChangeProfessionalActivityDialog';
import AddInventoryDialog from 'src/components/Technics/AddInventoryDialog';
import useOwnerLeaseInventoryUtils from 'src/hooks/useLeaseInventoryUtils';
import { isNil } from 'lodash';
import ExtendLeaseDialog from 'src/components/ui/Dialogs/ExtendLeaseDialog';
import EndLeaseDialog from 'src/components/ui/Dialogs/EndLeaseDialog';
import AddCustomInvoiceDialog from 'src/components/ui/Dialogs/AddCustomInvoiceDialog/AddCustomInvoiceDialog';

interface LeaseActionMenuDialogsProps {
  lease: LeaseExtended;
  mainUnit?: Unit;
  open: boolean;
  type: LeaseAction | null;
  onCloseDialog: () => void;
  leaseContract: S3Object | null | undefined;
  deleteFunctions?: DeepDeleteLeaseBundle;
  deepDeleteLease?: LeaseContext['deepDeleteLease'];
  updateLease?: LeaseContext['updateLease'];
}

const getLeaseVariousOperationOfType = (type: LeaseAction) => {
  if (type === LeaseAction.ADD_DISCOUNT) {
    return LeaseVariousOperationType.DISCOUNT;
  }
  if (type === LeaseAction.ADD_CHARGE) {
    return LeaseVariousOperationType.CHARGE;
  }
  return LeaseVariousOperationType.SERVICE;
};

const IntermediateLeaseActionMenuDialogs: React.FC<LeaseActionMenuDialogsProps> = ({
  lease,
  mainUnit,
  open,
  type,
  onCloseDialog,
  leaseContract,
  deepDeleteLease,
  updateLease,
  deleteFunctions,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const { hasBothFixtureEntranceAndExitInventory, hasBothFurnitureEntranceAndExitInventory } =
    useOwnerLeaseInventoryUtils(lease.id);
  if (!open) {
    return null;
  }

  const isVariousOperationDialog =
    type === LeaseAction.ADD_SERVICE || type === LeaseAction.ADD_CHARGE || type === LeaseAction.ADD_DISCOUNT;

  return (
    <>
      {type === LeaseAction.DELETE && (
        <ConfirmDialog
          open={type === LeaseAction.DELETE}
          confirmText={formatMessage({ id: 'lease.detail.action.confirmDelete' })}
          confirmAction={async () => {
            if (isNil(deepDeleteLease) || isNil(updateLease) || isNil(deleteFunctions)) {
              return;
            }
            await deepDeleteLease(lease, deleteFunctions);
            history.push({ pathname: RouteDestination.LEASES, state: { goBackUrl: '/' } });
            onCloseDialog();
          }}
          cancelAction={onCloseDialog}
          mainText={formatMessage({ id: 'lease.detail.action.deleteWarning' })}
          formatMessage={formatMessage}
        />
      )}
      {type === LeaseAction.RETRACT && (
        <ConfirmDialog
          open={type === LeaseAction.RETRACT}
          confirmText={formatMessage({ id: 'lease.detail.action.revokeConfirm' })}
          confirmAction={async () => {
            if (isNil(updateLease)) return;
            await updateLease(lease, { status: LeaseStatus.Cancelled });
            onCloseDialog();
          }}
          cancelAction={onCloseDialog}
          mainText={formatMessage({ id: 'lease.detail.action.revokeWarning' })}
          formatMessage={formatMessage}
        />
      )}
      {leaseContract && type === LeaseAction.VIEW_PDF && (
        <PreviewFileDialogs
          open={type === LeaseAction.VIEW_PDF}
          file={leaseContract}
          onClose={onCloseDialog}
          withSignatures={lease.status === LeaseStatus.Active}
        />
      )}
      {isVariousOperationDialog && (
        <AddLeaseVariousOperationDialog
          open={isVariousOperationDialog}
          onClose={onCloseDialog}
          lease={lease}
          operationType={getLeaseVariousOperationOfType(type!)}
        />
      )}
      {type === LeaseAction.ADD_CUSTOM_INVOICE && <AddCustomInvoiceDialog open onClose={onCloseDialog} lease={lease} />}
      {type === LeaseAction.ADD_CREDIT_NOTE && (
        <AddCreditNoteDialog open={type === LeaseAction.ADD_CREDIT_NOTE} onClose={onCloseDialog} lease={lease} />
      )}
      {type === LeaseAction.CHANGE_BANK && (
        <EditBankAccountDialog open={type === LeaseAction.CHANGE_BANK} onClose={onCloseDialog} lease={lease} />
      )}
      {type === LeaseAction.CHANGE_PROFESSIONAL_ACTIVITY && (
        <ChangeProfessionalActivityDialog
          open={type === LeaseAction.CHANGE_PROFESSIONAL_ACTIVITY}
          lease={lease}
          onClose={onCloseDialog}
        />
      )}
      {type === LeaseAction.ADD_FURNITURE_INVENTORY && !hasBothFurnitureEntranceAndExitInventory && (
        <AddInventoryDialog
          open={type === LeaseAction.ADD_FURNITURE_INVENTORY}
          onClose={onCloseDialog}
          id={lease.id}
          inventoryType="FURNITURE"
        />
      )}
      {type === LeaseAction.ADD_INVENTORY_OF_FIXTURE && !hasBothFixtureEntranceAndExitInventory && (
        <AddInventoryDialog
          open={type === LeaseAction.ADD_INVENTORY_OF_FIXTURE}
          onClose={onCloseDialog}
          id={lease.id}
          inventoryType="FIXTURE"
        />
      )}
      {type === LeaseAction.ADD_TICKET && (
        <Redirect
          to={{
            pathname: RouteDestination.ADD_TICKET,
            state: {
              unitId: mainUnit?.id,
              buildingId: mainUnit?.building?.id,
              goBackUrl: pathname,
            },
          }}
        />
      )}
      {type === LeaseAction.EXTEND && (
        <ExtendLeaseDialog open={type === LeaseAction.EXTEND} onClose={onCloseDialog} lease={lease} />
      )}
      {type === LeaseAction.END && (
        <EndLeaseDialog open={type === LeaseAction.END} onClose={onCloseDialog} lease={lease} />
      )}
    </>
  );
};

export default IntermediateLeaseActionMenuDialogs;
