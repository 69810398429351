import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { useUser } from 'src/hooks/UserContext';
import {
  CustomSubscription,
  OnCreateCustomSubscriptionSubscription,
  OnDeleteCustomSubscriptionSubscription,
  OnUpdateCustomSubscriptionSubscription,
  onCreateCustomSubscription,
  onDeleteCustomSubscription,
  onUpdateCustomSubscription,
  CustomSubscriptionType,
} from '@rentguru/commons-utils';
import ResetIcon from '../ui/ResetIcon';

import { useSubscriptions } from '@up2rent/fetch-utils';

const useCustomSubscriptions = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { refreshToken } = useUser();

  useSubscriptions<
    OnCreateCustomSubscriptionSubscription,
    OnUpdateCustomSubscriptionSubscription,
    OnDeleteCustomSubscriptionSubscription
  >(
    onCreateCustomSubscription,
    onUpdateCustomSubscription,
    onDeleteCustomSubscription,
    (data) => {
      const newData = data.onCreateCustomSubscription as CustomSubscription;
      if (newData.name === CustomSubscriptionType.COMMUNICATION) {
        enqueueSnackbar(formatMessage({ id: 'batchCommunicationsRefresh' }), {
          persist: true,
          variant: 'warning',
          preventDuplicate: true,
          key: 'Refresh Token',
          action: (
            <ResetIcon
              onClick={async () => {
                await refreshToken();
                closeSnackbar('Refresh Token');
                window.location.reload();
              }}
              style={{ cursor: 'pointer' }}
            />
          ),
        });
      }
    },
    () => {},
    () => {}
  );

  return null;
};

export default useCustomSubscriptions;
