import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isAccountingIntegrationActive } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { EndLeaseFormValues, iconnedRemarkMargins, useEndLeaseStyles } from './EndLeaseForm';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import { ReactComponent as InfoSvg } from '../../../../icons/info.svg';

const EndLeaseDeleteFields = () => {
  const { formatMessage } = useIntl();
  const { integrations, loading } = useIntegrations();
  const { values, setFieldValue } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const hasAccountingIntegration = isAccountingIntegrationActive(integrations);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.deleteLease` })}
      </Typography>
      <Grid>
        <FormControlLabel
          control={
            <CustomCheckBox
              onCheck={(event) => {
                setFieldValue(`confirmDelete`, event.target.checked);
              }}
              isChecked={values.confirmDelete}
              secondary
            />
          }
          label={
            <Typography style={{ fontSize: 15, fontWeight: 500 }}>
              {formatMessage({ id: 'lease.endExtendLease.confirmDelete' })}
            </Typography>
          }
        />
      </Grid>
      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.deleteReason` })}
      </Typography>
      <TextFieldArea
        name="comment"
        rows={3}
        rowsMax={3}
        placeholder={formatMessage({
          id: `lease.endExtendLease.deleteReason`,
        })}
        inputProps={{ style: { textAlign: 'left', bottom: 0, padding: 20 } }}
        className={classes.textField}
        value={values.comment}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      />

      {hasAccountingIntegration && (
        <IconnedRemark
          Icon={InfoSvg}
          noFixWidth
          style={iconnedRemarkMargins}
          message={
            <div>
              <Typography className={classes.infoMessageText}>
                {formatMessage({ id: 'lease.endExtendLease.warningAccountingIntegrationTitle' })}
              </Typography>
              <Typography className={classes.infoMessageText}>
                {formatMessage({ id: 'lease.endExtendLease.warningAccountingIntegration1' })}
              </Typography>
              <Typography className={classes.infoMessageText}>
                {formatMessage({ id: 'lease.endExtendLease.warningAccountingIntegration2' })}
              </Typography>
            </div>
          }
        />
      )}
    </>
  );
};

export default EndLeaseDeleteFields;
