import { Skeleton } from '@material-ui/lab';
import React, { CSSProperties } from 'react';

interface SkeletonComponentProps {
  loading: boolean;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const SkeletonComponent: React.FC<SkeletonComponentProps> = ({ loading, children, style = {} }) => {
  if (loading) return <Skeleton style={style}>{children}</Skeleton>;
  return <>{children} </>;
};

export default SkeletonComponent;
